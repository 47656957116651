import React from "react";
import { Table } from "semantic-ui-react";

import ViewType from "../../enums/ViewType";

import ItemsView from "./ItemsView";
import { RolePermissions, SortingDirection } from "../../enums";
import { FiltersMap, GenericFiltersMap } from "../../utils/filterUtils";
import { ColumnOption } from "../../interfaces/columnOptions";
import SortDirections from "../../enums/sortDirections";
import SortingOptions from "../../interfaces/SortingOptions";
import { ItemsTypes } from "../../enums/itemsTypes";
import { OnSelectionChanged } from "../../interfaces/onSelectionChanged";
import { CardsViewerItem } from "../../components/cardsViewer/types";
import cn from "classnames";

import styles from "./genericItemsView.module.scss";

export type GenericItemsViewProps<T> = {
  items: T[];
  itemsType?: ItemsTypes;
  isLoading: boolean;
  columnOptions?: ColumnOption<T>[];

  searchPlaceholder?: string;
  dataCount?: number;
  error?: string;
  selectedIds?: number[];
  filterOptions?: GenericFiltersMap<any>;
  appliedFilter?: FiltersMap;
  defaultSortingColumnName?: string;
  noResultsContent?: React.ReactElement;
  viewType?: ViewType;
  sortingDirection?: SortingDirection;
  customHeaderContent?: React.ReactElement;
  listViewRtnEvents?: string[];
  filterOptionsLoading?: boolean;
  sortOptions?: SortingOptions[];
  dragAndDrop?: React.ReactElement;
  orderBy?: string;
  blur?: boolean;
  className?: string;
  disableListViewButton?: boolean;
  doNotLoadPersistentViewType?: boolean;
  isSelectDisabled?: (item: T) => boolean;
  onSelectedItemChanged?: (selectedIds: number[]) => void;
  onSelectedGridItemsChanged?: (selectedIds: number[]) => void;
  onViewTypeChange?: (viewType: ViewType) => void;
  getFilterOptions?: () => void;
  getFilterForm?: () => React.ReactNode;
  resetFilter?: () => void;
  applyFilter?: (filter: FiltersMap) => void;
  fetchData?: (
    skip: number,
    top: number,
    sortingColumnName?: string,
    sortingDirection?: SortingDirection,
    appliedFilter?: FiltersMap,
  ) => void;
  renderSearch?: (accessRestricted: boolean) => React.ReactElement;
  permissions?: RolePermissions[];
  setPagination?: (page: number) => void;
  pagination?: number;
  setSortingColumnName?: (name: string) => void;
  setSortingDirection?: (direction: SortingDirection) => void;
  accessRestricted?: boolean;

  onLoad?: (
    skip: number,
    top: number,
    sortingColumnName: string,
    sortingDirection: SortingDirection,
    appliedFilter: FiltersMap,
  ) => void;
  tabAlias?: string;
  isAllDataLoaded?: boolean;
  onItemsPerPageAmountChange?: (itemsPerPage: number) => void;
  onSortChange?: (_ev: any, data: { value: any }) => void;
  setReloadListItems?: (reloadListItems: (enableSorting: boolean) => void) => void;
  onSelectionChanged?: OnSelectionChanged<T>;
  renderCard?: (props: CardsViewerItem<any>) => React.ReactElement;
  hideListGridViewSwitcherButton?: boolean;
};

export const GenericItemsView = <T,>(props: GenericItemsViewProps<T>) => {
  const {
    items,
    itemsType,
    isLoading,
    dataCount,
    filterOptions,
    getFilterOptions,
    appliedFilter,
    applyFilter,
    resetFilter,
    selectedIds,
    onSelectedItemChanged,
    fetchData,
    columnOptions,
    getFilterForm,
    defaultSortingColumnName,
    isSelectDisabled,
    searchPlaceholder,
    noResultsContent,
    viewType,
    sortingDirection,
    setSortingDirection,
    setSortingColumnName,
    listViewRtnEvents,
    renderSearch,
    customHeaderContent,
    filterOptionsLoading,
    permissions = [],
    setPagination,
    pagination,
    accessRestricted,
    onSelectedGridItemsChanged,
    onViewTypeChange,
    onLoad,
    tabAlias,
    isAllDataLoaded,
    onItemsPerPageAmountChange,
    blur,
    onSortChange,
    setReloadListItems,
    sortOptions,
    dragAndDrop,
    orderBy,
    onSelectionChanged,
    renderCard,
    className,
    disableListViewButton,
    doNotLoadPersistentViewType,
    hideListGridViewSwitcherButton,
  } = props;

  const buildTableBody = (listItem: T, isChecked?: boolean) => (
    <>
      {columnOptions?.map(
        (item, index) =>
          !item.isHide && (
            <Table.Cell
              className={cn(styles[item.className || ""] || item.className)}
              width={item.width}
              key={index}
              disabled={item.disabled?.(listItem)}
            >
              {item.render(listItem, isChecked)}
            </Table.Cell>
          ),
      )}
    </>
  );

  return (
    <ItemsView
      className={className}
      viewType={viewType || ViewType.LIST}
      columnOptions={columnOptions}
      sortingDirection={sortingDirection || SortDirections.Desc}
      searchPlaceholder={searchPlaceholder}
      buildTableBody={buildTableBody}
      getData={fetchData}
      itemsInlineCount={dataCount}
      isLoading={isLoading}
      items={items}
      itemsType={itemsType}
      onSelectedListItemsChanged={onSelectedItemChanged}
      onSelectedItemsChanged={onSelectedGridItemsChanged}
      renderFilterForm={getFilterForm}
      filterOptions={filterOptions}
      filterOptionsLoading={filterOptionsLoading}
      applyFilter={applyFilter}
      appliedFilter={appliedFilter}
      resetFilter={resetFilter}
      getFilterOptions={getFilterOptions}
      selectedIds={selectedIds}
      sortingColumnName={defaultSortingColumnName}
      setSortingColumnName={setSortingColumnName}
      setSortingDirection={setSortingDirection}
      isSelectDisabled={isSelectDisabled}
      noResultsContent={noResultsContent}
      listViewRtnEvents={listViewRtnEvents}
      renderSearch={renderSearch}
      customHeaderContent={customHeaderContent}
      permissions={permissions}
      setPagination={setPagination}
      pagination={pagination}
      accessRestricted={accessRestricted}
      onViewTypeChange={onViewTypeChange}
      onLoad={onLoad}
      tabAlias={tabAlias}
      isAllDataLoaded={isAllDataLoaded}
      itemsPerPageAmountChange={onItemsPerPageAmountChange}
      blur={blur}
      onSortChange={onSortChange}
      setReloadListItems={setReloadListItems}
      sortOptions={sortOptions}
      dragAndDrop={dragAndDrop}
      orderBy={orderBy}
      onSelectionChanged={onSelectionChanged}
      renderCard={renderCard}
      disableListViewButton={disableListViewButton}
      doNotLoadPersistentViewType={doNotLoadPersistentViewType}
      hideListGridViewSwitcherButton={hideListGridViewSwitcherButton}
    />
  );
};

export default GenericItemsView;
