import { Icon } from "semantic-ui-react";
import "./clearInputButton.scss";

export interface ClearInputButtonProps {
  onClick(): void;
  disabled?: boolean;
}

export const ClearInputButton: React.FC<ClearInputButtonProps> = ({ onClick, disabled }) => (
  <Icon className="clear-input-button times" link onClick={onClick} disabled={disabled} />
);
