import { Dispatcher } from "interfaces";
import { createDispatcher } from "../dispatcher";
import * as events from "../events/people/peopleEvents";
import { IMapping } from "./types";
import {
  updateFailedTaskDistributedOperation,
  updateProgressTaskDistributedOperation,
  updateSucceededTaskDistributedOperation,
} from "../../../../BackgroundTasks/state/backgroundTasksActions";
/* istanbul ignore next */
export const templateMapping = (mapping: IMapping, dispatcher: Dispatcher) => {
  const { dispatchTaskOperationSuccess } = createDispatcher(dispatcher);
  const payloadMapping = (data: { MessageId: string }) => ({
    messageId: data.MessageId,
  });
  mapping[events.ApproveAccountTemplateSuccess] = dispatchTaskOperationSuccess(payloadMapping);
  mapping[events.DeclineAccountTemplateSuccess] = dispatchTaskOperationSuccess(payloadMapping);
  mapping[events.DeleteTemplateGroupSuccess] = dispatchTaskOperationSuccess(payloadMapping);
  mapping[events.AccountRemovedFromTemplateGroupSuccess] = dispatchTaskOperationSuccess(payloadMapping);

  mapping[events.AddTemplateGroupPackAccountSuccess] = (payload) =>
    dispatcher(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[events.AddTemplateGroupPacksAccountMessageSuccess] = (payload) =>
    dispatcher(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[events.AddTemplateGroupPackAccountFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[events.AddTemplateGroupPacksAccountMessageFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));

  mapping[events.RemovePackFromTemplateGroupSuccess] = (payload) =>
    dispatcher(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[events.RemoveAllPackFromTemplateGroupMessageSuccess] = (payload) =>
    dispatcher(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[events.RemovePackFromTemplateGroupFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[events.RemoveAllPackFromTemplateGroupMessageFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));

  mapping[events.UpdateTemplateGroupAccountPackSuccess] = (payload) =>
    dispatcher(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[events.UpdateAllTemplateGroupAccountPackMessageSuccess] = (payload) =>
    dispatcher(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[events.UpdateTemplateGroupAccountPackFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[events.UpdateAllTemplateGroupAccountPackMessageFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));

  mapping[events.UpdateTemplateGroupPackAccountsSuccess] = (payload) =>
    dispatcher(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[events.UpdateAllTemplateGroupPackAccountsMessageSuccess] = (payload) =>
    dispatcher(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[events.UpdateTemplateGroupPackAccountsFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[events.UpdateAllTemplateGroupPackAccountsMessageFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));
};
