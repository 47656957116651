import PacksList from "../../../../../components/packs/packsList/PacksList";
import NoResults from "../../../../../components/noResults/NoResults";
import { getPeoplePacksGridSortingOptions } from "../../../Users/EditUser/UserPacks/userPackGridSortingOptions";
import { GroupPacksRequest } from "../../../../Licensing/Packs/types/requests";
import { Filters } from "../../../../../utils/queryUtils";
import UserPacksUtils from "../../../../../utils/userPacksUtils";
import { GroupPacksState } from "../../../../Licensing/Packs/state/slices/groupPacksGridSlice";
import { GroupPacksGridFetchType } from "../../../../Licensing/Packs/state/thunks/groupPacksGridThunk";
import { SortingDirection, ViewType } from "../../../../../enums";
import { PackOverview } from "../../../../Licensing/Packs/types/state";
import GroupPackListRow, { getColumnOptions } from "../../../../../components/packs/packsList/GroupPackListRow";
import {
  AddAllPackGroupSuccess,
  AddAllGroupLicenseMessageSuccess,
  RemoveAllGroupLicenseMessageSuccess,
} from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { useNavigate } from "react-router-dom";
import { sendTransientNotification } from "features/Notifications/state/notificationsActions";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import { issueLicensePermissionPredicate } from "../issueLicensePermissionPredicate";

export interface GroupPacksActions {
  fetchPacksGrid: (requestData: GroupPacksRequest) => GroupPacksGridFetchType;
  resetPacksGrid: () => void;
}

export interface GroupPacksProps {
  groupId: number;
  accountId: number;
  packsActions: GroupPacksActions;
  packsGrid: GroupPacksState;
  issueLicenseBtn: React.ReactElement;
  sendTransientNotification: (
    payload: Parameters<typeof sendTransientNotification>[0],
    dismissInSeconds: number,
  ) => void;
  onRemoveLicenseClick: (id: number) => void;
  isReadOnly: boolean;
  selectedPackIds: number[];
  onSelectedPacksChanged: (ids: number[]) => void;
}

const rtnEvents = [AddAllPackGroupSuccess, AddAllGroupLicenseMessageSuccess, RemoveAllGroupLicenseMessageSuccess];

export const GroupPacks = (props: GroupPacksProps) => {
  const licenseRemovalEnabled = !!useFeatureFlag(FeatureFlags.LicenseRemoval);
  const navigate = useNavigate();
  const columnOptions = getColumnOptions(licenseRemovalEnabled);

  const renderNoResults = () => (
    <NoResults
      title="This group does not have any pack licenses."
      description={
        <span>
          Looks like there are no pack licenses for this group yet. Issue a license now using the button below.
        </span>
      }
      iconClassName="fa-box-full"
    >
      {props.issueLicenseBtn}
    </NoResults>
  );

  const handleTitleClick = (id: number) => {
    navigate(`/licensing/packs/purchased/${id}`);
  };

  const loadPacksGrid = (
    skip: number,
    sortBy: string,
    sortDirection: SortingDirection,
    appliedFilter: Filters,
    searchTerm?: string,
    top?: number,
  ) => {
    const params = {
      groupId: props.groupId,
      accountId: props.accountId,
      skip: skip,
      top: top,
      filters: appliedFilter,
      searchTerm: searchTerm,
    };

    const requestData: GroupPacksRequest = {
      ...params,
      sortBy: UserPacksUtils.mapSortBy(sortBy),
      sortOrder: sortDirection,
    };

    return props.packsActions.fetchPacksGrid(requestData);
  };

  const getListRow = (pack: PackOverview) => {
    const isSelected = props.selectedPackIds.some((x) => x === pack.id);

    return (
      <GroupPackListRow
        pack={pack}
        onTitleClick={handleTitleClick}
        onRemoveLicenseClick={props.onRemoveLicenseClick}
        licenseRemovalEnabled={licenseRemovalEnabled}
        isSelected={isSelected}
      />
    );
  };

  return (
    <section className="scrollable-content">
      <PacksList
        viewType={ViewType.LIST}
        columnOptions={columnOptions}
        getListRow={getListRow}
        gridItems={props.packsGrid}
        loadGridItems={loadPacksGrid}
        resetGridItems={props.packsActions.resetPacksGrid}
        gridSortingOptions={getPeoplePacksGridSortingOptions()}
        renderNoResults={renderNoResults}
        refreshListEvents={rtnEvents}
        withSelection={licenseRemovalEnabled}
        isReadOnly={props.isReadOnly}
        selectedPackIds={props.selectedPackIds}
        onSelectedPacksChanged={props.onSelectedPacksChanged}
        packCardProps={{
          onTitleClick: handleTitleClick,
          onRemoveLicenseClick: props.onRemoveLicenseClick,
          licenseRemovalEnabled: licenseRemovalEnabled,
          issueLicensePermissionPredicate: issueLicensePermissionPredicate,
        }}
      />
    </section>
  );
};

export default GroupPacks;
