import { FC, useEffect, useState } from "react";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";

import "./confirmation.scss";
import { IWizardStep } from "../../../../../interfaces/IWizardStepsManager";
import ContentAssignmentModalTwoSteps from "../../../../../components/assignmentModals/contentAssignmentModal/contentAssignmentModalTwoSteps/ContentAssignmentModalTwoSteps";
import { PacksContextItem } from "../../../../../interfaces";
import { pluralize } from "../../../../../utils/stringUtils";

export interface ConfirmationProps extends IWizardStep {
  schedule: Date;
  scheduleTimeZone: string | null;
  communicationId: string;
  communicationTitle: string;
  communicationType: string;
  selectedUserIds: number[];
  selectedGroupIds: number[];
  promptText: string;
  onConfirm: () => void;
  onCancel: () => void;
  shouldShowLicensingModal: (id: string, type: string, userIds: number[], groupIds: number[]) => Promise<boolean>;
}

export const Confirmation: FC<ConfirmationProps> = (props) => {
  const {
    schedule,
    scheduleTimeZone,
    communicationId,
    communicationTitle,
    communicationType,
    selectedUserIds,
    selectedGroupIds,
    promptText,
    acceptHandlers,
    onConfirm,
    onCancel,
    shouldShowLicensingModal,
  } = props;

  const [modalVisible, showModal] = useState(false);

  useEffect(() => {
    acceptHandlers?.({
      onFinish: openLicenseModal,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmLicense = () => {
    showModal(false);
    onConfirm();
  };

  const cancelLicenseModal = () => {
    showModal(false);
    onCancel();
  };

  const openLicenseModal = async () => {
    const shouldShowModal = await shouldShowLicensingModal(
      communicationId,
      communicationType.toLowerCase(),
      selectedUserIds,
      selectedGroupIds,
    );

    if (shouldShowModal) {
      showModal(true);
    } else {
      onConfirm();
    }
  };

  const getEmailPackContextItem = (): PacksContextItem => {
    return {
      id: communicationId,
      title: communicationTitle,
      type: communicationType,
    };
  };

  return (
    <>
      <p className="email-send-session-confirmation-title">
        <b>Almost done!</b>
      </p>
      <p className="email-send-session-confirmation-text">
        <b>{communicationTitle}</b> will be sent to:
        <b> {selectedUserIds.length}</b> {pluralize("user", selectedUserIds.length)} and{" "}
        <b>{selectedGroupIds.length}</b> {pluralize("group", selectedGroupIds.length)} on{" "}
        <b>
          {scheduleTimeZone
            ? dateTimeUtils.formatDateWithTimeZone(schedule, scheduleTimeZone)
            : dateTimeUtils.formatDate(schedule)}
        </b>{" "}
        at
        <b> {dateTimeUtils.dateToTimeString(schedule, "h:mm A z", scheduleTimeZone)}.</b>
        <br />
        {promptText}
      </p>
      <ContentAssignmentModalTwoSteps
        showModal={modalVisible}
        flowHasOrigin={false}
        onCancel={cancelLicenseModal}
        onConfirm={confirmLicense}
        packContextItems={[getEmailPackContextItem()]}
        selectedUserIds={selectedUserIds}
        selectedGroupIds={selectedGroupIds}
        skipLicensing={false}
        skipNotifyStep={true}
        notifyStepDisabled={true}
      />
    </>
  );
};
