import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { CardReportingItem } from "components/cards/CardReporting/CardReporting";
import { DataPoint } from "components/charts/types/HorizontalBarChart";
import {
  PerformanceFilter,
  largestToSmallest,
  lineChartFactory,
  opensColor,
  performanceGray,
  roundToTwoDigits,
  startsColor
} from "features/Library/Common/utils/performanceUtils";
import eventsDataService from "features/Library/Events/services/eventsDataService";
import { ChartPeriod } from "hooks/useChartPeriodMeasure";

export type QueryFilterEventId = QueryFunctionContext<
  [
    _: string,
    filter: PerformanceFilter | null,
    eventId: number,
    sessionId?: number,
    sessionStart?: string,
    isOverview?: boolean,
    flowId?: number,
    isSession?: boolean,
  ],
  unknown
>;

export type QueryFilterEventIdSession = QueryFunctionContext<
  [_: string, filter: PerformanceFilter | null, eventId: number, sessionId: number, sessionStart: string, flowId?: number],
  unknown
>;

export type QueryFilterEventIdPeriod = QueryFunctionContext<
  [
    _: string,
    filter: PerformanceFilter | null,
    eventId: number,
    period: ChartPeriod,
    sessionId?: number,
    sessionStart?: string,
    flowId?: number,
  ],
  unknown
>;

export const getLineChartQuery = async ({ queryKey }: QueryFilterEventIdPeriod) => {
  const { data } = await eventsDataService.getEventDensity({
    ...queryKey[1],
    eventId: queryKey[2],
    period: queryKey[3],
    sessionId: queryKey[4],
    sessionStart: queryKey[5],
    flowId: queryKey[6],
  });

  const lineData = lineChartFactory(data);

  return {
    lineData,
  };
};

type LineChartProps = {
  filter: PerformanceFilter;
  eventId: number;
  chartPeriod: ChartPeriod;
  reportEnabled: boolean;
  sessionId?: number;
  sessionDatetime?: string;
  flowId?: number;
};

export const useEventLineChartQuery = ({
  filter,
  eventId,
  chartPeriod,
  reportEnabled,
  sessionId,
  sessionDatetime,
  flowId,
}: LineChartProps) =>
  useQuery({
    queryKey: ["eventLineChart", filter, eventId, chartPeriod, sessionId, sessionDatetime, flowId],
    queryFn: getLineChartQuery,
    enabled: reportEnabled,
  });

export type EventCardsDrillDown = {
  TotalRegistered: number;
  TotalAttended: number;
  AttendanceRate: string;
};

export type EventCards = EventCardsDrillDown & {
  TotalSessions: number;
};

const attendanceBody = (isSession?: boolean) => isSession ? "The percentage of sessions attended compared to the total number of registrations." : "The percentage of events attended compared to the total number of registrations.";

export const getActivityByTypeQuery = async ({ queryKey }: QueryFilterEventId) => {
  const { data } = await eventsDataService.getEventActivity({
    ...queryKey[1],
    eventId: queryKey[2],
    sessionId: queryKey[3],
    sessionStart: queryKey[4],
    flowId: queryKey[6],
    isSession: queryKey[7],
  });

  const engagement: DataPoint[] = [
    {
      id: "registered",
      category: "Registered",
      value: data.Registered ?? 0,
      fillColor: startsColor,
    },
    {
      id: "attended",
      category: "Attended",
      value: data.Attended ?? 0,
      fillColor: opensColor,
    },
    {
      id: "notAttended",
      category: "Not Attended",
      value: data.NotAttended ?? 0,
      fillColor: performanceGray,
    },
  ];

  let cards: CardReportingItem[] = [];
  if (queryKey[5]) {
    // Is overview
    cards = [
      {
        statDescription: "Total Events",
        stat: data.Events ?? 0,
        popupBody: "The total number of events in this account.",
      },
      {
        statDescription: "Total Registered",
        stat: data.Registered ?? 0,
        popupBody: "The total number of users who registered for this event.",
      },
      {
        statDescription: "Total Attended",
        stat: data.Attended ?? 0,
        popupBody: "The total number of users who attended this event.",
      },
      {
        statDescription: "Total Attendance Rate",
        stat: `${roundToTwoDigits((data.AttendanceRate ?? 0) * 100)}%`,
        popupBody: "The total percentage of events attended compared to the total number of registrations.",
      },
    ];
  } else {
    cards = [
      {
        statDescription: "Total Registered",
        stat: data.Registered ?? 0,
        popupBody: "The total number of users who registered for this event.",
      },
      {
        statDescription: "Total Attended",
        stat: data.Attended ?? 0,
        popupBody: "The total number of users who attended this event.",
      },
      {
        statDescription: "Total Not Attended",
        stat: data.NotAttended ?? 0,
        popupBody: "The total number of users who registered for this event, but did not attend.",
      },
      {
        statDescription: "Attendance Rate",
        stat: data.AttendanceRate ? `${roundToTwoDigits(data.AttendanceRate * 100)}%` : "0%",
        popupBody: attendanceBody(queryKey[7]),
      },
      {
        statDescription: "Total Sessions",
        stat: data.Sessions ?? 0,
        popupBody: "The total number of sessions available for this event.",
      },
    ];
  }

  engagement.sort(largestToSmallest);

  return { engagement, cards };
};

type EngagementProps = {
  filter: PerformanceFilter;
  eventId: number;
  reportEnabled: boolean;
  sessionId?: number;
  sessionDatetime?: string;
  isOverview?: boolean;
  flowId?: number;
  isSession?: boolean;
};

export const useEventEngagementQuery = ({
  filter,
  eventId,
  reportEnabled,
  sessionId,
  sessionDatetime,
  isOverview,
  flowId,
  isSession,
}: EngagementProps) =>
  useQuery({
    queryKey: ["eventActivityByType", filter, eventId, sessionId, sessionDatetime, isOverview, flowId, isSession],
    queryFn: getActivityByTypeQuery,
    enabled: reportEnabled,
  });

export const getTableQuery = async ({ queryKey }: QueryFilterEventId) => {
  const { data } = await eventsDataService.getSessionDetail({
    ...queryKey[1],
    eventId: queryKey[2],
    flowId: queryKey[3],
  });

  const formattedData = data.map((item) => {
    const secondsInMinutes = 60;
    return {
      ...item,
      id: item.SessionId,
      SessionDuration: item.SessionDuration * secondsInMinutes, // The endpoint returns the duration in minutes, but how we use this data expects it in seconds.
    };
  });

  return formattedData;
};

export const useEventTableQuery = ({ filter, eventId, reportEnabled, flowId }: EngagementProps) =>
  useQuery({
    queryKey: ["eventTable", filter, eventId, flowId],
    queryFn: getTableQuery,
    enabled: reportEnabled,
  });

type DrillDownProps = EngagementProps & {
  sessionId: number;
  sessionDatetime: string;
  flowId?: number;
};

export const getDrillDownQuery = async ({ queryKey }: QueryFilterEventIdSession) => {
  const { data } = await eventsDataService.getEventSessionUserDetail({
    ...queryKey[1],
    eventId: queryKey[2],
    sessionId: queryKey[3],
    sessionStart: queryKey[4],
    flowId: queryKey[5],
  });

  const dataWithId = data.map((item) => {
    return {
      ...item,
      id: item.UserId,
    };
  });

  return { table: dataWithId };
};

export const useDrillDownQuery = ({ filter, eventId, sessionId, sessionDatetime, flowId }: DrillDownProps) =>
  useQuery({
    queryKey: ["eventDrillDownReport", filter, eventId, sessionId, sessionDatetime, flowId],
    queryFn: getDrillDownQuery,
  });
