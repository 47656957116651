import qs from "qs";
import dataService from "./features/Application/services/dataServices/dataService";
import { DataService } from "./features/Application/services/dataServices/typedDataService";

const moboKey = "moboId";

const getMoboId = () => {
  const parsedHash = qs.parse(window.location.hash.slice(1));
  let moboId = parsedHash.moboId;
  if (moboId) {
    sessionStorage.setItem(moboKey, moboId);
  } else {
    moboId = sessionStorage.getItem(moboKey);
  }

  return parseInt(moboId);
};

const configureMoboMode = (moboId) => {
  dataService.registerMoboHeaderInterceptor(moboId);
  DataService.base.registerMoboHeaderInterceptor(moboId);
};

const clearMoboData = () => {
  sessionStorage.removeItem(moboKey);
};

const openMobo = (accountId) => window.open(`/#${moboKey}=${accountId}`, "_blank");

const mobo = {
  getMoboId,
  configureMoboMode,
  clearMoboData,
  openMobo,
};

export default mobo;
