import { shouldShowModalForGroupMembership } from "features/Licensing/ContentAssignmentModalLicensingSteps/state/thunks/licensingModalThunk";
import { bindAction } from "interfaces";
import { flatMap, isEmpty, isEqual, map } from "lodash";
import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import { UsersAssignmentModal } from "../../../../../components/assignmentModals";
import { usersFilter } from "../../../../../components/filterForms/UsersFilterForm/UsersFilterForm";
import FormFooter from "../../../../../components/forms/FormFooter";
import ValidatedForm from "../../../../../components/forms/ValidatedForm";
import NoResults from "../../../../../components/noResults/NoResults";
import { Strings } from "../../../../../enums";
import Enrollment from "../../../../../enums/groups/enrollment";
import userListUtils from "../../../../../utils/userListUtils";
import validationSchemas from "../../../../../utils/validationSchemas";
import { Context } from "../../../../Licensing/ContentAssignmentModalLicensingSteps/LicenseConfirmationModalContent/licenseConfirmationInfo/LicenseConfirmationInfo";
import { addUsersToGroups } from "../../../state/thunks/peopleThunk";
import AutomaticallyDetect, {
  convertEnrollmentRulesToFilter,
} from "../../AddEditGroupForms/AutomaticallyDetect/AutomaticallyDetect";
import GroupUsersList from "../../AddGroup/GroupUsers/GroupUsersList/GroupUsersList";

import "./editPeople.scss";

const noEnrollmentRules = [
  {
    source: null,
    filter: null,
    type: null,
    value: "",
  },
];

export class EditPeople extends Component {
  constructor(props) {
    super(props);

    const { enrollmentRules, hidePeople } = this.props;

    const groupMembersFilter =
      !isEmpty(enrollmentRules) && !hidePeople ? convertEnrollmentRulesToFilter(enrollmentRules) : {};

    this.state = {
      groupMembersAppliedFilter: { ...groupMembersFilter },
      selectedUsersToAddIds: [],
      isAutoEnrollRulesValid: true,
      savedEnrollmentRules: [...enrollmentRules],
    };
  }

  componentDidUpdate(prevProps) {
    const { enrollmentRules } = this.props;

    if (isEmpty(prevProps.enrollmentRules) && !isEmpty(enrollmentRules)) {
      this.applyGroupMembersFilter(convertEnrollmentRulesToFilter(enrollmentRules));
      this.setState({ savedEnrollmentRules: [...enrollmentRules] });
    }

    if (!isEqual(prevProps.enrollmentRules, enrollmentRules) && isEqual(prevProps.enrollmentRules, noEnrollmentRules)) {
      this.setState({ savedEnrollmentRules: [...enrollmentRules] });
    }
  }

  componentWillUnmount() {
    const { enrollmentType } = this.props;
    this.props.editGroupActions.resetMembersSearch();
    this.props.editGroupActions.resetGroupUsers();
    if (enrollmentType === Enrollment.Automatic) {
      this.resetGroupMembersFilter();
    }
  }

  loadMembersPage = (skip, top, sortingColumnName, sortingDirection, appliedFilter) => {
    const { fetchGroupUsersForList } = this.props.editGroupActions;
    const { search } = this.props;
    const isAutoEnrollment = false;

    const filterParams = search ? appliedFilter : usersFilter.buildFilterQuery(appliedFilter);
    const orderParams = search
      ? userListUtils.formatOrderParamsV2(sortingColumnName, sortingDirection)
      : userListUtils.formatOrderParams(sortingColumnName, sortingDirection);

    fetchGroupUsersForList(this.props.groupId, skip, top, orderParams, filterParams, isAutoEnrollment, search);
  };

  loadAutoEnrollMembersPage = (skip, top, orderParams, filterParams) => {
    const { fetchGroupUsersForList } = this.props.editGroupActions;
    const groupId = -1;
    const isAutoEnrollment = true;

    fetchGroupUsersForList(groupId, skip, top, orderParams, filterParams, isAutoEnrollment);
  };

  loadUsersToAddPage = (skip, top, sortingColumnName, sortingDirection, appliedFilter) => {
    const { fetchGroupUsersToAdd } = this.props.editGroupActions;
    const { addUsersSearch } = this.props;

    const filterParams = addUsersSearch ? appliedFilter : usersFilter.buildFilterQuery(appliedFilter);
    const orderParams = addUsersSearch
      ? userListUtils.formatOrderParamsV2(sortingColumnName, sortingDirection)
      : userListUtils.formatOrderParams(sortingColumnName, sortingDirection);

    fetchGroupUsersToAdd([this.props.groupId], skip, top, orderParams, filterParams, addUsersSearch);
  };

  applyGroupMembersFilter = (filter) => {
    this.setState({ groupMembersAppliedFilter: filter });
  };

  resetGroupMembersFilter = () => {
    this.setState({ groupMembersAppliedFilter: {} });
  };

  onSecondStep = (selectedIds) => {
    const { groupId, shouldShowLicensingModal } = this.props;
    this.setState({ selectedUsersToAddIds: selectedIds });

    return new Promise(async (resolve) => {
      const shouldShow = await shouldShowLicensingModal([groupId], selectedIds);
      resolve(shouldShow);
    });
  };

  createReloadListItems = (reloadListItems) => {
    this.reloadListItems = reloadListItems;
  };

  onSearchMembersChanged = (search) => {
    this.props.editGroupActions.setMembersSearch(search);
    this.reloadListItems?.(isEmpty(search));
  };

  onAddUsersConfirm = async (notificationSettings) => {
    const { groupId, onCloseAddUsersModal } = this.props;
    const { selectedUsersToAddIds } = this.state;

    this.props.addUsersToGroups(selectedUsersToAddIds, [groupId], notificationSettings);
    onCloseAddUsersModal();
  };

  createReloadListAddItems = (reloadListItems) => {
    this.reloadListAddItems = reloadListItems;
  };

  onSearchUsersAddChanged = (search) => {
    this.props.editGroupActions.setAddMembersSearch(search);
    this.reloadListAddItems?.(isEmpty(search));
  };

  renderUsersAddModal = () => {
    const {
      showAddPeopleModal,
      onCloseAddUsersModal,
      groupId,
      addUsersSearch,
      disableNotifications,
      isShouldShowModalLoading,
    } = this.props;
    const {
      usersToAdd: { items, itemsCount, isLoading },
    } = this.props.editPeople;

    return (
      <UsersAssignmentModal
        loadPage={this.loadUsersToAddPage}
        usersAmount={itemsCount}
        isListLoading={isLoading}
        isStepLoading={isShouldShowModalLoading}
        users={items}
        onSecondStep={this.onSecondStep}
        onConfirm={this.onAddUsersConfirm}
        showModal={showAddPeopleModal}
        onCancel={onCloseAddUsersModal}
        assignmentEntityType="group"
        focusedEntityIds={[groupId]}
        usersAssignments={{
          items: flatMap(items, (item) =>
            map(item.assignedGroupIds, (id) => ({
              userId: item.id,
              groupId: id,
            })),
          ),
          comparer: (assignment, id) => assignment.groupId === id,
        }}
        multipleAssignment={false}
        alreadyAssignedUserTooltip={Strings.tooltipMessages.alreadyAssigned.userToGroup.oneUser}
        showRoleFilter
        search={addUsersSearch}
        onSearchChanged={this.onSearchUsersAddChanged}
        setReloadListItems={this.createReloadListAddItems}
        context={Context.AddPeopleToGroups}
        disableNotifications={disableNotifications}
      />
    );
  };

  renderCustomNoResultContent = () => (
    <NoResults
      title="No Users Found"
      description="The template manager hasn’t added any users yet. When they do, they will appear here."
      iconClassName="fal fa-user"
    />
  );

  renderGroupMembersManualEnroll = () => {
    const { list, filterOptions } = this.props.editPeople;
    const {
      selectedUsers,
      onSelectedUsersChanged,
      onRemoveMemberClick,
      getFilterOptions,
      goToEditUser,
      search,
      hidePeople,
    } = this.props;

    const { groupMembersAppliedFilter } = this.state;

    return (
      <>
        <GroupUsersList
          isGroupCreation={false}
          isAutoEnrollment={false}
          users={list.items}
          addMembersBtn={this.props.addMembersBtn}
          usersCount={list.itemsCount}
          isLoading={list.isLoading}
          appliedUserFilter={groupMembersAppliedFilter}
          setUserFilter={this.applyGroupMembersFilter}
          resetUserFilter={this.resetGroupMembersFilter}
          selectedItemIds={selectedUsers}
          updateSelectedItems={onSelectedUsersChanged}
          loadPage={this.loadMembersPage}
          withSelection={true}
          renderHeaderSection={true}
          onRemoveMemberClick={onRemoveMemberClick}
          filterOptions={{ showRoleFilter: true, ...filterOptions }}
          getFilterOptions={getFilterOptions}
          goToEditUser={goToEditUser}
          isReadOnly={this.props.isReadOnly}
          setReloadListItems={this.createReloadListItems}
          search={search}
          onSearchChanged={this.onSearchMembersChanged}
          disableSearch
          deepLink={this.props.deepLink}
          customNoResultContent={hidePeople ? this.renderCustomNoResultContent : undefined}
        />
        {this.renderUsersAddModal()}
      </>
    );
  };

  bindGetValidatedValues = (getValidatedValues) => {
    this.getValidatedValues = getValidatedValues;
  };

  onAutoEnrollMembersSave = () => {
    const {
      groupId,
      editGroupActions: { updateGroupEnrollmentRules },
    } = this.props;

    const newEnrollmentRules = this.getValidatedValues().enrollmentRules;
    updateGroupEnrollmentRules(groupId, newEnrollmentRules);
    this.resetForm({ values: { enrollmentRules: [...newEnrollmentRules] } });
    this.setState({ savedEnrollmentRules: [...newEnrollmentRules] });
  };

  onAutoEnrollMembersCancel = () => {
    const { savedEnrollmentRules } = this.state;

    this.resetForm({ values: { enrollmentRules: [...savedEnrollmentRules] } });
    this.applyGroupMembersFilter(convertEnrollmentRulesToFilter(savedEnrollmentRules));
    setTimeout(() => this.forceUpdate(), 0); // temporary workaround since the general expression builder component is not ready
  };

  areEnrollmentRulesEdited = () => {
    const { savedEnrollmentRules } = this.state;
    return (
      !this.props.isReadOnly &&
      this.getValidatedValues &&
      !isEqual(this.getValidatedValues().enrollmentRules, savedEnrollmentRules)
    );
  };

  onIsAutoEnrollValidChange = (isValid) => {
    this.setState({ isAutoEnrollRulesValid: isValid });
  };

  renderTabContent = (showUsersList) => {
    if (showUsersList) {
      return this.renderGroupMembersManualEnroll();
    } else {
      return this.renderGroupMembersAutoEnroll();
    }
  };

  renderGroupMembersAutoEnroll = () => {
    const {
      enrollmentRules,
      editPeople: { list },
      isReadOnly,
      goToEditUser,
      fetchGroupEnrollmentUsers,
      resetEnrollmentUsers,
      rulesEngineOnCosmos,
      groupEnrollmentUsers,
      hidePeople,
    } = this.props;

    const { isAutoEnrollRulesValid, groupMembersAppliedFilter } = this.state;
    return (
      <>
        <ValidatedForm
          isInitialValid={true}
          bindGetValues={this.bindGetValidatedValues}
          className="group-members-auto-enroll"
          initialValues={{
            enrollmentRules: [...enrollmentRules],
          }}
          validationSchema={validationSchemas.automaticEnrollment}
          unsavedChangesPrompt={{
            title: "Exit Without Saving?",
            message: "Are you sure you want to exit without saving? All information entered will be lost.",
          }}
          formWidthComputer={16}
          disablePreventTransitionPrompt={!this.areEnrollmentRulesEdited()}
        >
          {(formProps) => {
            const { values, validatedFieldProps } = formProps;
            this.resetForm = validatedFieldProps.resetForm;
            return (
              <AutomaticallyDetect
                onIsValidChange={this.onIsAutoEnrollValidChange}
                values={values}
                validatedFieldProps={validatedFieldProps}
                users={rulesEngineOnCosmos ? groupEnrollmentUsers.items : list.items}
                usersCount={rulesEngineOnCosmos ? groupEnrollmentUsers.itemsCount : list.itemsCount}
                isLoading={rulesEngineOnCosmos ? groupEnrollmentUsers.isLoading : list.isLoading}
                loadUsers={this.loadAutoEnrollMembersPage}
                gridFilter={groupMembersAppliedFilter}
                setGridFilter={this.applyGroupMembersFilter}
                resetGridFilter={this.resetGroupMembersFilter}
                goToEditUser={goToEditUser}
                disabled={isReadOnly}
                deepLink={this.props.deepLink}
                rulesEngineOnCosmos={rulesEngineOnCosmos}
                fetchGroupEnrollmentUsers={fetchGroupEnrollmentUsers}
                resetEnrollmentUsers={resetEnrollmentUsers}
                hidePeople={hidePeople}
              />
            );
          }}
        </ValidatedForm>
        <FormFooter
          onSave={this.onAutoEnrollMembersSave}
          isSaveBtnDisabled={!isAutoEnrollRulesValid || !this.areEnrollmentRulesEdited() || isReadOnly}
          onCancel={this.onAutoEnrollMembersCancel}
          isCancelBtnDisabled={!this.areEnrollmentRulesEdited() || isReadOnly}
        />
      </>
    );
  };

  render() {
    const { enrollmentType, isGroupInfoLoading, showUsersList } = this.props;

    return isGroupInfoLoading || enrollmentType === undefined ? (
      <Dimmer.Dimmable className="edit-people-dimmer" as={Segment} dimmed={true}>
        <Dimmer active={true} inverted>
          <Loader active>Loading</Loader>
        </Dimmer>
      </Dimmer.Dimmable>
    ) : (
      this.renderTabContent(showUsersList)
    );
  }
}

EditPeople.defaultProps = {
  enrollmentRules: [],
};

EditPeople.propTypes = {
  groupId: PropTypes.number.isRequired,
  isReadOnly: PropTypes.bool,
  addMembersBtn: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  editGroupActions: PropTypes.object.isRequired,
  editPeople: PropTypes.object.isRequired,
  showAddPeopleModal: PropTypes.bool.isRequired,
  onCloseAddUsersModal: PropTypes.func.isRequired,
  enrollmentRules: PropTypes.array,
  onRemoveMemberClick: PropTypes.func,
  onSelectedUsersChanged: PropTypes.func,
  enrollmentType: PropTypes.oneOf([0, 1, 2]),
  isGroupInfoLoading: PropTypes.bool,
  selectedUsers: PropTypes.array,
  getFilterOptions: PropTypes.func,
  search: PropTypes.string,
  addUsersSearch: PropTypes.string,
  disableNotifications: PropTypes.bool,
  goToEditUser: PropTypes.func,
  deepLink: PropTypes.bool,
  rulesEngineOnCosmos: PropTypes.bool,
  hidePeople: PropTypes.bool,
  showUsersList: PropTypes.bool,
  fetchGroupEnrollmentUsers: PropTypes.func,
  resetEnrollmentUsers: PropTypes.func,
  groupEnrollmentUsers: PropTypes.shape({
    items: PropTypes.array.isRequired,
    itemsCount: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
};

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  isShouldShowModalLoading: state.licensing.licensingModal.isLoading,
  enrollmentUsers: state.people.enrollmentUsers,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => {
  return {
    addUsersToGroups: bindActionCreators(addUsersToGroups, dispatch),
    shouldShowLicensingModal: bindAction(shouldShowModalForGroupMembership, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPeople);
