import axios from "axios";
import { BasePerformanceRequestFilterParams } from "features/Library/Common/models";
import {
  EmailCounts,
  EmailDetails,
  EngagementCounts,
  AssetActivityCounts,
  FlowCards,
  FlowDetails,
  VideoActivityCounts,
  VideoDetails,
  PdfDetails,
  AssessmentActivityCounts,
  AssessmentEngagement,
  AssessmentDetails,
  AssessmentCards,
  SurveyActivityCounts,
  SurveyEngagement,
  SurveyDetails,
  SurveyCards,
} from "../types/content";
import { EngagementHorizontalData, EngagementLineData } from "features/Library/Pdfs/types/state";
import { VideoEngagementData } from "features/Library/Videos/state/slices/videoPerformanceSlice";

export const EMAIL_LINE_CHART_ENDPOINT = "/api/reports/v2/communications/linechart";
export const EMAIL_ENGAGEMENT_ENDPOINT = "/api/reports/v2/communications/engagement";
export const EMAIL_TABLE_ENDPOINT = "/api/reports/v2/overview/communications/performance";
export const FLOWS_LINE_CHART_ENDPOINT = "/api/reports/v2/overview/flows/linechart";
export const FLOWS_ENGAGEMENT_ENDPOINT = "/api/reports/v2/overview/flows/engagement";
export const FLOWS_TABLE_ENDPOINT = "/api/reports/v2/overview/flows/performance";
export const ASSESSMENT_ACTIVITY_ENDPOINT = "/api/reports/v2/assessments/linechart";
export const ASSESSMENT_ENGAGEMENT_ENDPOINT = "/api/reports/v2/assessments/engagement";
export const ASSESSMENT_CARDS_ENDPOINT = "/api/reports/v2/assessments/cards";
export const ASSESSMENT_DETAILS_ENDPOINT = "/api/reports/v2/overview/assessments/performance";
export const SURVEY_ACTIVITY_ENDPOINT = "/api/reports/v2/surveys/linechart";
export const SURVEY_ENGAGEMENT_ENDPOINT = "/api/reports/v2/surveys/engagement";
export const SURVEY_CARDS_ENDPOINT = "/api/reports/v2/surveys/cards";
export const SURVEY_DETAILS_ENDPOINT = "/api/reports/v2/overview/surveys/performance";

// Videos
export const VIDEO_LINE_CHART_ENDPOINT = "/api/reports/v2/media/linechart";
export const VIDEO_ENGAGEMENT_ENDPOINT = "/api/reports/v2/media/engagement";
export const VIDEO_TABLE_ENDPOINT = "/api/reports/v2/overview/media/performance";

// PDFs
export const PDFS_LINE_CHART_ENDPOINT = "/api/reports/v2/pdfs/linechart";
export const PDFS_ENGAGEMENT_ENDPOINT = "/api/reports/v2/pdfs/engagement";
export const PDFS_TABLE_ENDPOINT = "/api/reports/v2/overview/pdfs/performance";

export const getEmailUsage = (params: BasePerformanceRequestFilterParams = {}): Promise<{ data: EmailCounts[] }> => {
  return axios.get<EmailCounts[]>(EMAIL_LINE_CHART_ENDPOINT, {
    params,
  });
};

export const getEmailEngagement = (
  params: BasePerformanceRequestFilterParams = {},
): Promise<{ data: EngagementCounts }> => {
  return axios.get<EngagementCounts>(EMAIL_ENGAGEMENT_ENDPOINT, { params });
};

export const getEmailDetails = (params: BasePerformanceRequestFilterParams = {}): Promise<{ data: EmailDetails[] }> => {
  return axios.get<EmailDetails[]>(EMAIL_TABLE_ENDPOINT, { params });
};

export const getFlowsDailyData = (params: BasePerformanceRequestFilterParams) => {
  return axios.get<AssetActivityCounts[]>(FLOWS_LINE_CHART_ENDPOINT, { params });
};

export const getFlowsEngagement = (params: BasePerformanceRequestFilterParams) => {
  return axios.get<[FlowCards]>(FLOWS_ENGAGEMENT_ENDPOINT, { params });
};

export const getFlowsDetails = (params: BasePerformanceRequestFilterParams) => {
  return axios.get<FlowDetails[]>(FLOWS_TABLE_ENDPOINT, { params });
};

export const getVideoDailyData = (params: BasePerformanceRequestFilterParams) => {
  return axios.get<VideoActivityCounts[]>(VIDEO_LINE_CHART_ENDPOINT, { params });
};

export const getVideoEngagement = (params: BasePerformanceRequestFilterParams) => {
  return axios.get<VideoEngagementData>(VIDEO_ENGAGEMENT_ENDPOINT, { params });
};

export const getVideoDetails = (params: BasePerformanceRequestFilterParams) => {
  return axios.get<VideoDetails[]>(VIDEO_TABLE_ENDPOINT, { params });
};

export const getPdfsDailyData = (params: BasePerformanceRequestFilterParams) => {
  return axios.get<EngagementLineData[]>(PDFS_LINE_CHART_ENDPOINT, { params });
};

export const getPdfsEngagement = (params: BasePerformanceRequestFilterParams) => {
  return axios.get<EngagementHorizontalData>(PDFS_ENGAGEMENT_ENDPOINT, { params });
};

export const getPdfsTable = (params: BasePerformanceRequestFilterParams) => {
  return axios.get<PdfDetails[]>(PDFS_TABLE_ENDPOINT, { params });
};

export const getAssessmentActivity = (params: BasePerformanceRequestFilterParams) => {
  return axios.get<AssessmentActivityCounts[]>(ASSESSMENT_ACTIVITY_ENDPOINT, { params });
};

export const getAssessmentEngagement = (params: BasePerformanceRequestFilterParams) => {
  return axios.get<AssessmentEngagement>(ASSESSMENT_ENGAGEMENT_ENDPOINT, { params });
};

export const getAssessmentCards = (params: BasePerformanceRequestFilterParams) => {
  return axios.get<AssessmentCards>(ASSESSMENT_CARDS_ENDPOINT, { params });
};

export const getAssessmentDetails = (params: BasePerformanceRequestFilterParams) => {
  return axios.get<AssessmentDetails[]>(ASSESSMENT_DETAILS_ENDPOINT, { params });
};
export const getSurveyActivity = (params: BasePerformanceRequestFilterParams) => {
  return axios.get<SurveyActivityCounts[]>(SURVEY_ACTIVITY_ENDPOINT, { params });
};

export const getSurveyEngagement = (params: BasePerformanceRequestFilterParams) => {
  return axios.get<SurveyEngagement>(SURVEY_ENGAGEMENT_ENDPOINT, { params });
};

export const getSurveyCard = (params: BasePerformanceRequestFilterParams) => {
  return axios.get<SurveyCards>(SURVEY_CARDS_ENDPOINT, { params });
};

export const getSurveyDetails = (params: BasePerformanceRequestFilterParams) => {
  return axios.get<SurveyDetails[]>(SURVEY_DETAILS_ENDPOINT, { params });
};

export const getPdfExport = (params: BasePerformanceRequestFilterParams, abortController: AbortController) => {
  return axios.get("/api/dataexport/v2/overview/pdfs/performance", {
    responseType: "blob",
    params,
    signal: abortController.signal,
  });
};

export const getVideoExport = (params: BasePerformanceRequestFilterParams, abortController: AbortController) => {
  return axios.get("/api/dataexport/v2/overview/media/performance", {
    responseType: "blob",
    params,
    signal: abortController.signal,
  });
};

export const getEmailExport = (params: BasePerformanceRequestFilterParams, abortController: AbortController) => {
  return axios.get("/api/dataexport/v2/overview/communications/performance", {
    responseType: "blob",
    params,
    signal: abortController.signal,
  });
};

export const getFlowExport = (params: BasePerformanceRequestFilterParams, abortController: AbortController) => {
  return axios.get("/api/dataexport/v2/overview/flows/performance", {
    responseType: "blob",
    params,
    signal: abortController.signal,
  });
};

export const getAssessmentExport = (params: BasePerformanceRequestFilterParams, abortController: AbortController) => {
  return axios.get("/api/dataexport/v2/overview/assessments/performance", {
    responseType: "blob",
    params,
    signal: abortController.signal,
  });
};

export const getSurveyExport = (params: BasePerformanceRequestFilterParams, abortController: AbortController) => {
  return axios.get("/api/dataexport/v2/overview/surveys/performance", {
    responseType: "blob",
    params,
    signal: abortController.signal,
  });
};

export const getEventExport = (params: BasePerformanceRequestFilterParams, abortController: AbortController) => {
  return axios.get("/api/dataexport/v2/overview/events/performance", {
    responseType: "blob",
    params,
    signal: abortController.signal,
  });
};
