import "./App.scss"; // should be on first line

import { useCallback, useEffect, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { Loader } from "semantic-ui-react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { AccountsContent, LibraryContent, PeopleContent, ReportingContent, PacksContent } from "./views";
import { AppDispatch, RootState } from "./features/Application/globaltypes/redux";
import { Sidebar } from "./features/Sidebar";
import { RouteNames } from "./enums";
import environmentConfig from "./configuration/environmentConfig";

import * as userProfileActions from "./features/Application/thunks/userProfileThunk";
import * as backgroundTasksActions from "./features/BackgroundTasks/state/backgroundTasksActions";
import Inbox from "./features/Inbox";
import Help from "./features/Help";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import ErrorView from "./components/ErrorView/ErrorView";
import NotificationProvider from "./features/Application/SignalR/NotificationProvider";
import ChameleonProvider from "./features/Application/Chameleon/ChameleonProvider";
import BackgroundTaskLocalStorage from "./features/BackgroundTasks/storage/BackgroundTaskLocalStorage";
import ElasticLoaderDimmer from "./components/elasticLoaderDimmer/ElasticLoaderDimmer";
import Observable from "./utils/Observable";
import ErrorContainer from "./components/AppError/ErrorContainer";
import { ProductBoard } from "features/ProductBoard/ProductBoard";
import LdProvider from "./LdProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Alerts from "features/Notifications/Alerts";

export interface AppProps {
  isUserProfileLoaded: boolean;
  isMobo: boolean;
  showGeneralLoader: boolean;
  hasGlobalAccess: boolean;
  onSignout?: () => void;
}

export type AppPropsAll = AppProps & PropsFromRedux;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      retry: 0, // default: 3
    },
  },
});

export const App = (props: AppPropsAll) => {
  const location = useLocation();
  const errorStateChangedObserver = useRef(new Observable());
  const backgroundTaskLocalStorage = useRef(new BackgroundTaskLocalStorage());
  const { isUserProfileLoaded, permissions, onFetchUserProfile, onSignout } = props;

  // Redirect to user portal if user has no permissions
  useEffect(() => {
    if (isUserProfileLoaded && permissions.length === 0) {
      window.location.href = environmentConfig.eupUrl;
    }
  }, [isUserProfileLoaded, permissions.length]);

  useEffect(() => {
    errorStateChangedObserver.current.notify(false);
  }, [location]);

  useEffect(() => {
    if (!isUserProfileLoaded) {
      onFetchUserProfile();
    }

    const addApplicationInsights = () => {
      const aiInstumentationKeyConfigPlaceholder = "#{ApplicationInsights--InstrumentationKey}#";
      const aiInstumentationKey = environmentConfig.aiInstrumentationKey;
      if (aiInstumentationKey && aiInstumentationKey !== aiInstumentationKeyConfigPlaceholder) {
        const appInsights = new ApplicationInsights({
          config: {
            instrumentationKey: aiInstumentationKey,
          },
        });
        appInsights.loadAppInsights();
        appInsights.trackPageView();
        window.appInsights = appInsights;
      }
    };

    addApplicationInsights();
    backgroundTasksActions.loadAssetTasks(backgroundTaskLocalStorage.current.getAll());
  }, [isUserProfileLoaded, onFetchUserProfile]);

  const signOut = useCallback(() => {
    backgroundTaskLocalStorage.current.clearAll();
    sessionStorage.removeItem("moboId");
    onSignout?.();
  }, [onSignout]);

  if (!props.isUserProfileLoaded || permissions.length === 0) {
    return <Loader active />;
  }
  return (
    <LdProvider>
      <QueryClientProvider client={queryClient}>
        <ElasticLoaderDimmer isActive={props.showGeneralLoader} label="Processing..." />
        <Sidebar onSignout={signOut}>
          <ErrorBoundary onErrorStateChangedObserver={errorStateChangedObserver.current} errorView={<ErrorView />}>
            <ErrorContainer id={"error-content-root"}>
              <Routes>
                <Route path={`${RouteNames.accounts}`}>
                  <Route
                    path="*"
                    element={<AccountsContent isMobo={props.isMobo} hasGlobalAccess={props.hasGlobalAccess} />}
                  />
                </Route>
                <Route path={`${RouteNames.people}/*`} element={<PeopleContent />} />
                <Route path={`${RouteNames.reporting}/*`} element={<ReportingContent />} />
                <Route path={`${RouteNames.licensing}/*`} element={<PacksContent />} />
                <Route path={`${RouteNames.content}/*`} element={<LibraryContent />} />
                <Route path={RouteNames.productFeedbackUrl} element={<ProductBoard />} />
                <Route path="inbox" element={<Inbox />} />
                <Route path="help" element={<Help />} />
                <Route path="*" element={<Navigate to={RouteNames.availablePacks} replace />} />
              </Routes>
            </ErrorContainer>
          </ErrorBoundary>
        </Sidebar>
        <Alerts />
        <NotificationProvider />
        <ChameleonProvider />
      </QueryClientProvider>
    </LdProvider>
  );
};

const mapStateToProps = (state: RootState) => ({
  isUserProfileLoaded: state.userProfile.isUserProfileLoaded,
  permissions: state.userProfile.permissions,
  isMobo: state.userProfile.isMobo,
  showGeneralLoader: state.generalLoader.showGeneralLoader,
  hasGlobalAccess: state.userProfile.hasGlobalAccess,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onFetchUserProfile: bindActionCreators(userProfileActions.fetchUserProfile, dispatch),
  backgroundTasksActions: bindActionCreators(backgroundTasksActions, dispatch),
});

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connect(mapStateToProps, mapDispatchToProps)(App);
