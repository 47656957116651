import { combineReducers } from "@reduxjs/toolkit";
import GroupTemplate from "./GroupTemplateSlice";
import TemplateCustomers from "./TemplateCustomersSlice";
import TemplateOverview from "./TemplateOverviewSlice";
import TemplateFiltersSlice from "./TemplateFiltersSlice";
import TemplatePacksSlice from "./TemplatePacksSlice";
import TemplateContentSlice from "./TemplateContentSlice";
import TemplateContentFiltersSlice from "./TemplateContentFiltersSlice";
import TemplateAvailablePacksSlice from "./TemplateAvailablePacksSlice";

const content = combineReducers({
  content: TemplateContentSlice,
  filters: TemplateContentFiltersSlice,
});

export const GroupTemplateReducer = combineReducers({
  base: GroupTemplate,
  customers: TemplateCustomers,
  overview: TemplateOverview,
  filters: TemplateFiltersSlice,
  content: content,
  availablePacks: TemplateAvailablePacksSlice,
  packs: TemplatePacksSlice,
});

export type GroupTemplateState = ReturnType<typeof GroupTemplateReducer>;
