import { AxiosResponse } from "axios";
import { AlertTypes } from "enums";
import { AppDispatch } from "features/Application/globaltypes/redux";
import { BasePerformanceRequestFilterParams } from "features/Library/Common/models";
import { downloadExcelExport } from "features/Library/Common/utils/performanceUtils";
import { sendTransientNotification } from "features/Notifications/state/notificationsActions";
import {
  getAssessmentExport,
  getEmailExport,
  getEventExport,
  getFlowExport,
  getPdfExport,
  getSurveyExport,
  getVideoExport,
} from "features/Reporting/services/contentReportingService";
import { setExporting } from "../export/exportSlice";

let abortController = new AbortController();

export const doPdfExport = (filterParams: BasePerformanceRequestFilterParams) => {
  return sendExportRequest((abortController) => getPdfExport(filterParams, abortController), "PDFs");
};

export const doVideoExport = (filterParams: BasePerformanceRequestFilterParams) => {
  return sendExportRequest((abortController) => getVideoExport(filterParams, abortController), "Videos");
};

export const doEmailExport = (filterParams: BasePerformanceRequestFilterParams) => {
  return sendExportRequest((abortController) => getEmailExport(filterParams, abortController), "Emails");
};

export const doFlowExport = (filterParams: BasePerformanceRequestFilterParams) => {
  return sendExportRequest((abortController) => getFlowExport(filterParams, abortController), "Flows");
};

export const doAssessmentExport = (filterParams: BasePerformanceRequestFilterParams) => {
  return sendExportRequest((abortController) => getAssessmentExport(filterParams, abortController), "Assessments");
};

export const doSurveyExport = (filterParams: BasePerformanceRequestFilterParams) => {
  return sendExportRequest((abortController) => getSurveyExport(filterParams, abortController), "Surveys");
};

export const doEventExport = (filterParams: BasePerformanceRequestFilterParams) => {
  return sendExportRequest((abortController) => getEventExport(filterParams, abortController), "Events");
};

function sendExportRequest(
  request: (abortController: AbortController) => Promise<AxiosResponse<unknown>>,
  fileName: string,
) {
  abortController.abort();
  abortController = new AbortController();
  return async (dispatch: AppDispatch) => {
    dispatch(setExporting(true));
    try {
      const response = await request(abortController);
      dispatch(
        sendTransientNotification(
          { title: "Export Complete!", message: "File download has begun.", type: AlertTypes.success },
          5,
        ),
      );

      downloadExcelExport(response.data, fileName);
    } catch (err) {
      // Request was aborted manually, no need to change anything
      if (err.code === "ERR_CANCELED") {
        return;
      }
      dispatch(
        sendTransientNotification(
          {
            title: "Export failed",
            message: "Please try again later. If this persists, please contact BrainStorm support.",
            type: AlertTypes.error,
          },
          5,
        ),
      );
    } finally {
      dispatch(setExporting(false));
    }
  };
}
