import { FormikProps, withFormik } from "formik";
import { difference, intersection, isEmpty, some, union } from "lodash";
import { Component, createRef } from "react";
import { ConnectedProps, connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Dimmer, Loader } from "semantic-ui-react";

import { WithRouterProps, withRouter } from "../../../../adapters/withRouter/withRouter";
import { AutoenrollmentGroupNotificationsWarningBanner } from "../../../../components";
import { NotifyStepSwitch } from "../../../../components/notifyStep/NotifyStepSwitch";
import NotifyWizardStep from "../../../../components/notifyStep/NotifyWizardStep/NotifyWizardStep";
import {
  INotifyByEmailController,
  NotifyByEmailController,
} from "../../../../components/notifyStep/notifyByEmailController";
import AccessRestrictedMessage from "../../../../components/restrictedRoute/AccessRestrictedMessage";
import {
  AlertTypes,
  AssignmentPeopleContext,
  CommunicationChannels,
  RolePermissions,
  TemplateTypes,
} from "../../../../enums";
import { NotifyStepSettings, bindAction } from "../../../../interfaces";
import { FormOnChangedHandler } from "../../../../types";
import enrollmentRulesUtils from "../../../../utils/enrollmentRulesUtils";
import { Filters } from "../../../../utils/queryUtils";
import userListUtils from "../../../../utils/userListUtils";
import validationSchemas from "../../../../utils/validationSchemas";
import Restricted from "../../../Application/Restricted";
import { AppDispatch, RootState } from "../../../Application/globaltypes/redux";
import dataService from "../../../Application/services/dataServices/dataService";
import {
  GroupCreated,
  GroupCreatedSuccess,
  GroupInfoUpdated,
} from "../../../Application/services/realTimeNotification/events/people/peopleEvents";
import RtnEventsEmitter from "../../../Application/services/realTimeNotification/rtnEventsEmitter";
import { assignContentDistributed } from "../../../Library/PeopleAssignments/state/actions/commonActions";
import * as videosActionsRedux from "../../../Library/Videos/state/actions/videosActions";
import { resetContentPacksAction } from "../../../Licensing/ContentAssignmentModalLicensingSteps/state/thunks/assignmentModalLicensingThunk";
import LicensesStep from "../../../Licensing/Packs/shared/LicensesStep/LicensesStep";
import { issuePackLicenseToPeople } from "../../../Licensing/Packs/state/thunks/packPurchasedViewThunk";
import { sendTransientNotification } from "../../../Notifications/state/notificationsActions";
import { setSelectedContentType as setSelectedContentTypeAction } from "../../../People/state/slices/addToContentSlice";
import { initialNotifyConfigDefault } from "../../../SystemNotifications/config";
import { WithNotifyConfigProps, withNotifyConfig } from "../../../SystemNotifications/containers/withNotifyConfig";
import { resetState } from "../../../SystemNotifications/state/slices/notifyStepSlice";
import { NotifySettings } from "../../../SystemNotifications/types";
import { WizardWrapper as Wizard } from "../../../WizardWrapper";
import * as wizardWrapperActionsRedux from "../../../WizardWrapper/state/wizardWrapperActions";
import * as usersFilterActionsRedux from "../../Users/UsersOverview/state/filterActionCreators";
import { addUsersToGroups } from "../../state/thunks/peopleThunk";
import { ConfirmationState, FlowPriorityItem, GroupInfoForm, GroupUsersState, SelectedItemsState } from "../../types";
import { EnrollmentRule } from "../AddEditGroupForms/AutomaticallyDetect/AutomaticallyDetect";
import GroupConfiguration from "../AddEditGroupForms/GroupConfiguration/GroupConfiguration";
import groupDomainUtils from "../AddEditGroupForms/groupDomainUtils";
import GroupOwners from "./GroupOwners/GroupOwners";
import GroupUsers from "./GroupUsers/GroupUsers";
import * as addGroupActionsRedux from "./state/addGroupActions";

import AddPeoplePacksIssueLicenseModal from "components/assignmentModals/peopleIssueLicenseModal/AddPeoplePacksIssueLicenseModal";
import { FeatureFlags } from "featureFlags";
import { PackOverview } from "features/Licensing/Packs/types/state";
import { SelectionChangedType } from "interfaces/onSelectionChanged";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { LDProps } from "../../../LDProps";
import { defaultEnrollmentRule, isDefaultRuleSet } from "../AddEditGroupForms/EnrollmentBuilder/utils";
import { resetEnrollmentUsers } from "../state/slices/enrollmentUsersSlice";
import { fetchGroupEnrollmentUsers } from "../state/thunks/enrollmentUsersThunk";
import navigationUtils from "../../../../utils/navigationUtils";
import "./addGroup.scss";

const goToGroupsOverview = (navigate: ReturnType<typeof useNavigate>) => navigate("/people/groups");

export enum AddGroupSteps {
  ConfigureStep = 0,
  MembersStep = 1,
  OwnersStep = 2,
  LicensesStep = 3,
  ContentStep = 4,
  NotifyStep = 5,
}

interface AddGroupPropsOwn extends WithRouterProps { }

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = AddGroupPropsOwn &
  PropsFromRedux &
  WithNotifyConfigProps<NotifySettings> &
  FormikProps<GroupInfoForm> &
  LDProps;

interface State {
  isGroupUsersValid: boolean;
  isGroupUsersInitialValid: boolean;
  isLicenseConfirmed: boolean;
  groupSettings: {
    isAware: boolean;
    isVisibleToAll: boolean;
    isOpen: boolean;
    isWithAutoEnroll: boolean;
  };
  groupUsers: GroupUsersState;
  groupOwnerIds: number[];
  groupContent: SelectedItemsState;
  confirmation: ConfirmationState;
  licensesStep: {
    showModal: boolean;
    selectedItems: PackOverview[];
    areLicensesConfirmed: boolean;
    issueLicensePackIds: number[];
  };
  appliedUsersFilter: {};
  appliedOwnersFilter: {};
  isGroupSaved: boolean;
  nextButtonLabel: string;
  groupConfigurationDirty: boolean;
  notifyStep: {
    isValid: boolean;
    isLoaded: boolean;
  };
}

export class AddGroup extends Component<Props, State> {
  private readonly rulesEngineOnCosmos: boolean;
  constructor(props: Props) {
    super(props);
    this.state = {
      isGroupUsersValid: true,
      isGroupUsersInitialValid: true,
      isLicenseConfirmed: true,
      groupSettings: {
        isAware: true,
        isVisibleToAll: true,
        isOpen: true,
        isWithAutoEnroll: false,
      },
      groupUsers: {
        enrollmentRules: [defaultEnrollmentRule],
        userIds: [],
      },
      groupOwnerIds: [],
      groupContent: {
        selectedFlows: [],
        selectedVideos: [],
        selectedSurveys: [],
        selectedAssessments: [],
        selectedEvents: [],
        selectedPdfs: [],
      },
      confirmation: {
        blocks: [],
        priorityItems: [],
        show: false,
      },
      licensesStep: {
        showModal: false,
        selectedItems: [],
        areLicensesConfirmed: true,
        issueLicensePackIds: [],
      },
      appliedUsersFilter: {},
      appliedOwnersFilter: {},
      isGroupSaved: false,
      nextButtonLabel: "Save & Continue",
      groupConfigurationDirty: false,
      notifyStep: {
        isValid: false,
        isLoaded: false,
      },
    };
    this.rulesEngineOnCosmos = !!this.props.flags?.[FeatureFlags.RuleEngineOnCosmos];
  }

  componentDidMount() {
    if (!this.props.isMobo) {
      this.setState((_) => ({
        groupOwnerIds: [this.props.currentUserId],
      }));
    }

    this.props.addGroupActions.fetchAuthenticatedUser();
  }

  componentWillUnmount() {
    const {
      addGroupActions: { resetEnrollmentUsersFilter, reset },
      usersFilterActions: { resetUserFilter },
    } = this.props;

    reset();
    resetEnrollmentUsersFilter();
    resetUserFilter();

    const notifyByEmailController = this.notifyByEmailControllerRef.current;
    if (notifyByEmailController) {
      notifyByEmailController.resetState();
    }
  }

  triggerSubmitGroupUsers?: () => void;
  getDraftEnrollmentRules?: () => GroupUsersState;
  reloadGroupMembersListItems?: (withSearch: boolean) => void;
  isAnyGroupUsersSettings?: boolean;
  reloadGroupOwnersListItems?: (search: boolean) => void;

  // configuration step start
  onIsAwareChanged: FormOnChangedHandler = (_, data) => {
    const isAware = data.checked;
    this.setState((state) => ({
      groupSettings: {
        ...state.groupSettings,
        isAware,
        isVisibleToAll: isAware ? isAware : null,
        isOpen: isAware ? isAware : null,
      },
      groupConfigurationDirty: true,
    }));
  };

  onIsWithAutoEnrollChanged: FormOnChangedHandler = (_, data) => {
    const isWithAutoEnroll = data.checked;
    this.clearGroupUsers(isWithAutoEnroll);

    this.setState((state) => ({
      groupSettings: {
        ...state.groupSettings,
        isWithAutoEnroll,
      },
      isGroupUsersValid: !isWithAutoEnroll,
      groupConfigurationDirty: true,
    }));
  };

  onVisibleToAllChanged: FormOnChangedHandler = (_, data) => {
    const isVisibleToAll = data.checked;
    this.setState((state) => ({
      groupSettings: {
        ...state.groupSettings,
        isVisibleToAll,
      },
      groupConfigurationDirty: true,
    }));
  };

  onIsOpenChanged: FormOnChangedHandler = (_, data) => {
    const isOpen = !data.checked;
    this.setState((state) => ({
      groupSettings: {
        ...state.groupSettings,
        isOpen,
      },
      groupConfigurationDirty: true,
    }));
  };

  onSubmitGroupConfiguration = () => {
    if (!this.state.groupConfigurationDirty || !this.props.isValid) {
      return;
    }
    this.saveOrUpdateGroupInfo(this.props.values);
  };

  saveOrUpdateGroupInfo = async (groupConfiguration: GroupInfoForm) => {
    const config = {
      name: groupConfiguration.name,
      description: groupConfiguration.description,
      imageUrl: groupConfiguration.imageUrl,
      ...this.state.groupSettings,
    };

    if (!this.state.isGroupSaved) {
      this.props.addGroupActions.createGroup(config);
    } else {
      this.props.addGroupActions.updateGroupInfo(this.props.createdGroup.groupId as unknown as number, config);
    }
    RtnEventsEmitter.subscribeOnce([GroupCreatedSuccess, GroupCreated, GroupInfoUpdated], () =>
      this.onGroupSaved(groupConfiguration),
    );
  };

  onConfigurationDirtyChanged = (isDirty: boolean) => {
    if (this.state.isGroupSaved) {
      this.setState((_) => ({
        nextButtonLabel: isDirty ? "Update & Continue" : "Continue",
      }));
    }

    this.setState((_) => ({
      groupConfigurationDirty: isDirty,
    }));
  };
  // configuration step end

  // users group step start
  onGroupUsersValidityChange = (isGroupUsersValid: boolean) => {
    this.setState({ isGroupUsersValid });
  };

  onValidGroupUsersSubmit = async (groupUsers: GroupUsersState) => {
    this.setState({ groupUsers });
  };

  clearGroupUsers = (isWithAutoEnroll: boolean) => {
    this.setState({
      ...this.state,
      groupUsers: {
        enrollmentRules: isWithAutoEnroll ? [defaultEnrollmentRule] : [],
        userIds: [],
      },
    });

    const {
      addGroupActions: { resetEnrollmentUsersFilter },
      usersFilterActions: { resetUserFilter },
    } = this.props;

    resetEnrollmentUsersFilter();
    resetUserFilter();
  };

  getUsersFilterOptions = () => {
    const { usersFilterActions } = this.props;
    usersFilterActions.getFilterOptions();
  };

  bindSubmitGroupUsers = (submitForm: () => void) => {
    this.triggerSubmitGroupUsers = submitForm;
  };

  bindGetDraftEnrollmentRulesValues = (getDraftEnrollmentRules: () => GroupUsersState) => {
    this.getDraftEnrollmentRules = getDraftEnrollmentRules;
  };

  applyUsersFilter = (filter: Filters) => {
    this.setState({ appliedUsersFilter: filter });
  };

  resetUsersFilter = () => {
    this.setState({ appliedUsersFilter: {} });
  };

  applyOwnersFilter = (filter: Filters) => {
    this.setState({ appliedOwnersFilter: filter });
  };

  resetOwnersFilter = () => {
    this.setState({ appliedOwnersFilter: {} });
  };
  // users group step end

  onGroupSaved = (groupConfig: GroupInfoForm) => {
    const groupConfiguration = {
      ...groupConfig,
      groupId: this.props.createdGroup.groupId,
      roleId: this.props.createdGroup.roleId,
    };

    this.setState({
      nextButtonLabel: "Continue",
      //@ts-ignore
      createdGroup: groupConfiguration,
      isGroupSaved: true,
      groupConfigurationDirty: false,
    });
  };

  getPackIdsToIssueLicense = () => this.state.licensesStep.issueLicensePackIds;

  onProgressAsync = async (activeStepIndex: AddGroupSteps) => {
    const {
      licensesStep: { areLicensesConfirmed },
    } = this.state;
    const { groupId } = this.props.createdGroup;
    const packIds = this.getPackIdsToIssueLicense();

    if (activeStepIndex === AddGroupSteps.ConfigureStep) {
      this.onSubmitGroupConfiguration();
      this.props.submitForm();
    }

    if (activeStepIndex === AddGroupSteps.MembersStep && this.state.groupSettings.isWithAutoEnroll) {
      await this.triggerSubmitGroupUsers?.();
    }
    if (activeStepIndex === AddGroupSteps.MembersStep && !this.state.groupSettings.isWithAutoEnroll) {
      this.props.addGroupActions.resetMembersSearch();
    }
    if (activeStepIndex === AddGroupSteps.OwnersStep) {
      this.props.addGroupActions.resetOwnersSearch();
    }
    if (activeStepIndex === AddGroupSteps.LicensesStep && !areLicensesConfirmed) {
      this.issuePackLicenses(groupId as unknown as number, packIds);
    }
  };

  onRegressAsync = async (activeStepIndex: number) => {
    const {
      licensesStep: { areLicensesConfirmed },
    } = this.state;
    const { groupId } = this.props.createdGroup;
    const packIds = this.getPackIdsToIssueLicense();

    if (activeStepIndex === AddGroupSteps.MembersStep && this.state.groupSettings.isWithAutoEnroll) {
      const draftEnrollmentRulesWrapper = this.getDraftEnrollmentRules?.();
      this.setState((state: State) => ({
        groupUsers: {
          ...state.groupUsers,
          enrollmentRules: draftEnrollmentRulesWrapper?.enrollmentRules as EnrollmentRule[],
        },
      }));
    }
    if (activeStepIndex === AddGroupSteps.MembersStep && !this.state.groupSettings.isWithAutoEnroll) {
      this.props.addGroupActions.resetMembersSearch();
    }
    if (activeStepIndex === AddGroupSteps.OwnersStep) {
      this.props.addGroupActions.resetOwnersSearch();
    }
    if (
      activeStepIndex === AddGroupSteps.NotifyStep &&
      this.state.notifyStep.isLoaded &&
      this.props.notifyConfig.communicationChannel === CommunicationChannels.Email
    ) {
      const controller = this.notifyByEmailControllerRef.current;
      await controller?.saveChanges();
    }
    if (activeStepIndex === AddGroupSteps.LicensesStep && !areLicensesConfirmed) {
      this.issuePackLicenses(groupId as unknown as number, packIds);
    }

    this.setState({ isGroupUsersInitialValid: this.state.isGroupUsersValid });
  };

  issuePackLicenses = (groupId: number, selectedIds: number[]) => {
    const assignments = selectedIds.map((packId) =>
      userListUtils.mapToAssignmentItem(groupId, { id: packId }, "pack", AssignmentPeopleContext.Group),
    );
    this.props.issuePackLicense({
      peopleType: AssignmentPeopleContext.Group,
      items: assignments,
    });
    this.setState({ licensesStep: { ...this.state.licensesStep, areLicensesConfirmed: true } });
  };

  updateSelectedItems = (newUserIds: number[]) => {
    this.setState((state) => ({
      isLicenseConfirmed: this.isEmptyContent(state.groupContent) || isEmpty(newUserIds),
      groupUsers: {
        ...state.groupUsers,
        userIds: newUserIds,
        confirmed: isEmpty(newUserIds),
      },
      licensesStep: {
        ...state.licensesStep,
        areLicensesConfirmed: isEmpty(state.licensesStep.selectedItems) || isEmpty(newUserIds),
      },
    }));
  };

  updateSelectedOwners = (newUserIds: number[]) => {
    this.setState((state) => ({
      isLicenseConfirmed: this.isEmptyContent(state.groupContent) || isEmpty(newUserIds),
      groupOwnerIds: newUserIds,
    }));
  };

  ownersStepPredicate(userPermissions: RolePermissions[]) {
    if (groupDomainUtils.hasReadOnlyOwnersAccess(userPermissions)) {
      return true;
    }
    return (
      intersection([RolePermissions.GroupsCreate, RolePermissions.GroupsManage], userPermissions).length > 0 &&
      (difference([RolePermissions.UsersManage, RolePermissions.RolesView], userPermissions).length === 0 ||
        difference([RolePermissions.UsersView, RolePermissions.RolesManage], userPermissions).length === 0)
    );
  }

  triggerLicenceConfirmation = (proceed: (value: boolean) => void) => {
    this.setState((state) => ({
      ...state,
      confirmation: {
        ...state.confirmation,
        show: true,
        proceed: proceed,
      },
    }));
    this.props.resetContentPacks();
  };

  createNotificationSettings = async () => {
    const notifySettings = await this.notifyByEmailControllerRef.current?.getSettings(
      this.props.notifyConfigPayload.shouldNotify,
      this.props.notifyConfigPayload.communicationChannel,
      !this.state.groupSettings.isAware,
    );
    (notifySettings as NotifyStepSettings).id = groupDomainUtils.getAutoEnrolledGroupNotificationSettingsId(
      this.props.createdGroup.groupId,
    );
    this.props.addGroupActions.createNotificationSettings(notifySettings as NotifyStepSettings);
  };

  onFinishAsync = async () => {
    const {
      groupUsers: { userIds, enrollmentRules },
      groupOwnerIds,
      confirmation: { priorityItems },
      groupSettings: { isWithAutoEnroll },
    } = this.state;

    const {
      createdGroup: { groupId },
    } = this.props;

    if (isWithAutoEnroll && this.props.notifyConfigPayload.shouldNotify) {
      await this.createNotificationSettings();
    }

    if (!isEmpty(enrollmentRules) && !isDefaultRuleSet(enrollmentRules)) {
      const enrollmentRulesInServerFormat = enrollmentRulesUtils.mapRulesToSeverFormat(enrollmentRules);

      await dataService.updateGroupEnrollmentRules(groupId, enrollmentRulesInServerFormat);
    }

    if (some(groupOwnerIds)) {
      await dataService.assignUsersToRoles(groupOwnerIds, [this.props.createdGroup?.roleId]);
    }

    if (!isWithAutoEnroll && some(userIds)) {
      const notifySettings = await this.notifyByEmailControllerRef.current?.getSettings(
        this.props.notifyConfigPayload.shouldNotify,
        this.props.notifyConfigPayload.communicationChannel,
        !this.state.groupSettings.isAware,
      );

      this.props.addUsersToGroups(userIds, [groupId], notifySettings);
    }

    if (some(priorityItems)) {
      const contentAssignmentItems = this.getContentTabAssignments(groupId);
      this.props.assignContentToPeople({ peopleType: AssignmentPeopleContext.Group, items: contentAssignmentItems });
      this.props.resetContentPacks();
    }

    this.sendNotification();
    goToGroupsOverview(this.props.navigate);
  };

  sendNotification = () => {
    this.props.sendNotification({
      type: AlertTypes.success,
      message: "Group has been created!",
    });
  };

  getContentTabAssignments = (groupId: number) => {
    const {
      confirmation: { priorityItems },
    } = this.state;

    //@ts-ignore
    let contentAssignments = [];
    if (some(priorityItems)) {
      const { packContextItems, flowAssignments } = this.props;

      if (!!flowAssignments.length) {
        contentAssignments = userListUtils.mapAssignmentFlows(
          groupId,
          flowAssignments,
          priorityItems as FlowPriorityItem[],
          AssignmentPeopleContext.Group,
        );
      } else {
        const priorityItemsMapped = userListUtils.mapAssignmentItems(
          priorityItems.map((i) => {
            return { ...i, blockType: i.blockType };
          }),
          packContextItems,
        );

        contentAssignments = priorityItemsMapped.map((item) =>
          userListUtils.mapToAssignmentItem(groupId, item, item.blockType, AssignmentPeopleContext.Group),
        );
      }
    }

    //@ts-ignore
    return contentAssignments;
  };

  canProceedOrRecedeAsync = (activeIndex: number) => {
    const { isLicenseConfirmed, licensesStep } = this.state;

    if (activeIndex === AddGroupSteps.ContentStep && !isLicenseConfirmed) {
      return new Promise((resolve) => {
        this.triggerLicenceConfirmation(resolve);
      });
    }

    if (
      activeIndex === AddGroupSteps.LicensesStep &&
      !isEmpty(licensesStep.selectedItems) &&
      !licensesStep.areLicensesConfirmed
    ) {
      this.setState({ licensesStep: { ...this.state.licensesStep, showModal: true } });
      return new Promise((resolve) => {
        this.resolveLicensesStepProgress = () => resolve(true);
        this.rejectLicensesStepProgress = () => resolve(false);
      });
    }

    return Promise.resolve(true);
  };

  isEmptyContent = ({
    selectedFlows,
    selectedVideos,
    selectedEvents,
    selectedSurveys,
    selectedAssessments,
    selectedPdfs,
  }: SelectedItemsState) => {
    return (
      isEmpty(selectedFlows) &&
      isEmpty(selectedVideos) &&
      isEmpty(selectedEvents) &&
      isEmpty(selectedSurveys) &&
      isEmpty(selectedAssessments) &&
      isEmpty(selectedPdfs)
    );
  };

  onCancel = () => navigationUtils.goBackOrDefault(this.props.location, this.props.navigate, "/people/groups");

  createReloadGroupMembersListItems = (reloadListItems: () => void) => {
    this.reloadGroupMembersListItems = reloadListItems;
  };

  onSearchMembersChanged = (search: string) => {
    this.props.addGroupActions.setMembersSearch(search);
    this.reloadGroupMembersListItems?.(isEmpty(search));
  };

  createReloadGroupOwnersListItems = (reloadListItems: (enableSorting: boolean) => void) => {
    this.reloadGroupOwnersListItems = reloadListItems;
  };

  onSearchOwnersChanged = (search: string) => {
    this.props.addGroupActions.setOwnersSearch(search);
    this.reloadGroupOwnersListItems?.(isEmpty(search));
  };

  getGroupUsersNextButtonLabel = (isGroupSaved: boolean, nextButtonLabel: string) => {
    return !isGroupSaved ? "Save & Continue" : nextButtonLabel;
  };

  // Licenses step begin
  resolveLicensesStepProgress?: () => void;
  rejectLicensesStepProgress?: () => void;

  handleLicensesModalClose = () => {
    this.setState({ licensesStep: { ...this.state.licensesStep, issueLicensePackIds: [], showModal: false } });
  };

  handleLicensesModalCancel = () => {
    this.handleLicensesModalClose();
    this.rejectLicensesStepProgress && this.rejectLicensesStepProgress();
  };

  handleLicensesModalConfirm = () => {
    this.setState(
      {
        licensesStep: { ...this.state.licensesStep, showModal: false },
      },
      () => {
        this.resolveLicensesStepProgress && this.resolveLicensesStepProgress();
      },
    );
  };

  onIssueLicensePacksChange = (ids: number[]) => {
    this.setState({
      licensesStep: { ...this.state.licensesStep, issueLicensePackIds: ids },
    });
  };

  onSelectionChange = (props: { items: PackOverview[]; type: SelectionChangedType; }) => {
    const newItems =
      props.type === SelectionChangedType.Added
        ? union(this.state.licensesStep.selectedItems, props.items)
        : this.state.licensesStep.selectedItems.filter((x) => !props.items.map((y) => y.id).includes(x.id));
    this.setState({
      licensesStep: {
        ...this.state.licensesStep,
        selectedItems: newItems,
        areLicensesConfirmed: false,
      },
    });
  };
  // Licenses step end

  // Notify step begin
  notifyByEmailControllerRef = createRef<INotifyByEmailController | null>();
  handleNotifyStepOnValidChange = (isValid: boolean) => {
    this.setState({ notifyStep: { ...this.state.notifyStep, isValid } });
  };
  onNotifyStepLoaded = (controller: NotifyByEmailController) => {
    //@ts-ignore
    this.notifyByEmailControllerRef.current = controller;
    this.setState({ notifyStep: { ...this.state.notifyStep, isLoaded: true } });
  };

  renderInfoBanner = () =>
    this.state.groupSettings.isWithAutoEnroll ? <AutoenrollmentGroupNotificationsWarningBanner /> : <></>;
  // Notify step end

  isMembersStepLocked = () => {
    if (this.state.groupSettings.isWithAutoEnroll && this.state.isGroupSaved) {
      return !this.state.isGroupUsersValid;
    }
    return !this.state.isGroupSaved;
  };

  render() {
    const {
      groupSettings,
      groupUsers,
      appliedUsersFilter,
      appliedOwnersFilter,
      nextButtonLabel,
      isGroupSaved,
      groupOwnerIds,
    } = this.state;

    const {
      fetchEnrollmentUsers,
      fetchManualSelectionUsers,
      setEnrollmentUsersFilter,
      resetEnrollmentUsers,
      resetEnrollmentUsersFilter,
      fetchOwners,
    } = this.props.addGroupActions;

    const {
      isLoadingGroupInfo,
      accountId,
      currentUserEmail,
      currentUserId,
      userPermissions,
      membersSearch,
      ownersSearch,
      notifyConfig,
      fetchGroupUsersEnrollment,
      resetGroupUsersEnrollment,
      notifyConfigPayload: { setNotifyConfig, shouldNotify, communicationChannel },
    } = this.props;

    return (
      <Wizard
        id="add-group-wizard"
        finishButtonLabel="Finish"
        className="add-group"
        title="Create Group"
        onProgressAsync={this.onProgressAsync}
        onRegressAsync={this.onRegressAsync}
        canProceedAsync={this.canProceedOrRecedeAsync}
        canRecedeAsync={this.canProceedOrRecedeAsync}
        onCancel={this.onCancel}
        onFinishAsync={this.onFinishAsync}
      >
        <Wizard.Step
          label="Configure"
          isLocked={
            !this.props.isValid ||
            (this.props.submitCount === 0 && !this.state.groupConfigurationDirty) ||
            isLoadingGroupInfo
          }
          required
          isLockNavigation={!isGroupSaved || (this.state.groupConfigurationDirty && isGroupSaved)}
          nextButtonLabel={nextButtonLabel}
        >
          <Dimmer active={isLoadingGroupInfo} inverted>
            <Loader>Processing</Loader>
          </Dimmer>
          <GroupConfiguration
            {...this.props}
            onDirtyChanged={this.onConfigurationDirtyChanged}
            groupSettings={groupSettings}
            isAnyGroupUsersSettings={this.isAnyGroupUsersSettings}
            onIsAwareChanged={this.onIsAwareChanged}
            onIsWithAutoEnrollChanged={this.onIsWithAutoEnrollChanged}
            onVisibleToAllChanged={this.onVisibleToAllChanged}
            onIsOpenChanged={this.onIsOpenChanged}
            disableEnrollmentEdit={isGroupSaved}
            isLoadingGroupInfo={isLoadingGroupInfo}
          />
        </Wizard.Step>

        <Wizard.Step
          className="add-group-users-step"
          label="Members"
          isLocked={this.isMembersStepLocked()}
          isLockNavigation={this.isMembersStepLocked()}
          nextButtonLabel={this.getGroupUsersNextButtonLabel(isGroupSaved, nextButtonLabel)}
        >
          <Dimmer active={isLoadingGroupInfo || !isGroupSaved} inverted>
            <Loader>Processing</Loader>
          </Dimmer>
          <Restricted
            permissions={[RolePermissions.GroupsManage, RolePermissions.UsersView]}
            permissionPredicate={(userPermissionsList, permissionsList) =>
              difference(permissionsList, userPermissionsList).length === 0
            }
            placeholder={<AccessRestrictedMessage />}
          >
            <GroupUsers
              bindGetDraftEnrollmentRulesValues={this.bindGetDraftEnrollmentRulesValues}
              bindSubmitForm={this.bindSubmitGroupUsers}
              onIsValidChange={this.onGroupUsersValidityChange}
              groupUsers={groupUsers}
              enrollmentUsersOptions={this.props.enrollmentUsersOptions}
              setAutomaticEnrollmentGridFilter={setEnrollmentUsersFilter}
              resetAutomaticEnrollmentGridFilter={resetEnrollmentUsersFilter}
              onSubmit={this.onValidGroupUsersSubmit}
              isWithAutoEnroll={groupSettings.isWithAutoEnroll}
              loadEnrollmentUsers={fetchEnrollmentUsers}
              resetEnrollmentUsers={this.rulesEngineOnCosmos ? resetGroupUsersEnrollment : resetEnrollmentUsers}
              // Manual users
              loadManualSelectionUsers={fetchManualSelectionUsers}
              manualSelectionUsersOptions={this.props.manualUsersList}
              setUserFilter={this.applyUsersFilter}
              resetUsersFilter={this.resetUsersFilter}
              updateSelectedItems={this.updateSelectedItems}
              usersFilterOptions={this.props.usersFilterOptions}
              usersAppliedFilter={appliedUsersFilter}
              getFilterOptions={this.getUsersFilterOptions}
              setReloadListItems={this.createReloadGroupMembersListItems}
              search={membersSearch}
              onSearchChanged={this.onSearchMembersChanged}
              isInitialValid={this.state.isGroupUsersInitialValid}
              fetchGroupEnrollmentUsers={fetchGroupUsersEnrollment}
              groupEnrollmentUsers={this.props.enrollmentUsers}
              rulesEngineOnCosmos={this.rulesEngineOnCosmos}
            />
          </Restricted>
        </Wizard.Step>

        <Wizard.Step
          className="add-group-owners-step"
          label="Owners"
          isLocked={!isGroupSaved || isLoadingGroupInfo}
          isLockNavigation={isLoadingGroupInfo}
          nextButtonLabel={nextButtonLabel}
        >
          <Dimmer active={isLoadingGroupInfo} inverted>
            <Loader>Processing</Loader>
          </Dimmer>
          <Restricted
            permissions={[]}
            permissionPredicate={this.ownersStepPredicate}
            placeholder={<AccessRestrictedMessage />}
          >
            <GroupOwners
              currentUser={{ email: currentUserEmail, id: currentUserId }}
              accountId={accountId}
              selectedItemIds={groupOwnerIds}
              loadOwners={fetchOwners}
              ownersOptions={this.props.ownersList}
              setOwnerFilter={this.applyOwnersFilter}
              resetOwnerFilter={this.resetOwnersFilter}
              updateSelectedItems={this.updateSelectedOwners}
              filterOptions={this.props.usersFilterOptions}
              ownersAppliedFilter={appliedOwnersFilter}
              getFilterOptions={this.getUsersFilterOptions}
              setReloadListItems={this.createReloadGroupOwnersListItems}
              search={ownersSearch}
              onSearchChanged={this.onSearchOwnersChanged}
              disableSelect={() => groupDomainUtils.hasReadOnlyOwnersAccess(userPermissions)}
            />
          </Restricted>
        </Wizard.Step>

        <Wizard.Step
          label="Licenses"
          isLocked={!isGroupSaved || isLoadingGroupInfo}
          isLockNavigation={isLoadingGroupInfo}
          className="no-padding"
        >
          <>
            <LicensesStep
              selection={{ items: this.state.licensesStep.selectedItems, onSelect: this.onSelectionChange }}
              stepContext={AssignmentPeopleContext.Group}
            />
            <AddPeoplePacksIssueLicenseModal
              userIds={groupUsers.userIds}
              packs={this.state.licensesStep.selectedItems}
              show={this.state.licensesStep.showModal}
              onCancel={this.handleLicensesModalCancel}
              onConfirm={this.handleLicensesModalConfirm}
              onIssueLicensePacksChange={this.onIssueLicensePacksChange}
            />
          </>
        </Wizard.Step>
        <Wizard.Step
          label="Notify"
          isLocked={(shouldNotify && !(isGroupSaved && this.state.notifyStep.isValid)) || isLoadingGroupInfo}
          preRender
        >
          {this.renderInfoBanner()}
          <NotifyWizardStep
            templateType={TemplateTypes.AddedToGroup}
            onLoaded={this.onNotifyStepLoaded}
            onValidChange={this.handleNotifyStepOnValidChange}
            isDisabled={!groupSettings.isAware}
            shouldNotify={shouldNotify}
            communicationChannel={communicationChannel}
            renderSwitch={(switchProps) => (
              <NotifyStepSwitch
                config={notifyConfig}
                onNotifyConfigChange={setNotifyConfig}
                switchProps={switchProps}
              />
            )}
          />
        </Wizard.Step>
      </Wizard>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const { addGroup, usersOverview, enrollmentUsers } = state.people;

  return {
    isContentLoading: addGroup.createGroup.isContentLoading,
    isLoadingGroupInfo: addGroup.createGroup.isLoadingGroupInfo,
    createdGroup: addGroup.createGroup.createdGroup,
    accountId: state.userProfile.accountId,
    enrollmentUsersOptions: addGroup.enrollmentUsersFilterOptions,
    manualUsersList: addGroup.manualUsersFilterOptions,
    ownersList: addGroup.ownersList,
    usersFilterOptions: usersOverview.filterOptions,
    membersSearch: addGroup.membersSearch,
    ownersSearch: addGroup.ownersSearch,
    authenticatedUser: addGroup.authenticatedUser,
    userPermissions: state.userProfile.permissions,
    packContextItems: state.licensing.contentPacks.items,
    flowAssignments: state.licensing.contentPacks.flowAssignments,
    currentUserEmail: state.userProfile.name,
    currentUserId: state.userProfile.id,
    isMobo: state.userProfile.isMobo,
    enrollmentUsers: enrollmentUsers,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    videosActions: bindActionCreators(videosActionsRedux, dispatch),
    addGroupActions: bindActionCreators(addGroupActionsRedux, dispatch),
    wizardActions: bindActionCreators(wizardWrapperActionsRedux, dispatch),
    usersFilterActions: bindActionCreators(usersFilterActionsRedux, dispatch),
    resetContentPacks: bindActionCreators(resetContentPacksAction, dispatch),
    resetNotifyState: bindActionCreators(resetState, dispatch),
    setSelectedContentType: bindAction(setSelectedContentTypeAction, dispatch),
    sendNotification: bindAction(sendTransientNotification, dispatch),
    issuePackLicense: bindActionCreators(issuePackLicenseToPeople, dispatch),
    addUsersToGroups: bindActionCreators(addUsersToGroups, dispatch),
    assignContentToPeople: bindAction(assignContentDistributed, dispatch),
    fetchGroupUsersEnrollment: bindAction(fetchGroupEnrollmentUsers, dispatch),
    resetGroupUsersEnrollment: bindAction(resetEnrollmentUsers, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const component = withFormik({
  validationSchema: (props: Props) => {
    return props.createdGroup.groupId
      ? validationSchemas.getEditGroupSchema(props.createdGroup.groupId)
      : validationSchemas.group;
  },
  mapPropsToValues: () => {
    return {
      name: "",
      description: "",
      imageUrl: "",
    };
  },
  handleSubmit: (values, bag) => {
    bag.resetForm({ values, submitCount: 1 });
  },
})(
  //@ts-ignore
  withNotifyConfig<Props, NotifySettings>(withRouter(withLDConsumer()(AddGroup)), {
    ...initialNotifyConfigDefault,
    shouldNotifyUsers: false,
  }),
);

export default connector(component);
