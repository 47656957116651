import { combineReducers } from "redux";
import flowsReducer from "./Flows/state/flowsReducer";
import videosReducer from "./Videos/state/videosReducer";
import emailsReducer from "./Communications/Emails/state/reducers/emailsReducer";
import assessmentsReducer from "./Assessments/state/assessmentsReducer";
import surveyReducer from "./Surveys/state/surveyReducer";
import threatDefenceEmailTemplatesReducer from "./SimulatedPhishing/EmailTemplates/state/reducers/threatDefenceEmailTemplatesReducer";
import threatDefenceLandingPagesReducer from "./SimulatedPhishing/LandingPages/state/reducers/threatDefenceLandingPagesReducer";
import threatDefenceFeedbackPagesReducer from "./SimulatedPhishing/FeedbackPages/state/reducers/threatDefenceFeedbackPagesReducer";
import threatDefenceCampaignsReducer from "./SimulatedPhishing/Campaigns/state/reducers/threatDefenceCampaignsReducer";
import { threatDefenceSendingProfilesOverview } from "./SimulatedPhishing/SendingProfiles/state/reducers/threatDefenceSendingProfilesOverviewReducer";
import threatDefenceDirectSendSendingProfilesReducer from "./SimulatedPhishing/SendingProfiles/DirectSend/state/reducers/threatDefenceDirectSendSendingProfilesReducer";
import threatDefenceSmtpAuthSendingProfilesReducer from "./SimulatedPhishing/SendingProfiles/SmtpAuth/state/reducers/threatDefenceSmtpAuthSendingProfilesReducer";
import linksReducer from "./Links/state/reducers/linksReducer";
import { eventsReducer } from "./Events/state/reducers/eventsReducer";
import groupsAssignmentReducer from "./PeopleAssignments/state/reducers/groupsAssignmentReducer";
import usersAssignmentReducer from "./PeopleAssignments/state/reducers/usersAssignmentReducer";
import pdfsReducer from "./Pdfs/state/pdfsReducer";
import messagesReducer from "./Communications/Messages/state/reducers/messagesReducer";

const libraryReducer = combineReducers({
  flows: flowsReducer,
  videos: videosReducer,
  pdfs: pdfsReducer,
  userAssignment: usersAssignmentReducer,
  groupAssignment: groupsAssignmentReducer,
  emails: emailsReducer,
  messages: messagesReducer,
  assessments: assessmentsReducer,
  links: linksReducer,
  events: eventsReducer,
  threatDefenceEmailTemplates: threatDefenceEmailTemplatesReducer,
  threatDefenceLandingPages: threatDefenceLandingPagesReducer,
  threatDefenceFeedbackPages: threatDefenceFeedbackPagesReducer,
  threatDefenceCampaigns: threatDefenceCampaignsReducer,
  threatDefenceSendingProfiles: combineReducers({ threatDefenceSendingProfilesOverview }),
  threatDefenceDirectSendSendingProfiles: threatDefenceDirectSendSendingProfilesReducer,
  threatDefenceSmtpAuthSendingProfiles: threatDefenceSmtpAuthSendingProfilesReducer,
  surveys: surveyReducer,
});

export type LibraryState = ReturnType<typeof libraryReducer>;

export { libraryReducer };
