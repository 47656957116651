import { FC, useEffect, useMemo, useState } from "react";
import {
  lineChartFactory,
  noBarData,
  noData,
  roundToTwoDigits,
  validLineData,
  formattedAverageTime,
  PerformanceWithAccountFilter,
  startsColor,
  completesColor,
  lineChartTitles,
  totalActivity,
  dailyActivity,
  starts,
  completes,
} from "features/Library/Common/utils/performanceUtils";
import { ChartWrapper, HorizontalBarChart, LineChart, getDateFormatByCount } from "components/charts";
import CardReporting from "components/cards/CardReporting/CardReporting";
import { ColumnOption } from "interfaces/columnOptions";
import { GenericPerformanceList } from "../../shared/GenericPerformanceList";
import "features/Library/Common/utils/performanceSCSSUtils.scss";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { SurveyDetails, SurveyCards, SurveyActivityCounts } from "features/Reporting/types/content";
import { TextTruncate } from "components";
import { Tooltip } from "components/common/tooltip";
import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import * as contentReportingService from "features/Reporting/services/contentReportingService";
import { DataPoint } from "components/charts/types/HorizontalBarChart";
import { CommonAccountReportPropsExtended } from "../../types";
import { EntityType } from "features/Library/Flows/Designer/types";
import dateTimeUtils from "utils/dateTimeUtils";
import { sharedAccountReportingLineProps, sharedAccountReportingHorizontalBarProps } from "../../shared";

import styles from "../../contentOverviewReports.module.scss";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import { AppDispatch } from "features/Application/globaltypes/redux";
import { bindAction } from "interfaces";
import { setExportAction, setHasDataToExport } from "features/Reporting/state/export/exportSlice";
import { ConnectedProps, connect } from "react-redux";
import { doSurveyExport } from "features/Reporting/state/content/contentActions";
import { bindActionCreators } from "@reduxjs/toolkit";
import { setIsReportEnabled, reset } from "features/Reporting/state/toolbar/toolbarSlice";

export const getCardsReportData = (engagementData: SurveyCards) => {
  return [
    {
      statDescription: "Accounts",
      stat: engagementData.Customers,
    },
    {
      statDescription: `Total ${starts}`,
      stat: engagementData.Started,
    },
    {
      statDescription: `Total ${completes}`,
      stat: engagementData.Completions,
    },
    {
      statDescription: "Completion Rate",
      stat: `${roundToTwoDigits(engagementData.CompletionRate * 100)}%`,
    },
    {
      statDescription: "Avg. Completion Time",
      stat: formattedAverageTime(engagementData.AverageTime),
    },
  ];
};

export type Props = CommonAccountReportPropsExtended & PropsFromRedux;

export type ExtractsEvents<T, K extends keyof T> = T[K];

export type Events = ExtractsEvents<SurveyActivityCounts, "Events">;

export type RestructuredDay = Events & Pick<SurveyActivityCounts, "Date">;

type queryFilter = QueryFunctionContext<[_: string, filter: PerformanceWithAccountFilter], unknown>;

const fetchDailyData = async ({ queryKey }: queryFilter) => {
  const result = (await contentReportingService.getSurveyActivity(queryKey[1])).data;
  let newResult: RestructuredDay[] = [];

  for (const day of result) {
    const obj: Partial<RestructuredDay> = {};
    obj["Date"] = day.Date;
    for (const [key, value] of Object.entries(day.Events)) {
      obj[key as keyof Events] = value;
    }
    newResult.push(obj as RestructuredDay);
  }
  return lineChartFactory(newResult);
};

const fetchEngagementQuery = async ({ queryKey }: queryFilter) => {
  const result = (await contentReportingService.getSurveyEngagement(queryKey[1])).data;

  const usageBarData: DataPoint[] = [
    {
      id: starts.toLocaleLowerCase(),
      category: starts,
      value: result.StartedCount,
      fillColor: startsColor,
    },
    {
      id: completes.toLowerCase(),
      category: completes,
      value: result.CompletedCount,
      fillColor: completesColor,
    },
  ];
  return { ...result, usageBarData };
};

const fetchCardQuery = async ({ queryKey }: queryFilter) => {
  return (await contentReportingService.getSurveyCard(queryKey[1])).data;
};

const fetchDetailsQuery = async ({ queryKey }: queryFilter) => {
  const result = (await contentReportingService.getSurveyDetails(queryKey[1])).data;
  return result.map((item) => {
    const roundedCompletionRate = roundToTwoDigits(item.CompletionRate as number * 100);
    return {
      ...item,
      CompletionRate: roundedCompletionRate,
      id: item.SurveyId,
    };
  });
};

export const SurveyReport: FC<Props> = ({
  filter,
  setSelectedContent,
  setHasExportData,
  setExportAction,
  surveyExport,
  setIsReportEnabled,
  resetIsReportEnabled,
}) => {
  const [chartState, setChartState] = useState(totalActivity);
  const reportEnabled = useFeatureFlag(FeatureFlags.SurveyPerformanceOverview);

  const lineChartQuery = useQuery({
    queryKey: ["SurveyReportDailyData", filter],
    queryFn: fetchDailyData,
    enabled: reportEnabled,
  });

  const engagementQuery = useQuery({
    queryKey: ["SurveyReportEngagement", filter],
    queryFn: fetchEngagementQuery,
    enabled: reportEnabled,
  });

  const cardQuery = useQuery({
    queryKey: ["SurveyReportCard", filter],
    queryFn: fetchCardQuery,
    enabled: reportEnabled,
  });

  const detailsQuery = useQuery({
    queryKey: ["SurveyReportDetails", filter],
    queryFn: fetchDetailsQuery,
    enabled: reportEnabled,
  });

  useEffect(() => {
    setIsReportEnabled(reportEnabled);

    return () => {
      resetIsReportEnabled();
    };
  }, [reportEnabled, setIsReportEnabled, resetIsReportEnabled]);

  useEffect(() => {
    setHasExportData(!!detailsQuery.data && detailsQuery.data.length > 0);
  }, [detailsQuery.data, setHasExportData]);

  // Setting up correct export method if send entity is changed
  useEffect(() => {
    setExportAction({
      method: surveyExport,
      args: [filter],
      isExportEnabled: true,
    });
  }, [surveyExport, filter, setExportAction]);

  const lineData = useMemo(() => {
    if (chartState === dailyActivity) {
      return {
        xData: [lineChartQuery.data?.Date],
        yData: [lineChartQuery.data?.CompletedCount],
      };
    }
    return {
      xData: [lineChartQuery.data?.Date],
      yData: [lineChartQuery.data?.CumulativeCompletedCount],
    };
  }, [chartState, lineChartQuery.data]);

  const cardData = useMemo(() => {
    if (cardQuery.isSuccess) {
      const cards = getCardsReportData(cardQuery.data);
      return filter.showCustomers ? cards : cards.slice(1);
    }
  }, [cardQuery.data, cardQuery.isSuccess, filter.showCustomers]);

  const barDomain = useMemo(() => {
    if (engagementQuery.isSuccess) {
      let maxValue = Math.max(...engagementQuery.data.usageBarData.map((d) => d.value));
      return [0, Math.max(maxValue, 1)];
    }
  }, [engagementQuery.isSuccess, engagementQuery.data?.usageBarData]);

  const columns = useMemo<ColumnOption<SurveyDetails>[]>(
    () => [
      {
        name: "Survey",
        width: 7,
        isSortable: false,
        render(item) {
          return (
            <Tooltip
              target={
                filter.showCustomers ? (
                  <TextTruncate lines={2} className={styles.wordBreak}>
                    {item.SurveyTitle}
                  </TextTruncate>
                ) : (
                  <button
                    className={styles.linkButton}
                    onClick={() =>
                      setSelectedContent({ id: item.SurveyId, type: EntityType.Survey, name: item.SurveyTitle })
                    }
                  >
                    <TextTruncate lines={2} className={styles.wordBreak}>
                      {item.SurveyTitle}
                    </TextTruncate>
                  </button>
                )
              }
              content={item.SurveyTitle}
              showAlways
            />
          );
        },
      },
      {
        render(item) {
          return <div>{item.TotalStarts.toLocaleString()}</div>;
        },
        name: `Total ${starts}`,
        isSortable: false,
        width: 4,
      },
      {
        width: 4,
        name: `Total ${completes}`,
        render(item) {
          return <div>{item.TotalCompletes.toLocaleString()}</div>;
        },
        isSortable: false,
      },
      {
        isSortable: false,
        width: 4,
        name: "Completion Rate",
        render(item) {
          return <div>{item.CompletionRate}%</div>;
        },
      },
      {
        name: "Avg. Completion Time",
        width: 4,
        isSortable: false,
        render(item) {
          return <div>{formattedAverageTime(item.AverageTime)}</div>;
        },
      },
      {
        name: "Last Activity",
        width: 4,
        isSortable: false,
        render(item) {
          return <div>{dateTimeUtils.formatDate(item.LastActivity)}</div>;
        },
      },
    ],
    [filter.showCustomers, setSelectedContent],
  );

  if (!reportEnabled) {
    return <ReportUnavailable />;
  }

  return (
    <div className={styles.reportBody}>
      <div className={styles.lineChartContainer}>
        <ChartWrapper
          titles={lineChartTitles}
          showLegend
          legendLabels={[completes]}
          colorRange={[completesColor]}
          onChange={setChartState}
        >
          <RequestStatusRenderer state={lineChartQuery.status}>
            {lineChartQuery.isSuccess &&
              validLineData([lineChartQuery.data.CompletedCount, lineChartQuery.data.CumulativeCompletedCount]) ? (
              <LineChart
                xFormatterFunc={getDateFormatByCount(lineChartQuery.data.Date.length)}
                colorRange={[completesColor]}
                {...sharedAccountReportingLineProps}
                {...lineData}
              />
            ) : (
              noData(filter)
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className={styles.funnelChartContainer}>
        <ChartWrapper titles={["Engagement"]}>
          <RequestStatusRenderer state={engagementQuery.status}>
            {engagementQuery.isSuccess &&
              !noBarData(engagementQuery.data.StartedCount, engagementQuery.data.CompletedCount) ? (
              <HorizontalBarChart
                {...sharedAccountReportingHorizontalBarProps}
                domain={barDomain}
                data={engagementQuery.data.usageBarData}
              />
            ) : (
              noData(filter)
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className={styles.performanceCardSection}>
        <RequestStatusRenderer state={cardQuery.status}>
          {cardQuery.isSuccess && <CardReporting items={cardData!} />}
        </RequestStatusRenderer>
      </div>
      <div className={styles.table}>
        <RequestStatusRenderer state={detailsQuery.status}>
          {detailsQuery.isSuccess && (
            <GenericPerformanceList title="Surveys" rows={detailsQuery.data} columns={columns} filter={filter} />
          )}
        </RequestStatusRenderer>
      </div>
    </div>
  );
};

export const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setHasExportData: bindAction(setHasDataToExport, dispatch),
  surveyExport: bindActionCreators(doSurveyExport, dispatch),
  setExportAction: bindAction(setExportAction, dispatch),
  setIsReportEnabled: bindAction(setIsReportEnabled, dispatch),
  resetIsReportEnabled: bindAction(reset, dispatch),
});

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SurveyReport);
