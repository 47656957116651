import { BasicLinkButton, DeleteLinkButton, EditLinkButton, TextTruncate } from "components";
import { TemplateOverview, TemplateStatusEnum } from "../types/models";
import { ColumnOption } from "interfaces/columnOptions";
import { Title } from "components/listViewTemplates";
import dateTimeUtils from "utils/dateTimeUtils";
import { Tooltip } from "components/common/tooltip";
import { TemplateStatus } from "./TemplateStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ImageFallbackPlaceholder } from "components/imageFallbackPlaceholder/ImageFallbackPlaceholder";
import { faBuildingUser, faCircleCheck, faCircleX } from "@fortawesome/pro-solid-svg-icons";
import EllipsisPopupButton from "components/buttons/ellipsisPopupButton/EllipsisPopupButton";

import styles from "./TemplateOverview.module.scss";
import { noop } from "lodash";

export enum Columns {
  Name = "Name",
  Owner = "Owner",
  Added = "Added",
  Modified = "Modified",
  Customers = "Customers",
  Users = "Users",
  Status = "Status",
}

export const columnToParamMap: {
  [P in Lowercase<Columns>]: string;
} = {
  name: "name",
  owner: "publisher",
  status: "isDraft",
  modified: "dateModified",
  added: "dateCreated",
  customers: "customers",
  users: "users",
};

export const getColumnOptions = ({
  handleEdit,
  hasEditPermission,
  processTemplate,
  handleDelete,
  onlyOwn,
}: {
  handleEdit: (id: number, isApproved: boolean) => void;
  hasEditPermission: boolean;
  processTemplate: (id: number, approve: boolean, owner: string) => () => void;
  handleDelete: (id: number) => () => void;
  onlyOwn: boolean;
}): ColumnOption<TemplateOverview>[] => {
  return [
    {
      name: "",
      width: 1,
      className: styles["image-column"],
      isSortable: false,
      render: (item) => {
        return item.imageUrl ? (
          <div className={styles["image-container"]}>
            <ImageFallbackPlaceholder
              imageInfo={{
                className: styles["image-wrapper"],
                alt: "avatar",
                src: item.imageUrl,
              }}
            />
          </div>
        ) : (
          <FontAwesomeIcon icon={faBuildingUser} className={styles["image-placeholder"]} size="2x" />
        );
      },
    },
    {
      name: Columns.Name,
      width: 4,
      isSortable: true,
      render: (item) => (
        <Title
          title={item.name}
          onTitleClick={() => {
            return item.isOwn || isApproved(item) || isRequested(item) ? handleEdit(item.id, isApproved(item)) : noop;
          }}
          clamp={2}
          className="normal-color"
        />
      ),
    },
    {
      name: Columns.Owner,
      width: 3,
      isSortable: false,
      render: (item) => <Tooltip target={<TextTruncate>{item.publisher}</TextTruncate>} content={item.publisher} />,
    },
    {
      name: Columns.Added,
      width: 2,
      isSortable: true,
      render: (item) =>
        item.isOwn || item.status === TemplateStatusEnum.Approved ? dateTimeUtils.formatDate(item.dateCreated) : "-",
    },
    {
      name: Columns.Modified,
      width: 2,
      isSortable: true,
      render: (item) =>
        item.isOwn || item.status === TemplateStatusEnum.Approved ? dateTimeUtils.formatDate(item.dateModified) : "-",
    },
    {
      name: Columns.Customers,
      width: 2,
      isSortable: false,
      render: (item) => item.customers,
    },
    {
      name: Columns.Users,
      width: 2,
      isSortable: false,
      render: (item) => item.users,
    },

    {
      name: Columns.Status,
      width: 2,
      isSortable: false,
      isHide: onlyOwn,
      render: (item) => (item.isOwn ? "-" : <TemplateStatus status={item.status} />),
    },
    {
      name: "",
      width: 1,
      className: "align-right",
      render: (item) => (
        <EllipsisPopupButton
          circle
          outlinedEllipsis
          disabled={!item.isOwn && item.status !== TemplateStatusEnum.Pending}
        >
          {item.isOwn
            ? [
                <EditLinkButton
                  onClick={() => handleEdit(item.id, isApproved(item))}
                  isDisabled={hasEditPermission}
                  key="edit"
                />,
                <DeleteLinkButton onClick={handleDelete(item.id)} isDisabled={hasEditPermission} key="delete" />,
              ]
            : [
                <BasicLinkButton
                  text="Approve"
                  onClick={processTemplate(item.id, true, item.publisher)}
                  icon={faCircleCheck}
                  key="approve"
                />,
                <BasicLinkButton
                  text="Decline"
                  onClick={processTemplate(item.id, false, item.publisher)}
                  icon={faCircleX}
                  key="decline"
                />,
              ]}
        </EllipsisPopupButton>
      ),
    },
  ];
};

const isApproved = (item: TemplateOverview): boolean => item.status === TemplateStatusEnum.Approved;
const isRequested = (item: TemplateOverview): boolean => item.status === TemplateStatusEnum.Pending;
