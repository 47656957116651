import { Component, createRef } from "react";
import { DateInput } from "semantic-ui-calendar-react-yz";
import Moment, { updateLocale } from "moment";
import "moment/locale/en-gb";
import cn from "classnames";
import PropTypes from "prop-types";

import "./datePicker.scss";

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.containerRef = createRef();
  }

  markDate = (marked, value) => {
    if (marked) return marked;
    if (value) return undefined; // Value already marks the date without extra work
    return [new Date()];
  };

  componentDidMount() {
    this.updateLocaleFormats();
    this.forceUpdate(); //workaround for rendering in correct node
  }

  updateLocaleFormats() {
    updateLocale("en-gb", {
      months: [
        "January,",
        "February,",
        "March,",
        "April,",
        "May,",
        "June,",
        "July,",
        "August,",
        "September,",
        "October,",
        "November,",
        "December,",
      ],
      weekdaysMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    });
  }

  render() {
    const {
      placeholder,
      value,
      iconPosition,
      closeOnMouseLeave,
      mountNode,
      onChangeDate,
      verticalPosition,
      horizontalPosition,
      minDate,
      maxDate,
      initialDate,
      marked,
      popupPosition,
      format,
      highlightExpired,
      ...additionalProps
    } = this.props;

    return (
      <div className="date-picker" data-testid="date-picker">
        <div
          className={cn("date-picker-container", verticalPosition, horizontalPosition)}
          ref={this.containerRef}
          data-testid="date-picker-container"
        />
        <DateInput
          autoComplete="off"
          marked={this.markDate(marked, value)}
          initialDate={initialDate}
          localization="en"
          dateFormat={format ?? "MM/DD/YYYY"}
          className={cn("input", { "expired": highlightExpired } )}
          closable
          name="date"
          placeholder={placeholder}
          value={value}
          minDate={minDate} //may not work if Value month !== minDate month (error: a white page) but if initialDate is set it works
          maxDate={maxDate}
          iconPosition={iconPosition}
          closeOnMouseLeave={closeOnMouseLeave}
          mountNode={mountNode || this.containerRef.current}
          onChange={onChangeDate}
          popupPosition={popupPosition ?? "bottom left"}
          {...additionalProps}
        />
      </div>
    );
  }
}

DatePicker.defaultProps = {
  value: "",
  verticalPosition: "bottom",
  horizontalPosition: "auto",
  iconPosition: "left",
  placeholder: "",
  closeOnMouseLeave: true,
};

DatePicker.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  format: PropTypes.string,
  iconPosition: PropTypes.string,
  closeOnMouseLeave: PropTypes.bool,
  onChangeDate: PropTypes.func,
  verticalPosition: PropTypes.oneOf(["top", "bottom"]),
  horizontalPosition: PropTypes.oneOf(["auto", "left", "right"]),
  mountNode: PropTypes.node,
  minDate: PropTypes.oneOfType([PropTypes.objectOf(Moment), PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.objectOf(Moment), PropTypes.string]),
  initialDate: PropTypes.oneOfType([PropTypes.objectOf(Moment), PropTypes.string]),
  marked: PropTypes.oneOfType([PropTypes.arrayOf(Moment), PropTypes.arrayOf(Date)]),
  popupPosition: PropTypes.oneOf([
    "top left",
    "top right",
    "bottom left",
    "bottom right",
    "right center",
    "left center",
    "top center",
    "bottom center",
    undefined,
  ]),
  highlightExpired: PropTypes.bool,
};

export default DatePicker;
