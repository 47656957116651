import { Dispatch } from "redux";
import * as editGroupActionTypes from "./editGroupActionTypes";
import dataService from "../../../Application/services/dataServices/dataService";
import {
  getActionBaseProvider,
  getActionProvider,
  makeActionCreator,
  makeFetchActionCreator,
  makeFetchActionCreatorForItems,
} from "../../../Application/actions/actionsBuilder";
import { isForbidden } from "../../../../utils/statusCodeUtils";
import { Dispatcher } from "../../../../interfaces/redux";
import KnownHttpHeaders from "../../../Application/services/knownHttpHeaders";
import enrollmentRulesUtils from "../../../../utils/enrollmentRulesUtils";

export const fetchGroupUsersForList = (
  groupId: number,
  skip: number,
  top: number,
  orderByParams: string,
  queryParams: string,
  isAutoEnrollment: boolean,
  search?: string,
) =>
  makeFetchActionCreatorForItems(async () => {
    if (isAutoEnrollment) {
      const result = await dataService.getUsers(skip, top, orderByParams, queryParams);
      return {
        items: result.items,
        count: result.count,
      };
    }

    const { data, headers } = await dataService.getGroupMembersV2(
      groupId,
      skip,
      top,
      orderByParams,
      queryParams,
      search,
    );

    return {
      items: data,
      count: parseInt(headers[KnownHttpHeaders.RecordsCount]),
    };
  }, editGroupActionTypes.FETCH_GROUP_USERS);

export const resetGroupUsers = makeActionCreator(editGroupActionTypes.RESET_GROUP_USERS);

export const fetchOwners = (groupId: number, queryParams: object) => {
  const begin = getActionBaseProvider(editGroupActionTypes.FETCH_GROUP_OWNERS.FETCH_ITEMS_BEGIN);
  const success = getActionProvider<any>(editGroupActionTypes.FETCH_GROUP_OWNERS.FETCH_ITEMS_SUCCESS);
  const failure = getActionProvider<Error>(editGroupActionTypes.FETCH_GROUP_OWNERS.FETCH_ITEMS_FAILURE);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    try {
      const response = await dataService.getOwners(groupId, queryParams);
      const data = {
        items: response.items,
        itemsCount: response.totalCount,
      };
      dispatch(success(data));
    } catch (error) {
      dispatch(failure(error as Error));
    }
  };
};

export const fetchAssignedGroupOwners = (groupId: number) => {
  const begin = getActionBaseProvider(editGroupActionTypes.FETCH_ASSIGNED_GROUP_OWNERS.FETCH_ITEMS_BEGIN);
  const success = getActionProvider<any>(editGroupActionTypes.FETCH_ASSIGNED_GROUP_OWNERS.FETCH_ITEMS_SUCCESS);
  const failure = getActionProvider<Error>(editGroupActionTypes.FETCH_ASSIGNED_GROUP_OWNERS.FETCH_ITEMS_FAILURE);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    try {
      const result = await dataService.getOwners(groupId);
      const data = {
        items: result.items,
        totalCount: result.itemsCount,
      };
      dispatch(success(data));
    } catch (error) {
      dispatch(failure(error as Error));
    }
  };
};

export const resetGroupInfo = makeActionCreator(editGroupActionTypes.RESET_GROUP_INFO);

export const fetchGroupInfo = (id: number) => {
  const fetchGroupInfoBegin = makeActionCreator(editGroupActionTypes.FETCH_GROUP_INFO_BEGIN);

  const fetchGroupInfoSuccess = makeActionCreator(editGroupActionTypes.FETCH_GROUP_INFO_SUCCESS, "info");

  const fetchGroupInfoFailure = makeActionCreator(editGroupActionTypes.FETCH_GROUP_INFO_FAILURE, "error");

  const fetchGroupInfoForbidden = makeActionCreator(editGroupActionTypes.FETCH_GROUP_INFO_FORBIDDEN, "error");

  return async (dispatch: Dispatch) => {
    dispatch(fetchGroupInfoBegin());
    try {
      const result = await dataService.getGroupInfo(id);
      dispatch(fetchGroupInfoSuccess(result.data));
    } catch (error) {
      isForbidden(error?.response?.status)
        ? dispatch(fetchGroupInfoForbidden(error))
        : dispatch(fetchGroupInfoFailure(error));
    }
  };
};

export const fetchGroupUsersToAdd = (
  groupIds: number[],
  skip: number,
  top: number,
  orderByParams: string,
  queryParams: string,
  search?: string,
) =>
  makeFetchActionCreator(
    () =>
      !!search
        ? dataService.getGroupUsersToAddV2(groupIds, skip, top, orderByParams, queryParams, search)
        : dataService.getGroupUsersToAdd(groupIds, skip, top, orderByParams, queryParams),
    editGroupActionTypes.FETCH_GROUP_USERS_TO_ADD,
  );

export const updateGroupEnrollmentRules = (groupId: number, enrollmentRules: any[]) => {
  const updateGroupEnrollmentRulesBegin = makeActionCreator(editGroupActionTypes.UPDATE_GROUP_ENROLLMENT_RULES_BEGIN);

  const updateGroupEnrollmentRulesSuccess = makeActionCreator(
    editGroupActionTypes.UPDATE_GROUP_ENROLLMENT_RULES_SUCCESS,
    "enrollmentRules",
  );

  const updateGroupEnrollmentRulesFailure = makeActionCreator(
    editGroupActionTypes.UPDATE_GROUP_ENROLLMENT_RULES_FAILURE,
    "error",
  );

  return async (dispatch: Dispatch) => {
    dispatch(updateGroupEnrollmentRulesBegin());
    try {
      const enrollmentRulesInServerFormat = enrollmentRulesUtils.mapRulesToSeverFormat(enrollmentRules);

      await dataService.updateGroupEnrollmentRules(groupId, enrollmentRulesInServerFormat);
      dispatch(updateGroupEnrollmentRulesSuccess(enrollmentRules));
    } catch (error) {
      dispatch(updateGroupEnrollmentRulesFailure(error));
    }
  };
};

export const updateGroupInfo = makeActionCreator(editGroupActionTypes.UPDATE_GROUP_INFO, "info", "settings");

export const setMembersSearch = makeActionCreator(editGroupActionTypes.PEOPLE_EDIT_GROUP_USERS_SET_SEARCH, "search");
export const resetMembersSearch = makeActionCreator(editGroupActionTypes.PEOPLE_EDIT_GROUP_USERS_RESET_SEARCH);

export const setAddMembersSearch = makeActionCreator(
  editGroupActionTypes.PEOPLE_EDIT_GROUP_USERS_TO_ADD_SET_SEARCH,
  "search",
);
export const resetAddMembersSearch = makeActionCreator(
  editGroupActionTypes.PEOPLE_EDIT_GROUP_USERS_TO_ADD_RESET_SEARCH,
);
