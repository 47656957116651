import { FC } from "react";
import { Radio } from "semantic-ui-react";

import { NotifySettings } from "../../../../features/SystemNotifications/types";
import { SwitchProps } from "./types";

export interface DefaultSwitchProps extends SwitchProps<NotifySettings> {}

export const DefaultSwitch: FC<DefaultSwitchProps> = ({ onNotifyConfigChange, config, isDisabled, labelText }) => {
  return (
    <div className="switch">
      <Radio
        label={labelText ?? "Notify recipient(s) now"}
        onChange={(_, { checked }) => onNotifyConfigChange({ key: "shouldNotifyUsers", value: checked })}
        checked={config.shouldNotifyUsers}
        toggle
        disabled={isDisabled}
      />
    </div>
  );
};
