import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";

import { RootState } from "../../../Application/globaltypes/redux";
import * as reportingActions from "../../state/packs/packActions";
import { selectOwnerPacksLicensingState } from "../../state/packs/packSlice";

import SpPackReportList from "./SpPackReportList";
import SpPackReportDrilldown from "./drilldown/SpPackReportDrilldown";
import { RequestStatusRenderer } from "../../../../components/requestStatsRenderer/RequestStatusRenderer";

import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { reportingDataService } from "features/Reporting/services/reportingService";
import {
  PerformanceFilter, lineChartFactory,
  lineChartMargins,
  noBarData,
  noData,
  packTitles,
  transitionTime,
  validLineData,
  completesColor,
  startsColor,
  getBarDomain,
  PerformanceWithAccountFilter,
  defaultDateFilter
} from "features/Library/Common/utils/performanceUtils";
import { ChartWrapper, HorizontalBarChart, LineChart, TickValueType, getDateFormatByCount } from "components/charts";
import { scaleLinear, scaleTime } from "d3";
import cn from "classnames";

import styles from "./spPackReport.module.scss";
import CardReporting from "components/cards/CardReporting/CardReporting";
import { Engagement } from "features/Reporting/types/overview";
import { FeatureFlags } from "featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { ReportingFilter } from "components/reportingFilter/ReportingFilter";
import { OverviewHeader } from "components";
import { AccountTypes } from "features/Accounts/types";
import { fetchPackEngagementQuery } from "features/Reporting/queries/sharedQueries";
import { sharedAccountReportingHorizontalBarProps } from "features/Reporting/Content/shared";

type QueryFilter = QueryFunctionContext<[_: string, filter: PerformanceFilter], unknown>;

const COLOR_SCALE = [startsColor, completesColor];

const getCardsReportData = (engagementData: Engagement) => {
  return [
    {
      statDescription: "Packs",
      stat: engagementData.Packs,
    },
    {
      statDescription: "Licenses",
      stat: engagementData.Licenses,
    },
    {
      statDescription: "Licenses Issued",
      stat: `${engagementData.LicensesIssued}%`,
    },
    {
      statDescription: "Customers",
      stat: engagementData.Customers,
    },
    {
      statDescription: "Avg. Packs/Customers",
      stat: engagementData.AvgPacksCustomers,
    },
  ];
};

const fetchLineChart = async ({ queryKey }: QueryFilter) => {
  const result = (await reportingDataService.getLineChart(queryKey[1])).data;
  return lineChartFactory(result);
};

export const SpPackReport: FC<PropsFromRedux> = ({
  customerId,
  ownerPacksLicensingState,
  actions,
  accountType,
}) => {
  const [filter, setFilter] = useState<PerformanceWithAccountFilter>(defaultDateFilter());
  const isPackReportingOverviewEnabled = !!useFeatureFlag(FeatureFlags.PackReportingOverview);
  const [selectedPack, setSelectedPack] = useState<{ id: number; name: string; }>();
  const lineChartQuery = useQuery({
    queryKey: ["SPPackReportLineChart", filter],
    queryFn: fetchLineChart,
    enabled: isPackReportingOverviewEnabled,
  });

  const engagementQuery = useQuery({
    queryKey: ["SPPackReportEngagement", filter],
    queryFn: fetchPackEngagementQuery,
    enabled: isPackReportingOverviewEnabled,
  });

  useEffect(() => {
    actions.fetchOwnerPacksLicensing(customerId, filter);
  }, [actions, customerId, filter]);

  const handlePackSelectionReset = useCallback(() => setSelectedPack(undefined), []);

  const drilldownActive = useMemo(() => !!selectedPack, [selectedPack]);

  const renderRoot = useMemo(
    () => (
      <div className={cn(styles.body, { [styles["body-report"]]: isPackReportingOverviewEnabled })}>
        {isPackReportingOverviewEnabled && (
          <>
            <div className={styles.header}>
              <h2>Summary</h2>
              <ReportingFilter
                currentFilter={filter}
                callback={setFilter}
                includeAccounts={accountType === AccountTypes.BSI}
              />
            </div>
            <div className={cn(styles.lineChartContainer)}>
              <ChartWrapper
                titles={["Total Pack Activity"]}
                showLegend
                legendLabels={packTitles}
                colorRange={COLOR_SCALE}
              >
                <RequestStatusRenderer state={lineChartQuery.status}>
                  {lineChartQuery.isSuccess &&
                    validLineData([lineChartQuery.data.ActiveTrials, lineChartQuery.data.ActiveCustomers]) ? (
                    <LineChart
                      margins={lineChartMargins}
                      xScaleRatio={scaleTime}
                      yScaleRatio={scaleLinear}
                      xFormatterFunc={getDateFormatByCount(lineChartQuery.data.Date.length)}
                      yTickValueType={TickValueType.IntegersOnly}
                      transitionDuration={transitionTime}
                      xData={[lineChartQuery.data.Date, lineChartQuery.data.Date]}
                      yData={[lineChartQuery.data.ActiveTrials, lineChartQuery.data.ActiveCustomers]}
                      yFormatterFunc={",d"}
                      colorRange={COLOR_SCALE}
                    />
                  ) : (
                    noData(filter)
                  )}
                </RequestStatusRenderer>
              </ChartWrapper>
            </div>

            <div className={cn(styles.barChartContainer)}>
              <ChartWrapper
                titles={["Trials vs Purchased"]}
                showLegend
                legendLabels={packTitles}
                colorRange={COLOR_SCALE}
              >
                <RequestStatusRenderer state={engagementQuery.status}>
                  {engagementQuery.isSuccess &&
                    !noBarData(engagementQuery.data.ActiveTrials, engagementQuery.data.ActiveCustomers) ? (
                    <HorizontalBarChart
                      {...sharedAccountReportingHorizontalBarProps}
                      domain={getBarDomain(engagementQuery.data.flowUsageBarData)}
                      data={engagementQuery.data.flowUsageBarData}
                    />
                  ) : (
                    noData(filter)
                  )}
                </RequestStatusRenderer>
              </ChartWrapper>
            </div>

            <div className={styles.performanceCardSection}>
              <RequestStatusRenderer state={engagementQuery.status}>
                {engagementQuery.isSuccess && <CardReporting items={getCardsReportData(engagementQuery.data)} />}
              </RequestStatusRenderer>
            </div>
          </>
        )}

        {!isPackReportingOverviewEnabled && <OverviewHeader title="Packs and Licensing" />}
        <div className={styles.table}>
          <RequestStatusRenderer state={ownerPacksLicensingState}>
            {
              <SpPackReportList
                reduxFinished
                ownerPackLicenseData={ownerPacksLicensingState.value}
                onPackSelection={setSelectedPack}
              />
            }
          </RequestStatusRenderer>
        </div>
      </div>
    ),
    [
      ownerPacksLicensingState,
      filter,
      lineChartQuery,
      engagementQuery,
      isPackReportingOverviewEnabled,
      accountType,
    ],
  );

  const renderDrilldown = useMemo(
    () => (
      <>
        {selectedPack && (
          <SpPackReportDrilldown
            packId={selectedPack.id}
            packName={selectedPack.name}
            resetSelectedPack={handlePackSelectionReset}
            customerId={customerId}
          />
        )}
      </>
    ),
    [handlePackSelectionReset, selectedPack, customerId],
  );

  return (
    <div className={cn(styles["full-height"], { [styles["full-height-report"]]: isPackReportingOverviewEnabled })}>
      {drilldownActive ? renderDrilldown : renderRoot}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  customerId: state.userProfile.accountId,
  accountType: state.userProfile.accountTypeId,
  ownerPacksLicensingState: selectOwnerPacksLicensingState(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(reportingActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SpPackReport);
