import { FC, useEffect } from "react";

import {
  ContentOverviewReports,
  GraphReport,
  Packs,
  PackOverviewReports,
  DashboardReport,
} from "../../features/Reporting";
import NotFoundMessage from "../../components/notFound/NotFoundMessage";
import { useFeatureFlag } from "../../hooks/useFeatureFlag";
import { FeatureFlags } from "../../featureFlags";
import { Route, Routes } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import { GoogleReport } from "features/Reporting/Google/GoogleReport";
import { AppDispatch, RootState } from "features/Application/globaltypes/redux";
import { bindAction } from "interfaces";
import { fetchIntegrationsAction } from "features/Reporting/state/menu/integrationMenuThunk";
import { ConnectedProps, connect } from "react-redux";
import {
  DeleteIntegrationsSuccess,
  GoogleIntegrationCreateSuccess,
  IntegrationCreateSuccess,
  UpdateGoogleSuccess,
  UpdateIntegrationsIsActiveSuccess,
  UpdateMsGraphIntegrationSettingsSuccess,
} from "features/Application/services/realTimeNotification/events/accounts/accountsEvents";
import { useRtn } from "hooks/useRtn";
import { selectMenuItemsData, selectMenuItemsLoading } from "features/Reporting/state/menu/integrationMenuSlice";
import { ReportingPeople } from "features/Reporting/People/ReportingPeople";

export const events = [
  UpdateMsGraphIntegrationSettingsSuccess,
  UpdateGoogleSuccess,
  DeleteIntegrationsSuccess,
  GoogleIntegrationCreateSuccess,
  IntegrationCreateSuccess,
  UpdateIntegrationsIsActiveSuccess,
];

export const ReportingContent: FC<PropsFromRedux> = ({ integrations, integrationsLoaded, fetchIntegrations }) => {
  const isDashboardReportsEnabled = !!useFeatureFlag(FeatureFlags.DashboardReports);
  const isPackReportingOverviewEnabled = !!useFeatureFlag(FeatureFlags.PackReportingOverview);
  const isTopPackPerformanceEnabled = !!useFeatureFlag(FeatureFlags.PackTopLevelPerformance);
  const isMicrosoftGraphPerformanceEnabled = !!useFeatureFlag(FeatureFlags.MicrosoftGraphReports);
  const isGoogleReportingEnabled = !!useFeatureFlag(FeatureFlags.GoogleReports);
  const isPeopleReportingEnabled = !!useFeatureFlag(FeatureFlags.PeopleReports);

  const isGoogleIntegrationActive = integrations["Google"];
  const isMicrosoftIntegrationEnabled = integrations["MSGraph"];

  useEffect(() => {
    fetchIntegrations();
  }, [fetchIntegrations]);

  useRtn(events, fetchIntegrations);

  return (
    <div className="route-content-container">
      <Routes>
        {!integrationsLoaded && <Route path="*" element={<Loader active />} />}
        {isDashboardReportsEnabled && <Route path="overview/*" element={<DashboardReport />} />}
        {isTopPackPerformanceEnabled && (
          <Route path="packs" element={isPackReportingOverviewEnabled ? <PackOverviewReports /> : <Packs />} />
        )}
        {isMicrosoftGraphPerformanceEnabled && isMicrosoftIntegrationEnabled && (
          <Route path="graph/*" element={<GraphReport />} />
        )}
        <Route path="content/*" element={<ContentOverviewReports />} />
        {isPeopleReportingEnabled && <Route path="people/*" element={<ReportingPeople />} />}
        {isGoogleReportingEnabled && isGoogleIntegrationActive && <Route path="google/*" element={<GoogleReport />} />}
        <Route path="*" element={<NotFoundMessage />} />
      </Routes>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  integrations: selectMenuItemsData(state),
  integrationsLoaded: selectMenuItemsLoading(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchIntegrations: bindAction(fetchIntegrationsAction, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ReportingContent);
