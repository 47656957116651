/* istanbul ignore file */
import { useRef, useEffect, useCallback } from "react";

export function useEventCallback<TResult, TInput extends Array<any>>(
  fn: (...args: TInput) => TResult,
  dependencies: Array<any>,
) {
  const ref = useRef<(...args: TInput) => TResult>(() => {
    throw new Error("Event callback cannot be called during render");
  });

  useEffect(() => {
    ref.current = fn;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fn, ...dependencies]);

  return useCallback(
    (...args: TInput) => {
      const fn = ref.current;
      return fn(...args);
    },
    [ref],
  );
}
