import { Component, createRef, MutableRefObject } from "react";

import validationSchemas from "../../utils/validationSchemas";
import FieldsMapper from "../fieldsMapper/FieldsMapper";
import FileUploadSection from "../fileUploadSection/FileUploadSection";
import { ModalWithSteps } from "../modal";
import PreviewTable from "../previewTable/PreviewTable";

import { TemplateTypes } from "../../enums";
import TaskActions from "../../enums/TaskActions";
import dataService from "../../features/Application/services/dataServices/typedDataService";
import backgroundTasksEventsEmitter from "../../features/BackgroundTasks/events/backgroundTasksEventsEmitter";
import { FileTask, TaskPool } from "../../features/BackgroundTasks/taskPool";
import importUsersTasks from "../../features/People/Users/UsersOverview/backgroundTasks/importUsersTasks";
import { NotifyStepSettings } from "../../interfaces";
import fileUtils, { AcceptCancelationHandler, OnUploadProgressHandler } from "../../utils/fileUtils";

import { Button } from "components/buttons/button/Button";
import ModalSizes from "../../enums/modalSizes";
import { initialNotifyConfigDefault } from "../../features/SystemNotifications/config";
import {
  withNotifyConfig,
  WithNotifyConfigProps,
} from "../../features/SystemNotifications/containers/withNotifyConfig";
import { NotifySettings } from "../../features/SystemNotifications/types";
import { NotifyStep } from "../assignmentModals/commonSteps";
import { NotifyStepSwitch } from "../notifyStep/NotifyStepSwitch";

import "./usersFileUploadModal.scss";

export interface UsersFileUploadModalProps {
  isFileUploading: boolean;
  uploadProgress: number;
  uploadUsersFile: (
    accountId: number,
    actorId: number,
    contentType: string,
    file: File,
    onUploadProgressHandler: OnUploadProgressHandler,
    acceptCancellationHandler: AcceptCancelationHandler,
  ) => void;
  updateUsersFileUploadProgress: () => void;
  cancelUsersFileUpload: () => void;
  resetUsersFileUpload: () => void;
  getUploadedFileColumns: (fileName: string) => void;
  getUsersImportPreviewData: (fileName: string, previewItemsCount: number, arr: any[]) => void;
  resetUsersImportPreviewData: () => void;
  backgroundTasks: TaskPool;
  addBackgroundTask: (task: any) => void;
  removeBackgroundTask: (taskId: number | string) => void;
  cancelBackgroundTask: (taskId: number) => void;
  showGeneralLoaderWithTimeout: (timeout: number, cb: () => void) => void;
  uploadError: string | null;
  uploadedUsersFileColumns: any;
  usersImportPreviewData: any;
  showModal: boolean;
  accountId: number;
  importAccountId?: number;
  actorId: number;
  onClose: () => void;
  onFileDisposed: () => void;
  droppedFile: File | null;
}
export type Props = UsersFileUploadModalProps & WithNotifyConfigProps<NotifySettings>;

interface MappedField {
  mapFrom: any;
  mapTo: any;
  isSkipped: boolean;
  isCustomField: boolean;
}

interface MapToField {
  name: string;
  isRequired?: boolean;
}

interface UsersFileUploadModalState {
  mappedFields: MappedField[];
  titlesForPreview: string[];
  customFields: MapToField[];
  fileName: string;
  originalFileName: string;
  isNotifyStepLoaded: boolean;
  isNotifyStepValid: boolean;
}

export class UsersFileUploadModal extends Component<Props, UsersFileUploadModalState> {
  state = {
    mappedFields: [] as MappedField[],
    titlesForPreview: [] as string[],
    customFields: [] as MapToField[],
    isNotifyStepLoaded: false,
    isNotifyStepValid: false,
    fileName: "",
    originalFileName: "",
  };

  onPreviousNotifyStepRef = createRef() as MutableRefObject<(() => Promise<void>) | undefined>;
  getNotifySettingsRef = createRef() as MutableRefObject<(() => Promise<NotifyStepSettings | undefined>) | undefined>;

  uploadCancellationHandler: { abort: () => void } | null = null;

  requiredMapToFields = [
    { name: "Email", isRequired: true },
    { name: "First Name", isRequired: true },
    { name: "Last Name", isRequired: true },
  ];

  allMapToFields = [
    { name: "ID" },
    ...this.requiredMapToFields,
    { name: "Department" },
    { name: "Job Title" },
    { name: "Country" },
  ];

  previewRowDataSequenceAndOptions = [
    { name: "id", clamp: 1 },
    { name: "email", clamp: 1 },
    { name: "firstName", clamp: 1 },
    { name: "lastName", clamp: 1 },
    { name: "department", clamp: 1 },
    { name: "jobTitle", clamp: 1 },
    { name: "country", clamp: 1 },
  ];

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.showModal !== this.props.showModal && !this.props.showModal) {
      this.props.notifyConfigPayload.resetNotifyConfig();
    }
  }

  onCloseModal = () => {
    const { onClose, isFileUploading, resetUsersFileUpload, resetUsersImportPreviewData, onFileDisposed } = this.props;
    if (isFileUploading) {
      this.cancelUsersFileUpload();
    }
    onClose();
    resetUsersFileUpload();
    resetUsersImportPreviewData();
    onFileDisposed();
  };

  cancelUsersFileUpload = () => {
    this.props.cancelUsersFileUpload();
    this.uploadCancellationHandler?.abort();
  };

  acceptCancellationHandler = (uploadCancellationHandler: { abort: () => void }) => {
    this.uploadCancellationHandler = uploadCancellationHandler;
  };

  areAllRequiredFieldsMapped = (mapToFields: MapToField[]) => {
    const { mappedFields } = this.state;

    const requiredFieldNames = mapToFields.filter((field) => field.isRequired).map((field) => field.name);

    return (
      mappedFields.length > 0 &&
      !mappedFields.some((field) => requiredFieldNames.some((name) => field.mapTo === name) && !field.mapFrom)
    );
  };

  getMappedFieldsForApiRequest = () =>
    this.state.mappedFields
      .filter((f) => f.mapFrom && f.mapTo && !f.isSkipped)
      .map((f) => ({
        mapFrom: f.mapFrom,
        mapTo: f.mapTo,
        isCustomField: f.isCustomField,
      }));

  renderFileUploadStepActions = (nextStep: () => void) => {
    const { uploadProgress, uploadError, isFileUploading, uploadedUsersFileColumns, onFileDisposed } = this.props;

    return () => (
      <>
        <Button primary disabled content="Previous" />
        <Button
          blur
          primary
          disabled={
            uploadProgress !== 100 ||
            uploadError ||
            isFileUploading ||
            uploadedUsersFileColumns.isLoading ||
            uploadedUsersFileColumns.items === 0
          }
          content="Next"
          onClick={() => {
            onFileDisposed();
            nextStep();
          }}
        />
      </>
    );
  };

  renderFieldsMapperStepActions = (nextStep: () => void, prevStep: () => void) => {
    const { getUsersImportPreviewData } = this.props;
    const { fileName } = this.state;

    return () => (
      <>
        <Button primary content="Previous" onClick={prevStep} />
        <Button
          primary
          content="Next"
          disabled={!this.areAllRequiredFieldsMapped(this.allMapToFields)}
          onClick={() => {
            const previewItemsCount = 11;
            getUsersImportPreviewData(fileName, previewItemsCount, this.getMappedFieldsForApiRequest());
            nextStep();
          }}
        />
      </>
    );
  };

  renderPreviewTableStepActions = (nextStep: () => void, prevStep: () => void) => {
    const { usersImportPreviewData, resetUsersImportPreviewData } = this.props;
    return () => (
      <>
        <Button
          blur
          primary
          disabled={usersImportPreviewData.isLoading}
          content="Previous"
          onClick={() => {
            resetUsersImportPreviewData();
            prevStep();
          }}
        />
        <Button
          primary
          content="Next"
          disabled={usersImportPreviewData.isLoading}
          onClick={() => {
            nextStep();
          }}
        />
      </>
    );
  };

  renderNotifyStepActions = (_nextStep: () => void, prevStep: () => void) => {
    const {
      getUsersImportPreviewData,
      addBackgroundTask,
      removeBackgroundTask,
      cancelBackgroundTask,
      showGeneralLoaderWithTimeout,
      notifyConfig,
      importAccountId,
    } = this.props;
    const { fileName, originalFileName, isNotifyStepLoaded, isNotifyStepValid } = this.state;
    return (closeModal: () => void) => (
      <>
        <Button
          primary
          content="Previous"
          onClick={() => {
            this.onPreviousNotifyStepRef?.current?.();
            const previewItemsCount = 11;
            getUsersImportPreviewData(fileName, previewItemsCount, this.getMappedFieldsForApiRequest());
            prevStep();
          }}
        />
        <Button
          primary
          content="Finish"
          disabled={notifyConfig.shouldNotifyUsers && (!isNotifyStepLoaded || !isNotifyStepValid)}
          onClick={async () => {
            const notifySettings = await this.getNotifySettingsRef?.current?.();

            showGeneralLoaderWithTimeout(3000, async () => {
              const data = await (importAccountId
                ? dataService.users.startUsersImportForAccount(
                    fileName,
                    importAccountId,
                    this.getMappedFieldsForApiRequest(),
                    notifySettings,
                  )
                : dataService.users.startUsersImport(fileName, this.getMappedFieldsForApiRequest(), notifySettings));

              addBackgroundTask(importUsersTasks.InProgress(data.id, originalFileName));
              backgroundTasksEventsEmitter.updateHandlerOnce(TaskActions.close, data.id, removeBackgroundTask);
              backgroundTasksEventsEmitter.updateHandlerOnce(TaskActions.cancel, data.id, async (taskId: number) => {
                await dataService.users.cancelUsersImportTask(data);
                cancelBackgroundTask(taskId);
              });
              backgroundTasksEventsEmitter.updateHandler(TaskActions.click, data.id, (taskId: number) => {
                if ((this.props.backgroundTasks[taskId] as FileTask).fileUri) {
                  fileUtils.downloadFile((this.props.backgroundTasks[taskId] as FileTask).fileUri);
                }
              });
            });

            closeModal();
            this.onCloseModal();
          }}
        />
      </>
    );
  };

  onMappingChange = (newMappedFields: MappedField[]) => {
    const titlesForPreview = this.getTitlesForPreview(newMappedFields, this.allMapToFields);

    this.setState({
      mappedFields: newMappedFields,
      titlesForPreview: titlesForPreview,
    });
  };

  onFileChange = (fileName: string, originalFileName: string) => {
    const { getUploadedFileColumns } = this.props;
    getUploadedFileColumns(fileName);
    this.setState({
      mappedFields: [],
      fileName: fileName,
      originalFileName: originalFileName,
      customFields: [],
    });
  };

  onCustomFieldChange = (newCustomFields: MapToField[]) => {
    this.setState({
      customFields: newCustomFields,
    });
  };

  getTitlesForPreview = (mappedFields: MappedField[], mapToFields: MapToField[]) => {
    const titlesForPreview = mappedFields.filter((f) => f.mapFrom && f.mapTo && !f.isSkipped).map((f) => f.mapTo);

    return mapToFields.map((f) => f.name).filter((name) => titlesForPreview.includes(name));
  };

  getDroppedFiles() {
    const { droppedFile } = this.props;
    return !droppedFile ? [] : [droppedFile];
  }

  setIsDataValid = (isNotifyStepValid: boolean) => {
    this.setState({ isNotifyStepValid });
  };

  setIsDataLoaded = (isNotifyStepLoaded: boolean) => {
    this.setState({ isNotifyStepLoaded });
  };

  render() {
    const {
      showModal,
      accountId,
      actorId,
      isFileUploading,
      uploadProgress,
      uploadError,
      uploadUsersFile,
      updateUsersFileUploadProgress,
      uploadedUsersFileColumns,
      usersImportPreviewData,
      notifyConfig,
      notifyConfigPayload: { setNotifyConfig, shouldNotify, communicationChannel },
    } = this.props;

    const { mappedFields, titlesForPreview, customFields, isNotifyStepLoaded } = this.state;

    return (
      <ModalWithSteps
        className="users-file-upload-modal"
        showModal={showModal}
        onCancel={this.onCloseModal}
        scrolling
        size={ModalSizes.large}
        isLoading={usersImportPreviewData.isLoading}
      >
        <FileUploadSection
          header="Upload file"
          accountId={accountId}
          actorId={actorId}
          renderModalActions={this.renderFileUploadStepActions}
          isUploading={isFileUploading}
          uploadProgress={uploadProgress}
          uploadError={uploadError}
          uploadFile={uploadUsersFile}
          initialFiles={this.getDroppedFiles()}
          cancelFileUpload={this.cancelUsersFileUpload}
          updateProgress={updateUsersFileUploadProgress}
          acceptFileFormat=".csv"
          label="File (.csv)"
          propertyName="files"
          validationSchema={validationSchemas.usersFileUpload}
          acceptCancellationHandler={this.acceptCancellationHandler}
          onFileChange={this.onFileChange}
        >
          <p>
            Start by downloading this&nbsp;
            <span className="link" onClick={dataService.users.getImportFileTemplate} title="Download">
              template&nbsp;
            </span>
            to ensure your file is formatted correctly.
          </p>
        </FileUploadSection>

        <FieldsMapper
          header="Map Fields"
          renderModalActions={this.renderFieldsMapperStepActions}
          mapFromColumnTitle="CSV Column"
          mapToColumnTitle="Field Name"
          mapFromFields={uploadedUsersFileColumns.items}
          mapToFields={
            uploadedUsersFileColumns.items.length > this.requiredMapToFields.length
              ? this.allMapToFields
              : this.requiredMapToFields
          }
          onMappingChange={this.onMappingChange}
          onCustomFieldChange={this.onCustomFieldChange}
          mappedFields={mappedFields}
          customFields={customFields}
          allowCustomFields
        />

        <PreviewTable
          header="Mapping Confirmation"
          renderModalActions={this.renderPreviewTableStepActions}
          titles={titlesForPreview}
          rowDataSequenceAndOptions={this.previewRowDataSequenceAndOptions}
          items={usersImportPreviewData.items}
          withGradient={usersImportPreviewData.itemsCount > 11}
          itemsAmount={usersImportPreviewData.itemsCount}
        />

        <NotifyStep
          preRender
          header="Notify"
          renderModalActions={this.renderNotifyStepActions}
          onIsDataValidChange={this.setIsDataValid}
          onIsDataLoaded={this.setIsDataLoaded}
          isDataLoaded={isNotifyStepLoaded}
          onPreviousNotifyStepRef={this.onPreviousNotifyStepRef}
          getNotifySettingsRef={this.getNotifySettingsRef}
          notifyTemplateType={TemplateTypes.WelcomeEmail}
          renderSwitch={(switchProps) => (
            <NotifyStepSwitch config={notifyConfig} onNotifyConfigChange={setNotifyConfig} switchProps={switchProps} />
          )}
          shouldNotify={shouldNotify}
          communicationChannel={communicationChannel}
        />
      </ModalWithSteps>
    );
  }
}

export default withNotifyConfig<UsersFileUploadModalProps, NotifySettings>(
  UsersFileUploadModal,
  initialNotifyConfigDefault,
);
