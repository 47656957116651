import { useRef, useMemo, useEffect } from "react";
import * as d3 from "d3";
import { useDimensions } from "../../../hooks/useDimensions";
import { useChartResizeTransition } from "../hooks/useChartResizeTransition";
import { ChartPopup } from "../shared/ChartPopup/ChartPopup";
import { DEFAULT_MARGIN } from "../shared/constants";
import {
  APPEND_ONCE,
  calculateChartDimensions,
  drawCenteringGroup,
  getTranslateString,
  textEllipsis,
} from "../shared/helpers";
import { getTicksFromValueTypes } from "../shared/public";
import { useChartPopupReducer } from "../shared/ChartPopup/state";
import { ColumnDataPoint, Props } from "../types/ColumnChart";

import "./columnChart.scss";

const DEFAULT_FILL = "#686569";

export const ColumnChart: React.FC<Props> = ({
  margins = DEFAULT_MARGIN,
  domain = [0, 100],
  data,
  valueScale = d3.scaleLinear,
  barPadding = 0.6,
  transitionDurationInMs = 800,
  tooltipFormatter = (d) => d.value.toLocaleString(),
  defaultBarColor = DEFAULT_FILL,
  xAxisTickFormat = (d) => d.toLocaleString(),
  yAxisTickFormat = (d) => d.toLocaleString(),
  yTickValueType,
  onClick,
  shouldScroll = false,
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const yAxisRef = useRef<SVGSVGElement>(null);
  const onClickRef = useRef(onClick);
  const tooltipFormatterRef = useRef(tooltipFormatter);
  const [popupState, dispatchPopup] = useChartPopupReducer();

  const [measurements, measureRef] = useDimensions();
  const [transitionDurationRef, resizeRef] = useChartResizeTransition(transitionDurationInMs);

  useEffect(() => {
    onClickRef.current = onClick;
  }, [onClick]);

  useEffect(() => {
    tooltipFormatterRef.current = tooltipFormatter;
  }, [tooltipFormatter]);

  const { totalWidth, totalHeight, innerWidth, innerHeight } = useMemo(() => {
    const result = calculateChartDimensions(measurements, margins);

    let totalWidth: number;

    if (shouldScroll) {
      // The 50 below is definitely a magic number,
      // and is a placeholder until we can determine
      // the correct formula for width to show X number
      // of bars at a time
      totalWidth = Math.max(data.length * 50, result.totalWidth) - margins.left - margins.right;
    } else {
      totalWidth = result.totalWidth - margins.left - margins.right;
    }

    return {
      ...result,
      totalWidth,
      innerWidth: totalWidth - 10,
    };
  }, [measurements, margins, data.length, shouldScroll]);

  /**
   * Creating D3 functions/scales
   */

  const categories = useMemo(() => data.map((d) => d.category), [data]);

  const xScale = useMemo(() => {
    return d3.scaleBand().domain(categories).range([0, innerWidth]).padding(barPadding);
  }, [barPadding, categories, innerWidth]);

  const yScale = useMemo(() => {
    return valueScale().domain(domain).range([innerHeight, 0]).nice();
  }, [domain, innerHeight, valueScale]);

  const xAxis = useMemo(
    () => d3.axisBottom(xScale).tickSizeOuter(0).tickFormat(xAxisTickFormat).tickSize(0).tickPadding(5.5),
    [xAxisTickFormat, xScale],
  );

  const yAxis = useMemo(() => {
    const tickValues = getTicksFromValueTypes(yScale, yTickValueType);
    return d3.axisLeft(yScale).tickSize(-innerWidth).tickFormat(yAxisTickFormat).tickValues(tickValues).ticks(5);
  }, [innerWidth, yAxisTickFormat, yScale, yTickValueType]);

  // Draw the y-axis seperately in case of scrolling
  useEffect(() => {
    // On first run its common for the svg measurements to be 0
    // This causes issues when first drawing the line chart
    const invalidDimensions = innerWidth <= 0 || innerHeight <= 0;
    // There are issues when measuring things with JSDOM,
    // so this will always return early in test environments
    if (process.env.NODE_ENV !== "test" && invalidDimensions) return;

    const svg = d3.select(yAxisRef.current!);
    // Add y grid lines with labels
    const yAxisGroup = svg
      .selectAll<SVGGElement, boolean[]>(".y-axis-group")
      .data(APPEND_ONCE)
      .join(
        (enter) =>
          enter
            .append("g")
            .attr("class", "y-axis-group")
            // Not inside centering group, so have to offset myself
            .attr("transform", getTranslateString(margins.left, margins.top))
            .call(yAxis),
        (update) => {
          update
            .transition()
            .duration(transitionDurationRef.current)
            .attr("transform", getTranslateString(margins.left, margins.top))
            .call(yAxis);
          return update;
        },
      );
    yAxisGroup.select(".domain").remove();
    // yAxisGroup.selectAll("line").attr("class", "axisLine");
    // Lines will be drawn below
    yAxisGroup.selectAll("line").remove();
    yAxisGroup.selectAll<SVGTextElement, unknown>("text").attr("class", "axisText");
  }, [yAxis, margins.left, margins.top, transitionDurationRef, innerWidth, innerHeight]);

  // Draw the chart
  useEffect(() => {
    // On first run its common for the svg measurements to be 0
    // This causes issues when first drawing the line chart
    const invalidDimensions = innerWidth <= 0 || innerHeight <= 0;
    // There are issues when measuring things with JSDOM,
    // so this will always return early in test environments
    if (process.env.NODE_ENV !== "test" && invalidDimensions) return;

    const svg = d3.select(svgRef.current!);
    const mainArea = drawCenteringGroup(svg, 0, margins.top, transitionDurationRef.current);

    const xAxisEllipsis = (_data: unknown, index: number, elements: ArrayLike<SVGTextElement>) => {
      textEllipsis(elements[index], xScale.bandwidth(), -4, [elements[0], elements[1]]);
    };

    // Add X grid lines with labels
    const xAxisGroup = mainArea
      .selectAll<SVGGElement, boolean[]>(".x-axis-group")
      .data(APPEND_ONCE)
      .join(
        (enter) =>
          enter.append("g").attr("class", "x-axis-group").attr("transform", `translate(0, ${innerHeight})`).call(xAxis),
        (update) => {
          update.attr("transform", `translate(0, ${innerHeight})`).call(xAxis);
          return update;
        },
      );
    xAxisGroup.selectAll(".domain").remove();
    xAxisGroup.selectAll("line").attr("class", "axisLine");
    xAxisGroup
      .selectAll<SVGTextElement, unknown>("text")
      .attr("class", "axisText")
      .each(xAxisEllipsis)
      .on("mouseover", function (_d, i) {
        // If the text is not truncated, don't show the tooltip
        if (!this.textContent?.endsWith("\u2026")) {
          return;
        }

        const siblingLine = d3.select(this).node()?.previousElementSibling;
        const originalTextContent = d3.select(this).data()[0] as string;
        if (!siblingLine || !originalTextContent) return;

        const measure = siblingLine.getBoundingClientRect();

        dispatchPopup({
          type: "SET_POPUP",
          payload: {
            isOpen: true,
            textContent: xAxisTickFormat(originalTextContent, i),
            // Magic 3 is a slight offset to align with the tick
            position: { x: measure.x - 3, y: measure.y },
            isTop: true,
          },
        });
      })
      .on("mouseleave", function () {
        dispatchPopup({ type: "CLOSE_POPUP" });
      });

    // Add y grid lines with labels
    const yAxisGroup = mainArea
      .selectAll<SVGGElement, boolean[]>(".y-axis-group")
      .data(APPEND_ONCE)
      .join(
        (enter) => enter.append("g").attr("class", "y-axis-group").attr("transform", `translate(0, 0)`).call(yAxis),
        (update) => {
          update.transition().duration(transitionDurationRef.current).call(yAxis);
          return update;
        },
      );
    yAxisGroup.select(".domain").remove();
    yAxisGroup.selectAll("line").attr("class", "axisLine");
    // Text is drawn in a seperate useEffect in order to keep it
    yAxisGroup.selectAll("text").remove();

    function getBarHeight(d: ColumnDataPoint) {
      return innerHeight - yScale(d.value)!;
    }

    function baseBarTransition(
      selection: d3.Selection<SVGRectElement, ColumnDataPoint, d3.BaseType | SVGGElement, boolean>,
    ) {
      selection
        .transition()
        .duration(transitionDurationRef.current)
        .attr("y", (d) => innerHeight - getBarHeight(d) / 2)
        .attr("height", (d) => getBarHeight(d) / 2);
    }

    function roundedBarTransition(
      selection: d3.Selection<SVGRectElement, ColumnDataPoint, d3.BaseType | SVGGElement, boolean>,
    ) {
      selection
        .transition()
        .duration(transitionDurationRef.current)
        .attr("y", (d) => yScale(d.value)!)
        .attr("height", getBarHeight);
    }

    const getYCenterPosition = (d: ColumnDataPoint) => {
      return yScale(d.value)! + getBarHeight(d) / 2;
    };

    const getCenterString = (d: ColumnDataPoint) => {
      return `translate(${xScale(d.category)! + xScale.bandwidth() / 2},${getYCenterPosition(d)})`;
    };

    const centers = mainArea
      .selectAll(".centers")
      .data(data)
      .join(
        (enter) => enter.append("g").attr("class", "centers").attr("transform", getCenterString),
        (update) => update.attr("transform", getCenterString),
      );

    function mouseOver(this: d3.BaseType, point: ColumnDataPoint, index: number) {
      const center = centers.nodes()[index] as SVGGElement;
      const { x, y } = center.getBoundingClientRect();
      dispatchPopup({
        type: "SET_POPUP",
        payload: {
          position: { x, y },
          isOpen: true,
          textContent: tooltipFormatterRef.current(point),
          isTop: false,
        },
      });
    }

    function mouseLeave() {
      dispatchPopup({ type: "CLOSE_POPUP" });
    }

    // Draw the bars
    mainArea
      .selectAll(".baseRect")
      .data(data)
      .join(
        (enter) =>
          enter
            .append("rect")
            .attr("class", "baseRect")
            .attr("x", (d) => xScale(d.category)!)
            .attr("y", innerHeight)
            .attr("height", 0)
            .attr("width", xScale.bandwidth())
            .attr("fill", (d) => d.fillColor ?? defaultBarColor)
            .call(baseBarTransition),
        (update) =>
          update
            .attr("x", (d) => xScale(d.category)!)
            .attr("width", xScale.bandwidth())
            .attr("fill", (d) => d.fillColor ?? defaultBarColor)
            // Ignore is below because of type of argument, but it is acceptable
            // @ts-ignore
            .call(baseBarTransition),
      );

    const bars = mainArea
      .selectAll(".roundedRect")
      .data(data)
      .join(
        (enter) =>
          enter
            .append("rect")
            .attr("class", "roundedRect")
            .attr("x", (d) => xScale(d.category)!)
            .attr("y", innerHeight)
            .attr("height", 0)
            .attr("width", xScale.bandwidth())
            .attr("rx", xScale.bandwidth() / 2)
            .attr("ry", xScale.bandwidth() / 2)
            .attr("fill", (d) => d.fillColor ?? defaultBarColor)
            .call(roundedBarTransition),
        (update) =>
          update
            .attr("x", (d) => xScale(d.category)!)
            .attr("width", xScale.bandwidth())
            .attr("fill", (d) => d.fillColor ?? defaultBarColor)
            .attr("rx", xScale.bandwidth() / 2)
            .attr("ry", xScale.bandwidth() / 2)
            // Ignore is below because of type of argument, but it is acceptable
            // @ts-ignore
            .call(roundedBarTransition),
      )
      .on("mouseover", mouseOver)
      .on("mouseleave", mouseLeave);

    return () => {
      bars.on("mouseover", null).on("mouseleave", null);
    };
  }, [
    data,
    defaultBarColor,
    dispatchPopup,
    innerHeight,
    innerWidth,
    margins.top,
    transitionDurationRef,
    xAxis,
    xAxisTickFormat,
    xScale,
    yAxis,
    yScale,
  ]);

  return (
    <div ref={measureRef} className="columnChartRoot">
      <div ref={resizeRef} className="full">
        <svg
          className="y-axis-svg"
          ref={yAxisRef}
          width={measurements?.width ?? 0}
          height={measurements?.height ?? 0}
        />
        <div
          className="scrollable"
          // Need inline styles for dynamic widths
          style={{
            width: `calc(100% - ${margins.left}px - ${margins.right}px)`,
            marginLeft: margins.left,
            marginRight: margins.right,
          }}
        >
          <svg
            ref={svgRef}
            width={totalWidth}
            height={totalHeight}
            data-testid="column chart svg"
            preserveAspectRatio="none"
          />
          <ChartPopup open={popupState.isOpen} left={popupState.position.x} top={popupState.position.y}>
            <span>{popupState.textContent}</span>
          </ChartPopup>
        </div>
      </div>
    </div>
  );
};
