import { TextTruncate } from "components";
import { Tooltip } from "components/common/tooltip";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { RouteNames } from "enums";
import SortDirections from "enums/sortDirections";
import { RootState, AppDispatch } from "features/Application/globaltypes/redux";
import { PerformanceFilter, formattedAverageTime } from "features/Library/Common/utils/performanceUtils";
import { GenericPerformanceList } from "features/Reporting/Content/shared/GenericPerformanceList";
import { bindAction } from "interfaces";
import { ColumnOption } from "interfaces/columnOptions";
import moment from "moment";
import { FC, useEffect, useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import dateTimeUtils from "utils/dateTimeUtils";
import { selectPeopleDetailsValue, selectPeopleDetails, setTableSort } from "../state/slices/flowPerformanceSlice";
import { FlowPeopleDetails } from "../types/performance";
import { setHasDataToExport } from "features/Reporting/state/export/exportSlice";
import { RStatus } from "features/Application/globaltypes/fetchRequest";

import styles from "./flowPerformancePeople.module.scss";

export type FlowPerformancePeopleProps = {
  filter: PerformanceFilter;
} & PropsFromRedux;

const columnOptions: ColumnOption<FlowPeopleDetails>[] = [
  {
    name: "NAME",
    width: 5,
    isSortable: false,
    render: (entity: FlowPeopleDetails) => {
      return (
        <Link to={`/${RouteNames.peopleUsers}/${entity.UserId}`} className={styles.linkButton}>
          <Tooltip
            target={
              <TextTruncate>
                <Link to={`/${RouteNames.peopleUsers}/${entity.UserId}`}>
                  {entity.FirstName} {entity.LastName}
                </Link>
              </TextTruncate>
            }
            content={`${entity.FirstName} ${entity.LastName}`}
          />
        </Link>
      );
    },
  },
  {
    name: "EMAIL",
    width: 5,
    isSortable: false,
    render: (entity: FlowPeopleDetails) => (
      <div>
        <Tooltip target={<TextTruncate>{entity.Email}</TextTruncate>} content={entity.Email} />
        <div>&nbsp;</div>
      </div>
    ),
  },
  {
    name: "DATE STARTED",
    width: 5,
    isSortable: true,
    render: (entity: FlowPeopleDetails) => {
      const date = entity.StartDate ? dateTimeUtils.formatDate(entity.StartDate) : "-";

      return (
        <div>
          <div>{date}</div>
          <div>&nbsp;</div>
        </div>
      );
    },
  },
  {
    name: "DATE COMPLETED",
    width: 5,
    isSortable: true,
    render: (entity: FlowPeopleDetails) => {
      const date = entity.CompletionDate !== null ? dateTimeUtils.formatDate(entity.CompletionDate) : "-";

      return (
        <div>
          <div>{date}</div>
          <div>&nbsp;</div>
        </div>
      );
    },
  },
  {
    name: "COMPLETION TIME",
    width: 5,
    isSortable: true,
    render: (entity: FlowPeopleDetails) => {
      const completionTime = entity.CompletionTime
        ? formattedAverageTime(moment.duration(entity.CompletionTime).asSeconds())
        : "-";
      return (
        <div>
          <div>{completionTime}</div>
          <div>&nbsp;</div>
        </div>
      );
    },
  },
];

export const FlowPerformancePeopleComponent: FC<FlowPerformancePeopleProps> = ({
  filter,
  peopleDetails,
  peopleDetailsStatus,
  setTableSort,
  setHasExportData,
}) => {

  useEffect(() => {
    setHasExportData(peopleDetailsStatus.status === RStatus.Got && !!peopleDetails.length);
  }, [peopleDetails, setHasExportData, peopleDetailsStatus.status]);

  useEffect(() => {
    return () => {
      setTableSort([null, null]);
    };
  }, [setTableSort]);

  const handleSort = useCallback(
    (columnName: string, dir: SortDirections) => {
      setTableSort([columnName, dir]);
    },
    [setTableSort],
  );

  return (
    <RequestStatusRenderer state={peopleDetailsStatus}>
      <GenericPerformanceList
        rows={peopleDetails}
        columns={columnOptions}
        filter={filter}
        title="People"
        onSort={handleSort}
      />
    </RequestStatusRenderer>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    peopleDetails: selectPeopleDetailsValue(state),
    peopleDetailsStatus: selectPeopleDetails(state),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setTableSort: bindAction(setTableSort, dispatch),
  setHasExportData: bindAction(setHasDataToExport, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FlowPerformancePeopleComponent);
