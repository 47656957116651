import React, { FC } from "react";
import Segments from "../../../components/navigation/segments/Segments";
import { DetailsHeader } from "../../../components/sectionHeader";
import { OnedriveReport } from "./Onedrive/OnedriveReport";
import SharepointReport from "./Sharepoint/SharepointReport";
import { TeamsReport } from "./Teams/TeamsReport";
import { Navigate, Route, Routes } from "react-router-dom";

const pages = [
  { to: "teams", label: "Teams" },
  { to: "onedrive", label: "OneDrive" },
  { to: "sharepoint", label: "SharePoint" },
];

export const GraphReport: FC = () => {
  return (
    <div className="nested-content">
      <DetailsHeader title="Microsoft Graph" />
      <Segments to={"/reporting/graph"}>
        {pages.map((page, i) => (
          <Segments.Segment key={i} {...page} />
        ))}
      </Segments>

      <Routes>
        <Route path="teams" element={<TeamsReport />} />
        <Route path="onedrive" element={<OnedriveReport />} />
        <Route path="sharepoint" element={<SharepointReport />} />
        <Route path="*" element={<Navigate to="teams" replace />} />
      </Routes>
    </div>
  );
};
