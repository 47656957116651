import { combineReducers, Reducer } from "redux";

import { ReducerNamespaceTypes, ReducerEntityPrefixTypes } from "../../../../enums";
import * as actionTypes from "./editGroupActionTypes";
import { withFetchingChildEntityListItems } from "../../../Application/reducers/hoc/withFetchingItems";
import { Visibility, Awareness, Access } from "../../../../enums/groups";
import Enrollment from "../../../../enums/groups/enrollment";
import withSearch from "../../../Application/reducers/hoc/withSearch";
import groupPacksGridSlice from "../../../Licensing/Packs/state/slices/groupPacksGridSlice";
import enrollmentRulesUtils from "../../../../utils/enrollmentRulesUtils";
import { EnrollmentRule } from "../AddEditGroupForms/AutomaticallyDetect/AutomaticallyDetect";

const namespace = ReducerNamespaceTypes.People;
const entityPrefix = ReducerEntityPrefixTypes.EditGroup;

interface GroupInfo {
  isAware: Awareness;
  isVisibleToAll: Visibility;
  isOpen: Access;
  isWithAutoEnroll: Enrollment;
  enrollmentRules?: EnrollmentRule[];
}

interface GroupAction<T> {
  type: string;
  payload: T | null;
}

interface GroupState {
  info?: GroupInfo;
  data: {
    enrollmentRules?: any[];
  };
  settings: {
    isAware: Awareness | null;
    isOpen: Access | null;
    isVisibleToAll: Visibility | null;
    isWithAutoEnroll: Enrollment | null;
  };
  isLoading: boolean;
  error: string | null;
  isAccessAuthorized: boolean;
}

interface EditGroup extends GroupState {
  editInfo?: GroupInfo;
  enrollmentRules?: any[];
}

const initialState: EditGroup = {
  data: {},
  settings: {
    isAware: null,
    isOpen: null,
    isVisibleToAll: null,
    isWithAutoEnroll: null,
  },
  isLoading: false,
  error: null,
  isAccessAuthorized: true,
};

const extractSettings = (groupInfo?: GroupInfo) => {
  const isAware = groupInfo?.isAware === Awareness.Aware;
  return {
    isAware: isAware,
    isVisibleToAll: isAware ? groupInfo?.isVisibleToAll === Visibility.VisibleToAll : null,
    isOpen: isAware ? groupInfo?.isOpen === Access.Open : null,
    isWithAutoEnroll: groupInfo?.isWithAutoEnroll === Enrollment.Automatic,
  };
};

export const editInfo = (currentState?: EditGroup, action?: GroupAction<EditGroup>) => {
  const state = currentState || initialState;
  const type = action?.type;
  const payload = action?.payload || state;

  switch (type) {
    case actionTypes.FETCH_GROUP_INFO_BEGIN: {
      return {
        ...state,
        isLoading: true,
        error: null,
        isAccessAuthorized: true,
      };
    }

    case actionTypes.FETCH_GROUP_INFO_SUCCESS: {
      return {
        ...state,
        data: {
          ...payload.info,
          enrollmentRules: enrollmentRulesUtils.mapRulesToLocalFormat(payload.info?.enrollmentRules),
        },
        settings: extractSettings(payload.info),
        isLoading: false,
      };
    }

    case actionTypes.FETCH_GROUP_INFO_FAILURE: {
      return {
        ...state,
        ...state.editInfo,
        isLoading: false,
        error: payload.error,
      };
    }

    case actionTypes.FETCH_GROUP_INFO_FORBIDDEN: {
      return {
        ...state,
        ...state.editInfo,
        isLoading: false,
        error: payload.error,
        isAccessAuthorized: false,
      };
    }

    case actionTypes.RESET_GROUP_INFO: {
      return {
        ...state,
        data: {},
      };
    }

    case actionTypes.UPDATE_GROUP_ENROLLMENT_RULES_SUCCESS: {
      let newState = { ...state };
      if (payload.enrollmentRules) {
        newState.data.enrollmentRules = [...payload.enrollmentRules];
      }
      return newState;
    }

    case actionTypes.UPDATE_GROUP_INFO: {
      return {
        ...state,
        data: { ...state.data, ...payload.info },
        settings: payload.settings,
      };
    }

    default:
      return state;
  }
};

const delimiter = "__";

export const editGroup: Reducer = combineReducers({
  editInfo,
  editPeople: combineReducers({
    usersList: withFetchingChildEntityListItems(namespace, entityPrefix, actionTypes.GroupUsers),
    usersToAdd: withFetchingChildEntityListItems(namespace, entityPrefix, actionTypes.GroupUsersToAdd),
  }),
  ownersList: withFetchingChildEntityListItems(namespace, entityPrefix, actionTypes.GroupOwners),
  groupAssignedOwners: withFetchingChildEntityListItems(namespace, entityPrefix, actionTypes.GroupAssignedOwners),
  membersSearch: withSearch(namespace, entityPrefix.concat(delimiter, actionTypes.GroupUsers)),
  groupPacks: combineReducers({
    packsGrid: groupPacksGridSlice,
  }),
  addMembersSearch: withSearch(namespace, entityPrefix.concat(delimiter, actionTypes.GroupUsersToAdd)),
});

export type EditGroupState = ReturnType<typeof editGroup>;
