import React from "react";
import useNotifyConfig from "../hooks/useNotifyConfig";
import { INotifiable, NotifyStepConfigPayload } from "../types";

export interface WithNotifyConfigProps<T> {
  notifyConfigPayload: NotifyStepConfigPayload<T>;
  notifyConfig: T;
}

export const withNotifyConfig = <T, TConfig>(
  Component: React.ComponentType<T & WithNotifyConfigProps<TConfig>>,
  initialNotifyConfig: INotifiable<TConfig>,
) => {
  return (props: T) => {
    const [notifyConfig, notifyConfigPayload] = useNotifyConfig(initialNotifyConfig);

    return <Component {...props} notifyConfig={notifyConfig} notifyConfigPayload={notifyConfigPayload} />;
  };
};
