import { PerformanceFilter } from "features/Library/Common/utils/performanceUtils";
import { GoalArea } from "../shared/GoalArea";

interface Props {
  dateFilter: PerformanceFilter;
}

export const SharepointGoalCharts = ({ dateFilter }: Props) => {
  return (
    <div>
      <GoalArea dateFilter={dateFilter} header="Files Synced" application="SharePoint" activity="Files Synced" />
      <GoalArea dateFilter={dateFilter} header="Files Viewed" application="SharePoint" activity="Files Viewed" />
      <GoalArea dateFilter={dateFilter} header="Page Visits" application="SharePoint" activity="Page Visits" />
      <GoalArea dateFilter={dateFilter} header="Shared Files" application="SharePoint" activity="Shared Files" />
    </div>
  );
};
