const prefix = "notification";

export const UserCreated = `${prefix}:UserCreated`;
export const UserCsvCreated = `${prefix}:UserCsvCreated`;
export const UserUpdated = `${prefix}:UserUpdated`;
export const UserDeleted = `${prefix}:UserDeleted`;
export const UserDeleteFailed = `${prefix}:UserDeleteFailed`;
export const UserDeleteAllSuccess = `${prefix}:UserDeleteAllSuccess`;
export const UserDeleteAllFailure = `${prefix}:UserDeleteAllFailure`;
export const FailedToImportUsersFileResult = `${prefix}:FailedUserImportFileResult`;
export const FailedToImportUsers = `${prefix}:FailedToImportUsers`;
export const SucceedUserImport = `${prefix}:SucceedUserImport`;
export const AssignUsersToRoles = `${prefix}:AssignUsersToRoles`;
export const AssignUsersToRolesFailed = `${prefix}:AssignUsersToRolesFailed`;
export const GroupDuplicateFailed = `${prefix}:GroupDuplicatedFailed`;
export const GroupDuplicated = `${prefix}:GroupDuplicated`;
export const GroupCreated = `${prefix}:GroupCreated`;
export const GroupDeleted = `${prefix}:GroupDeleted`;
export const GroupDeleteFailed = `${prefix}:GroupDeletedFailed`;
export const GroupInfoUpdated = `${prefix}:GroupInfoUpdated`;
export const GroupInfoUpdateFailed = `${prefix}:GroupInfoChangedFailed`;
export const GroupCreatedSuccess = `${prefix}:GroupCreatedSuccess`;
export const GroupCreateFailed = `${prefix}:GroupCreateFailed`;
export const FailedUsersExport = `${prefix}:FailedUsersExport`;
export const SucceedUsersExport = `${prefix}:SucceedUsersExport`;
export const UnassignRolesSuccess = `${prefix}:UnassignRolesSuccess`;
export const UnassignRolesFailure = `${prefix}:UnassignRolesFailure`;
export const UpdateRoleInfoSuccess = `${prefix}:UpdateRoleInfoSuccess`;
export const UpdateRoleInfoFailure = `${prefix}:UpdateRoleInfoFailure`;
export const UpdateRolePermissionsSuccess = `${prefix}:UpdateRolePermissionsSuccess`;
export const UpdateRolePermissionsFailure = `${prefix}:UpdateRolePermissionsFailure`;
export const UserRemoveFromGroupSuccess = `${prefix}:UserRemoveFromGroupSuccess`;
export const UserRemoveFromGroupFail = `${prefix}:UserRemoveFromGroupFail`;
export const RoleDeletedSuccess = `${prefix}:RoleDeletedSuccess`;
export const RoleDeleteFailure = `${prefix}:RoleDeleteFailure`;
export const AddUserToGroupAllSuccess = `${prefix}:AddUserToGroupAllSuccess`;
export const AddUserToGroupAllFailure = `${prefix}:AddUserToGroupAllFailure`;
export const AddUserToGroupSuccess = `${prefix}:AddUserToGroupSuccess`;

// Templates
export const ApproveAccountTemplateSuccess = `${prefix}:ApproveAccountTemplateSuccess`;
export const DeclineAccountTemplateSuccess = `${prefix}:DeclineAccountTemplateSuccess`;
export const DeleteTemplateGroupSuccess = `${prefix}:DeleteTemplateGroupSuccess`;
export const AccountAddedToTemplateGroupSuccess = `${prefix}:AccountAddedToTemplateGroupSuccess`;
export const AccountRemovedFromTemplateGroupSuccess = `${prefix}:AccountRemovedFromTemplateGroupSuccess`;

export const AddTemplateGroupPacksAccountMessageSuccess = `${prefix}:AddTemplateGroupPacksAccountMessageSuccess`;
export const AddTemplateGroupPacksAccountMessageFailure = `${prefix}:AddTemplateGroupPacksAccountMessageFailure`;
export const AddTemplateGroupPackAccountSuccess = `${prefix}:AddTemplateGroupPackAccountSuccess`;
export const AddTemplateGroupPackAccountFailure = `${prefix}:AddTemplateGroupPackAccountFailure`;

export const RemoveAllPackFromTemplateGroupMessageSuccess = `${prefix}:RemoveAllPackFromTemplateGroupMessageSuccess`;
export const RemoveAllPackFromTemplateGroupMessageFailure = `${prefix}:RemoveAllPackFromTemplateGroupMessageFailure`;
export const RemovePackFromTemplateGroupSuccess = `${prefix}:RemovePackFromTemplateGroupSuccess`;
export const RemovePackFromTemplateGroupFailure = `${prefix}:RemovePackFromTemplateGroupFailure`;

export const UpdateAllTemplateGroupAccountPackMessageSuccess = `${prefix}:UpdateAllTemplateGroupAccountPackMessageSuccess`;
export const UpdateAllTemplateGroupAccountPackMessageFailure = `${prefix}:UpdateAllTemplateGroupAccountPackMessageFailure`;
export const UpdateTemplateGroupAccountPackSuccess = `${prefix}:UpdateTemplateGroupAccountPackSuccess`;
export const UpdateTemplateGroupAccountPackFailure = `${prefix}:UpdateTemplateGroupAccountPackFailure`;

export const UpdateAllTemplateGroupPackAccountsMessageSuccess = `${prefix}:UpdateAllTemplateGroupPackAccountsMessageSuccess`;
export const UpdateAllTemplateGroupPackAccountsMessageFailure = `${prefix}:UpdateAllTemplateGroupPackAccountsMessageFailure`;
export const UpdateTemplateGroupPackAccountsSuccess = `${prefix}:UpdateTemplateGroupPackAccountsSuccess`;
export const UpdateTemplateGroupPackAccountsFailure = `${prefix}:UpdateTemplateGroupPackAccountsFailure`;
