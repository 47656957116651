import { useState, useCallback, useMemo, useEffect } from "react";
import { isEqual } from "lodash";
import { Icon, Label } from "semantic-ui-react";
import FilterSidebar from "../filterSidebar/FilterSidebar";
import { calculateAppliedFilters } from "../../utils/filterUtils";
import { FilterFormBuilder, FilterViewType } from "../filterForms/FilterFormBuilder/FilterFormBuilder";
import { dateRange30, longFormDateFilter, validDateWithMoment } from "features/Library/Common/utils/performanceUtils";
import moment from "moment";
import { ReportingChip } from "components/reportingChip/ReportingChip";

import "./ReportingFilter.scss";

type FilterWithoutAccounts = {
  currentFilter: BaseFilter;
  callback: (filter: BaseFilter) => void;
  includeAccounts?: never;
};

type FilterWithAccounts = {
  currentFilter: AccountFilter;
  callback: (filter: AccountFilter) => void;
  includeAccounts: boolean;
};

export type FilterProps = FilterWithoutAccounts | FilterWithAccounts;

const initData: any = {
  dates: dateRange30(),
};

export interface BaseFilter {
  dateFrom: string;
  dateTo: string;
}

export interface AccountFilter extends BaseFilter {
  showCustomers: boolean;
}

const dateFilterMissingValues = (selectedData: BaseFilter) =>
  selectedData.dateFrom === "" || selectedData.dateTo === "";

function filtersAreIdentical(newFilter: DateFilterCallback, oldFilter: DateFilterCallback): boolean;
function filtersAreIdentical(newFilter: AccountFilterCallback, oldFilter: AccountFilterCallback): boolean;

function filtersAreIdentical(newFilter: SubmitCallback, oldFilter: SubmitCallback): boolean {
  return isEqual(newFilter, oldFilter);
}
const incomingDateFormat: moment.MomentFormatSpecification = "MM/DD/YYYY";

type DateFilterCallback = {
  dates: { from: string; to: string };
};

type AccountFilterCallback = DateFilterCallback & {
  showCustomers?: boolean;
};

type SubmitCallback = DateFilterCallback | AccountFilterCallback;

export const ReportingFilter = ({ currentFilter, callback, includeAccounts }: FilterProps) => {
  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState<SubmitCallback>(initData);

  // Toggle showCustomers to false if the box was checked before and
  // includeAccounts is set to false
  useEffect(() => {
    if (!includeAccounts && (currentFilter as AccountFilter)["showCustomers"] === true) {
      let copy = { ...currentFilter };
      // @ts-ignore
      callback({ ...copy, showCustomers: false });
    }
    // Disabling for now, may put callback back in later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilter, includeAccounts, selectedData]);

  // Changing performance filter style to be styled in the way FilterSidebar expects
  const formattedActiveFilter = useMemo<any>(() => {
    let filter: Partial<SubmitCallback> = {};
    if (!dateFilterMissingValues(currentFilter)) {
      filter.dates = {
        from: currentFilter.dateFrom,
        to: currentFilter.dateTo,
      };
    }
    if ((currentFilter as AccountFilter).showCustomers) {
      (filter as AccountFilterCallback).showCustomers = (currentFilter as AccountFilter).showCustomers;
    }
    return filter;
  }, [currentFilter]);

  const hide = useCallback(() => setShow(false), []);

  const handleSubmit = useCallback(
    (filter: SubmitCallback) => {
      let from = "",
        to = "";
      if (filter.dates) {
        // Manage dates
        from = filter.dates.from;
        to = filter.dates.to;
        if (dateFilterMissingValues({ dateFrom: from, dateTo: to })) {
          return;
        }
      }
      if (filtersAreIdentical(filter, formattedActiveFilter)) {
        return;
      }
      let newStateData: AccountFilterCallback = {
        dates: {
          from,
          to,
        },
      };
      if (includeAccounts) {
        newStateData.showCustomers = (filter as any).accounts;
      }

      setSelectedData(newStateData);
      setShow(false);

      const longFormDates = longFormDateFilter(from, to);
      if (!includeAccounts)
        callback({
          dateFrom: longFormDates.dateFrom,
          dateTo: longFormDates.dateTo,
          showCustomers: false,
        });
      else
        callback({
          dateFrom: longFormDates.dateFrom,
          dateTo: longFormDates.dateTo,
          showCustomers: !!(filter as AccountFilterCallback).showCustomers,
        });
    },
    [formattedActiveFilter, callback, includeAccounts],
  );

  const handleReset = useCallback(() => {
    setSelectedData(initData);
    // Applied filter is empty? No need to run callback
    if (Object.keys(formattedActiveFilter).length === 0) {
      return;
    }

    callback({ ...dateRange30(), showCustomers: false });
  }, [formattedActiveFilter, callback]);

  const filterCount = useMemo(() => calculateAppliedFilters(formattedActiveFilter), [formattedActiveFilter]);

  const updateFilter = useCallback((filter: SubmitCallback) => {
    setSelectedData(filter);
  }, []);

  const shouldApplyFilterBeDisabled = useMemo<boolean>(() => {
    if (filtersAreIdentical(selectedData, formattedActiveFilter)) return true;
    if (!validDateWithMoment(selectedData.dates.from) || !validDateWithMoment(selectedData.dates.to)) return true;
    if (moment(selectedData.dates.from, incomingDateFormat).isAfter(moment(selectedData.dates.to, incomingDateFormat)))
      return true;
    if (Object.keys(selectedData).length === 0) return true;

    return false;
  }, [selectedData, formattedActiveFilter]);

  const filterChipData = [
    {
      name: "Date",
      value: `${currentFilter.dateFrom} - ${currentFilter.dateTo}`,
    },
  ];

  const filterChips = filterChipData.map((chipData) => (
    <ReportingChip key={chipData.name} name={chipData.name} value={chipData.value} />
  ));

  return (
    <div className="filterRoot">
      <FilterSidebar
        visible={show}
        hideFilter={hide}
        applyFilter={handleSubmit}
        resetFilter={handleReset}
        appliedFilter={formattedActiveFilter}
        shouldDisableApply={shouldApplyFilterBeDisabled}
        defaultFilter={{ dates: dateRange30() }}
      >
        <Filters filter={selectedData} updateSubFilter={updateFilter} customerFilter={includeAccounts} />
      </FilterSidebar>
      {filterChips}
      <button
        className="filterVisibilityToggle"
        data-testid="filterVisiblityToggle"
        onClick={() => setShow((s) => !s)}
        type="button"
      >
        <Icon name="filter" />
        Filter
      </button>
      {filterCount > 0 && (
        <Label circular color="blue">
          {filterCount}
        </Label>
      )}
    </div>
  );
};

interface SubFilterProps {
  customerFilter?: boolean;
  filter: any;
  updateSubFilter: (filter: SubmitCallback) => void;
  updateFilter?: any;
}

const Filters = ({ customerFilter, filter, updateSubFilter, updateFilter }: SubFilterProps) => {
  const items = useMemo(() => {
    let returnVal = [
      {
        type: FilterViewType.DateRange,
        label: "Dates",
        propertyName: "dates",
      },
    ];
    if (customerFilter) {
      returnVal.push({
        type: FilterViewType.Checkbox,
        label: "Accounts",
        propertyName: "showCustomers",
      });
    }
    return returnVal;
  }, [customerFilter]);

  const handleUpdate = useCallback(
    (newFilter: any) => {
      updateSubFilter(newFilter);
      updateFilter(newFilter);
    },
    [updateFilter, updateSubFilter],
  );

  return (
    <FilterFormBuilder
      items={items}
      filterOptions={{}}
      filter={filter}
      // Ignoring because GenericFiltersMap does not definitvely
      // assert that the values we expect are present
      // @ts-ignore
      updateFilter={handleUpdate}
    />
  );
};
