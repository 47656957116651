import { connect, ConnectedProps } from "react-redux";

import { DetailsHeader } from "../../../../../components/sectionHeader";
import { OutstandingDetailsHeaderOwnProps } from "../../../../../components/sectionHeader/detailsHeader/types";
import { getPeopleEntityLabel } from "../../../../../utils/peopleAssignmentModalUtils";
import { bindAction } from "../../../../../interfaces";
import { pdfsStateSelector } from "../../selectors";
import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { fetchDraftPdfEntity } from "../../state/actions/pdfEntityStateActions";

import SelectedItemsLabel from "../../../../../components/sectionHeader/selectedItemsLabel/SelectedItemsLabel";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddPacksButton } from "features/Licensing/Packs/AssociatedPacks/AddPacksButton";
import { useRtn } from "hooks/useRtn";
import { fetchAssociatedPacks } from "features/Licensing/Packs/state/thunks/associatedPacksThunk";
import * as rtnEvents from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { ItemsTypes, RolePermissions } from "enums";
import Restricted from "features/Application/Restricted";
import AddAssetsToPackModal from "components/modal/AddAssetsToPacksModal/AddAssetsToPackModal";
import { noop } from "lodash";
import { bindActionCreators } from "@reduxjs/toolkit";
import { permissionPredicateForPacks } from "features/Library/Common/utils/performanceUtils";

interface EditPdfDetailsHeaderContainerPropsBase extends OutstandingDetailsHeaderOwnProps {
  pdfId: number;
  pdfsOverviewUrl: string;
  canAddPacks: boolean;
  showAddPacksButton: boolean;
  back: () => void;
}

export type EditPdfDetailsHeaderProps = EditPdfDetailsHeaderContainerPropsBase & PropsFromRedux;

export const EditPdfDetailsHeader = (props: EditPdfDetailsHeaderProps) => {
  const { selectedUserIds, selectedGroupIds, back, canAddPacks, showAddPacksButton, pdfId, fetchAssociatedPacks } = props;
  const [packsModalShown, setPacksModalShown] = useState(false);
  const renderAddPackButton = (hasAnyPermission: boolean) => (
    <AddPacksButton disabled={!canAddPacks} hasPermission={hasAnyPermission} onClick={() => setPacksModalShown(true)} />
  );

  useRtn([rtnEvents.AddContentToPackSuccess], () => fetchAssociatedPacks(ItemsTypes.Pdf, pdfId));

  const navigate = useNavigate();

  const onClose = () => navigate(props.pdfsOverviewUrl);
  const onEdit = () => props.fetchDraftPdfEntity(pdfId);

  const getHeaderTitle = useCallback(() => {
    const selectedPeopleCount = selectedUserIds.length + selectedGroupIds.length;

    if (selectedPeopleCount) {
      return (
        <SelectedItemsLabel
          itemName={getPeopleEntityLabel(selectedUserIds.length, selectedGroupIds.length)}
          selectedItemsAmount={selectedPeopleCount}
          shouldPluralize={false}
        />
      );
    } else {
      return props.title;
    }
  }, [props.title, selectedGroupIds.length, selectedUserIds.length]);

  return (
    <DetailsHeader
      title={getHeaderTitle()}
      pageTitle={props.title}
      titleForGA="Asset Details"
      backButton={back || props.pdfsOverviewUrl}
      defaultURL={props.pdfsOverviewUrl}
      canBeEdited={props.canBeEdited}
      crudPermission={props.crudPermission}
      canBePublished={props.canBePublished}
      isRevertVisible={props.isRevertVisible}
      invalidFormDetails={props.invalidFormDetails}
      publishedStatus={props.publishedStatus}
      entityStateActions={{
        onClose: onClose,
        onEdit: onEdit,
        onPublish: props.entityStateActions.onPublish,
        onRevert: props.entityStateActions.onRevert,
      }}
      hideEntityStateButtons={props.hideEntityStateButtons}
    >
      {showAddPacksButton && (
        <Restricted
          permissions={[RolePermissions.AssetsManage, RolePermissions.AssetsCreate, RolePermissions.PacksManage]}
          renderContent={(hasanyPermission) => renderAddPackButton(hasanyPermission)}
          permissionPredicate={permissionPredicateForPacks}
        />
      )}
      <AddAssetsToPackModal
        showModal={packsModalShown}
        onClose={/* istanbul ignore next */ () => setPacksModalShown(false)}
        selectedItemIds={[pdfId]}
        contentType="Pdf"
        onComplete={noop}
      />
    </DetailsHeader>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const pdfs = pdfsStateSelector(state);
  return {
    isPeopleSelected: !!(pdfs.edit.users.selectedIds.length || pdfs.edit.groups.selectedIds.length),
    selectedUserIds: pdfs.edit.users.selectedIds,
    selectedGroupIds: pdfs.edit.groups.selectedIds,
    selectedUsers: pdfs.edit.users.selectedItems,
    selectedGroups: pdfs.edit.groups.selectedItems,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchAssociatedPacks: bindActionCreators(fetchAssociatedPacks, dispatch),
  fetchDraftPdfEntity: bindAction(fetchDraftPdfEntity, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditPdfDetailsHeader);
