import { AppThunk } from "features/Application/globaltypes/redux";
import { fetchBegin, fetchSuccess } from "../TemplateCustomersSlice";
import { SortingDirection } from "enums";
import DataService from "features/Application/services/dataServices/typedDataService";
import backgroundTask from "features/BackgroundTasks/backgroundTask";
import { UpdateParams } from "interfaces";

export const getAvailableCustomers =
  (skip: number, top: number, sortBy: string, sortOrder: SortingDirection): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBegin());

      const result = await DataService.groupTemplates.getAvailableCustomers({
        top,
        skip,
        sortBy,
        sortOrder,
        term: getState().people.groupTemplate.customers.search,
      });

      dispatch(
        fetchSuccess({
          items: result.data.items,
          totalCount: result.data.count,
        }),
      );
    } catch {}
  };

export const getCustomers =
  (id: number, skip: number, top: number, sortBy: string, sortOrder: SortingDirection): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBegin());

      const result = await DataService.groupTemplates.getCustomers(id, {
        top,
        skip,
        sortBy,
        sortOrder,
        term: getState().people.groupTemplate.customers.search,
      });

      dispatch(
        fetchSuccess({
          items: result.data.items,
          totalCount: result.data.count,
        }),
      );
    } catch {}
  };

export const saveCustomers =
  (templateId: number): AppThunk =>
  async (_, getState) => {
    const customers = getState().people.groupTemplate.customers.customersToAdd;
    if (customers.length === 0) return;
    try {
      await DataService.groupTemplates.saveCustomers(templateId, customers);
    } catch {}
  };

export const removeCustomers =
  (templateId: number, customerIds: number[]): AppThunk =>
  async (dispatch) => {
    const multi = customerIds.length > 1;
    try {
      const updateParams: UpdateParams = {
        id: "RemovingTramplteCustomers",
        title: `Removing customers`,
        getMessageIds: async () => {
          const { data } = await DataService.groupTemplates.removeCustomers(templateId, customerIds);
          return data.messageIds;
        },
        successTransientMessage: `${multi ? "Customers have" : "Customer has"} been removed from this template successfully.`,
        successTitle: `${multi ? "Customers" : "Customer"} removed!`,
        failureTransientMessage: `Removing customers failed!`,
      };

      await backgroundTask.initializeBackgroundTask(updateParams, dispatch);
    } catch {}
  };
