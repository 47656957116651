import * as editUserActionTypes from "./editUserActionTypes";
import dataService from "../../../../Application/services/dataServices/dataService";
import {
  getActionBaseProvider,
  getActionProvider,
  makeActionCreator,
} from "../../../../Application/actions/actionsBuilder";
import { EditUserInfoState } from "../../../types";
import { isForbidden } from "../../../../../utils/statusCodeUtils";
import { AxiosError } from "axios";

export const resetUserInfo = makeActionCreator(editUserActionTypes.RESET_USER_INFO);

export const fetchUserInfo = (id: any) => {
  const fetchUserInfoBegin = getActionBaseProvider(editUserActionTypes.FETCH_USER_INFO_BEGIN);

  const fetchGroupInfoSuccess = getActionProvider<EditUserInfoState>(editUserActionTypes.FETCH_USER_INFO_SUCCESS);

  const fetchGroupInfoFailure = getActionProvider<Error>(editUserActionTypes.FETCH_USER_INFO_FAILURE);

  const fetchGroupInfoForbidden = getActionProvider<AxiosError>(editUserActionTypes.FETCH_USER_INFO_FORBIDDEN);

  return async (dispatch: any) => {
    dispatch(fetchUserInfoBegin());
    try {
      const result = await dataService.getUserInfo(id);
      dispatch(fetchGroupInfoSuccess({ info: result.data }));
    } catch (error) {
      isForbidden(error?.response?.status)
        ? dispatch(fetchGroupInfoForbidden(error))
        : dispatch(fetchGroupInfoFailure(error));
    }
  };
};

export const updateUserInfo = makeActionCreator(editUserActionTypes.UPDATE_USER_INFO, "info");
