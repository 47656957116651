import { IconDefinition, faCalendarCheck, faCalendarStar, faCalendarMinus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextTruncate } from "components";
import CardReporting from "components/cards/CardReporting/CardReporting";
import { Tooltip } from "components/common/tooltip";
import { FeatureFlags } from "featureFlags";
import {
  sharedAccountReportingHorizontalBarProps,
  sharedAccountReportingLineProps,
} from "features/Reporting/Content/shared";
import { GenericPerformanceList } from "features/Reporting/Content/shared/GenericPerformanceList";
import { useChartPeriodMeasure } from "hooks/useChartPeriodMeasure";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { ColumnOption } from "interfaces/columnOptions";
import { FC, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { ChartWrapper, HorizontalBarChart, LineChart, getDateFormatByCount } from "../../../../../../components/charts";
import { RequestStatusRenderer } from "../../../../../../components/requestStatsRenderer/RequestStatusRenderer";
import {
  PerformanceFilter,
  createDateRange,
  getBarDomain,
  lineChartTitles,
  noBarData,
  noData,
  opensColor,
  startsColor,
  totalActivity,
  validLineData,
} from "../../../../Common/utils/performanceUtils";
import { EventChartStates, legendLabelFormat } from "../EventPerformanceBody";
import { useDrillDownQuery, useEventEngagementQuery, useEventLineChartQuery } from "../Queries/queries";
import { EventSessionDetailEntity } from "../types";
import moment from "moment";
import { ConnectedProps, connect } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { bindAction } from "interfaces";
import { setHasDataToExport } from "features/Reporting/state/export/exportSlice";
import { RouteNames } from "enums";

import styles from "./eventPerformanceDrillDown.module.scss";

export type Props = PropsFromRedux & {
  eventId: number;
  sessionId: number;
  sessionDatetime: string;
  filter: PerformanceFilter;
  flowId?: number;
};

export enum EventStatus {
  Registered = "Registered",
  Attended = "Attended",
  NotAttended = "Not Attended",
}

export const EventStatusToIcon: { [key in EventStatus]: IconDefinition } = {
  [EventStatus.Registered]: faCalendarStar,
  [EventStatus.Attended]: faCalendarCheck,
  [EventStatus.NotAttended]: faCalendarMinus,
};

const getEventStatus = (entity: EventSessionDetailEntity, sessionDateTime: string): EventStatus => {
  if (entity.AttendedDatetime) {
    return EventStatus.Attended;
  }
  if (entity.RegistrationDatetime) {
    const sessionAsMoment = moment(sessionDateTime, "YYYY-MM-DDTHH:mm:ss");
    const now = moment();
    // User failed to attend the event
    if (sessionAsMoment.isBefore(now)) {
      return EventStatus.NotAttended;
    }
  }

  return EventStatus.Registered;
};

export const EventPerformanceDrilldown: FC<Props> = ({ eventId, flowId, sessionId, sessionDatetime, filter, setHasExportData }) => {
  const [lineChartState, setLineChartState] = useState<EventChartStates>(totalActivity);
  const reportEnabled = useFeatureFlag(FeatureFlags.EventReport);
  const dateRange = useMemo(() => createDateRange(filter.dateFrom, filter.dateTo), [filter.dateFrom, filter.dateTo]);

  const [chartPeriod, measureRef] = useChartPeriodMeasure(
    dateRange,
    sharedAccountReportingLineProps.margins!.left + sharedAccountReportingLineProps.margins!.right,
  );

  const lineChartQuery = useEventLineChartQuery({ filter, eventId, chartPeriod, reportEnabled, sessionId, sessionDatetime, flowId });
  const engagementQuery = useEventEngagementQuery({ filter, eventId, reportEnabled, sessionId, sessionDatetime, flowId, isSession: true });
  const drillDownQuery = useDrillDownQuery({ filter, eventId, sessionId, reportEnabled, sessionDatetime, flowId });

  useEffect(() => {
    setHasExportData(drillDownQuery.isSuccess && !!drillDownQuery.data.table.length);
  }, [setHasExportData, drillDownQuery.isSuccess, drillDownQuery.data]);

  const lineData = useMemo(() => {
    if (lineChartState !== totalActivity) {
      return {
        yData: [lineChartQuery.data?.lineData.Registered, lineChartQuery.data?.lineData.Attended],
      };
    }
    return {
      yData: [lineChartQuery.data?.lineData.CumulativeRegistered, lineChartQuery.data?.lineData.CumulativeAttended],
    };
  }, [
    lineChartState,
    lineChartQuery.data?.lineData.CumulativeRegistered,
    lineChartQuery.data?.lineData.CumulativeAttended,
    lineChartQuery.data?.lineData.Registered,
    lineChartQuery.data?.lineData.Attended,
  ]);

  const columns: ColumnOption<EventSessionDetailEntity>[] = useMemo(
    () => [
      {
        name: "Name",
        width: 7,
        isSortable: false,
        render: (entity: EventSessionDetailEntity) => {
          return (
            <Link to={`/${RouteNames.peopleUsers}/${entity.UserId}`} className={styles.linkButton}>
              <Tooltip target={<TextTruncate>{entity.FullName}</TextTruncate>} content={entity.FullName} />
            </Link>
          );
        },
      },
      {
        name: "Email",
        width: 7,
        isSortable: false,
        render: (entity: EventSessionDetailEntity) => (
          <Tooltip target={<TextTruncate>{entity.Email}</TextTruncate>} content={entity.Email} />
        ),
      },
      {
        name: "Status",
        width: 7,
        isSortable: false,
        render: (entity: EventSessionDetailEntity) => {
          const status = getEventStatus(entity, sessionDatetime);
          return (
            <div className={styles.icon}>
              <FontAwesomeIcon icon={EventStatusToIcon[status]} className={styles[`${EventStatusToIcon[status]}`]} />
              {status}
            </div>
          );
        },
      },
    ],
    [sessionDatetime],
  );

  return (
    <div className="performanceBody">
      <div className="graphs">
        <div className="lineChartContainer" ref={measureRef}>
          <ChartWrapper
            titles={lineChartTitles}
            showLegend
            legendLabels={["Registered", "Attended"]}
            legendTooltip={legendLabelFormat}
            colorRange={[startsColor, opensColor]}
            onChange={(value) => setLineChartState(value as EventChartStates)}
          >
            <RequestStatusRenderer state={lineChartQuery.status}>
              {lineChartQuery.isSuccess &&
                validLineData([lineChartQuery.data.lineData.Attended, lineChartQuery.data.lineData.Registered]) ? (
                <LineChart
                  {...sharedAccountReportingLineProps}
                  {...lineData}
                  xData={[lineChartQuery.data.lineData.Date, lineChartQuery.data.lineData.Date]}
                  colorRange={[startsColor, opensColor]}
                  xFormatterFunc={getDateFormatByCount(lineChartQuery.data.lineData.Date.length)}
                />
              ) : (
                noData(filter)
              )}
            </RequestStatusRenderer>
          </ChartWrapper>
        </div>
        <div className="funnelChartContainer">
          <ChartWrapper titles={["Engagement"]}>
            <RequestStatusRenderer state={engagementQuery.status}>
              {engagementQuery.isSuccess && !noBarData(...engagementQuery.data.engagement.map((d) => d.value)) ? (
                <HorizontalBarChart
                  {...sharedAccountReportingHorizontalBarProps}
                  data={engagementQuery.data.engagement}
                  domain={getBarDomain(engagementQuery.data.engagement)}
                  yAxisTickTooltipFormat={(l) => legendLabelFormat(l as string)}
                />
              ) : (
                noData(filter)
              )}
            </RequestStatusRenderer>
          </ChartWrapper>
        </div>
      </div>
      <div className={styles.cards}>
        <RequestStatusRenderer state={engagementQuery.status}>
          {engagementQuery.isSuccess && (
            <CardReporting
              items={engagementQuery.data.cards.filter((card) => card.statDescription !== "Total Sessions")}
            />
          )}
        </RequestStatusRenderer>
      </div>
      <div className={styles.table}>
        <h2 className={styles.sessionsHeader}>Attendees</h2>
        <RequestStatusRenderer state={drillDownQuery.status}>
          {drillDownQuery.isSuccess && (
            <GenericPerformanceList rows={drillDownQuery.data.table} columns={columns} filter={filter} />
          )}
        </RequestStatusRenderer>
      </div>
    </div>
  );
};


const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setHasExportData: bindAction(setHasDataToExport, dispatch),
  };
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EventPerformanceDrilldown);
