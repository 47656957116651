import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../enums/reducer";
import { RequestState, RStatus } from "../../../../Application/globaltypes/fetchRequest";
import { RootState } from "../../../../Application/globaltypes/redux";
import { SurveyPeopleDetails, SurveyQuestion, SurveyQuestionAnswer } from "../../types/state";
import { Content } from "../../types/models";
import { DataPoint } from "components/charts/types/HorizontalBarChart";
import { completes, completesColor, starts, startsColor } from "features/Library/Common/utils/performanceUtils";
import { cloneDeep } from "lodash";

const namespace = ReducerNamespaceTypes.Survey;
const entityPrefix = ReducerEntityPrefixTypes.Surveys_Performance;

export type EngagementLineData = {
  Date: string;
  Events: {
    CompletedCount: number;
    CumulativeCompletedCount: number;
  };
}[];

export type EngagementFunnelData = {
  CompletedCount: number;
  StartedCount: number;
};

export type CardsData = {
  AverageTime: number;
  CompletionRate: number;
  Completions: number;
  Started: number;
};

const initialRequestState: RequestState<any> = {
  status: RStatus.Idle,
  value: [],
  errorMessage: undefined,
};

export interface SurveyPerformanceState {
  engagementLineData: RequestState<EngagementLineData>;
  engagementFunnelData: RequestState<EngagementFunnelData>;
  surveyQuestionsData: RequestState<SurveyQuestion[]>;
  surveySelectedQuestionData: RequestState<SurveyQuestion>;
  surveySelectedAnswerData: RequestState<SurveyQuestionAnswer>;
  surveyContentData: Content;
  cardsData: RequestState<CardsData>;
  surveyPeopleDetails: RequestState<SurveyPeopleDetails[]>;
}

export const initialState: SurveyPerformanceState = {
  surveyQuestionsData: initialRequestState,
  engagementLineData: initialRequestState,
  engagementFunnelData: initialRequestState,
  surveySelectedQuestionData: initialRequestState,
  surveySelectedAnswerData: initialRequestState,
  surveyContentData: {
    surveyId: -1,
    questions: [],
    dateModified: "",
    title: "",
    isDraft: false,
  },
  cardsData: initialRequestState,
  surveyPeopleDetails: initialRequestState,
};

const surveyPerformanceSlice = createSlice({
  name: `${namespace}/${entityPrefix}`,
  initialState,
  reducers: {
    reqSurveyQuestionsData(state: SurveyPerformanceState) {
      state.surveyQuestionsData = {
        ...state.surveyQuestionsData,
        status: RStatus.Pending,
      };
    },
    reqEngagementLineData(state: SurveyPerformanceState) {
      state.engagementLineData = {
        ...state.engagementLineData,
        status: RStatus.Pending,
      };
    },
    reqEngagementFunnelData(state: SurveyPerformanceState) {
      state.engagementFunnelData = {
        ...state.engagementFunnelData,
        status: RStatus.Pending,
      };
    },
    reqSelectedQuestionData(state: SurveyPerformanceState) {
      state.surveySelectedQuestionData = {
        ...state.surveySelectedQuestionData,
        status: RStatus.Pending,
      };
    },
    reqSelectedAnswerData(state: SurveyPerformanceState) {
      state.surveySelectedAnswerData = {
        ...state.surveySelectedAnswerData,
        status: RStatus.Pending,
      };
    },
    reqPeopleDetails(state: SurveyPerformanceState) {
      state.surveyPeopleDetails = {
        ...state.surveyPeopleDetails,
        status: RStatus.Pending,
      };
    },
    reqCardsData(state: SurveyPerformanceState) {
      state.cardsData = {
        ...state.cardsData,
        status: RStatus.Pending,
      };
    },
    setEngagementLineData(
      state: SurveyPerformanceState,
      action: PayloadAction<{ data: SurveyPerformanceState["engagementLineData"]["value"] }>,
    ) {
      state.engagementLineData = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setSurveyQuestionsData(state: SurveyPerformanceState, action: PayloadAction<{ data: SurveyQuestion[] }>) {
      state.surveyQuestionsData = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setEngagementFunnelData(
      state: SurveyPerformanceState,
      action: PayloadAction<{ data: SurveyPerformanceState["engagementFunnelData"]["value"] }>,
    ) {
      state.engagementFunnelData = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setSelectedQuestionData(state: SurveyPerformanceState, action: PayloadAction<{ data: SurveyQuestion }>) {
      state.surveySelectedQuestionData = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setCardsData(
      state: SurveyPerformanceState,
      action: PayloadAction<{ data: SurveyPerformanceState["cardsData"]["value"] }>,
    ) {
      state.cardsData = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setSelectedAnswerData(state: SurveyPerformanceState, action: PayloadAction<{ data: SurveyQuestionAnswer }>) {
      state.surveySelectedAnswerData = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setPeopleDetails(state: SurveyPerformanceState, action: PayloadAction<{ data: SurveyPeopleDetails[] }>) {
      state.surveyPeopleDetails = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setSurveyContentData(state, action: PayloadAction<Content>) {
      state.surveyContentData = action.payload;
    },
    setSurveyQuestionsDataError(state: SurveyPerformanceState, action: PayloadAction<{ errorMessage: string }>) {
      state.surveyQuestionsData = {
        ...state.surveyQuestionsData,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    setEngagementLineDataError(state: SurveyPerformanceState, action: PayloadAction<{ errorMessage: string }>) {
      state.engagementLineData = {
        ...state.engagementLineData,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    setEngagementFunnelDataError(state: SurveyPerformanceState, action: PayloadAction<{ errorMessage: string }>) {
      state.engagementFunnelData = {
        ...state.engagementFunnelData,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    setSelectedQuestionDataError(state: SurveyPerformanceState, action: PayloadAction<{ errorMessage: string }>) {
      state.surveySelectedQuestionData = {
        ...state.surveySelectedQuestionData,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    setSelectedAnswerDataError(state: SurveyPerformanceState, action: PayloadAction<{ errorMessage: string }>) {
      state.surveySelectedAnswerData = {
        ...state.surveySelectedAnswerData,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    setPeopleDetailsError(state: SurveyPerformanceState, action: PayloadAction<{ errorMessage: string }>) {
      state.surveyPeopleDetails = {
        ...state.surveyPeopleDetails,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    setCardsDataError(state: SurveyPerformanceState, action: PayloadAction<{ errorMessage: string }>) {
      state.cardsData = {
        ...state.cardsData,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    reset() {
      return {
        ...initialState,
      };
    },
    resetDrilldown(state) {
      return {
        ...state,
        surveySelectedQuestionData: initialRequestState,
        surveySelectedAnswerData: initialRequestState,
      };
    },
    resetSelectedAnswer(state) {
      return {
        ...state,
        surveySelectedAnswerData: initialRequestState,
      };
    },
  },
});

// Actions
export const {
  reqSurveyQuestionsData,
  reqEngagementLineData,
  reqEngagementFunnelData,
  reqSelectedQuestionData,
  reqSelectedAnswerData,
  setSurveyQuestionsData,
  setEngagementLineData,
  setEngagementFunnelData,
  setSelectedQuestionData,
  setSelectedAnswerData,
  setSurveyContentData,
  setSurveyQuestionsDataError,
  setEngagementLineDataError,
  setEngagementFunnelDataError,
  setSelectedQuestionDataError,
  setSelectedAnswerDataError,
  reqPeopleDetails,
  setPeopleDetails,
  setPeopleDetailsError,
  reqCardsData,
  setCardsData,
  setCardsDataError,
  reset,
  resetDrilldown,
  resetSelectedAnswer,
} = surveyPerformanceSlice.actions;

// Selectors
export const selectEngagementLineData = (state: RootState) => state.library.surveys.base.performance.engagementLineData;
export const selectEngagementFunnelData = (state: RootState) =>
  state.library.surveys.base.performance.engagementFunnelData;
export const selectSurveyQuestionsData = (state: RootState) =>
  state.library.surveys.base.performance.surveyQuestionsData;
export const selectSurveySelectedQuestionData = (state: RootState) =>
  state.library.surveys.base.performance.surveySelectedQuestionData;
export const selectSurveySelectedAnswerData = (state: RootState) =>
  state.library.surveys.base.performance.surveySelectedAnswerData;
export const selectPeopleDetails = (state: RootState) => state.library.surveys.base.performance.surveyPeopleDetails;
export const selectCardsData = (state: RootState) => state.library.surveys.base.performance.cardsData;
export const selectSurveyContent = (state: RootState) => state.library.surveys.base.performance.surveyContentData;

// Line
export const selectEngagementLineStatus = createSelector(
  selectEngagementLineData,
  (engagementData) => engagementData.status,
);

export const selectEngagementLineDates = createSelector(selectEngagementLineData, (engagementData) =>
  engagementData.value.map((activity) => new Date(activity.Date)),
);

export const selectEngagementLineCompleted = createSelector(selectEngagementLineData, (engagementData) =>
  engagementData.value.map((activity) => activity.Events.CompletedCount),
);

export const selectEngagementLineCumulativeCompleted = createSelector(selectEngagementLineData, (engagementData) => {
  return engagementData.value.map((activity) => activity.Events.CumulativeCompletedCount);
});

export const selectEngagementLineError = createSelector(
  selectEngagementLineData,
  (engagementData) => engagementData.errorMessage,
);

// Funnel
export const selectEngagementFunnelStatus = createSelector(
  selectEngagementFunnelData,
  (engagementData) => engagementData.status,
);
export const selectEngagementFunnelValue = createSelector(
  selectEngagementFunnelData,
  (engagementData) => engagementData.value,
);
export const selectEngagementFunnelError = createSelector(
  selectEngagementFunnelData,
  (engagementData) => engagementData.errorMessage,
);

export const selectEngagementHorizontalData = createSelector(selectEngagementFunnelValue, (value): DataPoint[] => {
  return [
    {
      id: starts.toLowerCase(),
      category: starts,
      value: value.StartedCount,
      fillColor: startsColor,
    },
    {
      id: completes.toLowerCase(),
      category: completes,
      value: value.CompletedCount,
      fillColor: completesColor,
    },
  ];
});

// Survey
export const selectSurveyQuestionsStatus = createSelector(
  selectSurveyQuestionsData,
  (surveyQuestionsData) => surveyQuestionsData.status,
);
export const selectSurveyQuestionsValue = createSelector(selectSurveyQuestionsData, (surveyQuestionsData) => {
  return surveyQuestionsData.value.map((question) => {
    let formattedAnswers = question.Answers.map(handleAnswerFormatting);
    return {
      ...question,
      Answers: formattedAnswers,
    };
  });
});
export const selectSurveyQuestionsError = createSelector(
  selectSurveyQuestionsData,
  (surveyQuestionsData) => surveyQuestionsData.errorMessage,
);

export const selectSurveySelectedQuestionStatus = createSelector(
  selectSurveySelectedQuestionData,
  (surveySelectedQuestionData) => surveySelectedQuestionData.status,
);
export const selectSurveySelectedQuestionValue = createSelector(
  selectSurveySelectedQuestionData,
  (surveySelectedQuestionData) => {
    let formattedAnswers = surveySelectedQuestionData.value?.Answers?.map(handleAnswerFormatting);
    return {
      ...surveySelectedQuestionData.value,
      Answers: formattedAnswers,
    };
  },
);
export const selectSurveySelectedQuestionError = createSelector(
  selectSurveySelectedQuestionData,
  (surveySelectedQuestionData) => surveySelectedQuestionData.errorMessage,
);

export const selectSurveySelectedAnswerStatus = createSelector(
  selectSurveySelectedAnswerData,
  (surveySelectedAnswerData) => surveySelectedAnswerData.status,
);
export const selectSurveySelectedAnswerValue = createSelector(
  selectSurveySelectedAnswerData,
  (surveySelectedAnswerData) => handleAnswerFormatting(surveySelectedAnswerData.value),
);
export const selectSurveySelectedAnswerError = createSelector(
  selectSurveySelectedAnswerData,
  (surveySelectedAnswerData) => surveySelectedAnswerData.errorMessage,
);
// Cards
export const selectCardsStatus = createSelector(selectCardsData, (cardData) => cardData.status);
export const selectCardsValue = createSelector(selectCardsData, (cardData) => cardData.value);
export const selectCardsError = createSelector(selectCardsData, (cardData) => cardData.errorMessage);

const sortAndFilterUnique = (arr: SurveyPeopleDetails[], track = new Set()) => {
  const sortedArray = cloneDeep(arr);
  sortedArray.sort((a, b) => Date.parse(b.CompletionDate) - Date.parse(a.CompletionDate));
  return sortedArray.filter(({ UserId }) => (track.has(UserId) ? false : track.add(UserId)));
};

// People table
export const selectPeopleDetailsStatus = createSelector(selectPeopleDetails, (peopleDetails) => peopleDetails.status);
export const selectPeopleDetailsValue = createSelector(selectPeopleDetails, (peopleDetails) =>
  sortAndFilterUnique(peopleDetails.value),
);
export const selectPeopleDetailsError = createSelector(
  selectPeopleDetails,
  (peopleDetails) => peopleDetails.errorMessage,
);

export const selectOtherOptionData = createSelector(selectSurveyContent, (content) =>
  content.questions.map((q) => q.otherAnswerOptions),
);

function handleAnswerFormatting(answer: SurveyQuestionAnswer): SurveyQuestionAnswer {
  if (answer.AnswerId === "OTHER") {
    return {
      ...answer,
      AnswerText: "Other",
    };
  }
  return answer;
}

export default surveyPerformanceSlice.reducer;
