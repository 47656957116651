import { FilterMultiSelect, FilterMultiSelectWithAddition } from "../../multiSelect";
import { FilterDateRange } from "../../../components/datePicker";
import { FilterDropdown } from "../../dropdowns";
import { FilterEntity, GenericFiltersMap } from "../../../utils/filterUtils";
import { ReactNode } from "react";
import classNames from "classnames";
import { FilterCheckbox } from "components/common/checkbox/FilterCheckbox";

export interface FilterBuilderProps<T extends number | string> {
  filterOptions: GenericFiltersMap<T>;
  updateFilter: (filter: GenericFiltersMap<T>) => void;
  filter: GenericFiltersMap<T>;
  items: FilterItemBase<T>[];
}

export enum FilterViewType {
  DateRange,
  MultiSelect,
  MultiSelectWithAddition,
  Select,
  Checkbox,
}

export interface FilterItemBase<T extends string | number> {
  label: string;
  propertyName: T;
  type: FilterViewType;

  items?: FilterEntity;
  placeholder?: string;
  className?: string;
  wrapper?: (children: ReactNode) => React.ReactElement;
  condition?: () => boolean;
  otherProps?: object;
  hidden?: boolean;
}

const getFilterItem = <T extends string | number>(payload: {
  item: FilterItemBase<T>;
  filterOptions: GenericFiltersMap<T>;
  filter: GenericFiltersMap<T>;
  updateFilter: (filter: GenericFiltersMap<T>) => void;
}) => {
  const renderer = (children: ReactNode) => {
    if (payload.item.condition && !payload.item.condition()) {
      return null;
    }
    const wrapper = payload.item.wrapper;
    return wrapper ? wrapper(children) : <>{children}</>;
  };

  return (() => {
    switch (payload.item.type) {
      case FilterViewType.DateRange: {
        return renderer(
          !payload.item.hidden && (
            <FilterDateRange
              label={payload.item.label}
              propertyName={payload.item.propertyName as string}
              filter={payload.filter}
              updateFilter={payload.updateFilter}
              className={classNames(payload.item.className, "filter-item")}
              horizontalPositionSecondPicker="left"
              {...payload.item.otherProps}
            />
          ),
        );
      }
      case FilterViewType.MultiSelect: {
        return renderer(
          !payload.item.hidden && (
            <div className={classNames(payload.item.className, "filter-item")}>
              <div className="label">{payload.item.label}</div>
              <FilterMultiSelect
                propertyName={payload.item.propertyName as string}
                placeholder={payload.item.placeholder}
                items={payload.item.items || (payload.filterOptions[payload.item.propertyName] as any)}
                filter={payload.filter}
                updateFilter={payload.updateFilter}
                {...payload.item.otherProps}
              />
            </div>
          ),
        );
      }
      case FilterViewType.MultiSelectWithAddition: {
        return renderer(
          !payload.item.hidden && (
            <div className={classNames(payload.item.className, "filter-item")}>
              <div className="label">{payload.item.label}</div>
              <FilterMultiSelectWithAddition
                propertyName={payload.item.propertyName as string}
                placeholder={payload.item.placeholder}
                items={payload.item.items || (payload.filterOptions[payload.item.propertyName] as any)}
                filter={payload.filter}
                updateFilter={payload.updateFilter}
                {...payload.item.otherProps}
              />
            </div>
          ),
        );
      }
      case FilterViewType.Select: {
        return renderer(
          !payload.item.hidden && (
            <div className={classNames(payload.item.className, "filter-item")}>
              <div className="label">{payload.item.label}</div>
              <FilterDropdown
                propertyName={payload.item.propertyName as string}
                items={payload.item.items || (payload.filterOptions[payload.item.propertyName] as any)}
                filter={payload.filter}
                updateFilter={payload.updateFilter}
                defaultSelected={0}
                fluid
                selection
                {...payload.item.otherProps}
              />
            </div>
          ),
        );
      }
      case FilterViewType.Checkbox:
        return renderer(
          !payload.item.hidden && (
            <div className={classNames(payload.item.className, "filter-item")}>
              <div className="label">{payload.item.label}</div>
              <FilterCheckbox
                propertyName={payload.item.propertyName as string}
                filter={payload.filter}
                updateFilter={payload.updateFilter}
                className={classNames(payload.item.className, "filter-item")}
              />
            </div>
          ),
        );
      default:
        return null;
    }
  })();
};

export const FilterFormBuilder = <T extends number | string>(props: FilterBuilderProps<T>) => {
  const { filter, filterOptions, updateFilter } = props;
  return (
    <>
      {props.items.map((item, index) => {
        return <div key={index}>{getFilterItem({ item, filterOptions, filter, updateFilter })}</div>;
      })}
    </>
  );
};
