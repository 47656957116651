import * as Yup from "yup";
import awesomeDebouncePromise from "awesome-debounce-promise";
import isEqual from "lodash/isEqual";

import dataService from "../features/Application/services/dataServices/dataService";
import rolesDataService from "../features/People/services/rolesDataService";
import { uploadedImages, uploadedTeamsImages, usersFileUpload } from "./validationSchemas/filesValidationSchema";
import { labelValues, softwareApplicationValues } from "./validationSchemas/tagsValidationSchema";
import { emailInfo, emailTemplate, senderInfo } from "./validationSchemas/emailsValidationSchema";
import { emailTemplateContent, emailTemplateInfo } from "./validationSchemas/emailTemplatesValidationSchema";
import { assessmentContent, assessmentInfo, assessmentSettings } from "./validationSchemas/assessmentsValidationSchema";
import { linkInfo } from "./validationSchemas/linksValidationSchema";
import { landingPageContent, landingPageInfo } from "./validationSchemas/landingPagesValidationSchema";
import {
  sendingProfileInfo,
  smtpAuthSendingProfileConfigure,
  smtpAuthSendingProfileSettings,
  smtpAuthSendingProfileVerification,
} from "./validationSchemas/sendingProfilesValidationSchema";
import { surveyInfo, surveysContent } from "./validationSchemas/surveysValidationSchema";
import { sectionHeaderInfo } from "./validationSchemas/sectionHeaderValidationSchema";
import { packConfigure } from "./validationSchemas/packsValidationSchema";
import NewContact from "../interfaces/NewContact";
import { eventInfo } from "./validationSchemas/eventValidationSchemas";
import { campaignConfigure, campaignContent } from "./validationSchemas/campaignValidationSchema";
import { feedbackPageInfo } from "./validationSchemas/feedbackPagesValidationSchema";
import { closedCaptions, videoInfo, videoSettings } from "./validationSchemas/videosValidationSchema";
import {
  packRequestValidationSchema,
  packTrialRequestValidationSchema,
} from "./validationSchemas/packRequestValidationSchema";
import { PriorityLevels } from "../enums";
import PriorityLevelFormValues from "../interfaces/priorityLevelFormValues";
import { FilterOptionsEnum } from "./enrollmentRulesUtils";
import { externalTrigger } from "./validationSchemas/externalTriggerValidationSchema";
import { getPdfConfigurationSchema, getUploadedPdfConfigurationSchema } from "./validationSchemas/pdfsValidationSchema";
import { messageInfo, messageContent } from "./validationSchemas/messageValidationSchema";
import { msTeamsMessageSchema } from "./validationSchemas/msTeamsMessageValidationSchema";
import nameof from "./nameof";
import { CUSTOM_EMAIL } from "./emailUtils";
import moment from "moment";

const maxRoleNameLength = 512;
const maxNameLength = 128;
const maxFieldLength = 256;
const maxDescriptionLength = 255;
export const maxFlowDescriptionLength = 512;
const debounceTimeMs = 300;

const uniqueEmailMessage = "Email should be unique in scope of your organization.";

const isRoleNameExists = awesomeDebouncePromise(rolesDataService.isRoleNameExists, debounceTimeMs);

const isGroupNameExists = awesomeDebouncePromise(dataService.isGroupNameExists, debounceTimeMs);

const title = (max: number = 120) => {
  return Yup.string()
    .trim()
    .required("Title is required")
    .min(3, "Title must have at least 3 characters")
    .max(max, `Title must be maximum of ${max} characters long`);
};

const description = (max: number) => {
  return Yup.string().nullable().trim().max(max, `Description must be maximum of ${max} characters long`);
};

const requiredDescription = (max: number) => {
  return Yup.string()
    .trim()
    .required("Description is required")
    .min(3, "Description must have at least 3 characters")
    .max(max, `Description must be maximum of ${max} characters long`);
};

const requiredThumbnailUrl = Yup.string().trim().required("Image is required");

const firstName = Yup.string()
  .trim()
  .required("First name is required")
  .max(maxNameLength, `First name must be maximum of ${maxNameLength} characters long`);

const contactFirstName = Yup.string()
  .trim()
  .required("First name is required")
  .max(maxNameLength, `First name must be maximum of ${maxNameLength} characters long`);

const contactLastName = Yup.string()
  .trim()
  .required("Last name is required")
  .max(maxNameLength, `Last name must be maximum of ${maxNameLength} characters long`);

const contactDepartment = Yup.string()
  .trim()
  .max(maxFieldLength, `Department must be maximum of ${maxFieldLength} characters long`);

const contactJobTitle = Yup.string()
  .trim()
  .max(maxFieldLength, `Job title must be maximum of ${maxFieldLength} characters long`);

const email = Yup.string()
  .trim()
  .required("Email is required")
  .matches(CUSTOM_EMAIL, "Please enter correct email")
  .max(maxFieldLength, `Email must be maximum of ${maxFieldLength} characters long`);

const getContactEmailSchema = (contacts: Array<NewContact>) => {
  return email.test("unique-email", "Email should be unique", async (e) => {
    if (e && contacts) {
      const isEmailExists = contacts.some((contact) => contact.email === e);
      return !isEmailExists;
    }
    return true;
  });
};

const getContactSchema = (contacts: Array<NewContact>) => {
  return Yup.object().shape({
    firstName: contactFirstName,
    lastName: contactLastName,
    email: getContactEmailSchema(contacts),
    department: contactDepartment,
    title: contactJobTitle,
  });
};

const getRoleNameSchema = (initialRoleName: string) => {
  return Yup.string()
    .trim()
    .required("Role name is required")
    .max(maxRoleNameLength, `Role name must be maximum of ${maxRoleNameLength} characters long`)
    .test("unique-role-name", "Role name must be unique", async (name) => {
      if (name && name !== initialRoleName) {
        const isNameExists = await isRoleNameExists(name);
        return !isNameExists;
      }

      return true;
    });
};

const getRoleSchema = (initialRoleName: string) => {
  return Yup.object().shape({
    name: getRoleNameSchema(initialRoleName),
    description: description(maxDescriptionLength),
  });
};

const groupName = Yup.string()
  .required("Group name is required")
  .max(maxNameLength, `Group name must be maximum of ${maxNameLength} characters long`)
  .test("unique-group-name", "Group name should be unique", async (name) => {
    const isNameExists = name ? await isGroupNameExists(name) : false;
    return !isNameExists;
  });

const getCreateUserSchema = () =>
  Yup.object().shape({
    firstName,
    lastName,
    email: getUserEmailSchema(),
    department: Yup.string()
      .nullable()
      .trim()
      .max(maxFieldLength, `Department must be maximum of ${maxFieldLength} characters long`),
    jobTitle: Yup.string()
      .nullable()
      .trim()
      .max(maxFieldLength, `Job Title must be maximum of ${maxFieldLength} characters long`),
  });

const getEditUserSchema = (userId: number) =>
  Yup.object().shape({
    firstName,
    lastName,
    email: getUserEmailSchema(userId),
    department: Yup.string()
      .nullable()
      .trim()
      .max(maxFieldLength, `Department must be maximum of ${maxFieldLength} characters long`),
    jobTitle: Yup.string()
      .nullable()
      .trim()
      .max(maxFieldLength, `Job Title must be maximum of ${maxFieldLength} characters long`),
  });

const getEditGroupSchema = (groupId: number) => {
  return Yup.object().shape({
    name: getEditGroupNameSchema(groupId),
    description: requiredDescription(maxFieldLength),
  });
};

const getEditGroupNameSchema = (groupId: number) =>
  Yup.string()
    .required("Group name is required")
    .max(maxNameLength, `Group name must be maximum of ${maxNameLength} characters long`)
    .test("unique-group-name", "Group name should be unique", async (name) => {
      const isNameExists = await isGroupNameExists(name, groupId);
      return !isNameExists;
    });

const lastName = Yup.string()
  .trim()
  .required("Last name is required")
  .max(maxNameLength, `Last name must be maximum of ${maxNameLength} characters long`);

const isUserEmailAvailable = awesomeDebouncePromise(dataService.isUserEmailUnique, debounceTimeMs);

const getUserEmailSchema = (userId?: number) => {
  let lastUniquenessValidatedEmail = "";
  let isUnique = false;
  return email.test("unique-user-email", uniqueEmailMessage, async (e) => {
    if (e && e !== lastUniquenessValidatedEmail) {
      let result = await isUserEmailAvailable(e, userId);
      lastUniquenessValidatedEmail = e;
      isUnique = result;
    }

    return isUnique;
  });
};

const getIsWithinLastDaysSchema = (start: string, finish: string) => {
  const startSchema = Yup.string()
    .required("Value is required")
    .test("is-valid-number-of-days", "Must be number between 0 and 999", (value) => {
      if (!value) {
        return true;
      }
      const numberRegex = /^(0|[1-9]\d{0,2})$/;
      return numberRegex.test(value.trim());
    });

  let finishSchema = Yup.string()
    .required("Value is required")
    .test("is-valid-number-of-days", "Must be number between 1 and 999", (value) => {
      if (!value) {
        return true;
      }
      const numberRegex = /^[1-9]\d{0,2}$/;
      return numberRegex.test(value.trim());
    });

  if (start && finish) {
    const startNumber = parseInt(start);
    const finishNumber = parseInt(finish);
    if (finishNumber <= startNumber) {
      finishSchema = finishSchema.test(
        "finish-greater-than-start",
        "The second value must be greater than the first value",
        () => false,
      );
    }
  }

  return Yup.object().shape({
    start: startSchema,
    finish: finishSchema,
  });
};

const enrollmentRules = Yup.array().of(
  Yup.object({
    value: Yup.lazy((value) =>
      typeof value === "object"
        ? Yup.object().when("filter", ([filter], schema) => {
            if (value) {
              if (filter === FilterOptionsEnum.IsWithinLastDays) {
                return getIsWithinLastDaysSchema(value.start, value.finish);
              } else {
                return Yup.object({
                  from: Yup.date().required("Start date is required").typeError("Invalid date"),
                  to: Yup.date()
                    .required("End date is required")
                    .typeError("Invalid date")
                    .when("from", ([from], to) =>
                      from && !isNaN(Date.parse(from)) ? to.min(from, "End date must be greater than start date") : to,
                    ),
                });
              }
            }
            return schema;
          })
        : Yup.string()
            .required("Please enter value for the rule")
            .when("filter", ([filter], schema) => {
              if (value) {
                if (filter === FilterOptionsEnum.IsOnOrAfter || filter === FilterOptionsEnum.IsOnOrBefore) {
                  return Yup.string().test("date-validation", "Invalid date", () => !isNaN(Date.parse(value)));
                }
              }
              return schema;
            }),
    ),
  }),
);

const daysToComplete = Yup.number()
  .required("Days to complete value is required")
  .min(1, "Minimum value is 1")
  .max(100, "Maximum value is 100");

const getPrioritySchema = (disableDefault?: boolean) => {
  return Yup.lazy((priorityLevelFormValues: PriorityLevelFormValues) => {
    if (isEqual(priorityLevelFormValues.priorityLevel, PriorityLevels.required)) {
      return Yup.object().shape({ daysToComplete });
    }

    return Yup.object().test("Default disabled", "None option is disabled", (_, ctx) => {
      if (!disableDefault || !isEqual(priorityLevelFormValues.priorityLevel, PriorityLevels.none)) {
        return true;
      }

      return ctx.createError({
        message: "None option is disabled",
        path: nameof<PriorityLevelFormValues>("priorityLevel"),
      });
    });
  });
};

const minPriorityDueDate = moment().add(1, "day");
const maxPriorityDueDate = moment().add(100, "day");

const getPrioritySchemaV2 = (disableDefault?: boolean) => {
  return Yup.lazy((priorityLevelFormValues: PriorityLevelFormValues) => {
    if (
      isEqual(priorityLevelFormValues.priorityLevel, PriorityLevels.required) &&
      !priorityLevelFormValues.isFixedDueDate
    ) {
      return Yup.object().shape({ daysToComplete });
    }

    if (
      isEqual(priorityLevelFormValues.priorityLevel, PriorityLevels.required) &&
      priorityLevelFormValues.isFixedDueDate
    ) {
      return Yup.object().shape({
        fixedDueDate: Yup.date()
          .required("Date is required")
          .min(minPriorityDueDate, "The minimum date is 1 day ahead")
          .max(maxPriorityDueDate, "The maximum date is 100 days ahead")
          .test(
            "date-validation",
            "Invalid date",
            () =>
              priorityLevelFormValues.fixedDueDate != null && !isNaN(Date.parse(priorityLevelFormValues.fixedDueDate)),
          ),
      });
    }

    return Yup.object().test("Default disabled", "None option is disabled", (_, ctx) => {
      if (!disableDefault || !isEqual(priorityLevelFormValues.priorityLevel, PriorityLevels.none)) {
        return true;
      }

      return ctx.createError({
        message: "None option is disabled",
        path: nameof<PriorityLevelFormValues>("priorityLevel"),
      });
    });
  });
};

const schemas = {
  flowConfiguration: Yup.object().shape({
    title: title(128),
    thumbnailUrl: requiredThumbnailUrl,
    description: requiredDescription(maxFlowDescriptionLength),
  }),
  flowSettings: Yup.object().shape({
    labels: labelValues,
    softwareApplications: softwareApplicationValues,
  }),
  sendEmailValidationSchema: () =>
    Yup.object().shape({
      email: Yup.string().trim().matches(CUSTOM_EMAIL, "Please enter correct email").required("Email is required"),
    }),
  group: Yup.object().shape({
    name: groupName,
    description: requiredDescription(maxFieldLength),
  }),
  image: Yup.object().shape({
    uploadedImages,
    uploadedTeamsImages,
  }),
  usersFileUpload: Yup.object().shape({
    files: usersFileUpload,
  }),
  automaticEnrollment: Yup.object().shape({
    enrollmentRules,
  }),
  priority: getPrioritySchema,
  priorityV2: getPrioritySchemaV2,
  getEditGroupSchema,
  getCreateUserSchema,
  getEditUserSchema,
  getRoleSchema,
  getContactSchema,
  eventInfo,
  emailInfo,
  messageInfo,
  messageContent,
  emailTemplate,
  assessmentInfo,
  assessmentSettings,
  assessmentContent,
  videoInfo,
  videoSettings,
  closedCaptions,
  getPdfConfigurationSchema,
  getUploadedPdfConfigurationSchema,
  emailTemplateInfo,
  emailTemplateContent,
  landingPageInfo,
  landingPageContent,
  linkInfo,
  sendingProfileInfo,
  smtpAuthSendingProfileConfigure,
  smtpAuthSendingProfileSettings,
  smtpAuthSendingProfileVerification,
  surveyInfo,
  surveysContent,
  sectionHeaderInfo,
  externalTrigger,
  packConfigure,
  campaignConfigure,
  campaignContent,
  feedbackPageInfo,
  packRequestValidationSchema,
  packTrialRequestValidationSchema,
  msTeamsMessageSchema,
  senderInfo,
};

export default schemas;
