import { useEventCallback } from "hooks/useEventCallback";
import { bindAction } from "interfaces";
import { useCallback, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import FormFooter from "../../../../components/forms/FormFooter";
import { AppDispatch, RootState } from "../../../Application/globaltypes/redux";
import { EnrollmentRule } from "../../Groups/AddEditGroupForms/EnrollmentBuilder/utils";
import { GroupTemplateEnrollment } from "../Details/GroupTemplateEnrollment";
import { saveRulesAction } from "../state/thunks/TemplateEnrollmentThunk";

type Props = {
  disabled: boolean;
  templateId: number;
};

type AllProps = Props & PropsFromRedux;

const EditTemplateEnrollment = (props: AllProps) => {
  const { disabled, enrollmentRules, templateId, saveRules, accountType, isPreview } = props;
  const [isValid, setIsValid] = useState(true);
  const getValuesRef = useRef<() => { enrollmentRules: EnrollmentRule[] }>(() => ({ enrollmentRules }));
  const [isDirty, setIsDirty] = useState(false);
  const resetFormRef = useRef<() => void>(() => {});

  const save = useEventCallback(async () => {
    const enrollmentRules = getValuesRef.current?.().enrollmentRules;
    await saveRules(enrollmentRules, templateId);
  }, [templateId]);

  const onCancel = useCallback(() => {
    resetFormRef.current();
  }, []);

  return (
    <>
      <div className="scrollable-content">
        <GroupTemplateEnrollment
          disabled={disabled || isPreview}
          bindGetValues={(getValues) => (getValuesRef.current = getValues)}
          enrollmentRules={enrollmentRules}
          onIsValidChange={setIsValid}
          onIsDirtyChange={setIsDirty}
          accountType={accountType}
          bindFormReset={(resetForm) => (resetFormRef.current = resetForm)}
        />
      </div>
      {isPreview || (
        <FormFooter
          isSaveBtnDisabled={!(isDirty && isValid)}
          isCancelBtnDisabled={!isDirty}
          onSave={save}
          onCancel={onCancel}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const { groupTemplate } = state.people;
  return {
    enrollmentRules: groupTemplate.base.enrollmentRules,
    isPreview: groupTemplate.base.isPreview,
    accountType: state.userProfile.accountTypeId,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    saveRules: bindAction(saveRulesAction, dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditTemplateEnrollment);
