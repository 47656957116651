import { FC, useEffect, useMemo, useState } from "react";
import {
  lineChartFactory,
  noBarData,
  noData,
  roundToTwoDigits,
  validLineData,
  formattedAverageTime,
  startsColor,
  completesColor,
  chartColorScale,
  QueryFilter,
  lineChartTitles,
  chartLegendLabels,
  totalActivity,
  dailyActivity,
  starts,
  completes,
} from "features/Library/Common/utils/performanceUtils";
import { ChartWrapper, HorizontalBarChart, LineChart, getDateFormatByCount } from "components/charts";
import CardReporting from "components/cards/CardReporting/CardReporting";
import { ColumnOption } from "interfaces/columnOptions";
import { GenericPerformanceList } from "../../shared/GenericPerformanceList";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { VideoDetails } from "features/Reporting/types/content";
import { TextTruncate } from "components";
import { Tooltip } from "components/common/tooltip";
import { useQuery } from "@tanstack/react-query";
import * as contentReportingService from "features/Reporting/services/contentReportingService";
import { DataPoint } from "components/charts/types/HorizontalBarChart";
import { EngagementLineData, VideoEngagementData } from "features/Library/Videos/state/slices/videoPerformanceSlice";
import { sharedAccountReportingHorizontalBarProps, sharedAccountReportingLineProps } from "../../shared";
import { CommonAccountReportProps } from "../../types";
import dateTimeUtils from "utils/dateTimeUtils";
import { EntityType } from "features/Library/Flows/Designer/types";

import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import { AppDispatch } from "features/Application/globaltypes/redux";
import { ConnectedProps, connect } from "react-redux";
import { setExportAction, setHasDataToExport } from "features/Reporting/state/export/exportSlice";
import { bindAction } from "interfaces";
import { doVideoExport } from "features/Reporting/state/content/contentActions";
import { bindActionCreators } from "@reduxjs/toolkit";
import { setIsReportEnabled, reset } from "features/Reporting/state/toolbar/toolbarSlice";

import "features/Library/Common/utils/performanceSCSSUtils.scss";
import styles from "../../contentOverviewReports.module.scss";

export type ExtractsEvents<T, K extends keyof T> = T[K];
export type Events = ExtractsEvents<EngagementLineData[number], "Events">;
export type RestructuredDay = Events & Pick<EngagementLineData[number], "Date">;

export const getCardsReportData = (engagementData: VideoEngagementData) => {
  return [
    {
      statDescription: `Total ${starts}`,
      stat: engagementData.StartedCount ?? 0,
    },
    {
      statDescription: `Total ${completes}`,
      stat: engagementData.CompletedCount ?? 0,
    },
    {
      statDescription: "Completion Rate",
      stat: engagementData.CompletionRate ? `${roundToTwoDigits(engagementData.CompletionRate * 100)}%` : "0%",
    },
    {
      statDescription: "Avg. Video Duration",
      stat: engagementData.VideoDuration ? formattedAverageTime(engagementData.VideoDuration) : "0s",
    },
  ];
};

export type Props = CommonAccountReportProps & PropsFromRedux;

const fetchDailyData = async ({ queryKey }: QueryFilter) => {
  const result = await contentReportingService.getVideoDailyData(queryKey[1]);
  let newResult: RestructuredDay[] = [];

  for (const day of result.data) {
    const obj: Partial<RestructuredDay> = {};
    obj["Date"] = day.Date;
    for (const [key, value] of Object.entries(day.Events)) {
      obj[key as keyof Events] = value;
    }
    newResult.push(obj as RestructuredDay);
  }
  return lineChartFactory(newResult);
};

const fetchEngagementQuery = async ({ queryKey }: QueryFilter) => {
  const result = (await contentReportingService.getVideoEngagement(queryKey[1])).data;

  const usageBarData: DataPoint[] = [
    {
      id: starts.toLowerCase(),
      category: starts,
      value: result.StartedCount,
      fillColor: startsColor,
    },
    {
      id: completes.toLowerCase(),
      category: completes,
      value: result.CompletedCount,
      fillColor: completesColor,
    },
  ];
  return { ...result, usageBarData };
};

const fetchDetailsQuery = async ({ queryKey }: QueryFilter) => {
  const result = (await contentReportingService.getVideoDetails(queryKey[1])).data;
  return result.map((item) => {
    const roundedCompletionRate = roundToTwoDigits(item.CompletionRate as number);
    return {
      ...item,
      CompletionRate: roundedCompletionRate,
      id: item.VideoId,
    };
  });
};

export const VideoReport: FC<Props> = ({
  filter,
  setSelectedContent,
  setHasExportData,
  setExportAction,
  videoExport,
  setIsReportEnabled,
  resetIsReportEnabled,
}) => {
  const [chartState, setChartState] = useState(totalActivity);
  const reportEnabled = useFeatureFlag(FeatureFlags.VideoPerformanceOverview);

  const lineChartQuery = useQuery({
    queryKey: ["VideoReportDailyData", filter],
    queryFn: fetchDailyData,
    enabled: reportEnabled,
  });

  const engagementQuery = useQuery({
    queryKey: ["VideoReportEngagement", filter],
    queryFn: fetchEngagementQuery,
    enabled: reportEnabled,
  });

  const detailsQuery = useQuery({
    queryKey: ["VideoReportDetails", filter],
    queryFn: fetchDetailsQuery,
    enabled: reportEnabled,
  });

  useEffect(() => {
    setHasExportData(!!detailsQuery.data && detailsQuery.data.length > 0);
  }, [detailsQuery.data, setHasExportData]);

  // Setting up correct export method if send entity is changed
  useEffect(() => {
    setExportAction({
      method: videoExport,
      args: [filter],
      isExportEnabled: true,
    });
  }, [videoExport, filter, setExportAction]);

  useEffect(() => {
    setIsReportEnabled(reportEnabled);

    return () => {
      resetIsReportEnabled();
    };
  }, [reportEnabled, setIsReportEnabled, resetIsReportEnabled]);

  const lineData = useMemo(() => {
    if (chartState === dailyActivity) {
      return {
        xData: [lineChartQuery.data?.Date, lineChartQuery.data?.Date],
        yData: [lineChartQuery.data?.StartedCount, lineChartQuery.data?.CompletedCount],
      };
    }
    return {
      xData: [lineChartQuery.data?.Date, lineChartQuery.data?.Date],
      yData: [lineChartQuery.data?.CumulativeStartedCount, lineChartQuery.data?.CumulativeCompletedCount],
    };
  }, [chartState, lineChartQuery.data]);

  const cardData = useMemo(() => {
    if (engagementQuery.isSuccess) {
      const cards = getCardsReportData(engagementQuery.data);
      return cards;
    }
  }, [engagementQuery.data, engagementQuery.isSuccess]);

  const barDomain = useMemo(() => {
    if (engagementQuery.isSuccess) {
      let maxValue = Math.max(...engagementQuery.data.usageBarData.map((d) => d.value));
      return [0, Math.max(maxValue, 1)];
    }
  }, [engagementQuery.isSuccess, engagementQuery.data?.usageBarData]);

  const columns: ColumnOption<VideoDetails>[] = useMemo(
    () => [
      {
        name: "Video",
        width: 7,
        isSortable: false,
        render(item) {
          return (
            <Tooltip
              target={
                <button
                  className={styles.linkButton}
                  onClick={() =>
                    setSelectedContent({ id: item.VideoId, type: EntityType.Video, name: item.VideoTitle })
                  }
                >
                  <TextTruncate lines={2} className={styles.wordBreak}>
                    {item.VideoTitle}
                  </TextTruncate>
                </button>
              }
              content={item.VideoTitle}
              showAlways
            />
          );
        },
      },
      {
        isSortable: false,
        name: `Total ${starts}`,
        render(item) {
          return <div>{item.TotalStarts.toLocaleString()}</div>;
        },
        width: 4,
      },
      {
        isSortable: false,
        width: 4,
        name: `Total ${completes}`,
        render(item) {
          return <div>{item.TotalCompletes.toLocaleString()}</div>;
        },
      },
      {
        width: 4,
        render(item) {
          return <div>{roundToTwoDigits(item.CompletionRate as number * 100)}%</div>;
        },
        name: "Completion Rate",
        isSortable: false,
      },
      {
        name: "Video Duration",
        width: 4,
        isSortable: false,
        render(item) {
          return <div>{formattedAverageTime(item.VideoDuration)}</div>;
        },
      },
      {
        name: "Last Activity",
        width: 4,
        isSortable: false,
        render(item) {
          return <div>{dateTimeUtils.formatDate(item.LastActivity)}</div>;
        },
      },
    ],
    [setSelectedContent],
  );

  if (!reportEnabled) {
    return <ReportUnavailable />;
  }

  return (
    <div className={styles.reportBody}>
      <div className={styles.lineChartContainer}>
        <ChartWrapper
          titles={lineChartTitles}
          showLegend
          legendLabels={chartLegendLabels}
          colorRange={chartColorScale}
          onChange={setChartState}
        >
          <RequestStatusRenderer state={lineChartQuery.status}>
            {lineChartQuery.isSuccess &&
              validLineData([
                lineChartQuery.data.CompletedCount,
                lineChartQuery.data.CumulativeCompletedCount,
                lineChartQuery.data.StartedCount,
                lineChartQuery.data.CumulativeStartedCount,
              ]) ? (
              <LineChart
                {...sharedAccountReportingLineProps}
                xFormatterFunc={getDateFormatByCount(lineChartQuery.data.Date.length)}
                colorRange={chartColorScale}
                {...lineData}
              />
            ) : (
              noData(filter)
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className={styles.funnelChartContainer}>
        <ChartWrapper titles={["Engagement"]}>
          <RequestStatusRenderer state={engagementQuery.status}>
            {engagementQuery.isSuccess &&
              !noBarData(engagementQuery.data.StartedCount, engagementQuery.data.CompletedCount) ? (
              <HorizontalBarChart
                {...sharedAccountReportingHorizontalBarProps}
                domain={barDomain}
                data={engagementQuery.data.usageBarData}
              />
            ) : (
              noData(filter)
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className={styles.performanceCardSection}>
        <RequestStatusRenderer state={engagementQuery.status}>
          {engagementQuery.isSuccess && <CardReporting items={cardData!} />}
        </RequestStatusRenderer>
      </div>
      <div className={styles.table}>
        <RequestStatusRenderer state={detailsQuery.status}>
          {detailsQuery.isSuccess && (
            <GenericPerformanceList title="Videos" rows={detailsQuery.data} columns={columns} filter={filter} />
          )}
        </RequestStatusRenderer>
      </div>
    </div>
  );
};

export const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setHasExportData: bindAction(setHasDataToExport, dispatch),
  videoExport: bindActionCreators(doVideoExport, dispatch),
  setExportAction: bindAction(setExportAction, dispatch),
  setIsReportEnabled: bindAction(setIsReportEnabled, dispatch),
  resetIsReportEnabled: bindAction(reset, dispatch),
});

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(VideoReport);
