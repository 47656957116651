import { renderModalActionsFirstStep, renderModalActionsMiddleStep } from "components/assignmentModals/contentAssignmentModal/utils/contentAssignmentModalActions";
import { ModalActionConfig, RenderActions, RenderModalActionsType, StepsOptions } from "components/assignmentModals/types";
import { renderSteps } from "components/assignmentModals/utils/renderSteps";
import { Button } from "components/buttons/button/Button";
import ModalWithSteps from "components/modal/ModalWithSteps";
import { AppDispatch, RootState } from "features/Application/globaltypes/redux";
import { isEmpty, once } from "lodash";
import { FC, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import AddPacksModalStep from "./AddPacksModalStep";
import { PackMap } from "components/assignmentModals/groupTemplatePacksConfirmationModal/types";
import { PacksLicensingModalStep } from "./PacksLicensingModalStep";
import { bindAction } from "interfaces";
import { savePacks } from "../../state/thunks/TemplateAvailablePacksThunk";
import { reset } from "../../state/TemplateAvailablePacksSlice";
import cn from "classnames";
import styles from "./addPacksModal.module.scss";

export interface AddPacksModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  templateId: number;
}

export type AddPacksModalPropsAll = PropsFromRedux & AddPacksModalProps;

export const AddPacksModal: FC<AddPacksModalPropsAll> = ({
  showModal,
  setShowModal,
  selectedIds,
  templateId,
  addPacksToGroupTemplate,
  reset,
}) => {
  const selectedPacks = useRef<PackMap>(new Map());
  const [isDisabled, setIsDisabled] = useState(true);
  const renderPacksStep = (renderModalActions: RenderModalActionsType) => (
    <AddPacksModalStep
      templateId={templateId}
      packMap={selectedPacks.current}
      header="Add Packs"
      renderModalActions={renderModalActions}
    />
  );
  const renderPackAccountsStep = (renderModalActions: RenderModalActionsType) => (
    <PacksLicensingModalStep
      packMap={selectedPacks.current}
      header="Provision Licenses"
      setIsDisabled={setIsDisabled}
      renderModalActions={renderModalActions}
    />
  );

  const onModalClose = () => {
    setShowModal(false);
    selectedPacks.current.clear();
    reset();
  };

  const stepsOptions: StepsOptions[] = [
    {
      renderStep: renderPacksStep,
      modalActionConfig: {
        previous: {
          onClick: onModalClose,
        },
        next: {
          disabled: isEmpty(selectedIds),
        },
      },
    },
    {
      renderStep: renderPackAccountsStep,
      modalActionConfig: {
        confirm: {
          disabled: isDisabled,
          onClick: onModalClose,
        },
      },
    },
  ];

  const renderModalActionsLastStep =
    ({ previous, confirm }: ModalActionConfig) =>
    (_: () => void, prevStep: () => void) =>
    (closeModal: Function) => {
      const confirmHandler = async () => {
        await addPacksToGroupTemplate(templateId, [...selectedPacks.current.values()], [], true);
        confirm?.onClick?.();
        closeModal();
      };
      return (
        <>
          <Button
            primary
            blur
            className="previous"
            content="Previous"
            onClick={() => {
              previous?.onClick?.();
              prevStep();
            }}
          />
          <Button
            primary
            className="confirm"
            content="Finish"
            disabled={confirm?.disabled}
            onClick={once(confirmHandler)}
          />
        </>
      );
    };

  const renderActions: RenderActions = {
    renderModalActionsFirstStep,
    renderModalActionsLastStep,
    renderModalActionsMiddleStep,
  };

  return (
    <ModalWithSteps
      className={cn(styles.root, { [styles["multiple-packs"]]: selectedIds.length > 2 })}
      showModal={showModal}
      onCancel={onModalClose}
      scrolling
    >
      {renderSteps(stepsOptions, renderActions)}
    </ModalWithSteps>
  );
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addPacksToGroupTemplate: bindAction(savePacks, dispatch),
  reset: () => dispatch(reset()),
});

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    selectedIds: state.people.groupTemplate.availablePacks.packsToAdd,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddPacksModal);
