import { Component } from "react";
import { ConnectedProps, batch, connect } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Dimmer, Loader } from "semantic-ui-react";
import { WithRouterProps, withRouter } from "../../../../adapters/withRouter/withRouter";
import { ModalTypes, Segments } from "../../../../components";
import RevertConfirmationModalTs from "../../../../components/modal/RevertConfirmationModalTs";
import { ProtectedRoute } from "../../../../components/restrictedRoute/ProtectedRoute";
import { PublishedStatusTypes, RolePermissions, RouteNames, UsersGroupsContext } from "../../../../enums";
import { IModalManager, IObservable, ISegment, WizardStepHandlers } from "../../../../interfaces";
import ModalManager from "../../../../utils/ModalManager";
import Observable from "../../../../utils/Observable";
import WizardStepsManager from "../../../../utils/WizardStepsManager";
import { eventSessionsSchemaEdit } from "../../../../utils/validationSchemas/eventValidationSchemas";
import Restricted from "../../../Application/Restricted";
import { AppDispatch, RootState } from "../../../Application/globaltypes/redux";
import * as rtnEvents from "../../../Application/services/realTimeNotification/events/library/libraryEvents";
import RtnEventsEmitter from "../../../Application/services/realTimeNotification/rtnEventsEmitter";
import * as eventSessionsValidatorActions from "../../Events/state/actions/eventSessionsValidatorActions";
import { EnumDictionary } from "../../Surveys/Edit/types";
import Configure from "../EventDetails/Configure/Configure";
import EventPerformance from "../EventDetails/Performance/EventPerformance";
import RemindersTab from "../EventDetails/Reminders/RemindersTab";
import Sessions from "../EventDetails/Sessions/Sessions";
import SettingsTab from "../EventDetails/Settings/SettingsTab";
import * as commonEventActions from "../state/actions/commonEventActions";
import * as eventDetailsActions from "../state/actions/eventDetailsActions";
import * as eventEntityStateActions from "../state/actions/eventEntityStateActions";
import EditEventDetailsHeader from "./EditEventDetailsHeader/EditEventDetailsHeader";
import EventsSubheaderContainer from "./EventsSubheader/EventsSubheaderContainer";
import EditEventPeople from "./People/EditEventPeople";
import { EditEventState, EditEventSteps, showLastModified } from "./types";

import "./EditEvent.scss";
import { FeatureFlags } from "featureFlags";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { LDProps } from "../../../LDProps";
import AssociatedPacks from "features/Licensing/Packs/AssociatedPacks/AssociatedPacks";
import EntityTypes from "../../../../enums/eventTypes";
import { permissionPredicateForPacks } from "features/Library/Common/utils/performanceUtils";

const eventOverviewUrl = `/${RouteNames.contentEvents}`;

export type EventDetailsPropsAll = PropsFromRedux & WithRouterProps & LDProps;

export class EditEvent extends Component<EventDetailsPropsAll, EditEventState> {
  private stepsManager = new WizardStepsManager();
  private readonly eventOverviewUrl = `/${RouteNames.contentEvents}`;
  private readonly eventId: number;
  private readonly pages: EnumDictionary<EditEventSteps, ISegment>;
  private showPacksTab: boolean;
  private readonly onDiscardedObserver: IObservable<() => void>;
  private readonly onTriggerRemoveRemindersObserver: IObservable<
    (onRemoveConfirm: () => void, selectedItemsCount: number) => void
  >;
  private readonly revertModalManager: IModalManager;

  constructor(props: EventDetailsPropsAll) {
    super(props);
    this.showPacksTab = !!this.props.flags?.[FeatureFlags.AssociatedPacks];
    this.state = {
      showAddPeopleModal: false,
      showAddGroupModal: false,
      showRevertModal: false,
      isValid: true,
      people: {
        usersGroupsContext: UsersGroupsContext.Groups,
      },
      activePage: EditEventSteps.Performance,
    };
    this.pages = {
      [EditEventSteps.Performance]: {
        label: "Performance",
        onClick: () => this.stepsManager.goToPage(EditEventSteps.Performance),
        init: () => this.setState({ activePage: EditEventSteps.Performance }),
      },
      [EditEventSteps.Configure]: {
        to: "configure",
        label: "Configuration",
        onClick: () => {
          this.stepsManager.goToPage(EditEventSteps.Configure);
          if (!this.props.isEventLoaded) {
            this.props.detailsActions.getEvent(this.eventId);
          }
        },
        init: () => {
          this.setState({ activePage: EditEventSteps.Configure });
        },
      },
      [EditEventSteps.Settings]: {
        to: "settings",
        label: "Settings",
        onClick: () => this.stepsManager.goToPage(EditEventSteps.Settings),
        init: () => {
          this.setState({ activePage: EditEventSteps.Settings });
        },
      },
      [EditEventSteps.Content]: {
        to: "sessions",
        label: "Details",
        onClick: () => this.stepsManager.goToPage(EditEventSteps.Content),
        init: () => {
          this.setState({ activePage: EditEventSteps.Content });
        },
      },
      [EditEventSteps.Reminders]: {
        to: "reminders",
        label: "Reminders",
        onClick: () => this.stepsManager.goToPage(EditEventSteps.Reminders),
        init: () => {
          this.setState({ activePage: EditEventSteps.Reminders });
        },
      },
      [EditEventSteps.People]: {
        to: "people",
        label: "People",
        onClick: () => this.stepsManager.goToPage(EditEventSteps.People),
        init: () => {
          this.setState({ activePage: EditEventSteps.People });
        },
      },
      [EditEventSteps.AssociatedPacks]: {
        to: "packs",
        label: "Associated Packs",
        onClick: () => this.stepsManager.goToPage(EditEventSteps.AssociatedPacks),
        init: () => {
          this.setState({ activePage: EditEventSteps.AssociatedPacks });
        },
      },
    };

    this.stepsManager.subscribeOnActiveIndexChanged((_: any, activeStepIndex: EditEventSteps) => {
      this.pages[activeStepIndex].init?.();
    });

    this.eventId = this.getEventId();
    this.onDiscardedObserver = new Observable<() => void>();
    this.onTriggerRemoveRemindersObserver = new Observable<
      (onRemoveConfirm: () => void, selectedItemsCount: number) => void
    >();
    this.revertModalManager = new ModalManager(ModalTypes.RevertEvent, this.handleRevert, false, this.props.userId);
  }

  componentDidMount() {
    this.props.detailsActions.getEvent(this.eventId);
    RtnEventsEmitter.subscribe(
      [rtnEvents.EventPublishSuccess, rtnEvents.EventDiscardedSuccess, rtnEvents.ExternalEventEditModeSuccess],
      this.onPublishedEvent,
    );
  }

  componentWillUnmount() {
    this.stepsManager.dispose();
    this.revertModalManager.dispose();
    this.props.onUnmount();
    RtnEventsEmitter.unsubscribe(
      [rtnEvents.EventPublishSuccess, rtnEvents.EventDiscardedSuccess, rtnEvents.ExternalEventEditModeSuccess],
      this.onPublishedEvent,
    );
  }

  onPublishedEvent = () => {
    this.onDiscardedObserver.notify();
    this.props.onDiscard(this.eventId);
  };

  getEventId = () => {
    return Number.parseInt(this.props.params.id, 10);
  };

  handleRevert = () => {
    this.props.entityStateActions.fetchDiscardEventEntity(this.getEventId());
  };

  onRevert = () => this.revertModalManager.execute();

  setValidationState = (isValid: boolean) => {
    this.setState({ isValid });
  };

  setUsersGroupsContext = (viewType: UsersGroupsContext) => {
    this.setState({
      ...this.state,
      people: {
        usersGroupsContext: viewType,
      },
    });
  };

  back = () => {
    if (!this.stepsManager.goBack()) {
      return eventOverviewUrl;
    }
  };

  onErrorClick = () => {
    const { navigate, location } = this.props;
    const redirectTo = `${this.eventOverviewUrl}/${this.eventId}/${this.pages[EditEventSteps.Content].to}/`;
    if (!location.pathname.includes(redirectTo)) {
      navigate(redirectTo);
      this.stepsManager.goToPage(EditEventSteps.Content);
      this.setState({ activePage: EditEventSteps.Content });
    }
  };

  handlePublish = (id: number = this.eventId) => {
    const { tryPublish } = this.props.validateActions;

    tryPublish(this.eventId, false);
  };

  render() {
    const { event, location, sessionsResolved } = this.props;
    const { isValid } = this.state;
    const disabled = event.isDraft === false;
    const isPeopleTab = location.pathname.endsWith("people");
    const isRemindersTab = location.pathname.endsWith("reminders");
    const isPacksTab = location.pathname.endsWith("packs");
    const isPublishedAndNotPurchased = !this.props.event.isDraft && !this.props.event.isPurchased;

    return (
      <Restricted
        permissions={[RolePermissions.EventsManage, RolePermissions.EventsCreate]}
        renderContent={(hasAnyPermission: boolean) => (
          <div className="nested-content edit-event scrollable-content">
            <EditEventDetailsHeader
              eventId={this.eventId}
              eventsOverviewUrl={eventOverviewUrl}
              title={event.title}
              canBeEdited={hasAnyPermission && isPublishedAndNotPurchased}
              crudPermission={hasAnyPermission}
              canBePublished={hasAnyPermission && isValid && event.isDraft && sessionsResolved}
              isRevertDisabled={!hasAnyPermission}
              isRevertVisible={event.hasBeenPublished}
              invalidFormDetails={!isValid}
              publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!event.isDraft)}
              hideEntityStateButtons={isPeopleTab}
              isEllipsisMenuDisabled={!hasAnyPermission}
              onErrorClick={this.onErrorClick}
              entityStateActions={{
                onRevert: this.onRevert,
              }}
              onPublish={this.handlePublish}
              back={this.back}
              onTriggerRemoveRemindersObserver={this.onTriggerRemoveRemindersObserver}
              isRemindersTab={isRemindersTab}
              canAddPacks={isPublishedAndNotPurchased}
              showAddPacksButton={isPacksTab}
              permissionPredicate={permissionPredicateForPacks}
            />
            <RevertConfirmationModalTs modalManager={this.revertModalManager} />

            <Dimmer active={this.props.isLoading} inverted>
              <Loader />
            </Dimmer>
            <EventsSubheaderContainer
              publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!event.isDraft)}
              isUpdateInProgress={event.isDraft}
              lastModifiedDateTime={event.dateModified}
              showLastModified={showLastModified.has(this.state.activePage)}
            >
              <Segments to={`${eventOverviewUrl}/${this.eventId}`}>
                <Segments.Segment {...this.pages[EditEventSteps.Performance]} />
                <Segments.Segment {...this.pages[EditEventSteps.Configure]} />
                <Segments.Segment {...this.pages[EditEventSteps.Settings]} />
                <Segments.Segment {...this.pages[EditEventSteps.Content]} />
                <Segments.Segment {...this.pages[EditEventSteps.Reminders]} />
                <Segments.Segment {...this.pages[EditEventSteps.People]} />
                {this.showPacksTab && <Segments.Segment {...this.pages[EditEventSteps.AssociatedPacks]} />}
              </Segments>
            </EventsSubheaderContainer>
            <div className="scrollable-content">
              <Routes>
                <Route
                  path="/"
                  element={
                    <EventPerformance
                      acceptHandlers={(handlers) =>
                        this.stepsManager.acceptHandlers(handlers, EditEventSteps.Performance)
                      }
                      eventId={this.eventId}
                      eventTitle={event.title}
                      isRecurring={event.externalEventSessions.sessions[0]?.isPeriodic}
                    />
                  }
                />

                <Route
                  path={this.pages[EditEventSteps.Configure].to}
                  element={
                    <div className="edit-form">
                      <Configure
                        onIsValidChange={this.setValidationState}
                        entityId={this.eventId}
                        acceptHandlers={(handlers) =>
                          this.stepsManager.acceptHandlers(handlers, EditEventSteps.Configure)
                        }
                        subscribeOnDiscarded={this.onDiscardedObserver.subscribe}
                        unSubscribeOnDiscarded={this.onDiscardedObserver.unsubscribe}
                        disabled={!hasAnyPermission || disabled}
                      />
                    </div>
                  }
                />
                <Route
                  path={this.pages[EditEventSteps.Settings].to}
                  element={
                    <div className="edit-form">
                      <div className="edit-form">
                        <SettingsTab
                          eventId={this.eventId}
                          acceptHandlers={(handlers) =>
                            this.stepsManager.acceptHandlers(handlers, EditEventSteps.Settings)
                          }
                        />
                      </div>
                    </div>
                  }
                />
                <Route
                  path={this.pages[EditEventSteps.Content].to}
                  element={
                    <div className="edit-sessions">
                      <Sessions
                        entityId={this.eventId}
                        acceptHandlers={(handlers) =>
                          this.stepsManager.acceptHandlers(handlers, EditEventSteps.Content)
                        }
                        disabled={!hasAnyPermission || disabled}
                        subscribeOnDiscarded={this.onDiscardedObserver.subscribe}
                        unSubscribeOnDiscarded={this.onDiscardedObserver.unsubscribe}
                        schema={eventSessionsSchemaEdit}
                      />
                    </div>
                  }
                />
                <Route
                  path={this.pages[EditEventSteps.Reminders].to}
                  element={
                    <ProtectedRoute permissions={[RolePermissions.EventsView]}>
                      <div className="tab__content edit-form">
                        <RemindersTab
                          eventId={this.eventId}
                          acceptHandlers={(handlers: WizardStepHandlers) =>
                            this.stepsManager.acceptHandlers(handlers, EditEventSteps.Reminders)
                          }
                          hasAnyPermission={hasAnyPermission}
                          disabled={disabled || !hasAnyPermission}
                          onDiscardedObserver={this.onDiscardedObserver}
                          onTriggerRemoveRemindersObserver={this.onTriggerRemoveRemindersObserver}
                        />
                      </div>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path={this.pages[EditEventSteps.People].to}
                  element={
                    <ProtectedRoute permissions={[RolePermissions.EventsView]}>
                      <EditEventPeople
                        eventId={this.eventId}
                        title={event.title}
                        thumbnailUrl={event.thumbnailUrl}
                        acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, EditEventSteps.People)}
                        usersGroupsContext={this.state.people.usersGroupsContext}
                        setUsersGroupsContext={this.setUsersGroupsContext}
                      />
                    </ProtectedRoute>
                  }
                />
                {this.showPacksTab && (
                  <Route
                    path={this.pages[EditEventSteps.AssociatedPacks].to}
                    element={
                      <AssociatedPacks
                        acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, EditEventSteps.AssociatedPacks)}
                        contentType={EntityTypes.ExternalEvent}
                        contentId={this.eventId}
                        canAddPacks={isPublishedAndNotPurchased}
                        permissions={[RolePermissions.AssetsManage, RolePermissions.AssetsCreate, RolePermissions.PacksManage]}
                        permissionPredicate={permissionPredicateForPacks}
                      />}
                  />)}
                <Route path="*" element={<Navigate to="../" replace />} />
              </Routes>
            </div>
          </div>
        )}
      />
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const eventDetails = state.library.events.eventDetails;
  const eventValidatorReducer = state.library.events.eventSessionsValidationReducer;
  const assignedPeopleCount = state.library.events.users.itemsCount + state.library.events.groups.itemsCount;

  return {
    event: eventDetails.event,
    isLoading: eventDetails.isLoading,
    isEventLoaded: eventDetails.isEventLoaded,
    sessionsErrorMode: eventValidatorReducer.errorMode,
    sessionsResolved: eventValidatorReducer.resolved,
    userId: state.userProfile.id,
    assignedPeopleCount: assignedPeopleCount,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  detailsActions: bindActionCreators(eventDetailsActions, dispatch),
  entityStateActions: bindActionCreators(eventEntityStateActions, dispatch),
  validateActions: bindActionCreators(eventSessionsValidatorActions, dispatch),

  onDiscard: (id: number) => {
    batch(() => {
      dispatch(commonEventActions.discardAction());
      dispatch(eventDetailsActions.getEvent(id));
    });
  },

  onUnmount: () => {
    batch(() => {
      dispatch(eventDetailsActions.clearEvent());
      dispatch(eventSessionsValidatorActions.resetAction());
      dispatch(eventEntityStateActions.resetEventEntityState());
    });
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(withLDConsumer()(EditEvent)));
