import { FC, useState } from "react";
import Usage from "./sections/Usage";
import Activity from "./sections/Activity";
import { ReportingFilter } from "../../../../components/reportingFilter/ReportingFilter";
import { dateRange30 } from "features/Library/Common/utils/performanceUtils";
import { GoalArea } from "../shared/GoalArea";

import "./teamsReport.scss";
import { FeatureFlags } from "featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";

export interface Props {}

export const TeamsReport: FC<Props> = () => {
  const [dateFilter, setDateFilter] = useState(dateRange30());
  const goalAreaEnabled = useFeatureFlag(FeatureFlags.MsGraphGoalReporting);
  return (
    <div className="teams-root">
      <div className="filter-row">
        <h2>Summary</h2>
        <ReportingFilter currentFilter={dateFilter} callback={setDateFilter} />
      </div>
      {goalAreaEnabled && (
        <>
          <GoalArea dateFilter={dateFilter} header="Meetings" application="Teams" activity="Meetings" />
          <GoalArea dateFilter={dateFilter} header="Messages" application="Teams" activity="Messages" />
          <GoalArea dateFilter={dateFilter} header="Calls" application="Teams" activity="Calls" />
        </>
      )}
      <h2>Usage</h2>
      <Usage dateFilter={dateFilter} />
      <h2>Activity</h2>
      <Activity dateFilter={dateFilter} />
    </div>
  );
};
