import { ValidatedForm } from "components/forms";
import { AccountTypes } from "features/Accounts/types";
import EnrollmentBuilder from "features/People/Groups/AddEditGroupForms/EnrollmentBuilder/EnrollmentBuilder";
import { getDataSourceOptions } from "features/People/Groups/AddEditGroupForms/EnrollmentBuilder/options";
import { EnrollmentRule } from "features/People/Groups/AddEditGroupForms/EnrollmentBuilder/utils";
import { noop } from "lodash";
import React, { useMemo } from "react";
import { validationSchemas } from "utils";

import styles from "./groupTemplateEnrollment.module.scss";

interface Props {
  disabled?: boolean;
  onIsDirtyChange?: (isDirty: boolean) => void;
  onIsValidChange: (isValid: boolean) => void;
  enrollmentRules: EnrollmentRule[];
  bindGetValues: (getValues: () => { enrollmentRules: EnrollmentRule[] }) => void;
  bindFormReset?: (resetForm: () => void) => void;
  disablePreventTransitionPrompt?: boolean;
  accountType: number;
}

const DirtyObserver = (
  props: React.PropsWithChildren<{ onIsDirtyChange?: (isDirty: boolean) => void; isDirty: boolean }>,
) => {
  const { onIsDirtyChange, isDirty, children } = props;

  React.useEffect(() => {
    onIsDirtyChange?.(isDirty);
  }, [isDirty, onIsDirtyChange]);

  return <>{children}</>;
};

export const GroupTemplateEnrollment = (props: Props) => {
  const {
    disabled,
    onIsValidChange,
    enrollmentRules,
    bindGetValues,
    onIsDirtyChange,
    bindFormReset,
    disablePreventTransitionPrompt,
    accountType,
  } = props;
  const initialValues = useMemo(() => ({ enrollmentRules }), [enrollmentRules]);

  return (
    <ValidatedForm
      className={styles.root}
      validationSchema={validationSchemas.automaticEnrollment}
      initialValues={initialValues}
      formWidthComputer={16}
      onSubmit={noop}
      bindGetValues={bindGetValues}
      bindFormReset={bindFormReset}
      isInitialValid={undefined}
      validateOnMount
      disablePreventTransitionPrompt={disablePreventTransitionPrompt}
      enableReinitialize
    >
      {(formik) => (
        <DirtyObserver isDirty={formik.validatedFieldProps.dirty} onIsDirtyChange={onIsDirtyChange}>
          <EnrollmentBuilder
            disabled={!!disabled}
            values={formik.values}
            validatedFieldProps={formik.validatedFieldProps}
            onIsValidChange={onIsValidChange}
            handleCriteriaChange={noop}
            dataSourceOptions={getDataSourceOptions(
              disabled || accountType === AccountTypes.BSI || accountType === AccountTypes.MSP,
            )}
          />
        </DirtyObserver>
      )}
    </ValidatedForm>
  );
};
