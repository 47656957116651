import PackPicture from "../../../packPicture/PackPicture";
import { PackArchivedLabel } from "../packArchivedLabel/PackArchivedLabel";
import { PackTrialLabel } from "../packTrialLabel/PackTrialLabel";

import styles from "./packTitleCell.module.scss";
import { Title } from "../../../listViewTemplates";

export interface PackTitleCellProps {
  pack: PackTitleInfo;
  handleTitleClick: () => void;
}

export interface PackTitleInfo {
  title: string;
  isTrial: boolean;
  isArchived?: boolean;
}

export const PackTitleCell: React.FC<PackTitleCellProps> = ({ pack, handleTitleClick }) => {
  return (
    <div className={styles.container}>
      <PackPicture className={styles.picture} />
      <Title title={pack.title} onTitleClick={pack.isArchived ? undefined : handleTitleClick} />
      <PackTrialLabel isTrial={pack.isTrial} className={styles.label} />
      <PackArchivedLabel isArchived={pack.isArchived} className={styles.label} />
    </div>
  );
};
