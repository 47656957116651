import { useQuery } from "@tanstack/react-query";
import { CardReportingItem } from "components/cards/CardReporting/CardReporting";
import { DataPoint } from "components/charts/types/HorizontalBarChart";
import {
  PerformanceFilter,
  QueryFilter,
  clicksColor,
  completes,
  completesColor,
  lineChartFactory,
  opensColor,
  sendsColor,
  starts,
  startsColor,
} from "features/Library/Common/utils/performanceUtils";
import moment from "moment";
import { ParsedFlowGoals } from "../Dashboard/Overview/FlowGoals";
import { FlowGoalQuery } from "../Dashboard/Overview/OverviewReport";
import * as contentReportingService from "../services/contentReportingService";
import * as dashboardReportingService from "../services/dashboardReportingService";
import { reportingDataService } from "../services/reportingService";

export const fetchEmailEngagementQuery = async ({ queryKey }: QueryFilter) => {
  const { data } = await contentReportingService.getEmailEngagement(queryKey[1]);
  return {
    raw: data,
    barData: [
      {
        id: "sends",
        category: "Sends",
        value: data.Sends ?? 0,
        fillColor: sendsColor,
      },
      {
        id: "opens",
        category: "Opens",
        value: data.Opens ?? 0,
        fillColor: opensColor,
      },
      {
        id: "clicks",
        category: "Clicks",
        value: data.Clicks ?? 0,
        fillColor: clicksColor,
      },
    ],
  };
};

export type emailEngagementQueryProps = {
  filter: PerformanceFilter;
  enabled: boolean;
};

export const useEmailEngagementQuery = (props: emailEngagementQueryProps) =>
  useQuery({
    queryKey: ["EmailsReportEngagement", props.filter],
    queryFn: fetchEmailEngagementQuery,
    enabled: props.enabled,
  });

export const fetchFlowGoalsQuery = async () => {
  const ids: FlowGoalQuery = {
    OnboardingTransition: [],
    Remediation: [],
    Optimization: [],
  };

  let skip = 0;
  const top = 1000;
  let tempResponse = (await dashboardReportingService.fetchFlowData([1, 2, 3], top, skip, true)).data;
  while (tempResponse.length !== 0) {
    skip += top;
    tempResponse.forEach((item) => {
      if (item.goals?.objective?.id === 1) {
        ids.OnboardingTransition.push(item.id);
      } else if (item.goals?.objective?.id === 2) {
        ids.Remediation.push(item.id);
      } else {
        ids.Optimization.push(item.id);
      }
    });
    if (tempResponse.length !== top) {
      break;
    }
    tempResponse = (await dashboardReportingService.fetchFlowData([1, 2, 3], top, skip, true)).data;
  }
  const dateTo = moment().format("MM/DD/YYYY HH:mm:ss");
  const response = (await dashboardReportingService.fetchFlowGoalsData(ids, dateTo)).data;

  const onboardingTransitionBarData: DataPoint[] = [
    {
      id: starts.toLowerCase(),
      category: starts,
      value: response.OnboardingTransition.Engagement.InProgress,
      fillColor: startsColor,
    },
    {
      id: completes.toLowerCase(),
      category: completes,
      value: response.OnboardingTransition.Engagement.Completed,
      fillColor: completesColor,
    },
  ];
  const remediationBarData: DataPoint[] = [
    {
      id: starts.toLowerCase(),
      category: starts,
      value: response.Remediation.Engagement.InProgress,
      fillColor: startsColor,
    },
    {
      id: completes.toLowerCase(),
      category: completes,
      value: response.Remediation.Engagement.Completed,
      fillColor: completesColor,
    },
  ];
  const optimizationBarData: DataPoint[] = [
    {
      id: starts.toLowerCase(),
      category: starts,
      value: response.Optimization.Engagement.InProgress,
      fillColor: startsColor,
    },
    {
      id: completes.toLowerCase(),
      category: completes,
      value: response.Optimization.Engagement.Completed,
      fillColor: completesColor,
    },
  ];

  const formattedOnboardingTransitionLineChart = lineChartFactory(response.OnboardingTransition.LineChart);
  const formattedRemediationLineChart = lineChartFactory(response.Remediation.LineChart);
  const formattedOptimizationLineChart = lineChartFactory(response.Optimization.LineChart);

  const cardData: CardReportingItem[] = [
    {
      statDescription: "Onboarding / Transition",
      stat: response.OnboardingTransition.Total.toLocaleString(),
    },
    {
      statDescription: "Remediation",
      stat: response.Remediation.Total.toLocaleString(),
    },
    {
      statDescription: "Optimization",
      stat: response.Optimization.Total.toLocaleString(),
    },
  ];

  const formattedResponse: ParsedFlowGoals = {
    OnboardingTransition: {
      LineChart: {
        Date: formattedOnboardingTransitionLineChart.Date,
        InProgress: formattedOnboardingTransitionLineChart.InProgress,
        InProgressTotal: formattedOnboardingTransitionLineChart.InProgressTotal,
        Completed: formattedOnboardingTransitionLineChart.Completed,
        CompletedTotal: formattedOnboardingTransitionLineChart.CompletedTotal,
      },
      barData: onboardingTransitionBarData,
    },
    Remediation: {
      LineChart: {
        Date: formattedRemediationLineChart.Date,
        InProgress: formattedRemediationLineChart.InProgress,
        InProgressTotal: formattedRemediationLineChart.InProgressTotal,
        Completed: formattedRemediationLineChart.Completed,
        CompletedTotal: formattedRemediationLineChart.CompletedTotal,
      },
      barData: remediationBarData,
    },
    Optimization: {
      LineChart: {
        Date: formattedOptimizationLineChart.Date,
        InProgress: formattedOptimizationLineChart.InProgress,
        InProgressTotal: formattedOptimizationLineChart.InProgressTotal,
        Completed: formattedOptimizationLineChart.Completed,
        CompletedTotal: formattedOptimizationLineChart.CompletedTotal,
      },
      barData: optimizationBarData,
    },
  };
  return { ...formattedResponse, cardData };
};

export const useFlowGoalsQuery = (enabled = true) =>
  useQuery({
    queryKey: ["flowGoals"],
    queryFn: fetchFlowGoalsQuery,
    enabled,
  });

export const fetchPackEngagementQuery = async ({ queryKey }: QueryFilter) => {
  const result = (await reportingDataService.getEngagement(queryKey[1])).data;

  const flowUsageBarData: DataPoint[] = [
    {
      id: "active-trials",
      category: "Trials",
      value: result.ActiveTrials,
      fillColor: sendsColor,
    },
    {
      id: "active-customers",
      category: "Purchased",
      value: result.ActiveCustomers,
      fillColor: clicksColor,
    },
  ];
  return { ...result, flowUsageBarData };
};
