import { FC, useState } from "react";
import Activity from "./sections/Activity";
import { GoalArea } from "../shared/GoalArea";
import { ReportingFilter } from "components/reportingFilter/ReportingFilter";
import { dateRange30 } from "features/Library/Common/utils/performanceUtils";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";

export interface Props {}

export const OnedriveReport: FC<Props> = () => {
  const [dateFilter, setDateFilter] = useState(dateRange30());
  const goalAreaEnabled = useFeatureFlag(FeatureFlags.MsGraphGoalReporting);
  return (
    <div className="onedrive-root">
      <div className="onedrive-heading">
        <h2>Summary</h2>
        <ReportingFilter currentFilter={dateFilter} callback={setDateFilter} />
      </div>
      {goalAreaEnabled && (
        <>
          <GoalArea dateFilter={dateFilter} header="Files Shared" application="OneDrive" activity="Files Shared" />
          <GoalArea dateFilter={dateFilter} header="Files Synced" application="OneDrive" activity="Files Synced" />
          <GoalArea dateFilter={dateFilter} header="Files Viewed" application="OneDrive" activity="Files Viewed" />
        </>
      )}
      <Activity dateFilter={dateFilter} />
    </div>
  );
};
