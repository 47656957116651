import { ChartWrapper, HorizontalBarChart, LineChart } from "components/charts";
import styles from "./goalArea.module.scss";
import { useFlowGoalsCardsQuery, useFlowGoalsLineQuery, useFlowGoalsTotalsQuery, VALID_GOALS } from "../queries";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { noBarData, noData, PerformanceFilter, validLineData } from "features/Library/Common/utils/performanceUtils";
import {
  sharedAccountReportingHorizontalBarProps,
  sharedAccountReportingLineProps,
} from "features/Reporting/Content/shared";
import CardReporting from "components/cards/CardReporting/CardReporting";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import { useMemo } from "react";

export interface Props<T extends VALID_GOALS["application"]> {
  header: string;
  dateFilter: PerformanceFilter;
  application: T;
  activity: Extract<VALID_GOALS, { application: T }>["activity"];
}

export const GoalArea = <T extends VALID_GOALS["application"]>({
  header,
  dateFilter,
  application,
  activity,
}: Props<T>) => {
  const reportEnabled = useFeatureFlag(FeatureFlags.MsGraphGoalReporting);
  const goal = useMemo(
    () => ({ application, activity }) as Extract<VALID_GOALS, { application: T }>,
    [activity, application],
  );
  const goalLineQuery = useFlowGoalsLineQuery({
    dateFilter,
    goal,
    enabled: reportEnabled,
  });

  const goalTotalsQuery = useFlowGoalsTotalsQuery({
    dateFilter,
    goal,
    enabled: reportEnabled,
  });

  const goalCardsQuery = useFlowGoalsCardsQuery({
    dateFilter,
    goal,
    enabled: reportEnabled,
  });

  if (!reportEnabled) {
    return null;
  }

  return (
    <div className={styles.root}>
      <h2>{header}</h2>
      <div className={styles.ct}>
        <ChartWrapper titles={[`${header} Activity`]} {...goalLineQuery.data?.chartWrapperProps}>
          <RequestStatusRenderer state={goalLineQuery.status}>
            {goalLineQuery.data && validLineData(goalLineQuery.data.lineProps.yData) ? (
              <LineChart {...sharedAccountReportingLineProps} {...goalLineQuery.data.lineProps} />
            ) : (
              noData(dateFilter)
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
        <ChartWrapper titles={["Activity by Type"]}>
          <RequestStatusRenderer state={goalTotalsQuery.status}>
            {goalTotalsQuery.data && !noBarData(...goalTotalsQuery.data.data.map((d) => d.value)) ? (
              <HorizontalBarChart {...sharedAccountReportingHorizontalBarProps} {...goalTotalsQuery.data} />
            ) : (
              noData(dateFilter)
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className={styles.cr}>
        <RequestStatusRenderer state={goalCardsQuery.status}>
          {goalCardsQuery.data && <CardReporting items={goalCardsQuery.data} />}
        </RequestStatusRenderer>
      </div>
    </div>
  );
};
