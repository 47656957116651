import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Dimmer, Loader } from "semantic-ui-react";
import { Navigate, Route, Routes } from "react-router-dom";

import { RootState } from "../../../../Application/globaltypes/redux";
import { LandingPageDetailsState, Pages } from "./types";
import * as landingPageDetailsActions from "../state/actions/threatDefenceLandingPageDetailsActions";
import * as landingPageEntityStateActions from "../state/actions/threatDefenceLandingPageEntityStateActions";
import { DetailsHeader } from "../../../../../components/sectionHeader";
import Segments from "../../../../../components/navigation/segments/Segments";
import Configure from "../LandingPageDetails/Configure/Configure";
import Content from "../LandingPageDetails/Content/Content";
import WizardStepsManager from "../../../../../utils/WizardStepsManager";
import RtnEventsEmitter from "../../../../Application/services/realTimeNotification/rtnEventsEmitter";
import * as rtnEvents from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import LandingPageDetailsSubheaderContainer from "./LandingPageDetailsSubheaderContainer";
import PublishValidationInfo from "../../../../../components/publishValidationInfo/PublishValidationInfo";
import { RouteNames, PublishedStatusTypes } from "../../../../../enums";
import RevertConfirmationModal from "../../../../../components/modal/RevertConfirmationModal";
import ModalTypes from "../../../../../components/modal/ModalTypes";
import modalUtilsFactory, { ModalUtils } from "../../../../../utils/modalUtilsFactory";
import dataService from "../services/threatDefenceLandingPagesDataService";
import azureStorageUtils from "../../../../../utils/azureStorageUtils";
import { withRouter, WithRouterProps } from "../../../../../adapters/withRouter/withRouter";

const landingPageOverviewUrl = `/${RouteNames.landingPagesSimulatedPhishing}`;

export type LandingPageDetailsPropsAll = PropsFromRedux & WithRouterProps;

export class EditLandingPageDetails extends Component<LandingPageDetailsPropsAll, LandingPageDetailsState> {
  private readonly pages: Pages;
  private readonly id: number;
  private readonly revertModal: ModalUtils;

  private stepsManager = new WizardStepsManager();

  constructor(props: LandingPageDetailsPropsAll) {
    super(props);
    this.state = {
      isSaving: false,
      modifiedDate: undefined,
      isValid: true,
      isContentValid: true,
      isErrorViewMode: false,
      areErrorsResolved: false,
      showRevertModal: false,
    };
    this.pages = {
      configure: {
        index: 0,
        segment: {
          label: "Configuration",
          onClick: this.openConfigurePage,
        },
      },
      content: {
        index: 1,
        segment: {
          to: "content",
          label: "Content",
          onClick: this.openContentPage,
        },
      },
    };
    this.id = this.getLandingPageId();
    this.revertModal = modalUtilsFactory();
  }

  async componentDidMount() {
    this.props.landingPageDetailsActions.getThreatDefenceLandingPage(this.id);
    this.props.landingPageDetailsActions.getThreatDefenceLandingPageContent(this.id, false);

    RtnEventsEmitter.subscribe(
      [rtnEvents.LandingPagePublishSuccess, rtnEvents.LandingPageDiscardSuccess],
      this.onPublishedEvent,
    );
    const urlWithSas = await dataService.getThreatDefenceLandingPageContentUrlWithSasAsync(
      this.getLandingPageId(),
      !this.isReadOnlyMode(),
    );
    const html = await azureStorageUtils.downloadAsString(urlWithSas);

    if (!html) {
      this.setState({ isContentValid: false });
    }
  }

  componentWillUnmount() {
    this.stepsManager.dispose();
    RtnEventsEmitter.unsubscribe(
      [rtnEvents.LandingPagePublishSuccess, rtnEvents.LandingPageDiscardSuccess],
      this.onPublishedEvent,
    );
  }

  openConfigurePage = () => {
    this.stepsManager.goToPage(this.pages.configure.index);
  };

  openContentPage = () => {
    this.stepsManager.goToPage(this.pages.content.index);
  };

  onPublishedEvent = () =>
    [
      this.props.landingPageDetailsActions.clearThreatDefenceLandingPage,
      this.props.landingPageDetailsActions.getThreatDefenceLandingPage,
      this.props.landingPageDetailsActions.getThreatDefenceLandingPageContent,
    ].forEach((action) => action(this.id));

  handleRevert = () => {
    this.closeRevertPopup();
    this.props.landingPageEntityStateActions.fetchDiscardThreatDefenceLandingPageEntity(this.id);
  };

  onRevert = () => {
    this.revertModal.execute(this.id, this.handleRevert, () =>
      this.setState({
        ...this.state,
        showRevertModal: true,
      }),
    );
    this.resetValidation();
  };

  closeRevertPopup = () => {
    this.setState({
      ...this.state,
      showRevertModal: false,
    });
  };

  renderRevertModal = (isRevertVisible: boolean) => {
    return (
      <RevertConfirmationModal
        modalUtils={this.revertModal}
        modalType={ModalTypes.RevertLandingPage}
        open={this.state.showRevertModal}
        onClose={this.closeRevertPopup}
        isVisible={isRevertVisible}
      />
    );
  };

  getLandingPageId = () => {
    return Number.parseInt(this.props.params.id);
  };

  isReadOnlyMode = () => this.props.landingPage.isDraft === false;

  onIsValidChange = (isValid: boolean) => {
    this.setState({ isValid });
  };

  onContentIsValidChange = (isValid: boolean) => {
    if (this.state.isContentValid !== isValid) {
      this.setState({
        isContentValid: isValid,
        isErrorViewMode: false,
        areErrorsResolved: this.state.isErrorViewMode && isValid,
      });
    }
  };

  handleClose = () => {
    this.props.navigate(landingPageOverviewUrl);
  };

  handlePublish = () => {
    if (!this.state.isContentValid) {
      this.setState({ isErrorViewMode: true });
      return;
    }
    this.props.landingPageEntityStateActions.publishDraftThreatDefenceLandingPageEntity(this.id);
    this.resetValidation();
  };

  handleEdit = () => {
    this.props.landingPageEntityStateActions.fetchDraftThreatDefenceLandingPageEntity(this.id);
  };

  resetValidation = () => {
    this.setState({ isErrorViewMode: false, areErrorsResolved: false });
  };

  renderPublishValidationInfo = () => {
    const { navigate, location } = this.props;
    const { isErrorViewMode, areErrorsResolved } = this.state;
    const redirectTo = `${landingPageOverviewUrl}/${this.id}/content`;

    return (
      <PublishValidationInfo
        unableToPublish={isErrorViewMode}
        onErrorButtonClick={() => {
          if (!location.pathname.includes(redirectTo)) {
            navigate(redirectTo);
          }
        }}
        readyToPublish={areErrorsResolved}
      />
    );
  };

  render() {
    const { landingPage } = this.props;
    const { configure, content } = this.pages;
    const { isValid, isErrorViewMode } = this.state;
    const disabled = this.isReadOnlyMode();

    return (
      <div className="nested-content scrollable-content">
        <DetailsHeader
          title={landingPage.name}
          titleForGA="Landing Page Details"
          backButton={landingPageOverviewUrl}
          canBeEdited={!landingPage.isDraft}
          isRevertVisible={landingPage.hasBeenPublished}
          publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!landingPage.isDraft)}
          invalidFormDetails={!isValid}
          canBePublished={!isErrorViewMode}
          entityStateActions={{
            onPublish: this.handlePublish,
            onClose: this.handleClose,
            onEdit: this.handleEdit,
            onRevert: this.onRevert,
          }}
        >
          {this.renderPublishValidationInfo()}
        </DetailsHeader>
        {this.renderRevertModal(landingPage.hasBeenPublished)}
        <LandingPageDetailsSubheaderContainer
          publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!landingPage.isDraft)}
          isUpdateInProgress={landingPage.isDraft}
          lastModifiedDateTime={landingPage.dateModified}
        >
          <Segments to={`/${RouteNames.landingPagesSimulatedPhishing}/${this.id}`}>
            <Segments.Segment {...configure.segment} />
            <Segments.Segment {...content.segment} />
          </Segments>
        </LandingPageDetailsSubheaderContainer>
        <Dimmer active={this.props.isLoading} inverted>
          <Loader />
        </Dimmer>

        <Routes>
          <Route
            path="/"
            element={
              <div className="edit-form">
                <Configure
                  entityId={this.id}
                  acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, configure.index)}
                  onIsValidChange={this.onIsValidChange}
                  disabled={disabled}
                />
              </div>
            }
          />
          <Route
            path={content.segment.to}
            element={
              <div className="edit-form">
                <Content
                  entityId={this.id}
                  acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, content.index)}
                  onIsValidChange={this.onContentIsValidChange}
                  disabled={disabled}
                />
              </div>
            }
          />
          <Route path="*" element={<Navigate to="../" replace />} />
        </Routes>
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  landingPage: state.library.threatDefenceLandingPages.threatDefenceLandingPageDetails.landingPage,
  content: state.library.threatDefenceLandingPages.threatDefenceLandingPageDetails.html,
  isLoading: state.library.threatDefenceLandingPages.threatDefenceLandingPageDetails.isLoading,
  isLandingPageLoaded: state.library.threatDefenceLandingPages.threatDefenceLandingPageDetails.isLandingPageLoaded,
  isLandingPageContentLoaded:
    state.library.threatDefenceLandingPages.threatDefenceLandingPageDetails.isLandingPageContentLoaded,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  landingPageDetailsActions: bindActionCreators(landingPageDetailsActions, dispatch),
  landingPageEntityStateActions: bindActionCreators(landingPageEntityStateActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(EditLandingPageDetails));
