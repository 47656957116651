import { Dispatch } from "redux";
import { AlertTypes } from "../../../../../enums";
import { getAxiosError } from "../../../../../utils/axiosError";
import { sendTransientNotification } from "../../../../Notifications/state/notificationsActions";
import { BasePerformanceRequestFilterParams } from "../../../Common/models";
import { downloadExcelExport } from "../../../Common/utils/performanceUtils";
import DataService from "../../services/surveysDataService";
import {
  setEngagementLineData,
  setEngagementFunnelData,
  reqSurveyQuestionsData,
  setSurveyQuestionsData,
  setSurveyQuestionsDataError,
  reqSelectedQuestionData,
  setSelectedQuestionData,
  setSelectedQuestionDataError,
  reqSelectedAnswerData,
  setSelectedAnswerData,
  setSelectedAnswerDataError,
  setSurveyContentData,
  setCardsData,
  reqEngagementLineData,
  reqEngagementFunnelData,
  reqCardsData,
  setEngagementLineDataError,
  setEngagementFunnelDataError,
  setCardsDataError,
  reqPeopleDetails,
  setPeopleDetails,
  setPeopleDetailsError,
} from "../slices/surveyPerformanceSlice";
import { setExporting } from "features/Reporting/state/export/exportSlice";

export const fetchEngagementLineData = (surveyId: number, filterParams: BasePerformanceRequestFilterParams) => {
  return async (dispatch: Dispatch) => {
    dispatch(reqEngagementLineData());
    try {
      const data = await DataService.getSurveyEngagementLine(surveyId, filterParams);
      dispatch(
        setEngagementLineData({
          data,
        }),
      );
    } catch (err) {
      if (err.code !== "ERR_CANCELED") {
        const errorMessage = getAxiosError(err);
        dispatch(setEngagementLineDataError({ errorMessage }));
      }
    }
  };
};

export const fetchEngagementFunnelData = (surveyId: number, filterParams: BasePerformanceRequestFilterParams) => {
  return async (dispatch: Dispatch) => {
    dispatch(reqEngagementFunnelData());
    try {
      const data = await DataService.getSurveyEngagementFunnel(surveyId, filterParams);

      dispatch(
        setEngagementFunnelData({
          data,
        }),
      );
    } catch (err) {
      // Request was aborted manually, no need to change anything
      if (err.code !== "ERR_CANCELED") {
        const errorMessage = getAxiosError(err);
        dispatch(setEngagementFunnelDataError({ errorMessage }));
      }
    }
  };
};

export const fetchSurveyQuestionsData = (surveyId: number, filterParams: BasePerformanceRequestFilterParams) => {
  return async (dispatch: Dispatch) => {
    dispatch(reqSurveyQuestionsData());

    try {
      const data = await DataService.getSurveyQuestionsData(surveyId, filterParams);
      dispatch(setSurveyQuestionsData({ data }));
    } catch (err) {
      // Request was aborted manually, no need to change anything
      if (err.code !== "ERR_CANCELED") {
        const errorMessage = getAxiosError(err);
        dispatch(setSurveyQuestionsDataError({ errorMessage }));
      }
    }
  };
};

export const fetchCardsData = (surveyId: number, filterParams: BasePerformanceRequestFilterParams) => {
  return async (dispatch: Dispatch) => {
    dispatch(reqCardsData());
    try {
      const data = await DataService.getSurveyCards(surveyId, filterParams);

      dispatch(
        setCardsData({
          data,
        }),
      );
    } catch (err) {
      // Request was aborted manually, no need to change anything
      if (err.code !== "ERR_CANCELED") {
        const errorMessage = getAxiosError(err);
        dispatch(setCardsDataError({ errorMessage }));
      }
    }
  };
};

export const fetchSelectedQuestionData = (
  surveyId: number,
  questionId: string,
  filterParams: BasePerformanceRequestFilterParams,
) => {
  return async (dispatch: Dispatch) => {
    dispatch(reqSelectedQuestionData());

    try {
      const data = (await DataService.getSurveyQuestionDetails(surveyId, questionId, filterParams))[0];

      dispatch(setSelectedQuestionData({ data }));
    } catch (err) {
      const errorMessage = getAxiosError(err);
      dispatch(setSelectedQuestionDataError({ errorMessage }));
    }
  };
};

export const fetchSelectedAnswerData = (
  surveyId: number,
  questionId: string,
  answerId: string,
  filterParams: BasePerformanceRequestFilterParams,
) => {
  return async (dispatch: Dispatch) => {
    dispatch(reqSelectedAnswerData());

    try {
      const data = await DataService.getSurveyAnswerDetails(surveyId, questionId, answerId, filterParams);

      dispatch(setSelectedAnswerData({ data }));
    } catch (err) {
      // Request was aborted manually, no need to change anything
      if (err.code !== "ERR_CANCELED") {
        const errorMessage = getAxiosError(err);
        dispatch(setSelectedAnswerDataError({ errorMessage }));
      }
    }
  };
};

export const fetchPeopleDetails = (surveyId: number, filterParams: BasePerformanceRequestFilterParams) => {
  return async (dispatch: Dispatch) => {
    dispatch(reqPeopleDetails());

    try {
      const data = await DataService.getSurveyPeopleDetails(surveyId, filterParams);
      dispatch(setPeopleDetails({ data }));
    } catch (err) {
      if (err.code !== "ERR_CANCELED") {
        const errorMessage = getAxiosError(err);
        dispatch(setPeopleDetailsError({ errorMessage }));
      }
    }
  };
};

export const fetchOtherOptionInformation = (surveyId: number) => {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await DataService.getSurveyContentAsync(surveyId);
      dispatch(setSurveyContentData(data));
    } catch (err) {
      // For now, I think it's fine to ignore this error. Only thing we would
      // be missing is the * next to required Text Responses
      console.error(err);
    }
  };
};

export const doExport = (surveyId: number, filterParams: BasePerformanceRequestFilterParams, surveyTitle: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(setExporting(true));

    try {
      const response = await DataService.getExport(surveyId, filterParams);

      dispatch(
        sendTransientNotification(
          { title: "Export Complete!", message: "File download has begun.", type: AlertTypes.success },
          5,
        ),
      );

      downloadExcelExport(response.data, surveyTitle);
    } catch (err) {
      // Request was aborted manually, no need to change anything
      if (err.code === "ERR_CANCELED") {
        return;
      }
      dispatch(
        sendTransientNotification(
          {
            title: "Export failed",
            message: "Please try again later. If this persists, please contact BrainStorm support.",
            type: AlertTypes.error,
          },
          5,
        ),
      );
      const errorMessage = getAxiosError(err);
      console.error(errorMessage);
    } finally {
      dispatch(setExporting(false));
    }
  };
};
