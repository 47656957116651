import axios from "axios";
import { isEmpty } from "lodash";
import {
  AccountInfo,
  AccountType,
  ParentAccount,
  AddOn,
  RestrictedResource,
  SavingAddOn,
  ContractType,
  Subscription,
  EmailSetting,
  VerifyEmailSettingsRequest,
} from "../types";
import { SortingDirection } from "../../../enums";
import KnownHttpHeaders from "../../Application/services/knownHttpHeaders";
import StatusCode from "../../../enums/httpStatusCodes";
import { Account } from "../../../interfaces/Account";
import Contact from "../../../interfaces/contact";
import { PageOfItems } from "../../../interfaces";
import { GlobalRole } from "../../../interfaces/role";
import { DistributedOperationResult } from "../../../types";
import http, { HttpResponse } from "../../../lib/http";

const apiRoot = "/api/accounts";
const ownApiRoot = "/api/account";
const accountTypeRoot = "/api/account-types";

const addStaticValues = (items: any) => items.map((item: any) => ({ ...item, licensesCount: 10000 }));

interface AccountDataService {
  getAccounts(
    skip: number,
    top: number,
    orderProp: string,
    orderDirection: SortingDirection,
    searchText?: string,
    filter?: string,
  ): Promise<{ items: Array<Account>; itemsCount: number }>;

  getAccountInfo(id: number): Promise<AccountInfo>;

  getOwnAccountInfo(): Promise<HttpResponse<AccountInfo>>;

  isAccountNameExists(name: string): Promise<boolean>;

  isAccountAliasExists(alias: string): Promise<boolean>;

  createAccount(account: any): Promise<DistributedOperationResult>;

  getAccountTypes(): Promise<Array<AccountType>>;

  getAllAccountTypes(): Promise<Array<AccountType>>;

  getContractTypes(): Promise<Array<ContractType>>;

  getReplacementAccountTypes(accountId: number): Promise<Array<AccountType>>;

  getAccountTypeRoles(accountTypeId: number): Promise<GlobalRole[]>;

  getPermissions(): Promise<any>;

  getAvailableAccounts(): Promise<Array<Account>>;

  getParentAccounts(accountTypeId: number, childAccountId?: number): Promise<RestrictedResource<ParentAccount>>;

  getAccountAreas(): Promise<any>;

  getAccountContacts(
    accountId: number,
    skip: number,
    top: number,
    orderByParams: string,
    filterParams: string,
  ): Promise<PageOfItems<Contact>>;

  updateOwnAccount(data: any): Promise<any>;

  updateAccountInfo(accountId: number, data: any): Promise<any>;

  addAccountContacts(accountId: number, userIds: any): Promise<any>;

  updateAccountContacts(accountId: number, data: any): Promise<any>;

  getAddOns(accountId: number): Promise<Array<AddOn>>;

  updateAddOns(accountId: number, addOns: Array<SavingAddOn>): Promise<string>;

  getSubscriptionAgreement(accountId: number): Promise<Subscription>;

  deleteAccount(accountId: number): Promise<string>;

  getFlowsCountUsedInPack(accountId: number, packId: number): Promise<number>;

  removePackFromAccount(accountId: number, packId: number): Promise<string>;

  getEmailSettings(accountId: number): Promise<EmailSetting>;

  createEmailSettings(settings: EmailSetting): Promise<any>;

  updateEmailSettings(settings: EmailSetting): Promise<any>;

  deleteEmailSetting(accountId: number): Promise<any>;

  verifyEmailSettings(verifyRequest: VerifyEmailSettingsRequest): Promise<any>;

  getSmtpSenderSettings(accountId: number): Promise<any>;
}

const service: AccountDataService = {
  async getAccounts(
    skip: number,
    top: number,
    orderProp: string,
    orderDirection: SortingDirection,
    searchText?: string,
    filter?: string,
  ) {
    const config = {
      params: {
        top,
        skip,
        orderBy: `${orderProp} ${orderDirection}`,
        searchTerm: !isEmpty(searchText) ? searchText : undefined,
        filter: !isEmpty(filter) ? filter : undefined,
      },
    };

    const response = await axios.get(`${apiRoot}/linked`, config);

    return {
      items: addStaticValues(response.data.items),
      itemsCount: response.data.count,
    };
  },

  async getAccountInfo(id: number) {
    const { data } = await axios.get(`${apiRoot}/${id}`);

    return data;
  },

  async getOwnAccountInfo() {
    const response = await http.get<AccountInfo>(ownApiRoot);

    return response;
  },

  async getSubscriptionAgreement(accountId: number) {
    const response = await axios.get(`/api/contracts/subscription-agreement/${accountId}`);

    return response.data;
  },

  async isAccountNameExists(name: string) {
    const config = {
      params: {
        filter: `name eq "${name}"`,
      },
    };
    const response = await axios.get(apiRoot, config);
    return response.data && response.data.length > 0;
  },

  async isAccountAliasExists(alias: string) {
    const encodedAlias = encodeURIComponent(alias);
    try {
      await axios.head(`${apiRoot}/aliases/${encodedAlias}`);
      return true;
    } catch (error: any) {
      if (error.response?.status === StatusCode.NotFound) {
        return false;
      }
      throw error;
    }
  },

  async createAccount(account: any) {
    const body = { ...account, sendDefaultNotificationToMainContact: false };
    const response = await axios.post<DistributedOperationResult>(apiRoot, body);
    return response.data;
  },

  async getReplacementAccountTypes(accountId: number) {
    const accountTypes = await axios.get(`${apiRoot}/${accountId}/account-types`);
    return accountTypes.data;
  },

  async getAccountTypes() {
    const accountTypes = await axios.get(`${ownApiRoot}/account-types`);
    return accountTypes.data;
  },

  async getAllAccountTypes() {
    const accountTypes = await axios.get(accountTypeRoot);
    return accountTypes.data;
  },

  async getContractTypes() {
    const contractTypes = await axios.get("/api/contracts/types");
    return contractTypes.data;
  },

  async getAccountTypeRoles(accountTypeId: number) {
    const { data: roles } = await axios.get<GlobalRole[]>(`/api/account-types/${accountTypeId}/roles`);
    return roles;
  },

  async getPermissions() {
    const permissions = await axios.get(`${apiRoot}/permissions`);
    return permissions.data;
  },

  async getAvailableAccounts() {
    const accounts = await axios.get("/api/permissions/available-accounts");
    return accounts.data;
  },

  async getParentAccounts(accountTypeId: number, childAccountId?: number) {
    const response = await axios.get(`${accountTypeRoot}/${accountTypeId}/parents`, {
      params: {
        childAccountId,
      },
    });
    return {
      items: response.data,
      isResourceUnavailable: response.headers[KnownHttpHeaders.UnavailableResource] !== undefined,
    };
  },

  async getAccountAreas() {
    const response = await axios.get("/api/areas");
    return response.data;
  },

  async getAccountContacts(accountId: number, skip: number, top: number, orderByParams: string, filterParams: string) {
    const config: any = { params: { skip, top } };

    if (orderByParams) {
      config.params.orderBy = orderByParams;
    }

    if (filterParams) {
      config.params.filter = filterParams;
    }

    const response = await axios.get(`${apiRoot}/${accountId}/contacts`, config);
    return {
      items: response.data,
      count: parseInt(response.headers[KnownHttpHeaders.RecordsCount]),
    };
  },

  async updateOwnAccount(data: any) {
    const response = await axios.put(`${apiRoot}/info`, data);
    return response.data;
  },

  async updateAccountInfo(accountId: number, data: any) {
    const response = await axios.put(`${apiRoot}/${accountId}/info`, {
      ...data,
      companyName: data.name,
    });
    return response.data;
  },

  async addAccountContacts(accountId: number, userIds: any) {
    const response = await axios.post(`${apiRoot}/${accountId}/contacts`, userIds);
    return response.data;
  },

  async updateAccountContacts(accountId: number, data: any) {
    const response = await axios.put(`${apiRoot}/${accountId}/contacts`, data);
    return response.data;
  },

  async getAddOns(accountId: number) {
    const response = await axios.get(`${apiRoot}/${accountId}/addons`);
    return response.data;
  },

  async updateAddOns(accountId: number, addOns: Array<SavingAddOn>) {
    const response = await axios.post(`${apiRoot}/${accountId}/addons`, { addOns });
    return response.data;
  },

  async deleteAccount(accountId: number) {
    const response = await axios.delete(`${apiRoot}/${accountId}`);
    return response.data;
  },

  async getFlowsCountUsedInPack(accountId: number, packId: number) {
    const response = await axios.get(`${apiRoot}/${accountId}/purchased-flows/count?packId=${packId}`);
    return response.data;
  },

  async removePackFromAccount(accountId: number, packId: number) {
    const response = await axios.delete(`${apiRoot}/${accountId}/packs/${packId}`);
    return response.data;
  },

  async getEmailSettings(accountId: number) {
    const response = await axios.get(`${apiRoot}/${accountId}/smtp-settings`);
    if (response.data && response.data.length) {
      return response.data[0];
    }

    return null;
  },

  async createEmailSettings(settings: EmailSetting) {
    const response = await axios.post(`${apiRoot}/${settings.accountId}/smtp-settings`, settings);
    return response.data;
  },

  async updateEmailSettings(settings: EmailSetting) {
    const response = await axios.put(`${apiRoot}/${settings.accountId}/smtp-settings/${settings.id}`, settings);
    return response.data;
  },

  async deleteEmailSetting(accountId: number) {
    const response = await axios.delete(`${apiRoot}/${accountId}/smtp-settings`);
    return response.data;
  },

  async verifyEmailSettings(verifyRequest: VerifyEmailSettingsRequest) {
    const response = await axios.post("/api/smtp/verify", verifyRequest);
    return response.data;
  },

  async getSmtpSenderSettings(accountId: number) {
    const response = await axios.get(`${apiRoot}/${accountId}/smtp-settings/sender`);
    return response.data;
  },
};

export default service;
