import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { FeatureFlags } from "featureFlags";
import { PerformanceFilter } from "features/Library/Common/utils/performanceUtils";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { fetchPeopleOverviewUsersTable } from "../utils";
import { ColumnOption } from "interfaces/columnOptions";
import { PeopleOverviewUsersItem } from "features/Reporting/types/people";
import { TextTruncate } from "components";
import { Tooltip } from "components/common/tooltip";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { GenericPerformanceList } from "features/Reporting/Content/shared/GenericPerformanceList";
import { RouteNames } from "enums";

import "../../peopleStyles.scss";

export interface Props {
  dateFilter: PerformanceFilter;
  selectedAccountId: number | null;
}

export const EnterpriseTable = ({ dateFilter, selectedAccountId }: Props) => {
  const reportEnabled = !!useFeatureFlag(FeatureFlags.PeopleReports);

  const usersTableQuery = useQuery({
    queryFn: fetchPeopleOverviewUsersTable,
    queryKey: ["PeopleOverviewUsersTable", dateFilter, selectedAccountId, [], true],
    enabled: reportEnabled,
  });

  const columns: ColumnOption<PeopleOverviewUsersItem>[] = useMemo(
    () => [
      {
        name: "Name",
        width: 7,
        render(item) {
          return (
            <Tooltip
              target={
                <TextTruncate>
                  <Link to={`/${RouteNames.peopleUsers}/${item.UserId}`}>{item.UserFullName}</Link>
                </TextTruncate>
              }
              content={item.UserFullName}
            />
          );
        },
      },
      {
        name: "Department",
        width: 4,
        render(item) {
          return (
            <Tooltip
              target={<TextTruncate>{item.UserDepartment || "-"}</TextTruncate>}
              content={item.UserDepartment || "-"}
            />
          );
        },
      },
      {
        name: "Interactions",
        width: 4,
        render(item) {
          return <span>{item.InteractionCount.toLocaleString()}</span>;
        },
      },
    ],
    [],
  );

  return (
    <div className="tabSpacer">
      <RequestStatusRenderer state={usersTableQuery.status}>
        <GenericPerformanceList
          title="Most Active Users"
          rows={usersTableQuery.data!}
          columns={columns}
          filter={dateFilter}
        />
      </RequestStatusRenderer>
    </div>
  );
};
