import { Button } from "components/buttons/button/Button";
import { FormikProps, withFormik } from "formik";
import { isEmpty, partial, random, some } from "lodash";
import { Component } from "react";
import { batch, connect, ConnectedProps } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { bindActionCreators } from "redux";
import { CheckboxProps } from "semantic-ui-react";

import EditGroupPacksIssueLicenseModalContainer from "components/assignmentModals/peopleIssueLicenseModal/EditGroupIssueLicenseModalContainer/EditGroupPacksIssueLicenseModalContainer";
import {
  AutoenrollmentGroupNotificationsWarning,
  ContentAssignment,
  ContentAssignmentModal,
  DeleteLinkButton,
  DuplicateLinkButton,
  EditPriorityLinkButton,
  GenericSectionHeader,
  RemoveLinkButton,
  RestrictedAddButton,
  RestrictedByTooltip,
  RestrictedResource,
  TimestampLabel,
} from "../../../../components";
import { IContentAssignmentActions } from "../../../../components/contentAssignment/types";
import Segments from "../../../../components/navigation/segments/Segments";
import {
  DeleteGroupsConfirmationModal,
  RemoveMembersConfirmationModal,
  RemovePriorityConfirmationModal,
} from "../../../../components/people";
import { Enrollment } from "../../../../enums/groups";
import { bindAction, ContentPriorityLevel, UpdateParams } from "../../../../interfaces";
import { pluralize } from "../../../../utils/stringUtils";
import userListUtils, { contentToPayloadPropMap } from "../../../../utils/userListUtils";
import validationSchemas from "../../../../utils/validationSchemas";
import { AppDispatch, RootState } from "../../../Application/globaltypes/redux";
import dataService from "../../../Application/services/dataServices/typedDataService";
import usersDataService from "../../../Application/services/dataServices/usersDataService";
import backgroundTask from "../../../BackgroundTasks/backgroundTask";
import * as backgroundTasksActionsRedux from "../../../BackgroundTasks/state/backgroundTasksActions";
import { ContentType, PeopleType } from "../../../Library/PeopleAssignments/types";
import * as notificationsActionsRedux from "../../../Notifications/state/notificationsActions";
import * as contentAssignmentActionsRedux from "../../ContentAssignmentState/ContentAssignmentActions";
import rolesDataService from "../../services/rolesDataService";
import * as addToContentActionsRedux from "../../state/slices/addToContentSlice";
import { ConfirmationState, GroupInfoForm } from "../../types";
import * as usersFilterActionsRedux from "../../Users/UsersOverview/state/filterActionCreators";
import * as addGroupActionsRedux from "../AddGroup/state/addGroupActions";
import * as groupsOverviewActionsRedux from "../GroupsOverview/state/groupsOverviewActions";
import OwnersAssignmentModal from "./AddOwners/OwnersAssigmentModal/OwnersAssignmentModal";
import * as editGroupActionsRedux from "./editGroupActions";
import EditInfo from "./EditInfo/EditInfo";
import EditOwners from "./EditOwners/EditOwners";
import { removeUsersFromGroupTask } from "./EditPeople/backgroundTasks/removeUsersFromGroupTask";
import EditPeople from "./EditPeople/EditPeople";
import GroupPacksContainer from "./GroupPacks/GroupPacksContainer/GroupPacksContainer";

import RemovePriorityButton from "components/buttons/linkButtons/RemovePriorityButton/RemovePriorityButton";
import RemoveGroupLicenseConfirmationDialog from "components/packs/removeGroupLicenseConfirmationDialog/RemoveGroupLicenseConfirmationDialog";
import { FeatureFlags } from "featureFlags";
import { reset } from "features/Licensing/ContentAssignmentModalLicensingSteps/state/slices/licensingModalSlice";
import { LICENCE_CONTENT } from "features/People/constants";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { withRouter, WithRouterProps } from "../../../../adapters/withRouter/withRouter";
import ChangePeopleContentPriorityModal from "../../../../components/assignmentModals/changePriorityModal/ChangePeopleContentPriorityModal";
import EditNotifySettings from "../../../../components/notifyStep/EditNotifySettings/EditNotifySettings";
import {
  AddPeopleToContentTypes,
  AssignmentPeopleContext,
  RolePermissions,
  RouteNames,
  Strings,
  TemplateTypes,
} from "../../../../enums";
import groupListUtils from "../../../../utils/groupListUtils";
import navigationUtils from "../../../../utils/navigationUtils";
import Observable from "../../../../utils/Observable";
import Restricted from "../../../Application/Restricted";
import { GroupFlowPriorityChangedSuccess } from "../../../Application/services/realTimeNotification/events/library/libraryEvents";
import * as peopleEventTypes from "../../../Application/services/realTimeNotification/events/people/peopleEvents";
import RtnEventsEmitter from "../../../Application/services/realTimeNotification/rtnEventsEmitter";
import { LDProps } from "../../../LDProps";
import {
  editPeopleContentPriority,
  removeContentPrioritiesDistributed,
} from "../../../Library/PeopleAssignments/state/actions/commonActions";
import {
  getAssignmentByContentId,
  getUpdatedPriorityItems,
  mapUserAssignmentsToPriorityItems,
  selectedStateInitial,
} from "../../../Library/PeopleAssignments/utils/peopleAssignmentUtils";
import { videosStateSelector } from "../../../Library/Videos/state/selectors";
import { accontPacksSearchSelector } from "../../../Licensing/Packs/state/slices/accountPacksSearchSlice";
import {
  issuePackLicenseToPeople,
  removePackLicenseFromPeople,
} from "../../../Licensing/Packs/state/thunks/packPurchasedViewThunk";
import { defaultEnrollmentRule } from "../AddEditGroupForms/EnrollmentBuilder/utils";
import groupDomainUtils from "../AddEditGroupForms/groupDomainUtils";
import { resetEnrollmentUsers } from "../state/slices/enrollmentUsersSlice";
import { fetchGroupEnrollmentUsers } from "../state/thunks/enrollmentUsersThunk";
import "./editGroup.scss";
import { issueLicensePermissionPredicate } from "./issueLicensePermissionPredicate";

export type EditGroupProps = FormikProps<GroupInfoForm> & EditGroupPropsOwn & PropsFromRedux & LDProps;

interface EditGroupPropsOwn extends WithRouterProps {
  editGroupActions: {
    fetchGroupInfo: (groupId: number) => void;
    resetGroupInfo: () => void;
    updateGroupInfo: () => void;
  };
  addGroupActions: {
    fetchOwners: () => void;
  };
  addOwnersList: any;
  backgroundTasksActions: {
    addOperationV1: () => void;
    addOperationV2: () => void;
  };
  notificationsActions: {
    sendTransientNotification: () => void;
  };
  showAddToContentModal: boolean;
}

interface EditGroupState {
  showAddUsersModal: boolean;
  showAddOwnersModal: boolean;
  showPeopleIssueLicenseModal: boolean;
  showDeleteConfirmation: boolean;
  isStepLoading: boolean;
  isLicenseConfirmed: boolean;
  showRemoveGroupMembersConfirmation: boolean;
  selectedEnrolledMembersIds: number[];
  clickedEnrolledMemberIdToRemove: number | null;
  groupContent: Record<ContentType, { ids: number[]; undeletableIds: number[] }>;
  confirmation: ConfirmationState;
  contentType: AddPeopleToContentTypes;
  selectedPackIds: number[];
  clickedGroupPackIdToRemove: number;
  removeLicenseModal: {
    show: boolean;
    packsCount: number;
  };
}

const contentListViewRtnEvents = [GroupFlowPriorityChangedSuccess];

export class EditGroup extends Component<LDProps & EditGroupProps, EditGroupState> {
  private readonly rulesEngineOnCosmos: boolean;
  private readonly flexiblePriorityDueDate: boolean;
  constructor(props: EditGroupProps) {
    super(props);

    this.state = {
      showAddUsersModal: false,
      showAddOwnersModal: false,
      showPeopleIssueLicenseModal: false,
      showDeleteConfirmation: false,
      isStepLoading: false,
      isLicenseConfirmed: true,
      showRemoveGroupMembersConfirmation: false,
      selectedEnrolledMembersIds: [],
      clickedEnrolledMemberIdToRemove: null,
      groupContent: { ...selectedStateInitial },
      confirmation: {
        blocks: [],
        priorityItems: [],
        show: false,
      },
      contentType: AddPeopleToContentTypes.Flows,
      selectedPackIds: [],
      clickedGroupPackIdToRemove: 0,
      removeLicenseModal: {
        show: false,
        packsCount: 0,
      },
    };

    this.rulesEngineOnCosmos = !!this.props.flags?.[FeatureFlags.RuleEngineOnCosmos];
    this.flexiblePriorityDueDate = !!this.props.flags?.[FeatureFlags.FlexiblePriorityDueDate];
  }

  segmentPaths = {
    members: "members",
    users: "users",
    owners: "owners",
    content: "content",
    licenses: "licenses",
    notify: "notify",
    enrollment: "enrollment",
  };

  infoStepIndex = 1;
  membersStepIndex = 2;
  usersStepIndex = 3;
  ownersStepIndex = 4;
  contentStepIndex = 5;
  licensesStepIndex = 6;

  groupId = parseInt(this.props.params.id);

  notificationId = groupDomainUtils.getAutoEnrolledGroupNotificationSettingsId(this.groupId);

  onChangePriorityObserver = new Observable();
  onClearPriorityObserver = new Observable();

  componentDidMount() {
    this.props.editGroupActions.fetchGroupInfo(this.groupId);
    RtnEventsEmitter.subscribe(contentListViewRtnEvents, this.resetGroupContent);
    RtnEventsEmitter.subscribe(peopleEventTypes.GroupDuplicated, this.onDuplicationGroup);
  }

  componentWillUnmount() {
    this.props.editGroupActions.resetGroupInfo();
    RtnEventsEmitter.unsubscribe(contentListViewRtnEvents, this.resetGroupContent);
    RtnEventsEmitter.unsubscribe(peopleEventTypes.GroupDuplicated, this.onDuplicationGroup);
  }

  hasGroupEditPermissions = () => this.props.userPermissions.includes(RolePermissions.GroupsManage);

  onCloseAddOwnersModal = () => {
    this.setState({ showAddOwnersModal: false });
  };

  getGroupRoleId = async () => {
    const tag = `group:${this.groupId}`;
    const role = await rolesDataService.getRoles({ tags: tag });
    return role.items.length === 1 ? role.items[0].id : null;
  };

  onAddOwnersConfirm = async (ownersToAddIds: number[]) => {
    const roleId = await this.getGroupRoleId();
    if (roleId) {
      const count = ownersToAddIds.length;
      const params: UpdateParams = {
        id: `AddOwnersToGroup`,
        title: "Add owners to group",
        getMessageIds: async () => {
          const response = await usersDataService.assignUsersToRoles(ownersToAddIds, [roleId]);
          return [response.data];
        },
        successTransientMessage: (
          <>
            <strong>Owners added!</strong>&nbsp;({count}) {pluralize("Group Owner", count)}{" "}
            {count === 1 ? "has" : "have"} been added to this group successfully.
          </>
        ),
        failureTransientMessage: "Adding owners to group failed!",
      };

      const {
        backgroundTasksActions: { addOperationV1 },
        notificationsActions: { sendTransientNotification },
      } = this.props;

      backgroundTask.updateEntity(params, {
        addOperation: addOperationV1,
        sendTransientNotification,
      });
    }
    this.onCloseAddOwnersModal();
  };

  renderOwnersAddModal = () => {
    const { isStepLoading, showAddOwnersModal } = this.state;
    const { fetchOwners } = this.props.addGroupActions;
    const {
      addOwnersList: { items, itemsCount, isLoading },
    } = this.props;

    return (
      showAddOwnersModal && (
        <OwnersAssignmentModal
          groupId={this.groupId}
          loadPage={fetchOwners}
          ownersAmount={itemsCount}
          isListLoading={isLoading}
          isStepLoading={isStepLoading}
          owners={items}
          onConfirm={this.onAddOwnersConfirm}
          showModal={showAddOwnersModal}
          onCancel={this.onCloseAddOwnersModal}
        />
      )
    );
  };

  renderAddToContentModal = () =>
    this.props.showAddToContentModal && <ContentAssignmentModal peopleType={PeopleType.Group} />;

  goToGroupsOverview = () => {
    this.props.navigate(`/${RouteNames.peopleGroups}`);
  };

  onSelectedEnrolledMembersChanged = (ids: number[]) => {
    this.setState({ selectedEnrolledMembersIds: ids });
  };

  onSelectedPacksChanged = (ids: number[]) => {
    this.setState({ selectedPackIds: ids });
  };

  goToEditUser = (userId: number) => {
    this.props.navigate(`/${RouteNames.peopleUsers}/${userId}`);
  };

  getCurrentStepIndex = () => {
    const {
      location: { pathname },
    } = this.props;
    let stepIndex;
    switch (pathname.split("/").at(-1)) {
      case this.segmentPaths.members:
        stepIndex = this.membersStepIndex;
        break;
      case this.segmentPaths.enrollment:
        stepIndex = this.membersStepIndex;
        break;
      case this.segmentPaths.users:
        stepIndex = this.usersStepIndex;
        break;
      case this.segmentPaths.owners:
        stepIndex = this.ownersStepIndex;
        break;
      case this.segmentPaths.content:
        stepIndex = this.contentStepIndex;
        break;
      case this.segmentPaths.licenses:
        stepIndex = this.licensesStepIndex;
        break;
      default:
        stepIndex = this.infoStepIndex;
    }

    return stepIndex;
  };

  addMembersBtn = (hasPermission: boolean = true) => {
    return (
      <RestrictedAddButton
        buttonText="Add People"
        onClick={() => {
          this.setState({ showAddUsersModal: true });
        }}
        hasPermission={hasPermission}
        className="add-member"
      />
    );
  };

  removeMembersBtn = (
    <RemoveLinkButton
      className="remove-member-button"
      onClick={() => {
        this.setState({ showRemoveGroupMembersConfirmation: true });
      }}
    />
  );

  saveGroupInfo = () => {
    const {
      editGroupActions: { updateGroupInfo },
      values: { ownerIds, settings, imageUrl, name, description },
    } = this.props;

    const newGroupInfo = {
      imageUrl,
      name,
      description,
    };

    const newSettings = { ...settings };

    updateGroupInfo(newGroupInfo, newSettings);

    const updateRequestData = {
      ownerIds,
      imageUrl,
      name,
      description,
      ...newSettings,
    };

    const params = {
      id: `UpdateGroupInformation`,
      title: "Update group information",
      indeterminate: true,
      getMessageIds: async () => {
        const {
          data: { messageId },
        } = await dataService.groups.updateGroupInfo(this.groupId, updateRequestData);
        return [messageId];
      },
      successTransientMessage: "Group information update succeeded!",
      failureTransientMessage: "Group information update failed!",
    };
    const {
      backgroundTasksActions: { addOperationV1 },
      notificationsActions: { sendTransientNotification },
    } = this.props;

    backgroundTask.updateEntity(params, {
      addOperation: addOperationV1,
      sendTransientNotification,
    });
  };

  onCloseAddUsersModal = () => {
    this.props.editGroupActions.resetAddMembersSearch();
    this.props.resetLicensingModal();
    this.setState({ showAddUsersModal: false });
  };

  onDuplicateGroup = async (groupId: number) => {
    const params = {
      id: "DuplicateGroup",
      title: `Duplicate group`,
      getMessageIds: async () => {
        const {
          data: { messageIds },
        } = await dataService.groups.duplicateGroups([groupId]);
        return messageIds;
      },
      successTransientMessage: "Group has been duplicated!",
      failureTransientMessage: "Group duplicate failed!",
    };
    const {
      backgroundTasksActions: { addOperationV1 },
      notificationsActions: { sendTransientNotification },
    } = this.props;

    await backgroundTask.updateEntity(params, {
      addOperation: addOperationV1,
      sendTransientNotification,
    });
  };

  onDuplicationGroup = (payload: any) => {
    const duplicateId = payload.GroupId.toString();
    const currentTab = this.props.location.pathname.split("/").at(-1);
    this.props.editGroupActions.fetchGroupInfo(duplicateId);
    this.props.navigate(`/${RouteNames.peopleGroups}/${duplicateId}/${currentTab}`);
  };

  onDeleteGroup = async (groupId: number) => {
    this.goToGroupsOverview();

    const params = {
      id: "DeleteGroup",
      title: "Group deletion",
      getMessageIdsV2: () => [`GroupDeleted_${groupId}`],
      callApi: () => dataService.groups.deleteGroups([groupId]),
      successTransientMessage: "Group deleted successfully!",
      failureTransientMessage: "Group delete failed!",
    };

    const {
      backgroundTasksActions: { addOperationV2 },
      notificationsActions: { sendTransientNotification },
    } = this.props;

    await backgroundTask.updateEntityV2(params, {
      addOperation: addOperationV2,
      sendTransientNotification,
    });
  };

  onRemoveEnrolledGroupMembers = () => {
    const {
      backgroundTasksActions: { addOperationV2 },
      notificationsActions: { sendTransientNotification },
    } = this.props;

    const { clickedEnrolledMemberIdToRemove, selectedEnrolledMembersIds } = this.state;
    const enrolmentMembersToRemoveIds = clickedEnrolledMemberIdToRemove
      ? [clickedEnrolledMemberIdToRemove]
      : selectedEnrolledMembersIds;

    removeUsersFromGroupTask(enrolmentMembersToRemoveIds, this.groupId, (params) =>
      backgroundTask.updateEntityV2(params, {
        addOperation: addOperationV2,
        sendTransientNotification,
      }),
    );

    this.setState({
      selectedEnrolledMembersIds: [],
      showRemoveGroupMembersConfirmation: false,
      clickedEnrolledMemberIdToRemove: null,
    });
  };

  getMembersStepButtons = () => {
    const {
      groupInfo: { isWithAutoEnroll },
    } = this.props;
    const { selectedEnrolledMembersIds } = this.state;
    let buttons: React.ReactElement[] = [];
    if (isWithAutoEnroll !== undefined && isWithAutoEnroll === Enrollment.Manual) {
      if (selectedEnrolledMembersIds.length > 0) {
        buttons = [this.removeMembersBtn];
      } else {
        buttons = [this.addMembersBtn(this.hasGroupEditPermissions())];
      }
    }
    return buttons;
  };

  getContentStepButtons = () => {
    if (this.props.groupInfo.readOnly) {
      return;
    }

    if (!this.hasSelectedContent()) {
      return [this.addContentBtn()];
    }

    const flows = this.state.groupContent[ContentType.Flow];
    const unremovableFlowSelected = flows.undeletableIds.length > 0;

    const hasEditPermission =
      groupListUtils.getCanEditContent(this.state.groupContent, this.props.userPermissions) &&
      this.hasGroupEditPermissions();
    return [
      <RestrictedByTooltip hasPermission={hasEditPermission} key="edit-priority-button">
        <EditPriorityLinkButton
          className="edit-priority-button"
          onClick={this.onBulkEditPriorityClick}
          isDisabled={!hasEditPermission}
        />
      </RestrictedByTooltip>,
      <RemovePriorityButton
        key="remove-priority-button"
        onClick={() => this.onClearPriorityClick(flows.ids)}
        isDisabled={unremovableFlowSelected || !this.hasGroupEditPermissions()}
        context="people"
        entityType={ContentType.Flow}
        peopleType={PeopleType.Group}
        showTooltip={unremovableFlowSelected}
        multiple
      />,
    ];
  };

  hideContentCreate = () => !LICENCE_CONTENT.includes(this.state.contentType);

  addContentBtn = () =>
    this.hideContentCreate() ? undefined : (
      <RestrictedAddButton
        className="add-content create-button"
        onClick={() => {
          this.props.onAddContent(this.groupId, this.state.contentType);
        }}
        hasPermission={this.hasGroupEditPermissions()}
        buttonText={Strings.modalTitles.addContent}
      />
    );

  addOwnersBtn = (
    <RestrictedAddButton
      className="add-owners create-button"
      onClick={() => {
        this.onOpenAddOwnersModal();
      }}
      hasPermission={this.hasGroupEditPermissions()}
      buttonText="Add Owners"
    />
  );

  issueLicensesToGroup = (selectedPackIds: number[]) => {
    if (some(selectedPackIds)) {
      const groupAssignments = selectedPackIds.map((packId) =>
        userListUtils.mapToAssignmentItem(this.groupId, { id: packId }, "pack", AssignmentPeopleContext.Group),
      );

      this.props.issuePackLicense({
        peopleType: AssignmentPeopleContext.Group,
        items: groupAssignments,
      });
    }

    this.hideIssueLicenseModal();

    return Promise.resolve();
  };

  setShowPeopleIssueLicenseModal(show: boolean) {
    this.setState({
      showPeopleIssueLicenseModal: show,
    });
  }

  onOpenIssueLicenseModal = () => {
    this.setShowPeopleIssueLicenseModal(true);
  };

  hideIssueLicenseModal = () => {
    this.setShowPeopleIssueLicenseModal(false);
  };

  issueLicenseHeaderBtn = (
    <Restricted
      permissions={[]}
      permissionPredicate={issueLicensePermissionPredicate}
      renderContent={(hasAnyPermission) => (
        <RestrictedByTooltip hasPermission={hasAnyPermission}>
          <Button
            primary
            className="create-button"
            content="Issue License"
            onClick={this.onOpenIssueLicenseModal}
            disabled={!hasAnyPermission}
          />
        </RestrictedByTooltip>
      )}
    />
  );

  renderPeopleIssueLicenseModal = () => {
    const { accountId } = this.props;

    return (
      this.state.showPeopleIssueLicenseModal && (
        <EditGroupPacksIssueLicenseModalContainer
          showModal={this.state.showPeopleIssueLicenseModal}
          onCancel={this.hideIssueLicenseModal}
          accountId={accountId}
          onConfirm={this.issueLicensesToGroup}
          peopleId={this.groupId}
          assignmentPeopleContext={AssignmentPeopleContext.Group}
        />
      )
    );
  };

  onRemoveLicenseClick = async (packId: number = 0) => {
    const { selectedPackIds } = this.state;
    const packsCount = packId > 0 ? 1 : selectedPackIds.length;

    this.setState({
      removeLicenseModal: {
        show: true,
        packsCount: packsCount,
      },
      clickedGroupPackIdToRemove: packId,
    });
  };

  packsToRemove = () => {
    const { selectedPackIds, clickedGroupPackIdToRemove } = this.state;
    return clickedGroupPackIdToRemove > 0 ? [clickedGroupPackIdToRemove] : selectedPackIds;
  };

  onRemoveGroupLicense = () => {
    const packsToRemove = this.packsToRemove();

    const licenses = packsToRemove.map((packId) =>
      userListUtils.mapToAssignmentItem(this.groupId, { id: packId }, "pack", AssignmentPeopleContext.Group),
    );

    this.props.removePackLicense({
      peopleType: AssignmentPeopleContext.Group,
      items: licenses,
    });

    this.closeRemoveLicenseConfirmation();
    this.setState({ selectedPackIds: [] });
  };

  closeRemoveLicenseConfirmation = () => {
    this.setState({
      removeLicenseModal: {
        show: false,
        packsCount: 0,
      },
      clickedGroupPackIdToRemove: 0,
    });
  };

  removePacksBtn = (
    <Restricted
      permissions={[]}
      permissionPredicate={issueLicensePermissionPredicate}
      renderContent={(hasAnyPermission) => (
        <RestrictedByTooltip hasPermission={hasAnyPermission}>
          <RemoveLinkButton
            className="remove-pack-button"
            onClick={this.onRemoveLicenseClick}
            isDisabled={!hasAnyPermission}
          />
        </RestrictedByTooltip>
      )}
    />
  );

  onOpenAddOwnersModal = () => {
    this.setState({ showAddOwnersModal: true });
  };

  createButton = (contentType: AddPeopleToContentTypes) => {
    const { groupInfo } = this.props;
    return this.hideContentCreate() ? undefined : (
      <RestrictedByTooltip hasPermission={groupInfo.canEdit}>
        <Button
          className="add-content create-button"
          primary
          onClick={() => {
            this.props.onAddContent(this.groupId, contentType);
          }}
          disabled={groupInfo.readOnly || !groupInfo.canEdit}
        >
          {`Add ${contentType}`}
        </Button>
      </RestrictedByTooltip>
    );
  };

  hasSelectedContent = () => {
    const { groupContent } = this.state;
    return (
      Object.values(groupContent)
        .map((item) => item.ids)
        .flat().length > 0
    );
  };

  isContentTabOpened = () => this.getCurrentStepIndex() === this.contentStepIndex;
  isInfoTabOpened = () => this.getCurrentStepIndex() === this.infoStepIndex;

  renderHeaderButtons = () => {
    const { selectedPackIds } = this.state;
    switch (this.getCurrentStepIndex()) {
      case this.membersStepIndex:
        return this.getMembersStepButtons();
      case this.contentStepIndex:
        return this.getContentStepButtons();
      case this.ownersStepIndex:
        return [this.addOwnersBtn];
      case this.licensesStepIndex:
        if (selectedPackIds.length) {
          return [this.removePacksBtn];
        }
        return [this.issueLicenseHeaderBtn];
      default:
        return [];
    }
  };

  renderDropdownButtons = () => {
    const group = this.props.groupInfo;
    const { selectedEnrolledMembersIds, selectedPackIds } = this.state;
    if (
      group.readOnly ||
      selectedEnrolledMembersIds.length ||
      (this.hasSelectedContent() && this.isContentTabOpened()) ||
      selectedPackIds.length
    ) {
      return null;
    }

    const buttons = [
      <DuplicateLinkButton
        key="duplicate-group"
        onClick={() => {
          this.onDuplicateGroup(group.id);
        }}
        isDisabled={!this.hasGroupEditPermissions()}
      />,
    ];

    if (group.canEdit) {
      buttons.push(
        <DeleteLinkButton key="delete-group" onClick={() => this.setState({ showDeleteConfirmation: true })} />,
      );
    }

    return buttons;
  };

  renderConfirmationModals = () => {
    const { groupInfo } = this.props;
    const { selectedEnrolledMembersIds, showDeleteConfirmation, clickedEnrolledMemberIdToRemove, removeLicenseModal } =
      this.state;

    return (
      <>
        <DeleteGroupsConfirmationModal
          open={showDeleteConfirmation}
          onCancel={() => {
            this.setState({ showDeleteConfirmation: false });
          }}
          onContinue={() => {
            this.onDeleteGroup(groupInfo.id);
          }}
        />
        <RemoveMembersConfirmationModal
          open={this.state.showRemoveGroupMembersConfirmation}
          usersCount={clickedEnrolledMemberIdToRemove ? 1 : selectedEnrolledMembersIds.length}
          onCancel={() => {
            this.setState({
              showRemoveGroupMembersConfirmation: false,
              clickedEnrolledMemberIdToRemove: null,
            });
          }}
          onContinue={this.onRemoveEnrolledGroupMembers}
          featureName="group"
        />
        <RemovePriorityConfirmationModal peopleType={PeopleType.Group} removeObserver={this.onClearPriorityObserver} />
        <RemoveGroupLicenseConfirmationDialog
          groupsCount={1}
          packsCount={removeLicenseModal.packsCount}
          triggerOpen={removeLicenseModal.show}
          onCancel={this.closeRemoveLicenseConfirmation}
          onContinue={this.onRemoveGroupLicense}
        />
      </>
    );
  };

  onIsAwareChanged = (_: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    const isAware = data.checked;
    const {
      values: { settings },
    } = this.props;
    this.props.setFieldValue("settings", {
      ...settings,
      isAware,
      isVisibleToAll: isAware ? isAware : null,
      isOpen: isAware ? isAware : null,
    });
  };

  onVisibleToAllChanged = (_: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    const isVisibleToAll = data.checked;
    const {
      values: { settings },
    } = this.props;
    this.props.setFieldValue("settings", {
      ...settings,
      isVisibleToAll,
    });
  };

  onIsOpenChanged = (_: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    const isOpen = !data.checked;
    const {
      values: { settings },
    } = this.props;
    this.props.setFieldValue("settings", { ...settings, isOpen });
  };

  getFilterOptions = () => {
    const { usersFilterActions } = this.props;
    usersFilterActions.getFilterOptions();
  };

  onSelectedContentChanged = (prop: ContentType) => (items: any[]) => {
    const selectedItems = items.reduce<{ ids: number[]; undeletableIds: number[] }>(
      (acc, value) => {
        acc.ids.push(value.id);
        !value.canRemove && acc.undeletableIds.push(value.id);
        return acc;
      },
      { ids: [], undeletableIds: [] },
    );
    const newState = { ...this.state };
    newState.groupContent[prop] = selectedItems;
    newState.isLicenseConfirmed = true;
    newState.confirmation.show = false;
    this.setState(newState, this.createConfirmationBlocks);
  };

  onSelectedContentTypeChange = (type: AddPeopleToContentTypes) => {
    this.onSelectedContentChanged(ContentType.Flow)([]);
    this.setState({ contentType: type });
  };

  createConfirmationBlocks = () => {
    const { groupContent } = this.state;
    const contentIds = Array.from(
      new Set(
        Object.values(groupContent)
          .map((item) => item.ids)
          .flat(),
      ),
    );
    let newConfirmation = { ...this.state.confirmation };
    newConfirmation.blocks = this.getConfirmationBlocks(contentIds);

    this.setState({ confirmation: newConfirmation });
  };

  /* istanbul ignore next */
  getConfirmationBlocks = (contentIds: number[]) => {
    return contentIds.map((id, i) => {
      const licensesAmount = random(4000, 7000);
      const usedLicensesAmount = random(355, 530);
      const issuing = random(3, 100);
      const collectionNum = i + 1;

      return {
        id,
        name: `Collection Name - ${collectionNum}`,
        licensesAmount,
        usedLicensesAmount,
        issuing,
      };
    });
  };

  onLicenseConfirmationClosed = () => {
    const {
      confirmation: { proceed },
    } = this.state;

    this.setState(
      (state) => ({
        confirmation: {
          ...state.confirmation,
          show: false,
          proceed: undefined,
        },
      }),
      () => proceed?.(false),
    );
  };

  onLicenseConfirmed = () => {
    const {
      confirmation: { proceed },
    } = this.state;

    this.setState(
      (state) => ({
        isLicenseConfirmed: true,
        confirmation: {
          ...state.confirmation,
          show: false,
          proceed: undefined,
        },
      }),
      () => proceed?.(true),
    );
  };

  renderConfigureTab = () => {
    const { isGroupInfoLoading } = this.props;

    return (
      <EditInfo
        {...this.props}
        isInfoLoading={isGroupInfoLoading}
        onIsAwareChanged={this.onIsAwareChanged}
        saveGroupInfo={this.saveGroupInfo}
        onVisibleToAllChanged={this.onVisibleToAllChanged}
        onIsOpenChanged={this.onIsOpenChanged}
        readonly={!this.hasGroupEditPermissions()}
      />
    );
  };

  resetGroupContent = () => {
    this.setState({ groupContent: { ...selectedStateInitial } });
  };

  renderChangePriorityModal = () => (
    <ChangePeopleContentPriorityModal
      onSave={(assignments: { [key in PeopleType]?: ContentPriorityLevel[] }) => {
        getUpdatedPriorityItems(assignments, (items: ContentPriorityLevel[]) => {
          this.props.editPriority(items, this.groupId, this.flexiblePriorityDueDate);
        });
        this.setState((state) => ({
          groupContent: userListUtils.mapToContentTypeRecord(state.groupContent),
        }));
      }}
      onTriggerModalObserver={this.onChangePriorityObserver}
    />
  );

  onEditPriorityClick = (contentType: ContentType) => (contentId: number) => {
    const { groupContentAssignment } = this.props;
    // pass view type when we'll get actual state from items view (is not reliable now)
    // for now using this questionable implementation
    const assignments = userListUtils.getContentItems(contentType, groupContentAssignment);
    const selectedAssignments = getAssignmentByContentId(assignments, [contentId]);
    const priorityItems = mapUserAssignmentsToPriorityItems(
      selectedAssignments,
      contentType,
      this.flexiblePriorityDueDate,
    );
    this.onChangePriorityObserver.notify(() => {}, { [PeopleType.Group]: priorityItems });
  };

  /* istanbul ignore next */
  onClearPriorityClick = (ids: number[]) => {
    this.onClearPriorityObserver.notify(() => {
      const flowItems = { [ContentType.Flow]: ids };
      this.props.removeContentPriorities(this.groupId, AssignmentPeopleContext.Group, flowItems);

      this.setState((state) => ({
        groupContent: userListUtils.mapToContentTypeRecord(state.groupContent),
      }));
    });
  };

  onBulkEditPriorityClick = () => {
    const { groupContentAssignment } = this.props;
    const { groupContent } = this.state;

    // Workaround until we store selected items instead of just id-s
    const contentType = contentToPayloadPropMap[this.state.contentType];
    const selectedIds = groupContent[contentType].ids;
    const assignments = userListUtils.getContentItems(contentType, groupContentAssignment);
    const selectedAssignments = getAssignmentByContentId(assignments, selectedIds);
    const priorityItems = mapUserAssignmentsToPriorityItems(
      selectedAssignments,
      contentType,
      this.flexiblePriorityDueDate,
    );

    this.onChangePriorityObserver.notify(() => {}, { [PeopleType.Group]: priorityItems });
  };

  renderContentTab = () => {
    const { groupContent, confirmation } = this.state;
    const { contentAssignmentActions, groupInfo, userPermissions } = this.props;
    const contentActions: IContentAssignmentActions = {
      fetchSurveysList: partial(contentAssignmentActions.fetchGroupSurveysList, this.groupId),
      resetSurveysList: contentAssignmentActions.resetAssignmentSurveysList,
      // @ts-ignore
      fetchAssessmentsList: partial(contentAssignmentActions.fetchGroupAssessmentsList, this.groupId),
      resetAssessmentsList: contentAssignmentActions.resetAssignmentAssessmentsList,
      // @ts-ignore
      fetchFlowsList: partial(contentAssignmentActions.fetchGroupFlowsList, this.groupId),
      // @ts-ignore
      fetchFlowsGrid: partial(contentAssignmentActions.fetchGroupFlowsGridLazy, this.groupId),
      resetFlowsGrid: contentAssignmentActions.resetAssignmentFlowsGrid,
      resetFlowsList: contentAssignmentActions.resetAssignmentFlowsList,
      // @ts-ignore
      fetchVideosList: partial(contentAssignmentActions.fetchGroupAssetsList, this.groupId),
      // @ts-ignore
      fetchVideosGrid: partial(contentAssignmentActions.fetchGroupAssetsGridLazy, this.groupId),
      resetVideoGrid: contentAssignmentActions.resetAssignmentAssetsGrid,
      resetVideoList: contentAssignmentActions.resetAssignmentAssetsList,
      // @ts-ignore
      fetchEventsList: partial(contentAssignmentActions.fetchGroupEventsList, this.groupId),
      // @ts-ignore
      fetchEventsGrid: partial(contentAssignmentActions.fetchGroupEventsGridLazy, this.groupId),
      resetEventsGrid: contentAssignmentActions.resetAssignmentEventsGrid,
      resetEventsList: contentAssignmentActions.resetAssignmentEventsList,
      // @ts-ignore
      fetchPdfsList: partial(contentAssignmentActions.fetchGroupPdfsList, this.groupId),
      // @ts-ignore
      fetchPdfsGrid: partial(contentAssignmentActions.fetchGroupPdfsGridLazy, this.groupId),
      resetPdfsGrid: contentAssignmentActions.resetAssignmentPdfsGrid,
      resetPdfsList: contentAssignmentActions.resetAssignmentPdfsList,
    };
    return (
      <div className="edit-group-content-step">
        <ContentAssignment
          flowButton={this.createButton(AddPeopleToContentTypes.Flows)}
          videoButton={this.createButton(AddPeopleToContentTypes.Videos)}
          eventButton={this.createButton(AddPeopleToContentTypes.Events)}
          surveyButton={this.createButton(AddPeopleToContentTypes.Surveys)}
          assessmentButton={this.createButton(AddPeopleToContentTypes.Assessments)}
          pdfButton={this.createButton(AddPeopleToContentTypes.PDFs)}
          flowContextMenuButtonHandlers={{
            onEditPriority: this.onEditPriorityClick(ContentType.Flow),
            onRemove: this.onClearPriorityClick,
          }}
          deepLink
          contentAssignmentActions={contentActions}
          selectedVideos={groupContent[ContentType.Video].ids}
          onSelectedVideosChanged={this.onSelectedContentChanged(ContentType.Video)}
          selectedFlows={groupContent[ContentType.Flow].ids}
          onSelectedFlowsChanged={this.onSelectedContentChanged(ContentType.Flow)}
          selectedSurveys={groupContent[ContentType.Survey].ids}
          onSelectedSurveysChanged={this.onSelectedContentChanged(ContentType.Survey)}
          selectedAssessments={groupContent[ContentType.Assessment].ids}
          onSelectedAssessmentsChanged={this.onSelectedContentChanged(ContentType.Assessment)}
          selectedPdfs={groupContent[ContentType.Pdf].ids}
          onSelectedPdfsChanged={this.onSelectedContentChanged(ContentType.Pdf)}
          selectedEvents={groupContent[ContentType.Event].ids}
          onSelectedEventsChanged={this.onSelectedContentChanged(ContentType.Event)}
          onLicenseConfirmed={this.onLicenseConfirmed}
          onLicenseConfirmationClosed={this.onLicenseConfirmationClosed}
          confirmation={confirmation}
          isReadOnly={groupInfo.readOnly || !groupInfo.canEdit || !this.hasGroupEditPermissions()}
          peopleType={PeopleType.Group}
          setSelectedContentType={this.onSelectedContentTypeChange}
          userPermissions={userPermissions}
        />
      </div>
    );
  };

  renderLicensingTab = () => {
    const { groupInfo } = this.props;
    const { selectedPackIds } = this.state;
    return (
      <>
        <GroupPacksContainer
          issueLicenseBtn={this.issueLicenseHeaderBtn}
          groupId={this.groupId}
          accountId={this.props.accountId}
          sendTransientNotification={this.props.notificationsActions.sendTransientNotification}
          onRemoveLicenseClick={this.onRemoveLicenseClick}
          isReadOnly={!groupInfo.canEdit}
          selectedPackIds={selectedPackIds}
          onSelectedPacksChanged={this.onSelectedPacksChanged}
        />
        {this.renderPeopleIssueLicenseModal()}
      </>
    );
  };

  renderPeopleTab = (isUsersTab?: boolean) => {
    const {
      groupInfo,
      groupSettings,
      editPeople,
      isGroupInfoLoading,
      membersSearch,
      addMembersSearch,
      fetchGroupUsersEnrollment,
      resetEnrollmentUsers,
      enrollmentUsers,
    } = this.props;
    const { showAddUsersModal, selectedEnrolledMembersIds } = this.state;
    return (
      <EditPeople
        groupId={this.groupId}
        editGroupActions={this.props.editGroupActions}
        editPeople={editPeople}
        addMembersBtn={this.addMembersBtn(this.hasGroupEditPermissions())}
        showAddPeopleModal={showAddUsersModal}
        onCloseAddUsersModal={this.onCloseAddUsersModal}
        enrollmentType={groupInfo.isWithAutoEnroll}
        isGroupInfoLoading={isGroupInfoLoading}
        enrollmentRules={isEmpty(groupInfo.enrollmentRules) ? [defaultEnrollmentRule] : groupInfo.enrollmentRules}
        selectedUsers={selectedEnrolledMembersIds}
        onSelectedUsersChanged={this.onSelectedEnrolledMembersChanged}
        getFilterOptions={this.getFilterOptions}
        goToEditUser={this.goToEditUser}
        isReadOnly={groupInfo.readOnly || !groupInfo.canEdit || !this.hasGroupEditPermissions()}
        onRemoveMemberClick={(id) => {
          this.setState({
            showRemoveGroupMembersConfirmation: true,
            clickedEnrolledMemberIdToRemove: id,
          });
        }}
        search={membersSearch}
        addUsersSearch={addMembersSearch}
        disableNotifications={!groupSettings.isAware}
        deepLink
        rulesEngineOnCosmos={this.rulesEngineOnCosmos}
        fetchGroupEnrollmentUsers={fetchGroupUsersEnrollment}
        resetEnrollmentUsers={resetEnrollmentUsers}
        groupEnrollmentUsers={enrollmentUsers}
        hidePeople={groupInfo.readOnly}
        showUsersList={isUsersTab || groupInfo.isWithAutoEnroll !== Enrollment.Automatic}
      />
    );
  };

  renderOwnersTab = () => {
    const { editGroupActions, ownersList, editPeople, userPermissions } = this.props;
    return (
      <EditOwners
        groupId={this.groupId}
        loadOwners={editGroupActions.fetchOwners}
        ownersOptions={ownersList}
        filterOptions={editPeople.filterOptions}
        getFilterOptions={this.getFilterOptions}
        disableSelect={() => groupDomainUtils.hasReadOnlyOwnersAccess(userPermissions)}
      />
    );
  };

  renderNotifyTab = () => (
    <EditNotifySettings
      templateType={TemplateTypes.AddedToGroup}
      notificationId={this.notificationId}
      disabled={!this.props.groupSettings.isAware!}
      infoBannerContent={<AutoenrollmentGroupNotificationsWarning />}
      saveComplete={this.saveGroupInfo}
    />
  );

  render() {
    const { groupInfo, isAccessAuthorized, params, location, navigate } = this.props;
    const readOnly = groupInfo.readOnly;

    return (
      <RestrictedResource isAuthorized={isAccessAuthorized}>
        <section className="edit-group nested-content">
          <GenericSectionHeader
            title={groupInfo.name || ""}
            titleForGA="Group Details"
            goBackAction={() => navigationUtils.goBackOrDefault(location, navigate, `/${RouteNames.peopleGroups}`)}
            dropdownButtons={this.renderDropdownButtons()}
            buttons={this.renderHeaderButtons()}
          />
          <div className="last-modified-label-container">
            {this.isInfoTabOpened() && (
              <TimestampLabel label="Last modified" dateTime={groupInfo.dateModified} format="MM/DD/YYYY h:mm A" />
            )}
          </div>
          {this.renderConfirmationModals()}
          {this.renderAddToContentModal()}
          {this.renderOwnersAddModal()}
          {this.renderChangePriorityModal()}
          <Segments to={`/${RouteNames.peopleGroups}/${params.id}`}>
            {!readOnly && <Segments.Segment label="Configure" />}
            <Segments.Segment
              to={readOnly ? this.segmentPaths.enrollment : this.segmentPaths.members}
              label={readOnly ? "Enrollment" : "Members"}
            />
            {readOnly && <Segments.Segment to={this.segmentPaths.users} label="Users" />}
            {!readOnly && <Segments.Segment to={this.segmentPaths.owners} label="Owners" />}
            <Segments.Segment to={this.segmentPaths.content} label="Content" />
            {!readOnly && <Segments.Segment to={this.segmentPaths.licenses} label="Licenses" />}
            {!readOnly && this.props.groupInfo.isWithAutoEnroll === Enrollment.Automatic && (
              <Segments.Segment to={this.segmentPaths.notify} label="Notify" />
            )}
          </Segments>
          <Routes>
            {!readOnly && <Route path="/" element={this.renderConfigureTab()} />}
            <Route path={this.segmentPaths.members} element={this.renderPeopleTab()} />
            <Route path={this.segmentPaths.enrollment} element={this.renderPeopleTab()} />
            <Route path={this.segmentPaths.users} element={this.renderPeopleTab(true)} />
            <Route path={this.segmentPaths.owners} element={this.renderOwnersTab()} />
            <Route path={this.segmentPaths.content} element={this.renderContentTab()} />
            <Route path={this.segmentPaths.licenses} element={this.renderLicensingTab()} />
            <Route path={this.segmentPaths.notify} element={this.renderNotifyTab()} />
            <Route path="*" element={<Navigate to={readOnly ? this.segmentPaths.enrollment : "../"} replace />} />
          </Routes>
        </section>
      </RestrictedResource>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const editGroup = state.people.editGroup;
  const assetsFilterOptions = videosStateSelector(state).overview.filters;

  return {
    accountId: state.userProfile.accountId,
    userPermissions: state.userProfile.permissions,
    backgroundTasks: state.backgroundTasks.tasks,
    groupSettings: editGroup.editInfo.settings,
    groupInfo: editGroup.editInfo.data,
    isGroupInfoLoading: editGroup.editInfo.isLoading,
    isAccessAuthorized: editGroup.editInfo.isAccessAuthorized,
    editPeople: {
      list: editGroup.editPeople.usersList,
      filterOptions: state.people.usersOverview.filterOptions,
      usersToAdd: editGroup.editPeople.usersToAdd,
    },
    ownersList: editGroup.ownersList,
    assetsFilterOptions: assetsFilterOptions,
    addOwnersList: state.people.addGroup.ownersList,
    showAddToContentModal: state.people.addToContentModal.showModal,
    membersSearch: editGroup.membersSearch,
    addMembersSearch: editGroup.addMembersSearch,
    accountPacksSearch: accontPacksSearchSelector(state),
    groupContentAssignment: state.people.contentAssignment,
    enrollmentUsers: state.people.enrollmentUsers,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    contentAssignmentActions: bindActionCreators(contentAssignmentActionsRedux, dispatch),
    editGroupActions: bindActionCreators(editGroupActionsRedux, dispatch),
    backgroundTasksActions: bindActionCreators(backgroundTasksActionsRedux, dispatch),
    notificationsActions: bindActionCreators(notificationsActionsRedux, dispatch),
    usersFilterActions: bindActionCreators(usersFilterActionsRedux, dispatch),
    groupsOverviewActions: bindActionCreators(groupsOverviewActionsRedux, dispatch),
    addGroupActions: bindActionCreators(addGroupActionsRedux, dispatch),
    addToContentActions: bindActionCreators(addToContentActionsRedux, dispatch),
    onAddContent: (groupId: number, contentType: AddPeopleToContentTypes) => {
      batch(() => {
        dispatch(addToContentActionsRedux.setSelectedContentType(contentType));
        dispatch(addToContentActionsRedux.setSelectedPeopleIds([groupId]));
        dispatch(addToContentActionsRedux.setSelectedPeopleType(AssignmentPeopleContext.Group));
      });
    },
    issuePackLicense: bindActionCreators(issuePackLicenseToPeople, dispatch),
    removePackLicense: bindActionCreators(removePackLicenseFromPeople, dispatch),
    editPriority: (priorityLevels: ContentPriorityLevel[], peopleId: number, useFlexibleDueDate: boolean) =>
      dispatch(editPeopleContentPriority(priorityLevels, peopleId, PeopleType.Group, useFlexibleDueDate)),
    removeContentPriorities: bindAction(removeContentPrioritiesDistributed, dispatch),
    resetLicensingModal: bindAction(reset, dispatch),
    fetchGroupUsersEnrollment: bindAction(fetchGroupEnrollmentUsers, dispatch),
    resetEnrollmentUsers: bindAction(resetEnrollmentUsers, dispatch),
  };
};

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/* istanbul ignore next */
const component = withFormik<EditGroupProps, any>({
  enableReinitialize: true,
  validationSchema: (props: EditGroupProps) => validationSchemas.getEditGroupSchema(parseInt(props.params.id)),
  mapPropsToValues: (props: EditGroupProps) => {
    const info = props.groupInfo;
    const settings = props.groupSettings;
    const ownerIds = info.owners ? info.owners.map((a: { id: number }) => a.id) : [];
    return {
      ownerIds: ownerIds,
      name: info.name,
      description: info.description,
      imageUrl: info.imageUrl,
      settings: settings,
    };
  },
  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
})(withRouter(EditGroup));

// @ts-ignore
export default connector(withRouter(withLDConsumer()(component)));
