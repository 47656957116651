import { useEffect } from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";
import { Icon } from "semantic-ui-react";

import EllipsisPopupButton from "../buttons/ellipsisPopupButton/EllipsisPopupButton";
import LeftArrowButton from "../buttons/arrowButtons/LeftArrowButton";
import miscUtils from "../../utils/miscellaneousUtils";
import { SendPageView } from "../../utils/googleAnalytics";

import "./genericSectionHeader.scss";

function GenericSectionHeader(props) {
  useEffect(() => {
    miscUtils.setPageTitle(props.title);

    let titleToSend = props.titleForGA ?? props.title;
    if (titleToSend) {
      SendPageView({
        title: titleToSend,
      });
    }
  }, [props.title, props.titleForGA]);

  return (
    <header className="generic-section-header">
      <div className="title-section">
        {renderGoBackButton(props)}
        <h2>{props.title}</h2>
      </div>
      <div className="buttons-section">{renderButtonsSection(props)}</div>
    </header>
  );
}

function renderGoBackButton(props) {
  return props.showGoBackIcon ? <LeftArrowButton size="large" onClick={props.goBackAction} /> : null;
}

function renderButtonsSection(props) {
  if (props.isReadOnly) {
    return (
      <div className="read-only-label">
        <Icon className="fas fa-ban icon" size="large" />
        READ ONLY
      </div>
    );
  }

  return (
    <>
      {props.buttons &&
        props.buttons.map((button) => (
          <div className="button-container" key={v4()}>
            {button}
          </div>
        ))}
      <div>{props.dropdownButtons && <EllipsisPopupButton>{props.dropdownButtons}</EllipsisPopupButton>}</div>
    </>
  );
}

GenericSectionHeader.defaultProps = {
  showGoBackIcon: true,
};

GenericSectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  titleForGA: PropTypes.string,
  goBackAction: PropTypes.func,
  buttons: PropTypes.array,
  dropdownButtons: PropTypes.array,
  isReadOnly: PropTypes.bool,
  showGoBackIcon: PropTypes.bool,
};

export default GenericSectionHeader;
