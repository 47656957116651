import { Header, Icon } from "semantic-ui-react";
import Tooltip from "components/common/tooltip/Tooltip";
import { FormikProps, withFormik } from "formik";
import { ValidatedForm } from "components";
import nameof from "utils/nameof";
import { getEmailSettingsSenderInfoSchema } from "utils/validationSchemas/emailSettingsSchema";
import schemasUtils from "utils/validationSchemasUtils";
import { Dispatch, SetStateAction, useEffect } from "react";
import "./schedule.scss";

export interface SenderInformationOwnProps {
  onIsValidChange: Dispatch<SetStateAction<boolean>>;
  onSenderNameChange: Dispatch<SetStateAction<string | undefined>>;
  onSenderEmailChange: Dispatch<SetStateAction<string | undefined>>;
  onCustomizeSenderChange: Dispatch<SetStateAction<boolean>>;
  senderName: string | undefined;
  senderEmail: string | undefined;
  customizeSender: boolean;
  defaultSenderName: string | undefined;
  defaultSenderEmail: string | undefined;
}

export interface SenderInformationView {
  senderName: string;
  senderEmail: string;
  customizeSender: boolean;
}

export type SenderInformationProps = SenderInformationOwnProps & FormikProps<SenderInformationView>;

export const SenderInformation: React.FC<SenderInformationProps> = (props) => {
  const {
    defaultSenderName,
    defaultSenderEmail,
    values,
    errors,
    dirty,
    touched,
    isValid,
    handleChange,
    handleReset,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    resetForm,
    onIsValidChange,
    onSenderNameChange,
    onSenderEmailChange,
    onCustomizeSenderChange,
  } = props;

  const validatedFieldProps = {
    errors,
    touched,
    dirty,
    handleChange,
    handleReset,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    resetForm,
  };

  useEffect(() => {
    onCustomizeSenderChange(values.customizeSender);
    onSenderNameChange(!values.customizeSender ? defaultSenderName : values.senderName);
    onSenderEmailChange(!values.customizeSender ? defaultSenderEmail : values.senderEmail);
    onIsValidChange(!values.customizeSender ? true : isValid);
    setFieldsTouched();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, values.customizeSender, values.senderName, values.senderEmail]);

  const setFieldsTouched = () => {
    setTimeout(() => setFieldTouched(nameof("senderName"), true));
    setTimeout(() => setFieldTouched(nameof("senderEmail"), true));
  };

  const renderFields = () => {
    return (
      <div className="schedule-container">
        <Header>Sender Information</Header>
        <div className="checkbox-field">
          <ValidatedForm.CheckboxField
            {...validatedFieldProps}
            toggle
            label={"Customize Sender"}
            value={values.customizeSender}
            propertyName={nameof<SenderInformationView>("customizeSender")}
          />
          <Tooltip
            target={<Icon className="info circle" />}
            content={"Ensure that sender rewriting is configured on your SMTP server to enable this feature."}
            position="top center"
            styles={{ width: `${20}rem` }}
            hideOnScroll
            showAlways
          />
        </div>
        <>
          <ValidatedForm.InputField
            {...validatedFieldProps}
            label="Sender Name"
            value={values.senderName}
            disabled={!values.customizeSender}
            placeholder="Enter Sender Name"
            propertyName={nameof<SenderInformationView>("senderName")}
            markAsRequired
          />
          <ValidatedForm.InputField
            {...validatedFieldProps}
            label="Sender Email"
            value={values.senderEmail}
            disabled={!values.customizeSender}
            placeholder="Enter Sender Email"
            propertyName={nameof<SenderInformationView>("senderEmail")}
            markAsRequired
          />
        </>
      </div>
    );
  };

  return (
    <ValidatedForm disablePreventTransitionPrompt={true} {...props}>
      {renderFields()}
    </ValidatedForm>
  );
};

export default withFormik<SenderInformationOwnProps, SenderInformationView>({
  validationSchema: () => {
    return getEmailSettingsSenderInfoSchema();
  },
  mapPropsToValues: (props: SenderInformationOwnProps) => {
    return {
      senderName: props.senderName,
      senderEmail: props.senderEmail,
      customizeSender: props.customizeSender,
    } as SenderInformationView;
  },
  handleSubmit: () => {}, //NOSONAR
  isInitialValid: (props: SenderInformationOwnProps) => {
    return schemasUtils.isValidSync(getEmailSettingsSenderInfoSchema(), props);
  },
  enableReinitialize: true,
  validateOnMount: true,
})(SenderInformation);
