import { useFormik } from "formik";
import { useEffect, useMemo } from "react";
import { Icon } from "semantic-ui-react";
import environmentConfig from "configuration/environmentConfig";
import { CopyableInput, FormSectionHeader } from "../../../components";
import ValidatedForm from "../../../components/forms/ValidatedFormWithoutFormik";
import FeatureFlag from "../../../components/featureFlag/FeatureFlag";
import ValidatedField from "components/forms/ValidatedField";
import { EndpointsToGetSasLink } from "../../../enums";
import ImageAspectRatio from "../../../enums/imageAspectRatio";
import { FeatureFlags } from "../../../featureFlags";
import nameof from "../../../utils/nameof";
import { ensureTrimmed } from "../../../utils/stringUtils";
import { getAccountSchema } from "../../../utils/validationSchemas/accountValidationSchema";
import { AccountType, ParentAccount, ContractType, AccountConfigurationInfo } from "../types";
import Tooltip from "../../../components/common/tooltip/Tooltip";
import styles from "./accountConfiguration.module.scss";
import moment from "moment";
import { isEmpty } from "lodash";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { TimezoneDropdown } from "features/Library/Common/Fields/timezone-dropdown/TimezoneDropdown";
import { WithFormikPayload } from "features/Library/Common/Fields/WithFormikPayload";

export interface AccountConfigurationProps {
  onIsValidChange: (isValid: boolean) => void;
  onDirtyChanged: (isDirty: boolean) => void;
  accountTypes: AccountType[];
  contractTypes: ContractType[];
  parentAccounts: {
    items: ParentAccount[];
    isResourceUnavailable: boolean;
  };
  isLoadingAccountTypes?: boolean;
  isLoadingContractTypes?: boolean;
  isLoadingParentAccounts?: boolean;
  isOwnAccount?: boolean;
  isEdit?: boolean;
  accountId?: number;
  onFetchParentAccounts?: (accountTypeId: number) => void;
  currentConfiguration?: AccountConfigurationInfo;
  isMobo: boolean;
  hasGlobalAccess: boolean;
  bindResetForm?: (resetForm: () => void) => void;
  bindSubmitForm?: (submitForm: () => void) => void;
  onSubmit: (info: AccountConfigurationInfo) => void;
  showAccountId?: boolean;
}

export const AccountConfiguration = (props: AccountConfigurationProps) => {
  const {
    currentConfiguration,
    isMobo,
    hasGlobalAccess,
    parentAccounts,
    accountId,
    isEdit,
    onFetchParentAccounts,
    isOwnAccount,
  } = props;
  const { isResourceUnavailable } = parentAccounts;
  const { name, alias, canChangeAccountType, canEdit } = currentConfiguration || {};

  const isRequiredParent = (isMobo || !hasGlobalAccess) && !isResourceUnavailable;
  const accountTimeZone = useFeatureFlag(FeatureFlags.AccountTimezone);

  const schema = useMemo(() => {
    return getAccountSchema(isRequiredParent, name, alias, isEdit);
  }, [alias, isRequiredParent, name, isEdit]);

  const defaultAccountConfiguration = useMemo(() => {
    return defaultAccount(isRequiredParent && !isEdit && !hasGlobalAccess ? accountId : undefined);
  }, [isRequiredParent, isEdit, hasGlobalAccess, accountId]);

  const formik = useFormik<Partial<AccountConfigurationInfo>>({
    initialValues: isEmpty(currentConfiguration) ? defaultAccountConfiguration : currentConfiguration,
    onSubmit: (values: Partial<AccountConfigurationInfo>) => {
      const casted = schema.cast(values) as AccountConfigurationInfo;
      props.onSubmit(casted);
    },
    validationSchema: schema,
    validateOnMount: true,
    enableReinitialize: true,
  });

  const setTimeoutFieldValue = (name: string, value: any, shouldValidate?: boolean) => {
    formik.setTouched({
      sessionTimeoutDays: true,
      sessionTimeoutHours: true,
      sessionTimeoutMinutes: true,
      sessionTimeoutInMinutes: true,
    });

    return formik.setValues((prev) => {
      const result: Partial<AccountConfigurationInfo> = { ...prev, [name]: value };
      result.sessionTimeoutInMinutes = moment
        .duration({
          days: result.sessionTimeoutDays,
          hours: result.sessionTimeoutHours,
          minutes: result.sessionTimeoutMinutes,
        })
        .asMinutes();
      return result;
    }, shouldValidate);
  };

  const { dirty, setFieldValue, values, isValid, submitForm, resetForm } = formik;
  const { parentAccountId, accountTypeId } = values;
  const { onDirtyChanged, isLoadingParentAccounts, onIsValidChange, bindResetForm, bindSubmitForm } = props;

  useEffect(() => {
    if (bindResetForm) {
      bindResetForm(resetForm);
    }
  }, [bindResetForm, resetForm]);

  useEffect(() => {
    if (bindSubmitForm) {
      bindSubmitForm(submitForm);
    }
  }, [bindSubmitForm, submitForm]);

  useEffect(() => {
    onDirtyChanged(dirty);
  }, [dirty, onDirtyChanged]);

  useEffect(() => {
    onIsValidChange(isValid);
  }, [isValid, onIsValidChange]);

  useEffect(() => {
    if (
      isResourceUnavailable ||
      !accountTypeId ||
      accountTypeId <= 0 ||
      (isEdit && (!hasGlobalAccess || isOwnAccount))
    ) {
      return;
    }
    onFetchParentAccounts?.(accountTypeId);
  }, [accountTypeId, isResourceUnavailable, hasGlobalAccess, isOwnAccount, isEdit, onFetchParentAccounts]);

  const renderBody = () => {
    const { isLoadingAccountTypes, accountTypes, isOwnAccount, contractTypes, isLoadingContractTypes, showAccountId } =
      props;
    const {
      accountTypeId,
      alias,
      contractTypeId,
      name,
      logoUri,
      isDemo,
      magicLinkLogin,
      sessionTimeoutDays,
      sessionTimeoutHours,
      sessionTimeoutMinutes,
      sessionTimeoutInMinutes,
      timeZone,
    } = formik.values;

    const isDisabled = isEdit && (!canEdit || isOwnAccount);

    const accountTypesOptions = getOptions(accountTypes);
    const parentAccountsOptions = getOptions(parentAccounts.items);

    return (
      <>
        <FormSectionHeader title="Account Information" />
        {showAccountId && (
          <ValidatedField propertyName="" label="Account ID">
            <CopyableInput value={accountId!.toString()} readonly />
          </ValidatedField>
        )}
        <ValidatedForm.DropdownField
          placeholder="Select Account Type"
          label="Account Type"
          value={accountTypeId}
          propertyName={nameof<AccountConfigurationInfo>("accountTypeId")}
          className={styles["account-type"]}
          options={accountTypesOptions}
          isLoading={isLoadingAccountTypes}
          disabled={isLoadingAccountTypes || (isEdit && (!canChangeAccountType || !canEdit || isOwnAccount))}
          markAsRequired
          testID={"account-type"}
          {...formik}
        >
          {hasGlobalAccess && (
            <div className={styles["demo"]}>
              <ValidatedForm.CheckboxField
                toggle
                propertyName={nameof<AccountConfigurationInfo>("isDemo")}
                value={isDemo}
                fieldClassName={styles["demo__toggle"]}
                disabled={isDisabled}
                testId={"demo-toggle"}
                {...formik}
              />
              <span className={styles["demo__label"]}>Demo</span>
            </div>
          )}
        </ValidatedForm.DropdownField>
        <ValidatedForm.DropdownField
          placeholder="Select Parent Account"
          label="Parent Account"
          value={parentAccountId}
          minCharacters={3}
          propertyName={nameof<AccountConfigurationInfo>("parentAccountId")}
          options={parentAccountsOptions}
          isLoading={isLoadingParentAccounts}
          disabled={
            isLoadingParentAccounts ||
            !accountTypeId ||
            isResourceUnavailable ||
            (isEdit && (!hasGlobalAccess || isOwnAccount))
          }
          search
          clearable
          lazyLoad
          markAsRequired={isRequiredParent}
          testID={"parent-account"}
          {...formik}
        />
        <div className={styles["company"]}>
          <div className={styles["company__image"]}>
            <ValidatedForm.ImagePreview
              value={logoUri}
              propertyName={nameof<AccountConfigurationInfo>("logoUri")}
              label="Logo"
              placeholder="Logo"
              endpointToGetSasLink={EndpointsToGetSasLink.Account}
              disabled={isEdit && !canEdit}
              isHidden={true}
              aspectRatio={ImageAspectRatio.SQUARE_1X1}
              shouldValidate={false}
              {...formik}
            />
          </div>
          <div className={styles["company__name"]}>
            <ValidatedForm.InputField
              label="Company Name"
              value={name}
              propertyName={nameof<AccountConfigurationInfo>("name")}
              placeholder="Enter Company Name"
              markAsRequired
              disabled={isDisabled}
              onBlur={() =>
                ensureTrimmed(name, (value) => setFieldValue(nameof<AccountConfigurationInfo>("name"), value))
              }
              testId={"name"}
              {...formik}
            />
          </div>
        </div>
        <ValidatedForm.InputField
          label="Account Alias"
          value={alias}
          propertyName={nameof<AccountConfigurationInfo>("alias")}
          placeholder={isEdit ? undefined : "e.g. company"}
          disabled={isDisabled}
          onBlur={() =>
            ensureTrimmed(alias, (value) => setFieldValue(nameof<AccountConfigurationInfo>("alias"), value))
          }
          {...formik}
        />
        <FormSectionHeader title="Contract Information" />
        <ValidatedForm.DropdownField
          placeholder="Select One"
          label="Contract Type"
          value={contractTypeId}
          propertyName={nameof<AccountConfigurationInfo>("contractTypeId")}
          options={getOptions(contractTypes)}
          isLoading={isLoadingContractTypes}
          disabled={isLoadingContractTypes || isDisabled}
          markAsRequired
          {...formik}
        />
        <FormSectionHeader title="Authentication Settings" />
        <FeatureFlag flag={FeatureFlags.MagicLogin}>
          <div className={styles["magic-login"]}>
            <ValidatedForm.CheckboxField
              toggle
              propertyName={nameof<AccountConfigurationInfo>("magicLinkLogin")}
              value={magicLinkLogin}
              fieldClassName={styles["magic-login__toggle"]}
              disabled={isEdit && !canEdit}
              testId={"magic-login-toggle"}
              {...formik}
            />
            <span className={styles["magic-login__label"]}>Enable Magic Link Authentication</span>
            <Tooltip
              tooltipClassName={styles["magic-login__tooltip"]}
              className={styles["magic-login__tooltip-target"]}
              target={<Icon className="info circle" />}
              content={magicLoginTooltip}
              position="top center"
              hoverable
              hideOnScroll
              showAlways
            />
          </div>
        </FeatureFlag>
        {isEdit && (
          <>
            <div className={styles["section-header"]}>
              <FormSectionHeader title="Session Timeout" />
              <Tooltip
                tooltipClassName={styles["section-header__tooltip"]}
                className={styles["section-header__tooltip-target"]}
                target={<Icon className="info circle" />}
                content={sessionTimeoutTooltip}
                position="top center"
                hoverable
                hideOnScroll
                showAlways
              />
            </div>
            <div className={styles["session-timeout"]}>
              <div className={styles["timeout-input"]}>
                <ValidatedForm.InputField
                  label="Days"
                  value={sessionTimeoutDays}
                  propertyName={nameof<AccountConfigurationInfo>("sessionTimeoutDays")}
                  disabled={!canEdit}
                  errorPosition={"top"}
                  inputType={"number"}
                  {...formik}
                  setFieldValue={setTimeoutFieldValue}
                />
              </div>
              <div className={styles["timeout-input"]}>
                <ValidatedForm.InputField
                  label="Hours"
                  value={sessionTimeoutHours}
                  propertyName={nameof<AccountConfigurationInfo>("sessionTimeoutHours")}
                  disabled={!canEdit}
                  errorPosition={"top"}
                  inputType={"number"}
                  {...formik}
                  setFieldValue={setTimeoutFieldValue}
                />
              </div>
              <div className={styles["timeout-input"]}>
                <ValidatedForm.InputField
                  label="Minutes"
                  value={sessionTimeoutMinutes}
                  propertyName={nameof<AccountConfigurationInfo>("sessionTimeoutMinutes")}
                  disabled={!canEdit}
                  errorPosition={"top"}
                  inputType={"number"}
                  {...formik}
                  setFieldValue={setTimeoutFieldValue}
                />
              </div>
              <div className={styles["timeout-input"]}>
                <ValidatedForm.InputField
                  className={styles["total-minutes"]}
                  value={sessionTimeoutInMinutes}
                  propertyName={nameof<AccountConfigurationInfo>("sessionTimeoutInMinutes")}
                  disabled={!canEdit}
                  {...formik}
                />
              </div>
            </div>
          </>
        )}
        {accountTimeZone && (
          <>
            <div className={styles["section-header"]}>
              <FormSectionHeader title="DEFAULT TIMEZONE" />
              <Tooltip
                tooltipClassName={styles["section-header__tooltip"]}
                className={styles["section-header__tooltip-target"]}
                target={<Icon className="info circle" />}
                content={
                  "Sets the default timezone for this account. End-users can override it in their individual settings."
                }
                position="top center"
                hoverable
                hideOnScroll
                showAlways
              />
            </div>
            <WithFormikPayload
              propertyName={nameof<AccountConfigurationInfo>("timeZone")}
              {...formik}
              render={(props) => (
                <TimezoneDropdown
                  label="Timezone"
                  placeholder="Default is UTC. Select to customize for this account."
                  setFieldTouched={formik.setFieldTouched}
                  setFieldValue={formik.setFieldValue}
                  value={timeZone}
                  clearable
                  disabled={isEdit && !canEdit}
                  {...props}
                />
              )}
            />
          </>
        )}
      </>
    );
  };

  return (
    <section className="account-info-form">
      <ValidatedForm
        loaderOnSubmit={!isEdit}
        unsavedChangesPrompt={{
          title: "Exit Without Saving?",
          message: "Are you sure you want to exit without saving this account? All information entered will be lost.",
        }}
        {...formik}
      >
        {renderBody()}
      </ValidatedForm>
    </section>
  );
};

const defaultAccount = (parentAccountId: number | undefined): Partial<AccountConfigurationInfo> => {
  return {
    name: "",
    isDemo: false,
    magicLinkLogin: false,
    parentAccountId: parentAccountId,
  };
};

const getOptions = (options: { id: number; name: string }[]) => {
  return options.map((x) => ({
    key: x.id,
    value: x.id,
    text: x.name,
  }));
};

const magicLoginTooltip = (
  <span>
    Activate this feature to offer users a login link via email for a simplified login process.&nbsp;
    <a href={environmentConfig.helpCenterUrl} target="_blank" rel="noopener noreferrer">
      Visit our Help Center
    </a>
    &nbsp;for more information.
  </span>
);

const sessionTimeoutTooltip = <span>Adjust the session timeout duration. Minimum: 10 minutes. Maximum: 365 days.</span>;
