import { BasicLinkButton, RestrictedByTooltip } from "components";
import { Title } from "components/listViewTemplates";
import UserInfoCell from "components/userInfoCell/UserInfoCell";
import { User } from "interfaces";
import { ColumnOption } from "interfaces/columnOptions";
import dateTimeUtils from "utils/dateTimeUtils";

export enum Columns {
  Name = "Name",
  Department = "Department",
  JobTitle = "Job title",
  Added = "Added",
}

export const columnToParamMap: {
  [P in Lowercase<Columns>]: string;
} = {
  name: "name",
  department: "department",
  added: "added",
  "job title": "jobtitle",
};

export const getColumnOptions = ({
  handleDelete,
  hasPermission,
  removeButtonDisabled,
}: {
  hasPermission: boolean;
  removeButtonDisabled: boolean;
  handleDelete: (id: number) => void;
}): ColumnOption<User>[] => {
  return [
    {
      name: Columns.Name,
      width: 4,
      isSortable: true,
      className: "user-name-header",
      render: (user) => <UserInfoCell user={user} />,
    },
    {
      name: Columns.Department,
      width: 3,
      isSortable: true,
      render: (user) => <Title title={user.department} />,
    },
    {
      name: Columns.JobTitle,
      width: 3,
      isSortable: true,
      render: (user) => <Title title={user.title} />,
    },
    {
      name: Columns.Added,
      width: 3,
      isSortable: true,
      render: (user) => dateTimeUtils.formatDate(user.createDate),
    },
    {
      name: "",
      width: 2,
      className: "align-right",
      render: (user) => (
        <RestrictedByTooltip hasPermission={hasPermission}>
          <BasicLinkButton
            key={"Remove"}
            text=""
            iconClassName={"fa-trash-alt"}
            onClick={() => handleDelete(user.id)}
            isDisabled={removeButtonDisabled}
          />
        </RestrictedByTooltip>
      ),
    },
  ];
};
