import React, { FC, SyntheticEvent, useState } from "react";
import { DropdownProps } from "semantic-ui-react";

import { UsersGroupsContext } from "../../../../../enums";
import { PeopleWrapperProps } from "../types";
import { PeopleSegmentationDropdown } from "../../../../../components";

import Restricted from "../../../../Application/Restricted";

const PeopleWrapper: FC<PeopleWrapperProps> = ({
  permission,
  renderUsersOverview,
  renderGroupsOverview,
  usersGroupsContext,
  onContextChanged,
}): React.ReactElement => {
  const [context, setContext] = useState<UsersGroupsContext>(usersGroupsContext ?? UsersGroupsContext.Groups);

  const onChangeContext = (_: SyntheticEvent<HTMLElement, Event>, data: DropdownProps): void => {
    const value = data.value as UsersGroupsContext;
    setContext(value);
    onContextChanged?.(value);
  };

  const renderContextSwitcher = (): React.ReactElement => (
    <PeopleSegmentationDropdown onChange={onChangeContext} defaultOption={context} />
  );

  const contentList = (isReadOnly: boolean) => ({
    [UsersGroupsContext.Users]: renderUsersOverview(renderContextSwitcher(), isReadOnly),
    [UsersGroupsContext.Groups]: renderGroupsOverview(renderContextSwitcher(), isReadOnly),
  });

  return (
    <Restricted
      permissions={[permission]}
      renderContent={(hasAnyPermission) => contentList(!hasAnyPermission)[context]}
    />
  );
};
export default PeopleWrapper;
