import { FC, useEffect } from "react";
import { bindAction } from "interfaces";
import { fetchIntegrationsAction } from "features/Reporting/state/menu/integrationMenuThunk";
import { AppDispatch, RootState } from "features/Application/globaltypes/redux";
import { ConnectedProps, connect } from "react-redux";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import NavigationMenuItem from "../navigationMenuItem/NavigationMenuItem";
import { RouteNames } from "enums";
import { events } from "views/reporting/ReportingContent";
import { useRtn } from "hooks/useRtn";
import { selectMenuItemsData } from "features/Reporting/state/menu/integrationMenuSlice";

export interface Props extends PropsFromRedux {
  topLevelItemClick: () => void;
  expanded: boolean;
  toggleNavigation: () => void;
  hideNavigation: () => void;
}

export const ReportingNavigationMenu: FC<Props> = ({
  topLevelItemClick,
  expanded,
  toggleNavigation,
  hideNavigation,
  integrations,
  fetchIntegrations,
}) => {
  const isPackReportingOverviewEnabled = !!useFeatureFlag(FeatureFlags.PackReportingOverview);
  const isTopPackPerformanceEnabled = !!useFeatureFlag(FeatureFlags.PackTopLevelPerformance);
  const isGraphPerformanceEnabled = !!useFeatureFlag(FeatureFlags.MicrosoftGraphReports);
  const isDashboardReportsEnabled = !!useFeatureFlag(FeatureFlags.DashboardReports);
  const isGoogleReportingEnabled = !!useFeatureFlag(FeatureFlags.GoogleReports);
  const isPeopleReportingEnabled = !!useFeatureFlag(FeatureFlags.PeopleReports);

  const isGoogleIntegrationEnabled = integrations["Google"];
  const isMicrosoftIntegrationEnabled = integrations["MSGraph"];

  useEffect(() => {
    fetchIntegrations();
  }, [fetchIntegrations]);

  useRtn(events, fetchIntegrations);

  return (
    <NavigationMenuItem
      label="Reporting"
      icon="fa-chart-pie"
      path={RouteNames.reporting}
      onClick={topLevelItemClick}
      expanded={expanded}
      toggleNavigation={toggleNavigation}
    >
      {isDashboardReportsEnabled && (
        <NavigationMenuItem label="Dashboard" path="overview" hideNavigation={hideNavigation} />
      )}
      {isGraphPerformanceEnabled && isMicrosoftIntegrationEnabled && (
        <NavigationMenuItem label="Microsoft Graph" path="graph" hideNavigation={hideNavigation} />
      )}
      {isGoogleReportingEnabled && isGoogleIntegrationEnabled && (
        <NavigationMenuItem label="Google Graph" path="google" hideNavigation={hideNavigation} />
      )}
      {isTopPackPerformanceEnabled && (
        <NavigationMenuItem
          label={isPackReportingOverviewEnabled ? "Packs & Add-Ons" : "Packs & Licensing"}
          path="packs"
          hideNavigation={hideNavigation}
        />
      )}
      {isPeopleReportingEnabled && <NavigationMenuItem label="People" path="people" hideNavigation={hideNavigation} />}
      <NavigationMenuItem label="Content" path="content" hideNavigation={hideNavigation} />
    </NavigationMenuItem>
  );
};

const mapStateToProps = (state: RootState) => ({
  integrations: selectMenuItemsData(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchIntegrations: bindAction(fetchIntegrationsAction, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ReportingNavigationMenu);
