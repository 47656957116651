import { DefaultHttpClient, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

import KnownHttpHeaders from "../knownHttpHeaders";

class RtnLogger {
  log(logLevel, message) {
    if (logLevel === LogLevel.Error) {
      this.error(message);
    } else {
      this.trace(message);
    }
  }

  trace(message) {
    console.log(message);
    if (window.appInsights) {
      window.appInsights.trackTrace({ message: message });
    }
  }

  error(message) {
    console.error(message);
    if (window.appInsights) {
      window.appInsights.trackException({ exception: new Error(message) });
    }
  }
}

class BrainstormHttpClient extends DefaultHttpClient {
  constructor(logger, mobo) {
    super(logger);
    this.mobo = mobo;
  }

  send(request) {
    if (this.mobo && request.url.includes("negotiate")) {
      request.headers[KnownHttpHeaders.MoboId] = this.mobo.id;
    }

    return super.send(request);
  }
}

export default class RealTimeNotificationClient {
  constructor(hubUrl, getAccessToken, mobo, rtnHandler) {
    this.logger = new RtnLogger();
    this.rtnHandler = rtnHandler;
    this.connectionBuilder = new HubConnectionBuilder()
      .withUrl(hubUrl, {
        logger: LogLevel.Information,
        accessTokenFactory: () => {
          this.logger.log(LogLevel.Trace, "Token accessed");
          return getAccessToken();
        },
        httpClient: new BrainstormHttpClient(this.logger, mobo),
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: () => 2000,
      })
      .configureLogging(this.logger);
  }

  subscribe(connection) {
    connection.on("ReceiveMessage", (type, payload) => {
      this.logger.log(LogLevel.Trace, `Message received. Type: ${type}. Payload: ${payload}`);
      this.rtnHandler.handle(`notification:${type}`, payload);
    });

    connection.onclose(async (error) => {
      if (error) {
        this.logger.log(LogLevel.Error, `Got disconnected from SignalR hub: ${error.message}.`);
        await connection.start();
      }
      this.reconnect();
    });
  }

  async connect() {
    this.connection = this.connectionBuilder.build();
    console.log(this.connection);
    this.subscribe(this.connection);

    try {
      await this.connection.start();
      this.logger.log(LogLevel.Trace, "SignalR hub connection started successfully.");
    } catch (error) {
      this.logger.log(LogLevel.Error, `SignalR hub connection failed to start: ${error.message}.`);
    }
  }

  async disconnect() {
    try {
      await this.connection.stop();
      this.logger.log(LogLevel.Trace, "SignalR hub disconnected successfully.");
    } catch (error) {
      this.logger.log(LogLevel.Error, `SignalR disconnect failed: ${error.message}.`);
    }
  }

  async reconnect() {
    let newConnection = this.connectionBuilder.build();
    await newConnection.start();
    this.subscribe(newConnection);
    this.connection.off("ReceiveMessage");
    await this.disconnect();
    this.connection = newConnection;
  }
}
