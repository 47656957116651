import React from "react";
import { DropdownProps } from "semantic-ui-react";

import ContextSwitcherDropdown, { ContextSwitcherOption } from "../contextSwitcherDropdown/contextSwitcherDropdown";

import "./peopleSegmentationDropdown.scss";

const peopleOptions: ContextSwitcherOption[] = [
  {
    key: "Users",
    label: "Users",
    iconName: "fa-user",
  },
  {
    key: "Groups",
    label: "Groups",
    iconName: "fa-users",
  },
];

interface PeopleSegmentationDropdownProps extends DropdownProps {
  options?: ContextSwitcherOption[];
  defaultOption?: string;
}

export default function PeopleSegmentationDropdown(props: PeopleSegmentationDropdownProps) {
  return (
    <ContextSwitcherDropdown
      data-testid="people-segmentation-dropdown"
      className="people-segmentation-dropdown"
      options={peopleOptions}
      {...props}
    />
  );
}
