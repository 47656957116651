export enum FeatureFlags {
  WebexIntegration = "webex-integration",
  ExternalTriggerFlow = "flow-external-trigger",
  PackTopLevelPerformance = "PackTopLevelPerformance",
  ReportingOverview = "reporting-overview",
  MicrosoftGraphReports = "microsoft-graph-reports",
  EmailReport = "FED-AP-EmailPerformanceEndpoints-06072023",
  PdfReport = "FED-AP-PdfPerformanceEndpoints-06282023",
  EventReport = "FED-AP-EventsPerformance-temp-02212023",
  EventReportFilter = "event-report-filter",
  AssessmentReport = "FED-AP-AssessmentPerformanceEndpoints-06282023",
  SurveyReport = "FED-AP-SurveyPerformanceEndpoints-06292023",
  VideoReport = "FED-AP-VideoPerformanceEndpoints-06262023",
  FlowReport = "FED-AP-FlowPerformanceEndpoints-06292023",
  PdfOverviewEndpoints = "FED-AP-PdfPerformanceOverview-07312023",
  PackReportingOverview = "FED-AP-PackPerformanceOverview-07212023",
  VideoPerformanceOverview = "FED-AP-VideoPerformanceOverview-07252023",
  AssessmentPerformanceOverview = "FED-AP-AssessmentPerformanceOverview-07272023",
  SurveyPerformanceOverview = "FED-AP-SurveyPerformanceOverview-07272023",
  EmailPerformanceOverview = "FED-AP-EmailPerformanceOverview-08282023",
  FlowPerformanceOverview = "FED-AP-FlowPerformanceOverview-08282023",
  EventPerformanceOverview = "FED-AP-EventPerformanceOverview-06102024",
  GoogleReports = "FED-AP-GoogleReports-09272023",
  PeopleReports = "FED-AP-PeopleReports-01182023",
  MauReports = "FED-AP-MauReport-07162024",
  UserPerformance = "FED-AP-UserPerformanceReport-01172024v2",
  MsGraphGoalReporting = "flow-goals-ms-graph-report",
  SmtpDirectSend = "smtp-directsend",
  FlowVersioningMigration = "flow-versioning-migration",
  DashboardReports = "dashboard-reports",
  DeleteContentWithDependenciesFeature = "delete-content-with-dependencies",
  MagicLogin = "magic-login",
  AssociatedPacks = "associated-packs",
  LicenseRemoval = "license-removal",
  AwsEncoding = "aws-encoding",
  AccountTemplates = "account-template",
  RecurringEventReport = "events-performance-recurring",
  AccountTimezone = "account-time-zone",
  GroupTemplates = "group-template",
  RuleEngineOnCosmos = "rules-engine-on-cosmos",
  CommunicationTimezone = "communication-time-zone",
  LicenseGroupsFilter = "license-type-groups-filter",
  FlexiblePriorityDueDate = "flexible-priority-due-date",
  ContentVisibility = "content-visibility",
}
