import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { DataPoint } from "components/charts/types/HorizontalBarChart";
import { PerformanceFilter } from "features/Library/Common/utils/performanceUtils";
import {
  getGoalLineChart as goalLineMap,
  getGoalEngagementChart,
  getGoalCards as goalCardsMap,
} from "features/Library/Flows/state/selectors/flowPerformanceGoalSelectors";
import dateTimeUtils from "utils/dateTimeUtils";

export type VALID_GOALS =
  | { application: "Teams"; activity: "Meetings" }
  | { application: "Teams"; activity: "Messages" }
  | { application: "Teams"; activity: "Calls" }
  | { application: "OneDrive"; activity: "Files Shared" }
  | { application: "OneDrive"; activity: "Files Synced" }
  | { application: "OneDrive"; activity: "Files Viewed" }
  | { application: "SharePoint"; activity: "Files Synced" }
  | { application: "SharePoint"; activity: "Files Viewed" }
  | { application: "SharePoint"; activity: "Page Visits" }
  | { application: "SharePoint"; activity: "Shared Files" };

type VALID_GOAL_STRINGS =
  | `${Extract<VALID_GOALS, { application: "Teams" }>["application"]} ${Extract<VALID_GOALS, { application: "Teams" }>["activity"]}`
  | `${Extract<VALID_GOALS, { application: "OneDrive" }>["application"]} ${Extract<VALID_GOALS, { application: "OneDrive" }>["activity"]}`
  | `${Extract<VALID_GOALS, { application: "SharePoint" }>["application"]} ${Extract<VALID_GOALS, { application: "SharePoint" }>["activity"]}`;

type GoalQueryKey = { dateFilter: PerformanceFilter; goal: VALID_GOALS; enabled?: boolean };

type Params = QueryFunctionContext<[_name: string, dateFilter: PerformanceFilter, goal: VALID_GOALS]>;

const lineTooltipFormatter = (x: Date, y: number) => `${dateTimeUtils.formatDate(x)}: ${y.toLocaleString()}`;

const getGoalLineChart = async ({ queryKey }: Params): Promise<ReturnType<typeof goalLineMap>> => {
  const { data } = await axios.get("/api/reports/v2/flows/goals/linechart", {
    params: { ...queryKey[1], ...queryKey[2] },
  });

  const goalName = `${queryKey[2].application} ${queryKey[2].activity}`;

  const lineChartMap = goalLineMap(data, goalName as VALID_GOAL_STRINGS);
  lineChartMap.lineProps.tooltipFormatter = lineTooltipFormatter;

  return lineChartMap;
};

const goalTotalTooltip = (d: DataPoint) => `${d.category}: ${d.value.toLocaleString()}`;

const getGoalTotals = async ({ queryKey }: Params): Promise<ReturnType<typeof getGoalEngagementChart>> => {
  const { data } = await axios.get("/api/reports/v2/flows/goals/totals", {
    params: { ...queryKey[1], ...queryKey[2] },
  });

  const goalName = `${queryKey[2].application} ${queryKey[2].activity}`;
  const engagementChart = getGoalEngagementChart(data, goalName as VALID_GOAL_STRINGS);
  engagementChart.tooltipFormatter = goalTotalTooltip;

  return engagementChart;
};

const getGoalCards = async ({ queryKey }: Params): Promise<ReturnType<typeof goalCardsMap>> => {
  const { data } = await axios.get("/api/reports/v2/flows/goals/cards", {
    params: { ...queryKey[1], ...queryKey[2] },
  });

  return goalCardsMap(data, queryKey[2]);
};

export const useFlowGoalsLineQuery = ({ dateFilter, goal, enabled }: GoalQueryKey) =>
  useQuery({
    queryKey: ["goals-line", dateFilter, goal],
    queryFn: getGoalLineChart,
    enabled,
  });

export const useFlowGoalsTotalsQuery = ({ dateFilter, goal, enabled }: GoalQueryKey) =>
  useQuery({
    queryKey: ["goals-bar", dateFilter, goal],
    queryFn: getGoalTotals,
    enabled,
  });

export const useFlowGoalsCardsQuery = ({ dateFilter, goal, enabled }: GoalQueryKey) =>
  useQuery({
    queryKey: ["goals-cards", dateFilter, goal],
    queryFn: getGoalCards,
    enabled,
  });
