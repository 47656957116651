import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { FeatureFlags } from "featureFlags";
import { BasePerformanceQueryContext, PerformancePeriodQueryContext } from "features/Library/Common/models";
import {
  completesColor,
  createDateRange,
  dateRange30,
  getFormattedTimeStringFromPeriod,
  lineChartFactory,
  noBarData,
  noData,
  startsColor,
  validLineData,
} from "features/Library/Common/utils/performanceUtils";
import {
  fetchCollaborationLineChartData,
  fetchCollaborationTotalsData,
} from "features/Reporting/services/googleReportingService";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { useMemo, useState } from "react";

import styles from "../googleReport.module.scss";
import { ChartWrapper, GroupedBarChart, HorizontalBarChart, LineChart } from "components/charts";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import {
  sharedAccountReportingHorizontalBarProps,
  sharedAccountReportingLineProps,
} from "features/Reporting/Content/shared";

import { DataPoint } from "components/charts/types/HorizontalBarChart";
import { BarChartData } from "components/charts/types/Shared";
import { ReportingFilter } from "components/reportingFilter/ReportingFilter";
import {
  COLOR_SCALE,
  ChartState,
  handleDropdownChange,
  sharedGoogleReportingBarProps,
} from "../utils/shared";
import { useChartPeriodMeasure } from "hooks/useChartPeriodMeasure";
import { useGoogleTooltipFormats } from "../utils/useGoogleTooltipFormats";
import { SERVER_DATE_ONLY_FORMAT } from "utils/dateTimeUtils";

type CollaborationBarData = {
  email: number;
  chat: number;
};

const LEGEND_LABELS = ["Sent Emails", "Sent Chats"];

const getLineChartQuery = async ({ queryKey }: PerformancePeriodQueryContext) => {
  const { data } = await fetchCollaborationLineChartData({ ...queryKey[1], period: queryKey[2] });
  const lineData = lineChartFactory(
    data.map((d) => {
      const { EventDate, ...rest } = d;
      return {
        ...rest,
        Date: EventDate,
      };
    }),
  );

  const groupedUsersColumnData: BarChartData<CollaborationBarData>[] = data.map((d) => ({
    group: moment(d.EventDate, "YYYY-MM-DD").format(SERVER_DATE_ONLY_FORMAT),
    email: d.EmailUsers,
    chat: d.ChatUsers,
  }));

  const activityBarData: BarChartData<CollaborationBarData>[] = data.map((d) => ({
    group: moment(d.EventDate, "YYYY-MM-DD").format(SERVER_DATE_ONLY_FORMAT),
    email: d.EmailsSent,
    chat: d.ChatsSent,
  }));

  const order: (keyof CollaborationBarData)[] = ["email", "chat"];

  return {
    lineData,
    groupedUsersColumnData,
    activityBarData,
    order,
  };
};

const getTotalsQuery = async ({ queryKey }: BasePerformanceQueryContext) => {
  const { data } = await fetchCollaborationTotalsData(queryKey[1]);
  const domain = [0, Math.max(1, Math.max(data.EmailsSent, data.ChatsSent))];
  const barData: DataPoint[] = [
    {
      id: "emails",
      fillColor: startsColor,
      value: data.EmailsSent,
      category: "Emails",
    },
    {
      id: "chats",
      fillColor: completesColor,
      value: data.ChatsSent,
      category: "Chats",
    },
  ];
  return {
    barData,
    domain,
  };
};

export const GoogleCollaborations = () => {
  const enabled = useFeatureFlag(FeatureFlags.GoogleReports);
  const [usersChartState, setUsersChartState] = useState<ChartState>("line");
  const [activityChartState, setActivityChartState] = useState<ChartState>("line");
  const [filter, setFilter] = useState(dateRange30());

  const totalsQuery = useQuery({
    queryKey: ["GoogleCollaborationTotals", filter],
    queryFn: getTotalsQuery,
  });

  const dateRange = useMemo(() => createDateRange(filter.dateFrom, filter.dateTo), [filter.dateFrom, filter.dateTo]);

  const [chartPeriod, measureRef] = useChartPeriodMeasure(
    dateRange,
    sharedAccountReportingLineProps.margins!.left + sharedAccountReportingLineProps.margins!.right,
  );

  const { lineChartFormatter, groupedBarFormatter } = useGoogleTooltipFormats(chartPeriod, dateRange);

  const lineChartQuery = useQuery({
    queryKey: ["googleCollaborationLine", filter, chartPeriod],
    queryFn: getLineChartQuery,
    enabled: enabled && chartPeriod !== "UNSET",
  });

  const activityChart = useMemo(() => {
    if (!lineChartQuery.data) {
      return <></>;
    }
    return activityChartState === "line" ? (
      <LineChart
        {...sharedAccountReportingLineProps}
        xData={[lineChartQuery.data.lineData.Date, lineChartQuery.data.lineData.Date]}
        yData={[lineChartQuery.data.lineData.EmailsSent, lineChartQuery.data.lineData.ChatsSent]}
        xFormatterFunc={(d) => getFormattedTimeStringFromPeriod(d, chartPeriod, dateRange)}
        colorRange={COLOR_SCALE}
        tooltipFormatter={lineChartFormatter}
      />
    ) : (
      <GroupedBarChart
        {...sharedGoogleReportingBarProps}
        data={lineChartQuery.data.activityBarData}
        order={lineChartQuery.data.order}
        xTickFormatter={(d) => getFormattedTimeStringFromPeriod(d.toLocaleString(), chartPeriod, dateRange)}
        tooltipFormatter={groupedBarFormatter}
      />
    );
  }, [activityChartState, chartPeriod, dateRange, groupedBarFormatter, lineChartQuery.data, lineChartFormatter]);

  const activeUsersChart = useMemo(() => {
    if (!lineChartQuery.data) {
      return <></>;
    }
    return usersChartState === "line" ? (
      <LineChart
        {...sharedAccountReportingLineProps}
        xData={[lineChartQuery.data.lineData.Date, lineChartQuery.data.lineData.Date]}
        yData={[lineChartQuery.data.lineData.EmailUsers, lineChartQuery.data.lineData.ChatUsers]}
        xFormatterFunc={(d) => getFormattedTimeStringFromPeriod(d, chartPeriod, dateRange)}
        colorRange={COLOR_SCALE}
        tooltipFormatter={lineChartFormatter}
      />
    ) : (
      <GroupedBarChart
        {...sharedGoogleReportingBarProps}
        tooltipFormatter={groupedBarFormatter}
        xTickFormatter={(d) => getFormattedTimeStringFromPeriod(d.toLocaleString(), chartPeriod, dateRange)}
        data={lineChartQuery.data.groupedUsersColumnData}
        order={lineChartQuery.data.order}
      />
    );
  }, [chartPeriod, dateRange, groupedBarFormatter, lineChartQuery.data, lineChartFormatter, usersChartState]);

  return (
    <div className={styles.body}>
      <div className={styles.header}>
        <h2>Usage</h2>
        <ReportingFilter currentFilter={filter} callback={({ dateFrom, dateTo }) => setFilter({ dateFrom, dateTo })} />
      </div>
      <div className={styles.reportBody}>
        <div className={styles.activeUsersContainer}>
          <ChartWrapper
            titles={["Active Users - Line Chart", "Active Users - Column Chart"]}
            legendLabels={["Gmail", "Chat"]}
            showLegend
            onChange={(option) => handleDropdownChange(option, setUsersChartState)}
            colorRange={COLOR_SCALE}
          >
            <RequestStatusRenderer state={lineChartQuery.status}>
              {lineChartQuery.isSuccess &&
              validLineData([lineChartQuery.data.lineData.EmailUsers, lineChartQuery.data.lineData.ChatUsers])
                ? activeUsersChart
                : noData(filter)}
            </RequestStatusRenderer>
          </ChartWrapper>
        </div>
        <div className={styles.header}>
          <h2>Activity</h2>
        </div>
        <div className={styles.activityContainer}>
          <div className={styles.activity} ref={measureRef}>
            <ChartWrapper
              titles={["Email and Chats - Line Chart", "Email and Chats - Column Chart"]}
              legendLabels={LEGEND_LABELS}
              showLegend
              onChange={(option) => handleDropdownChange(option, setActivityChartState)}
              colorRange={COLOR_SCALE}
            >
              <RequestStatusRenderer state={lineChartQuery.status}>
                {lineChartQuery.isSuccess &&
                validLineData([lineChartQuery.data.lineData.EmailsSent, lineChartQuery.data.lineData.ChatsSent])
                  ? activityChart
                  : noData(filter)}
              </RequestStatusRenderer>
            </ChartWrapper>
          </div>
        </div>
        <div className={styles.barChartContainer}>
          <ChartWrapper titles={["Emails vs Chats"]} showLegend legendLabels={LEGEND_LABELS} colorRange={COLOR_SCALE}>
            <RequestStatusRenderer state={totalsQuery.status}>
              {totalsQuery.isSuccess && !noBarData(...totalsQuery.data.barData.map((d) => d.value)) ? (
                <HorizontalBarChart
                  {...sharedAccountReportingHorizontalBarProps}
                  domain={totalsQuery.data.domain}
                  data={totalsQuery.data.barData}
                />
              ) : (
                noData(filter)
              )}
            </RequestStatusRenderer>
          </ChartWrapper>
        </div>
      </div>
    </div>
  );
};
