import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { BasePerformanceRequestFilterParams } from "features/Library/Common/models";
import { PerformanceFilter, QueryFilter } from "features/Library/Common/utils/performanceUtils";
import { peopleReportingService } from "features/Reporting/services/peopleReportingService";
import { PeopleActivityDetails } from "features/Reporting/types/people";

const fetchPeopleInteractionTable = async ({ queryKey }: QueryFilter) => {
  const result = await peopleReportingService.activity.getPeopleInteractionTable(queryKey[1]);
  return result.data.map((d) => ({ ...d, id: d.InteractionType })).filter((d) => d.InteractionCount > 0);
};

const fetchPeopleOutreachTable = async ({ queryKey }: QueryFilter) => {
  const result = await peopleReportingService.activity.getPeopleOutreachTable(queryKey[1]);
  return result.data.map((d) => ({ ...d, id: d.OutreachType })).filter((d) => d.OutreachCount > 0);
};

const getDetails = async ({
  queryKey,
}: QueryFunctionContext<
  [_name: string, filters: BasePerformanceRequestFilterParams, selectedType: string, selectedCategory: string]
>) => {
  let data: PeopleActivityDetails[];
  if (queryKey[3] === "outreach") {
    ({ data } = await peopleReportingService.activity.getPeopleOutreachDetails({ ...queryKey[1], type: queryKey[2] }));
  } else {
    ({ data } = await peopleReportingService.activity.getPeopleInteractionDetails({
      ...queryKey[1],
      type: queryKey[2],
    }));
  }
  return data.map((d) => ({ ...d, id: `${d.Title} ${d.Id} ${d.Date} ${d.Time}` }));
};

export const usePeopleOutreachTableQuery = (dateFilter: PerformanceFilter) =>
  useQuery({
    queryFn: fetchPeopleOutreachTable,
    queryKey: ["people outreach table", dateFilter],
  });

export const usePeopleInteractionTableQuery = (dateFilter: PerformanceFilter) =>
  useQuery({
    queryFn: fetchPeopleInteractionTable,
    queryKey: ["people interaction table", dateFilter],
  });

type DrilldownQueryPararms = {
  dateFilter: PerformanceFilter;
  selectedType: string | undefined;
  selectedCategory: string | undefined;
};

export const usePeopleActivityDrilldownQuery = (params: DrilldownQueryPararms) =>
  useQuery({
    queryFn: getDetails,
    queryKey: ["people activity details", params.dateFilter, params.selectedType!, params.selectedCategory!],
    enabled: !!params.selectedCategory,
  });
