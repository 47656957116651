import { useCallback } from "react";

import { DefaultSwitch } from "../NotifyStepContent/switches/DefaultSwitch";
import { ButtonGroup } from "../../buttonGroup";
import { SwitchPropsConfig, SwitchPropsData } from "../NotifyStepContent/switches/types";
import { NotifySettings } from "../../../features/SystemNotifications/types";

import styles from "./notifyStepSwitch.module.scss";

interface NotifyStepSwitchProps extends SwitchPropsData<NotifySettings> {
  switchProps: SwitchPropsConfig;
  labelText?: string;
  hideChannelSwitch?: boolean;
}

export const tabs = ["Use Email", "Use Teams"];

export const NotifyStepSwitch = ({ switchProps, onNotifyConfigChange, config, labelText }: NotifyStepSwitchProps) => {
  const handleCommunicationChannelChange = useCallback(
    (value: string) =>
      onNotifyConfigChange({
        key: "communicationChannel",
        value: tabs.findIndex((name) => name === value),
      }),
    [onNotifyConfigChange],
  );

  return (
    <div className={styles.root}>
      <DefaultSwitch
        config={config}
        onNotifyConfigChange={onNotifyConfigChange}
        labelText={labelText}
        {...switchProps}
      />
      <ButtonGroup
        items={tabs}
        defaultValue={tabs[config.communicationChannel]}
        isDisabled={switchProps.isDisabled || !config.shouldNotifyUsers}
        onChange={handleCommunicationChannelChange}
      />
    </div>
  );
};
