import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { usePerformanceBreadcrumbs } from "../../../Common/Hooks/usePerformanceBreadcrumbs";
import { ReportingFilter } from "../../../../../components/reportingFilter/ReportingFilter";
import EventPerformanceBody from "./EventPerformanceBody";
import { FeatureFlags } from "../../../../../featureFlags";
import { useFeatureFlag } from "../../../../../hooks/useFeatureFlag";
import { dateRange30 } from "features/Library/Common/utils/performanceUtils";
import { IWizardStep, bindAction } from "interfaces";

import "../../../Common/utils/performanceSCSSUtils.scss";
import styles from "./eventPerformance.module.scss";
import { Dispatch } from "@reduxjs/toolkit";
import { RootState } from "features/Application/globaltypes/redux";
import { ConnectedProps, connect } from "react-redux";
import { selectExportInfo, selectExporting, selectIsExportActive, reset as resetExport, selectHasDataToExport } from "features/Reporting/state/export/exportSlice";
import { ReportingExport } from "components/reportingExport/ReportingExport";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";

export type Props = IWizardStep & PropsFromRedux & {
  eventId: number;
  eventTitle: string;
  isRecurring: boolean;
};

export const EventPerformance: FC<Props> = ({ eventId, eventTitle, isRecurring, acceptHandlers, resetExports, exportInfo, isExportEnabled, exportStatus, hasDataToExport }) => {
  const [filter, setFilter] = useState(dateRange30());
  const reportEnabled = useFeatureFlag(FeatureFlags.EventReport);
  const eventReportDrilldownFilterEnabled = useFeatureFlag(FeatureFlags.EventReportFilter);

  const { domElements, registerBreadcrumb, hasBreadcrumbsToDisplay } = usePerformanceBreadcrumbs("Summary");

  useEffect(() => {
    acceptHandlers?.({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      resetExports();
    };
  }, [resetExports]);

  const handleExport = useCallback(() => {
    exportInfo?.method(...exportInfo.args);
  }, [exportInfo]);

  const showFilter = useMemo(() => {
    if (hasBreadcrumbsToDisplay && !eventReportDrilldownFilterEnabled) {
      return null;
    }
    return (
      <div className={styles.eventActions}>
        <ReportingFilter currentFilter={filter} callback={({ dateFrom, dateTo }) => setFilter({ dateFrom, dateTo })} />
        {isExportEnabled && (
          <ReportingExport onClick={handleExport} currentlyExporting={exportStatus} disabled={!hasDataToExport} />
        )}
      </div>
    );
  }, [eventReportDrilldownFilterEnabled, filter, hasBreadcrumbsToDisplay, exportStatus, handleExport, isExportEnabled, hasDataToExport]);

  if (!reportEnabled) {
    return <ReportUnavailable />;
  }

  return (
    <div className={`${styles.performanceRoot} performanceRoot`}>
      <header className={styles.eventPerformanceHeader}>
        {!hasBreadcrumbsToDisplay ? (
          <h2 className={styles.eventTitle}>Summary</h2>
        ) : (
          <div className={styles.breadCrumbs}>{domElements}</div>
        )}
        {showFilter}
      </header>

      <div className={`${styles.performanceRoot} performanceRoot`}>
        <EventPerformanceBody isRecurring={isRecurring} eventId={eventId} eventTitle={eventTitle} registerBreadcrumb={registerBreadcrumb} filter={filter} />
      </div>
    </div>
  );
};


const mapStateToProps = (state: RootState) => {
  return {
    exportInfo: selectExportInfo(state),
    exportStatus: selectExporting(state),
    isExportEnabled: selectIsExportActive(state),
    hasDataToExport: selectHasDataToExport(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    resetExports: bindAction(resetExport, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EventPerformance);
