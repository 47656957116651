import { FC, useCallback, useMemo, useState } from "react";
import { bindActionCreators } from "redux";
import { connect, ConnectedProps } from "react-redux";
import PublishValidationInfo from "../../../../../components/publishValidationInfo/PublishValidationInfo";
import { DetailsHeader } from "../../../../../components/sectionHeader";
import { OutstandingDetailsHeaderOwnProps } from "../../../../../components/sectionHeader/detailsHeader/types";
import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { getPeopleEntityLabel } from "../../../../../utils/peopleAssignmentModalUtils";
import * as eventEntityStateActions from "../../state/actions/eventEntityStateActions";

import SelectedItemsLabel from "../../../../../components/sectionHeader/selectedItemsLabel/SelectedItemsLabel";
import { useNavigate } from "react-router-dom";
import { IObservable } from "interfaces";
import { deleteReminders } from "../../state/thunks/remindersThunk";
import { DeleteLinkButton } from "components";
import { fetchAssociatedPacks } from "features/Licensing/Packs/state/thunks/associatedPacksThunk";
import { AddPacksButton } from "features/Licensing/Packs/AssociatedPacks/AddPacksButton";
import { useRtn } from "hooks/useRtn";
import { AddContentToPackSuccess } from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { RolePermissions } from "enums";
import Restricted from "features/Application/Restricted";
import { noop } from "lodash";
import AddAssetsToPackModal from "components/modal/AddAssetsToPacksModal/AddAssetsToPackModal";
import EntityTypes from "../../../../../enums/eventTypes";

interface EditEventDetailsHeaderContainerPropsBase extends OutstandingDetailsHeaderOwnProps {
  eventId: number;
  eventsOverviewUrl: string;
  onErrorClick: () => void;
  onPublish: () => void;
  back: () => void;
  isRemindersTab: boolean;
  onTriggerRemoveRemindersObserver: IObservable<(onRemoveConfirm: () => void, selectedItemsCount: number) => void>;
  canAddPacks: boolean;
  showAddPacksButton: boolean;
  permissionPredicate: (userPermissions: RolePermissions[], permissionsList?: RolePermissions[]) => boolean;
}

export type EditEventDetailsHeaderProps = EditEventDetailsHeaderContainerPropsBase & PropsFromRedux;

const events = [AddContentToPackSuccess];

export const EditEventDetailsHeader: FC<EditEventDetailsHeaderProps> = (props: EditEventDetailsHeaderProps) => {
  const {
    eventId,
    title,
    selectedUserIds,
    selectedGroupIds,
    isRemindersTab,
    onTriggerRemoveRemindersObserver,
    selectedReminders,
    anyRemindersSelected,
    deleteReminders,
    canAddPacks,
    showAddPacksButton,
    fetchAssociatedPacks,
    permissionPredicate
  } = props;

  const [packsModalShown, setPacksModalShown] = useState(false);
  const renderAddPackButton = (hasAnyPermission: boolean) => (
    <AddPacksButton disabled={!canAddPacks} hasPermission={hasAnyPermission} onClick={() => setPacksModalShown(true)} />
  );

  const fetchPacks = useCallback(() => fetchAssociatedPacks(EntityTypes.ExternalEvent, eventId), [eventId, fetchAssociatedPacks]);

  useRtn(events, fetchPacks);


  const navigate = useNavigate();

  const shouldRenderDeleteRemindersButton = useMemo<boolean>(
    () => isRemindersTab && anyRemindersSelected,
    [isRemindersTab, anyRemindersSelected],
  );

  const renderCustomHeader = () => {
    const hasActiveErrors = !props.sessionsResolved;
    return (
      <>
        {shouldRenderDeleteRemindersButton ? (
          <DeleteLinkButton onClick={onRemoveReminders} />
        ) : (
          props.sessionsErrorMode && (
            <PublishValidationInfo
              unableToPublish={hasActiveErrors}
              readyToPublish={!hasActiveErrors}
              onErrorButtonClick={props.onErrorClick}
            />
          )
        )}
      </>
    );
  };

  const onClose = () => navigate(props.eventsOverviewUrl);
  const onEdit = () => props.eventEntityStateActions.fetchDraftEventEntity(eventId);
  const onRemoveReminders = useCallback(() => {
    onTriggerRemoveRemindersObserver.notify(() => {
      deleteReminders(
        eventId,
        selectedReminders.map((reminder) => reminder.id),
      );
    }, selectedReminders.length);
  }, [onTriggerRemoveRemindersObserver, deleteReminders, eventId, selectedReminders]);

  const getHeaderTitle = useCallback(() => {
    const selectedPeopleCount = selectedUserIds.length + selectedGroupIds.length;

    if (selectedPeopleCount || selectedReminders.length) {
      const itemName = selectedPeopleCount
        ? getPeopleEntityLabel(selectedUserIds.length, selectedGroupIds.length)
        : "Reminder";
      const selectedItemsAmount = selectedPeopleCount ? selectedPeopleCount : selectedReminders.length;
      return (
        <SelectedItemsLabel
          itemName={itemName}
          selectedItemsAmount={selectedItemsAmount}
          shouldPluralize={selectedItemsAmount > 1}
        />
      );
    } else {
      return title;
    }
  }, [title, selectedUserIds, selectedGroupIds, selectedReminders]);

  return (
    <DetailsHeader
      title={getHeaderTitle()}
      pageTitle={title}
      titleForGA="Event Details"
      backButton={props.back || props.eventsOverviewUrl}
      defaultURL={props.eventsOverviewUrl}
      canBeEdited={props.canBeEdited}
      crudPermission={props.crudPermission}
      canBePublished={props.canBePublished}
      isRevertDisabled={props.isRevertDisabled}
      isRevertVisible={props.isRevertVisible}
      invalidFormDetails={props.invalidFormDetails}
      publishedStatus={props.publishedStatus}
      entityStateActions={{
        onClose: onClose,
        onPublish: props.onPublish,
        onEdit: onEdit,
        onRevert: props.entityStateActions.onRevert,
      }}
      hideEntityStateButtons={props.hideEntityStateButtons || shouldRenderDeleteRemindersButton}
      isEllipsisMenuDisabled={props.isEllipsisMenuDisabled}
      actionButtons={props.actionButtons}
    >
      {(props.hideEntityStateButtons && shouldRenderDeleteRemindersButton) || renderCustomHeader()}
      {showAddPacksButton && (
        <Restricted
          permissions={[RolePermissions.AssetsManage, RolePermissions.AssetsCreate, RolePermissions.PacksManage]}
          renderContent={(hasanyPermission) => renderAddPackButton(hasanyPermission)}
          permissionPredicate={permissionPredicate}
        />
      )}
      <AddAssetsToPackModal
        showModal={packsModalShown}
        onClose={() => setPacksModalShown(false)}
        selectedItemIds={[eventId]}
        contentType={EntityTypes.ExternalEvent}
        onComplete={noop}
      />
    </DetailsHeader>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const events = state.library.events;
  const reminders = events.eventReminders;
  const eventValidatorReducer = events.eventSessionsValidationReducer;
  return {
    sessionsResolved: eventValidatorReducer.resolved,
    sessionsErrorMode: eventValidatorReducer.errorMode,
    selectedUserIds: events.users.selectedIds,
    selectedGroupIds: events.groups.selectedIds,
    selectedUsers: events.users.selectedItems,
    selectedGroups: events.groups.selectedItems,
    selectedReminders: reminders.selectedItems,
    anyRemindersSelected: reminders.selectedItems.length > 0,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchAssociatedPacks: bindActionCreators(fetchAssociatedPacks, dispatch),
  eventEntityStateActions: bindActionCreators(eventEntityStateActions, dispatch),
  deleteReminders: bindActionCreators(deleteReminders, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditEventDetailsHeader);
