import { FC, useCallback, useEffect, useRef } from "react";
import { bindActionCreators } from "@reduxjs/toolkit";
import { connect, ConnectedProps } from "react-redux";
import { SearchInput } from "../../../../../../../components";
import { isEmpty } from "lodash";

import UsersListContainer from "./UsersListContainer/UsersListContainer";
import Restricted from "../../../../../../Application/Restricted";

import { AssignmentPeopleContext, RolePermissions, SortingDirection } from "../../../../../../../enums";
import { EventAssignmentColumns, EventColumnToParamMap, getEventUserColumnOptions } from "./getColumnOptions";
import { AppDispatch, RootState } from "../../../../../../Application/globaltypes/redux";

import { DEFAULT_REQUEST_PARAMS } from "../../../../../PeopleAssignments/Overview/helpers/constants";
import { fetchEventUsers } from "../../../../state/thunks/eventUsersThunk";
import { resetSelectedItems } from "../../../../state/slices/eventUsersSlice";
import { FiltersMap } from "../../../../../../../utils/filterUtils";
import { getFilterOptions as getFilterOptionsAction } from "../../../../../../People/Users/UsersOverview/state/filterActionCreators";
import {
  setFilter as setFilterAction,
  resetFilter as resetFilterAction,
  setSearch as setSearchAction,
  resetSearch as resetSearchAction,
} from "../../../../state/actions/eventsUserAssignmentActions";
import { Filters } from "../../../../../../../utils/queryUtils";
import UsersFilterForm from "../../../../../../../components/filterForms/UsersFilterForm/UsersFilterForm";
import ContentUsersNoResults from "../../../../../Common/Fields/ContentUsersNoResults";
import { useContentAssignmentsPermissions } from "../../../../../../../hooks/useContentAssignmentsPermissions";

export interface UsersOverviewPropsBase {
  eventId: number;
  customHeaderContent: React.ReactElement;
}

export type UsersOverviewProps = UsersOverviewPropsBase & PropsFromRedux;

export const UsersOverview: FC<UsersOverviewProps> = (props: UsersOverviewProps) => {
  const {
    eventId,
    customHeaderContent,
    filterOptions,
    fetchUsers,
    onUnmount,
    setSearch,
    resetSearch,
    setFilter,
    resetFilter,
    getFilterOptions,
  } = props;
  const reloadListItems = useRef<(enableSorting: boolean) => void>();

  // @ts-ignore
  const renderFilterForm = (): React.ReactElement => <UsersFilterForm />;
  const renderSearchInput = (): React.ReactElement => (
    <SearchInput placeholder="Search for People..." onChange={onSearchChanged} />
  );

  useEffect(
    () => () => {
      resetSearch();
      onUnmount();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const getEventUsers = (
    skip: number = DEFAULT_REQUEST_PARAMS.skip,
    top: number = DEFAULT_REQUEST_PARAMS.top,
    orderBy: string = EventAssignmentColumns.Name,
    sortingDirection: SortingDirection = DEFAULT_REQUEST_PARAMS.sortingDirection,
    appliedFilter: FiltersMap = DEFAULT_REQUEST_PARAMS.appliedFilter,
  ): void => {
    const sortingColumn = EventColumnToParamMap[orderBy.toLowerCase() as Lowercase<EventAssignmentColumns>];
    fetchUsers({
      entityId: eventId,
      skip,
      top,
      sortingColumn,
      sortingDirection,
      filter: appliedFilter,
      term: props.search,
    });
  };

  const getFilterOptionsMemo = useCallback(() => getFilterOptions(), [getFilterOptions]);
  const applyFilterMemo = useCallback((filter: Filters) => setFilter(filter), [setFilter]);
  const resetFilterMemo = useCallback(() => resetFilter(), [resetFilter]);

  const createReloadListItems = (reloadListItemsFunc: (enableSorting: boolean) => void) => {
    reloadListItems.current = reloadListItemsFunc;
  };

  const onSearchChanged = (search: string): void => {
    setSearch(search);
    reloadListItems.current?.(isEmpty(search));
  };

  const permissionPredicate = useContentAssignmentsPermissions(
    AssignmentPeopleContext.User,
    RolePermissions.EventsManage,
  );

  return (
    <Restricted
      permissions={[]}
      permissionPredicate={permissionPredicate}
      renderContent={() => (
        <UsersListContainer
          defaultSortingColumnName={EventAssignmentColumns.Name}
          sortingDirection={SortingDirection.Ascending}
          columnOptions={getEventUserColumnOptions()}
          getFilterForm={renderFilterForm}
          applyFilter={applyFilterMemo /* istanbul ignore next  () => {}*/} //NOSONAR
          appliedFilter={props.appliedFilter}
          filterOptions={filterOptions}
          filterOptionsLoading={filterOptions.isLoading}
          resetFilter={resetFilterMemo /* istanbul ignore next  () => {}*/} //NOSONAR}
          getFilterOptions={getFilterOptionsMemo}
          fetchData={getEventUsers}
          renderSearch={renderSearchInput}
          setReloadListItems={createReloadListItems}
          customHeaderContent={customHeaderContent}
          isSelectDisabled={() => true}
          noResultsContent={<ContentUsersNoResults filtered={!isEmpty(props.appliedFilter) || !!props.search} />}
        />
      )}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  accountId: state.userProfile.accountId,
  filterOptions: state.people.usersOverview.filterOptions,
  appliedFilter: state.library.events.userAssignment.filter.appliedFilter,
  search: state.library.events.userAssignment.search,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchUsers: bindActionCreators(fetchEventUsers, dispatch),
  getFilterOptions: () => dispatch(getFilterOptionsAction()),
  onUnmount: () => dispatch(resetSelectedItems()),
  setFilter: (filter: Filters) => dispatch(setFilterAction(filter)),
  resetFilter: () => dispatch(resetFilterAction()),
  setSearch: (search: string) => dispatch(setSearchAction(search)),
  resetSearch: () => dispatch(resetSearchAction()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UsersOverview);
