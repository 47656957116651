import { FC } from "react";
import Segments from "../../../components/navigation/segments/Segments";
import { DetailsHeader } from "../../../components/sectionHeader";
import { Navigate, Route, Routes } from "react-router-dom";
import { Packs } from "..";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";

const pages = [{ to: "", label: "Packs" }];

export const PackOverviewReports: FC = () => {
  const isPackReportingOverviewEnabled = !!useFeatureFlag(FeatureFlags.PackReportingOverview);
  return (
    <div className={"nested-content"}>
      <DetailsHeader title={isPackReportingOverviewEnabled ? "Packs & Add-Ons" : "Packs and Licensing"} />
      {isPackReportingOverviewEnabled && (
        <Segments to={"/reporting/packs"}>
          {pages.map((page, i) => (
            <Segments.Segment key={i} {...page} />
          ))}
        </Segments>
      )}
      <Routes>
        <Route path="/" element={<Packs />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
};
