import dataService from "features/Application/services/dataServices/dataService";
import { EnrollmentRule, isDefaultRuleSet } from "features/People/Groups/AddEditGroupForms/EnrollmentBuilder/utils";
import { isEmpty } from "lodash";
import enrollmentRulesUtils from "utils/enrollmentRulesUtils";
import { setEnrollmentRules, setIsLoading } from "../GroupTemplateSlice";
import { AppDispatch } from "features/Application/globaltypes/redux";

export const saveRules = async (rules: EnrollmentRule[], groupId: number) => {
  if (!isEmpty(rules) && !isDefaultRuleSet(rules)) {
    const enrollmentRulesInServerFormat = enrollmentRulesUtils.mapRulesToSeverFormat(rules);

    await dataService.updateGroupEnrollmentRules(groupId, enrollmentRulesInServerFormat);
  }
};

export const saveRulesAction = (rules: EnrollmentRule[], groupId: number) => async (dispatch: AppDispatch) => {
  dispatch(setIsLoading(true));
  try {
    await saveRules(rules, groupId);
    dispatch(setEnrollmentRules(rules));
  } catch (error) {}
  dispatch(setIsLoading(false));
};
