import axios from "axios";
import { TagsToContent } from "../../../../interfaces";
import { WithPagedDataV2, GetPagedDataByIdRequestV3 } from "../../../../interfaces/getPagedDataRequest";
import { formatFiltersV2api, mapToV2Filters, serializeArrayAndFilterNullable } from "../../../../utils/queryUtils";
import { escapeTerm } from "../../../../utils/searchUtils";
import KnownHttpHeaders from "../../../Application/services/knownHttpHeaders";
import { v2FilterMap } from "../../../People/types";
import { SurveyPayload, UpdateContentPayload } from "../types/models";
import { SaveTagsRequest } from "../types/requests";
import { ISurveysDataService } from "../types/services";
import { EngagementLineData, CardsData, EngagementFunnelData } from "../state/slices/surveyPerformanceSlice";
import { SurveyQuestion, SurveyQuestionAnswer, SurveyPeopleDetails } from "../types/state";
import { BasePerformanceRequestFilterParams } from "../../Common/models";

// For abort previous requests if filter changes
let topLevelRequests = new AbortController();
let answersAbortController = new AbortController();
let peopleDetailsAbortController = new AbortController();
let exportAbortController = new AbortController();

const surveysDataService: ISurveysDataService = {
  getPublishedSurveysByIds(ids: string[], showPurchased: boolean) {
    const config = {
      params: {
        showPurchased,
        filter: ids.map((x) => `(PrimaryId eq ${x})`).join(" or "),
      },
    };

    return axios.get("/api/assets/surveys/published", config);
  },

  updateSurveyAsync(data: SurveyPayload) {
    return axios.put(`/api/assets/surveys/${data.id}`, data);
  },

  updateContentAsync(data: UpdateContentPayload) {
    return axios.put(`/api/assets/surveys/${data.id}/questions`, data);
  },

  getSurveyAsync(id: number) {
    return axios.get(`/api/assets/surveys/${id}`);
  },

  getSurveyContentAsync(id: number) {
    return axios.get(`/api/assets/surveys/${id}/questions`);
  },

  getSurveyPublishedQuestions(ids: number[], abortController: AbortController) {
    return axios.get("/api/v4/assets/surveys/questions/published", {
      params: {
        ids,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
      signal: abortController.signal,
    });
  },

  async getSurveyUsersAsyncV2<T>(entityId: number, request: WithPagedDataV2<T>) {
    const config = {
      params: request,
      paramsSerializer: serializeArrayAndFilterNullable,
    };

    const response = await axios.get(`/api/v4/content/survey/${entityId}/users`, config);

    return {
      items: response.data,
      count: parseInt(response.headers[KnownHttpHeaders.RecordsCount]),
    };
  },

  async getSurveyGroupsAsyncV2(request: GetPagedDataByIdRequestV3) {
    const { entityId, skip, top, term, filter } = request;

    const response = await axios.get(`/api/v4/content/survey/${entityId}/groups`, {
      params: {
        skip,
        top,
        ...formatFiltersV2api(mapToV2Filters(filter!, v2FilterMap)),
        term: escapeTerm(term),
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    });

    return {
      items: response.data,
      count: parseInt(response.headers[KnownHttpHeaders.RecordsCount]),
    };
  },

  async deleteSurveyAsync(ids: number[]) {
    const result = await axios.delete("/api/assets/surveys", {
      data: { surveyIds: ids.map((id) => id.toString()) },
    });
    return result.data;
  },

  async duplicateSurveyAsync(ids: number[]) {
    const result = await axios.post("/api/assets/surveys/duplicate", { surveyIds: ids.map((id) => id.toString()) });
    return result.data;
  },

  getPublishersFilterOptionsAsync() {
    return axios.get("/api/assets/surveys/publishers");
  },

  getSurveysTagsAsync(showPurchased: boolean) {
    return axios.get("/api/v2/assets/surveys/tags", { params: { showPurchased } });
  },
  getSurveyDependenciesAsync(id: number) {
    return axios.get(`api/assets/surveys/${id}/dependencies`);
  },
  addSurveyTags(data: TagsToContent) {
    return axios.post(`/api/assets/surveys/tags`, data);
  },
  saveTags(request: SaveTagsRequest) {
    return axios.put(`/api/assets/surveys/${request.id}/tags`, {
      tagType: request.tagType,
      tags: request.tags,
    });
  },

  // Performance tab requests
  async getSurveyEngagementLine(surveyId: number, filterParams: BasePerformanceRequestFilterParams) {
    const response = await axios.get<EngagementLineData>("/api/reports/v2/surveys/linechart", {
      params: {
        surveyId,
        period: "DAY",
        ...filterParams,
      },
      signal: topLevelRequests.signal,
    });
    return response.data;
  },

  async getSurveyEngagementFunnel(surveyId: number, filterParams: BasePerformanceRequestFilterParams) {
    const response = await axios.get<EngagementFunnelData>("/api/reports/v2/surveys/engagement", {
      params: {
        surveyId,
        ...filterParams,
      },
      signal: topLevelRequests.signal,
    });
    return response.data;
  },

  async getSurveyQuestionsData(surveyId: number, filterParams: BasePerformanceRequestFilterParams) {
    const response = await axios.get<SurveyQuestion[]>("/api/reports/v2/surveys/performance", {
      params: {
        surveyId,
        ...filterParams,
      },
      signal: topLevelRequests.signal,
    });
    return response.data;
  },

  async getSurveyCards(surveyId: number, filterParams: BasePerformanceRequestFilterParams) {
    const response = await axios.get<CardsData>("/api/reports/v2/surveys/cards", {
      params: {
        surveyId,
        ...filterParams,
      },
      signal: topLevelRequests.signal,
    });
    return response.data;
  },

  async getSurveyQuestionDetails(
    surveyId: number,
    questionId: string,
    filterParams: BasePerformanceRequestFilterParams,
  ) {
    const response = await axios.get<[SurveyQuestion]>("/api/reports/v2/surveys/performance-question", {
      params: { surveyId, questionId, ...filterParams },
    });
    return response.data;
  },

  async getSurveyAnswerDetails(
    surveyId: number,
    questionId: string,
    answerId: string,
    filterParams: BasePerformanceRequestFilterParams,
  ): Promise<SurveyQuestionAnswer> {
    answersAbortController.abort();
    answersAbortController = new AbortController();

    const response = await axios.get<SurveyQuestionAnswer>("/api/reports/v2/surveys/performance-answer", {
      params: { surveyId, questionId, answerId, ...filterParams },
      signal: answersAbortController.signal,
    });

    return response.data;
  },

  async getSurveyPeopleDetails(
    surveyId: number,
    filterParams: BasePerformanceRequestFilterParams,
  ): Promise<SurveyPeopleDetails[]> {
    peopleDetailsAbortController.abort();
    peopleDetailsAbortController = new AbortController();

    const response = await axios.get<SurveyPeopleDetails[]>("/api/reports/v2/surveys/people-details", {
      params: { surveyId, ...filterParams },
      signal: peopleDetailsAbortController.signal,
    });

    return response.data;
  },

  async getExport(surveyId: number, filterParams: BasePerformanceRequestFilterParams) {
    exportAbortController.abort();
    exportAbortController = new AbortController();
    return axios.get("/api/dataexport/v2/surveys/user-progress", {
      responseType: "blob",
      params: {
        surveyId,
        ...filterParams,
      },
      signal: exportAbortController.signal,
    });
  },

  // On filter change
  cancelTopLevelPerformanceRequests() {
    topLevelRequests.abort();
    topLevelRequests = new AbortController();
  },

  cancelSurveyAnswerDetails() {
    answersAbortController.abort();
  },
};

export default surveysDataService;
