import { PayloadAction } from "@reduxjs/toolkit";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "enums";
import { FetchingItemsState, createFetchingItemsSlice } from "features/Application/slices/createFetchingItemsSlice";
import { CustomerView } from "../types/models";

export interface TemplateCustomersState extends FetchingItemsState<CustomerView> {
  customersToAdd: number[];
  search: string;
  selectedIds: number[];
}

export const initialState: TemplateCustomersState = {
  isLoading: false,
  items: [],
  itemsCount: 0,
  customersToAdd: [],
  selectedIds: [],
  search: "",
};

const slice = createFetchingItemsSlice({
  initialState,
  namePayload: {
    entity: ReducerEntityPrefixTypes.TemplateGroups,
    name: "Customers",
    namespace: ReducerNamespaceTypes.People,
  },
  reducers: {
    setCustomersToAdd: (state, action: PayloadAction<number[]>) => {
      state.customersToAdd = action.payload;
    },
    setSelectedIds: (state, action: PayloadAction<number[]>) => {
      state.selectedIds = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
  },
});

export const { fetchBegin, fetchFailure, fetchSuccess, reset, setCustomersToAdd, setSelectedIds, setSearch } =
  slice.actions;

export default slice.reducer;
