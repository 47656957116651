import axios from "axios";
import { SortingDirection } from "../../../../enums";
import StatusCode from "../../../../enums/httpStatusCodes";
import environmentConfig from "../../../../configuration/environmentConfig";
import flowsDataService from "../../../Library/Flows/services/flowsDataService";
import videoDataService from "../../../Library/Videos/services/videoDataService";
import groupsDataService from "../../../People/services/groupsDataService";
import blobDataService from "./blobDataService";
import midnightService from "../midnightService/midnightService";
import usersDataService from "./usersDataService";
import KnownHttpHeaders from "../knownHttpHeaders";
import threatDefenceDataService from "../../../Library/SimulatedPhishing/EmailTemplates/services/threatDefenceEmailTemplatesDataService";
import notificationSettingsService from "../../../SystemNotifications/services/notificationSettingsService";
import { templateService } from "../../../SystemNotifications/services/templateService";

const initDefaultHeaders = () => {
  axios.defaults.baseURL = environmentConfig.apiUrl;
  axios.defaults.headers = {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
  };
};

initDefaultHeaders();

const dataService = {
  registerAccessTokenFactory(getAccessToken) {
    axios.interceptors.request.use(async (config) => {
      const token = await getAccessToken();

      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }

      return config;
    });
  },

  registerMoboHeaderInterceptor(moboId) {
    axios.interceptors.request.use((config) => {
      if (config.headers[KnownHttpHeaders.MoboId] === undefined) config.headers[KnownHttpHeaders.MoboId] = moboId; //NOSONAR

      return config;
    });
  },

  registerNotFoundInterceptor(cb) {
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        const response = error.response;

        if (response && response.status === StatusCode.NotFound && response.config.method.toLowerCase() !== "head") {
          cb && cb();
        }

        return Promise.reject(error);
      },
    );
  },

  async getVendors() {
    return axios.get("/api/collections/publishers");
  },

  async getVendorCollections(vendorId) {
    return axios.get(`/api/content/vendors/${vendorId}/collections`);
  },

  async getVendorAssets(vendorId, loadCount, skipCount) {
    const config = {
      params: {
        top: loadCount,
      },
    };

    if (skipCount !== 0) {
      config.params.skip = skipCount;
    }

    return axios.get(`/api/vendors/${vendorId}/items`, config);
  },

  async getCountries() {
    const config = {
      params: {
        orderBy: `name ${SortingDirection.Ascending}`,
      },
    };

    return axios.get("/api/countries", config);
  },

  async getItemsToAdd(collectionId, loadCount, skipCount) {
    const config = {
      params: {
        top: loadCount,
        skip: skipCount,
      },
    };

    return axios.get(`/api/collections/${collectionId}/itemsToAdd`, config);
  },

  async updateRoleAssignment(userId, roleIds) {
    return axios.post(`/api/users/${userId}/roles`, {
      roleIds,
    });
  },

  async updateGroupAssignment(userId, groupIds) {
    return axios.put(`/api/users/${userId}/groups`, {
      groupIds,
    });
  },
};

export default Object.assign(
  dataService,
  flowsDataService,
  videoDataService,
  groupsDataService,
  blobDataService,
  midnightService,
  usersDataService,
  threatDefenceDataService,
  {
    systemNotifications: {
      settings: notificationSettingsService,
      templates: templateService,
    },
  },
);
