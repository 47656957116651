import { Dispatch, bindActionCreators } from "@reduxjs/toolkit";
import cn from "classnames";
import { scaleLinear } from "d3";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import { RootState } from "../../../Application/globaltypes/redux";
import { RegisterBreadcrumbCallback } from "../../Common/Hooks/usePerformanceBreadcrumbs";
import dataService from "../services/surveysDataService";
import {
  resetSelectedAnswer,
  selectCardsData,
  selectCardsValue,
  selectEngagementFunnelData,
  selectEngagementFunnelValue,
  selectEngagementHorizontalData,
  selectEngagementLineCompleted,
  selectEngagementLineCumulativeCompleted,
  selectEngagementLineData,
  selectEngagementLineDates,
  selectOtherOptionData,
  selectPeopleDetailsStatus,
  selectPeopleDetailsValue,
  selectSurveyQuestionsData,
  selectSurveyQuestionsValue,
} from "../state/slices/surveyPerformanceSlice";
import * as surveyPerformanceActions from "../state/thunks/surveyPerformanceThunk";

import { timeScale } from "components/charts/shared/bsi-time-scale";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import { FeatureFlags } from "featureFlags";
import { setExportAction } from "features/Reporting/state/export/exportSlice";
import { reset, setIsNavigationEnabled, setIsReportEnabled } from "features/Reporting/state/toolbar/toolbarSlice";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { bindAction } from "interfaces";
import CardReporting from "../../../../components/cards/CardReporting/CardReporting";
import {
  ChartWrapper,
  HorizontalBarChart,
  LineChart,
  TickValueType,
  getDateFormatByCount,
} from "../../../../components/charts";
import { RequestStatusRenderer } from "../../../../components/requestStatsRenderer/RequestStatusRenderer";
import {
  PerformanceFilter,
  lineChartMargins,
  completes,
  completesColor,
  dailyActivity,
  formattedAverageTime, lineChartTitles,
  noBarData,
  noData,
  roundToTwoDigits,
  starts,
  totalActivity,
  transitionTime,
  validLineData
} from "../../Common/utils/performanceUtils";
import { SurveyQuestion, SurveyQuestionAnswer } from "../types/state";
import { SurveyPerformanceList } from "./SurveyPerformanceList/SurveyPerformanceList";
import SurveyPerformanceQuestionDrillDown from "./SurveyPerformanceQuestionDrillDown/SurveyPerformanceQuestionDrillDown";

import "../../Common/utils/performanceSCSSUtils.scss";
import "./SurveyPerformance.scss";
import SurveyPerformancePeopleComponent from "./SurveyPerformancePeople";
import { sharedAccountReportingHorizontalBarProps } from "features/Reporting/Content/shared";

export interface Props extends PropsFromRedux {
  surveyId: number;
  surveyTitle: string;
  flowId?: number;
  registerBreadcrumb: RegisterBreadcrumbCallback;
  dateFilter: PerformanceFilter;
  hideDrilldownHeader?: boolean;
}

export type CardsDataFormatted = {
  AverageTime: string;
  CompletionRate: string;
  Completions: number;
  NotStarted: number;
};

export type CardsDataFormattedV2 = {
  AverageTime: string;
  CompletionRate: number;
  Completions: number;
  Started: number;
};

export const SurveyPerformanceBody: FC<Props> = ({
  surveyId,
  surveyTitle,
  flowId,
  registerBreadcrumb,
  dateFilter,
  hideDrilldownHeader,
  setExportAction,
  actions,
  // Removes drilldown table when selected question changes
  resetSelectedAnswer,

  engagementLineStatus,
  engagementLineDates,
  engagementLineCompleted,
  engagementLineCumulativeCompleted,

  engagementFunnelStatus,
  engagementFunnelData,
  engagementBarData,

  surveyQuestionsStatus,
  surveyQuestionsData,

  cardsData,
  cardsStatus,

  otherOptionsState,
  setIsReportEnabled,
  resetIsReportEnabled,
  setIsNavigationEnabled,
}) => {
  const [newLineChartState, setNewLineChartState] = useState(totalActivity);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number>(); // Used for fetching other answer
  const [selectedQuestion, setSelectedQuestion] = useState<SurveyQuestion>();
  const [selectedAnswer, setSelectedAnswer] = useState<SurveyQuestionAnswer>();
  const reportEnabled = useFeatureFlag(FeatureFlags.SurveyReport);

  const breadcrumbCallback = useCallback(() => {
    setSelectedQuestionIndex(undefined);
    setSelectedQuestion(undefined);
    setSelectedAnswer(undefined);
  }, []);

  // Setting up correct export method if send entity is changed
  useEffect(() => {
    setExportAction({
      method: actions.doExport,
      args: [surveyId, { ...dateFilter, flowId }, surveyTitle],
      isExportEnabled: true,
    });
  }, [actions.doExport, dateFilter, surveyId, setExportAction, flowId, surveyTitle]);

  useEffect(() => {
    setIsReportEnabled(reportEnabled);

    return () => {
      resetIsReportEnabled();
    };
  }, [reportEnabled, setIsReportEnabled, resetIsReportEnabled]);

  useEffect(() => {
    if (reportEnabled) {
      actions.fetchEngagementLineData(surveyId, { ...dateFilter, flowId });
      actions.fetchEngagementFunnelData(surveyId, { ...dateFilter, flowId });
      actions.fetchCardsData(surveyId, { ...dateFilter, flowId });
      actions.fetchSurveyQuestionsData(surveyId, { ...dateFilter, flowId });
      actions.fetchPeopleDetails(surveyId, { ...dateFilter, flowId });

      return () => {
        dataService.cancelTopLevelPerformanceRequests();
      };
    }
  }, [actions, dateFilter, surveyId, flowId, reportEnabled]);

  const getQuestionIndex = (question: SurveyQuestion): number => surveyQuestionsData.indexOf(question);

  const handleListQuestionSelection = (question: SurveyQuestion) => {
    registerBreadcrumb({ text: question.QuestionText, action: breadcrumbCallback });
    setSelectedQuestion(question);
    setSelectedQuestionIndex(getQuestionIndex(question));
  };

  const preparePrevQuestion = (selectedQuestion: SurveyQuestion): SurveyQuestion => {
    const previousIndex = getQuestionIndex(selectedQuestion) - 1;
    const wrappedIndex = (previousIndex + surveyQuestionsData.length) % surveyQuestionsData.length;
    setSelectedQuestionIndex(wrappedIndex);
    return surveyQuestionsData[wrappedIndex];
  };

  const getPrevQuestion = (): void => {
    if (selectedQuestion) {
      const prev = preparePrevQuestion(selectedQuestion);
      registerBreadcrumb({ text: prev.QuestionText, action: breadcrumbCallback }, true);
      setSelectedQuestion(prev);
      setSelectedAnswer(undefined);
      resetSelectedAnswer();
    }
  };

  const prepareNextQuestion = (selectedQuestion: SurveyQuestion): SurveyQuestion => {
    const nextIndex = getQuestionIndex(selectedQuestion) + 1;
    const wrappedIndex = nextIndex % surveyQuestionsData.length;
    setSelectedQuestionIndex(wrappedIndex);
    return surveyQuestionsData[wrappedIndex];
  };

  const getNextQuestion = (): void => {
    if (selectedQuestion) {
      const next = prepareNextQuestion(selectedQuestion);
      registerBreadcrumb({ text: next.QuestionText, action: breadcrumbCallback }, true);
      setSelectedQuestion(next);
      setSelectedAnswer(undefined);
      resetSelectedAnswer();
    }
  };

  const selectedQuestionOtherInformation = useMemo(
    () =>
      selectedQuestionIndex && otherOptionsState && selectedQuestionIndex in otherOptionsState
        ? otherOptionsState[selectedQuestionIndex]
        : undefined,
    [otherOptionsState, selectedQuestionIndex],
  );

  const selectionExists = useMemo<boolean>(() => {
    setIsNavigationEnabled(!selectedQuestion);
    return !!selectedQuestion;
  }, [selectedQuestion, setIsNavigationEnabled]);

  const lineInfo = useMemo(
    () => ({
      xData: [engagementLineDates],
      yData: newLineChartState === dailyActivity ? [engagementLineCompleted] : [engagementLineCumulativeCompleted],
      yScaleRatio: scaleLinear,
      yFormatterFunc: ",d",
      colorRange: [completesColor],
    }),
    [engagementLineCompleted, engagementLineCumulativeCompleted, engagementLineDates, newLineChartState],
  );

  const renderDrillDown = (question: SurveyQuestion, answer?: SurveyQuestionAnswer): React.ReactElement => (
    <SurveyPerformanceQuestionDrillDown
      surveyId={surveyId}
      flowId={flowId}
      question={question}
      otherOptionInformation={selectedQuestionOtherInformation}
      filter={dateFilter}
      answer={answer}
      hideHeader={hideDrilldownHeader}
      onSelectAnswer={setSelectedAnswer}
      onNextQuestion={getNextQuestion}
      onPrevQuestion={getPrevQuestion}
    />
  );

  const renderNormalBody = () => (
    <div className="body">
      <div className="performanceChartsSection">
        <div className="graphs">
          <div className={cn("lineChartContainer", "user_chart_container")}>
            <ChartWrapper
              titles={lineChartTitles}
              showLegend
              legendLabels={[completes]}
              onChange={setNewLineChartState}
              colorRange={[completesColor]}
            >
              <RequestStatusRenderer state={engagementLineStatus}>
                {validLineData(lineInfo.yData) ? (
                  <LineChart
                    margins={lineChartMargins}
                    xScaleRatio={timeScale}
                    xFormatterFunc={getDateFormatByCount(engagementLineDates.length)}
                    yTickValueType={TickValueType.IntegersOnly}
                    transitionDuration={transitionTime}
                    {...lineInfo}
                  />
                ) : (
                  noData(dateFilter)
                )}
              </RequestStatusRenderer>
            </ChartWrapper>
          </div>

          <div className={cn("funnelChartContainer", "user_chart_container")}>
            <ChartWrapper
              showLegend={!reportEnabled}
              legendLabels={["Not Started", "Started", "Completed"]}
              titles={["Engagement"]}
            >
              <RequestStatusRenderer state={engagementFunnelStatus}>
                {noBarData(engagementFunnelData.StartedCount, engagementFunnelData.CompletedCount) ? (
                  noData(dateFilter)
                ) : (
                  <HorizontalBarChart
                    {...sharedAccountReportingHorizontalBarProps}
                    data={engagementBarData}
                    domain={[0, Math.max(...engagementBarData.map((e) => e.value))]}
                  />
                )}
              </RequestStatusRenderer>
            </ChartWrapper>
          </div>
        </div>
      </div>
      <div className="performanceCardSection">
        <RequestStatusRenderer state={cardsStatus}>
          {cardsData && (
            <CardReporting
              items={[
                {
                  statDescription: `Total ${starts}`,
                  stat: cardsData.Started,
                  popupHeader: `Total ${starts}`,
                  popupBody: "Surveys started",
                },
                {
                  statDescription: `Total ${completes}`,
                  stat: cardsData.Completions,
                  popupHeader: `Total ${completes}`,
                  popupBody: "Number of completions",
                },
                {
                  statDescription: "Completion Rate",
                  stat: `${roundToTwoDigits(cardsData.CompletionRate * 100)}%`,
                  popupHeader: "Completion Rate",
                  popupBody: "Percentage of users that have completed the survey.",
                  width: "13.75rem",
                },
                {
                  statDescription: "Avg. Completion Time",
                  stat: formattedAverageTime(cardsData.AverageTime),
                  popupHeader: "Avg. Completion Time",
                  popupBody: "Average time to complete survey",
                  width: "7.6rem",
                },
              ]}
            />
          )}
        </RequestStatusRenderer>
      </div>
      <div className="questions-answers-container">
        <RequestStatusRenderer state={surveyQuestionsStatus}>
          <RequestStatusRenderer state={engagementFunnelStatus}>
            {!noBarData(engagementFunnelData.StartedCount, engagementFunnelData.CompletedCount) ? (
              <SurveyPerformanceList
                reportEnabled={reportEnabled}
                questions={surveyQuestionsData}
                selectQuestion={handleListQuestionSelection}
                // Internally this calls selectQuestion
                selectAnswer={setSelectedAnswer}
              />
            ) : (
              noData(dateFilter)
            )}
          </RequestStatusRenderer>
        </RequestStatusRenderer>
      </div>
      <div className="performanceTableSection">
        <SurveyPerformancePeopleComponent surveyId={surveyId} flowId={flowId} filter={dateFilter} />
      </div>
    </div>
  );

  if (!reportEnabled) {
    return <ReportUnavailable />;
  }

  return !selectionExists ? renderNormalBody() : renderDrillDown(selectedQuestion!, selectedAnswer);
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    engagementLineStatus: selectEngagementLineData(state),
    engagementLineDates: selectEngagementLineDates(state),
    engagementLineCompleted: selectEngagementLineCompleted(state),
    engagementLineCumulativeCompleted: selectEngagementLineCumulativeCompleted(state),

    engagementFunnelStatus: selectEngagementFunnelData(state),
    engagementFunnelData: selectEngagementFunnelValue(state),
    engagementBarData: selectEngagementHorizontalData(state),

    surveyQuestionsStatus: selectSurveyQuestionsData(state),
    surveyQuestionsData: selectSurveyQuestionsValue(state),

    cardsStatus: selectCardsData(state),
    cardsData: selectCardsValue(state),

    peopleDetailsStatus: selectPeopleDetailsStatus(state),
    peopleDetailsData: selectPeopleDetailsValue(state),

    otherOptionsState: selectOtherOptionData(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators(surveyPerformanceActions, dispatch),
    resetSelectedAnswer: bindAction(resetSelectedAnswer, dispatch),
    setExportAction: bindAction(setExportAction, dispatch),
    setIsReportEnabled: bindAction(setIsReportEnabled, dispatch),
    resetIsReportEnabled: bindAction(reset, dispatch),
    setIsNavigationEnabled: bindAction(setIsNavigationEnabled, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SurveyPerformanceBody);
