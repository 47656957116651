import { RenderStepType } from "../../../components/assignmentModals/types";
import { TemplateTypes, UsersGroupsContext } from "../../../enums";
import { NotifyStepSettings, PriorityLevel } from "../../../interfaces";
import { Removable, WithId } from "../../../types";

export interface EditPeopleAssignmentsProps {
  entityItems: EntityItemType[];
  isLoading?: boolean;
  usersGroupsContext: UsersGroupsContext;
  showModal: boolean;
  onCloseModal: () => void;
  onAddPeopleConfirm: (
    selectedPeopleToAddIds: number[],
    priorityLevels: PriorityLevel[],
    notificationSettings?: NotifyStepSettings,
  ) => void;
  renderFirstCustomStep: RenderStepType;
  selectedIds: number[];
  contentType: string;
  skipNotifyStep?: boolean;
  notifyStepDisabled?: boolean;
  notifyTemplateType?: TemplateTypes;
}

export type EntityItemType = FlowItem | DefaultItem;

export interface FlowItem extends EntityItems {
  contentType?: ContentType.Flow | null;
  canAutoStart: boolean;
}

export interface DefaultItem extends EntityItems {
  contentType?: null;
}

export interface EntityItems {
  id: number;
  title: string;
  thumbnailUrl: string;
  durationInSeconds?: number;
  hasBeenPublished?: boolean;
  contentType?: ContentType | null;
}

export interface EntityToPeopleAssignments<T = {}> {
  contentType: string;
  peopleType: string;
  peopleId: number;
  contentId: number;
  priority: number;
  dueDate?: number | null;
  daysToComplete?: number | null;
  fixedDueDate?: string | null;
  packIds?: number[];
  payload?: T;
}

export interface AssignPeopleLicense {
  peopleType: string;
  peopleId: number;
  packId: number;
}

export interface FlowPayload {
  autoStart: boolean;
}

export interface EntityToPeopleUnAssignments {
  contentType: string;
  peopleType: string;
  peopleId: number;
  contentId: number;
}

export interface EntityToPeopleRemovePriorities extends EntityToPeopleUnAssignments {}
export interface EntityToContentRemovePriorities extends EntityToPeopleUnAssignments {}

export enum PeopleType {
  User = "user",
  Group = "group",
}

export enum ContentType {
  Survey = "survey",
  Video = "video",
  Flow = "flow",
  Assessment = "assessment",
  Event = "externalEvent",
  Pdf = "pdf",
}

export interface PeopleAssignment extends Removable, WithId {
  isInherited?: boolean;
  permissionIssuedDate: Date;
  priorityLevelId: number;
  allowRemovePriority?: boolean;
  dueDate?: Date | string;
  daysToComplete?: number;
  firstName?: string;
  lastName?: string;
}

export interface GroupPeopleAssignment extends PeopleAssignment {
  name?: string;
}

export interface PeopleAssignmentsHandlers {
  onRemove: (people: { [key in PeopleType]?: number[] }) => void;
  onEditPriority?: (people: { [key in PeopleType]?: PeopleAssignment[] }) => void;
}
