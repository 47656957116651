import { AppDispatch, RootState } from "features/Application/globaltypes/redux";
import { EnrollmentRule } from "features/People/Groups/AddEditGroupForms/EnrollmentBuilder/utils";
import { useEventCallback } from "hooks/useEventCallback";
import { IWizardStep } from "interfaces";
import { useEffect, useRef } from "react";
import { ConnectedProps, connect } from "react-redux";
import { GroupTemplateEnrollment } from "../Details/GroupTemplateEnrollment";
import { setEnrollmentRules, setIsEnrollmentValid } from "../state/GroupTemplateSlice";
import { saveRules } from "../state/thunks/TemplateEnrollmentThunk";

interface Props extends IWizardStep, PropsFromRedux {
  disabled?: boolean;
}

export const CreateEnrollment = (props: Props) => {
  const { disabled, setIsEnrollmentValid, acceptHandlers, setEnrollmentRules, enrollmentRules, groupId, accountType } =
    props;
  const getValuesRef = useRef<() => { enrollmentRules: EnrollmentRule[] }>(() => ({ enrollmentRules }));

  const save = useEventCallback(async () => {
    const enrollmentRules = getValuesRef.current?.().enrollmentRules;
    await saveRules(enrollmentRules, groupId);
  }, [groupId]);

  useEffect(() => {
    const setRules = () => setEnrollmentRules(getValuesRef.current().enrollmentRules);
    acceptHandlers?.({
      onNext: setRules,
      onPrevious: setRules,
      onFinish: save,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GroupTemplateEnrollment
      disabled={disabled}
      bindGetValues={(getValues) => (getValuesRef.current = getValues)}
      enrollmentRules={enrollmentRules}
      onIsValidChange={setIsEnrollmentValid}
      accountType={accountType}
      disablePreventTransitionPrompt
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    groupId: state.people.groupTemplate.base.groupId,
    enrollmentRules: state.people.groupTemplate.base.enrollmentRules,
    accountType: state.userProfile.accountTypeId,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    setIsEnrollmentValid: (isValid: boolean) => dispatch(setIsEnrollmentValid(isValid)),
    setEnrollmentRules: (rules: EnrollmentRule[]) => dispatch(setEnrollmentRules(rules)),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CreateEnrollment);
