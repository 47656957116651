import { FC } from "react";
import { connect, ConnectedProps } from "react-redux";
import { AppDispatch, RootState } from "features/Application/globaltypes/redux";
import { Button } from "components/buttons/button/Button";
import ModalWithSteps from "components/modal/ModalWithSteps";
import AddCustomersModalStep from "./AddCustomersModalStep";
import "./AddCustomersModal.scss";

export interface AddCustomersModalProps {
  showModal: boolean;
  onCancel: () => void;
  onFinish: () => void;
  id: number;
}

export type AddCustomersModalPropsAll = PropsFromRedux & AddCustomersModalProps;

export const AddCustomersModal: FC<AddCustomersModalPropsAll> = ({
  showModal,
  onCancel,
  onFinish,
  id,
  selectedIds,
}) => {
  const renderModalActions = () => {
    return (closeModal: () => void) => (
      <>
        <Button basic className="cancel" content="Cancel" onClick={onCancel} />
        <Button
          primary
          className="confirm"
          content="Finish"
          disabled={selectedIds.length === 0}
          onClick={() => {
            onFinish();
            closeModal();
          }}
        />
      </>
    );
  };

  return (
    <ModalWithSteps
      className="add-customers-to-template-modal"
      scrolling={false}
      isLoading={false}
      showModal={showModal}
      onCancel={onCancel}
    >
      <AddCustomersModalStep id={id} header="Add Customers" renderModalActions={renderModalActions} />
    </ModalWithSteps>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    selectedIds: state.people.groupTemplate.customers.customersToAdd,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddCustomersModal);
