import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";
import { Routes, Route, Navigate } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";
import { AppDispatch, RootState } from "../../../Application/globaltypes/redux";
import { PackDetailsState, Pages } from "./types";
import { ModalSizes, PublishedStatusTypes, RouteNames } from "../../../../enums";
import ModalTypes from "../../../../components/modal/ModalTypes";
import Segments from "../../../../components/navigation/segments/Segments";
import WizardStepsManager from "../../../../utils/WizardStepsManager";
import * as packDetailsActions from "../state/actions/packDetailsActions";
import * as packEntityStateActions from "../state/actions/packEntityStateActions";
import RtnEventsEmitter from "../../../Application/services/realTimeNotification/rtnEventsEmitter";
import * as rtnEvents from "../../../Application/services/realTimeNotification/events/library/libraryEvents";
import Configure from "../PackDetails/Configure/Configure";
import PackContent from "../PackDetails/Content/PackContent";
import EditPackSubheaderContainer from "./EditPackSubheaderContainer";
import { IModalManager } from "../../../../interfaces/IModalManager";
import ModalManager from "../../../../utils/ModalManager";
import RevertConfirmationModalTs from "../../../../components/modal/RevertConfirmationModalTs";
import { DeleteLinkButton } from "../../../../components/buttons/linkButtons";
import packsDataService from "../services/packsDataService";
import backgroundTask from "../../../BackgroundTasks/backgroundTask";
import * as backgroundTasksActions from "../../../../features/BackgroundTasks/state/backgroundTasksActions";
import * as notificationsActions from "../../../../features/Notifications/state/notificationsActions";
import PackPerformance from "../PackDetails/Performance/PackPerformance";
import { bindAction } from "../../../../interfaces/redux";
import { resetContext } from "../state/slices/packContentTabContextSlice";
import PackVisibilityTabs from "../PackDetails/Visibility/PackVisibilityTabs";
import DeletePackConfirmationDialog from "../../../../components/packs/deletePackConfirmationDialog/DeletePackConfirmationDialog";
import EditPackDetailsHeader from "./EditPackDetailsHeader";
import { withRouter, WithRouterProps } from "../../../../adapters/withRouter/withRouter";
import PublishPackConfirmationModal from "./publishPackConfirmationModal/PublishPackConfirmationModal";
import { hasFlowsCreatePermissionSelector, hasPacksManagePermissionSelector } from "../state/selectors";
import { shouldShowModal } from "features/Licensing/ContentAssignmentModalLicensingSteps/state/thunks/licensingModalThunk";
import { TriggerableConfirmationModal } from "components";
import Observable from "utils/Observable";
import { reset } from "features/Licensing/ContentAssignmentModalLicensingSteps/state/slices/licensingModalSlice";
import styles from "./editPack.module.scss";

const packsOverviewUrl = `/${RouteNames.licensingPacks}`;

export type EditPackDetailsPropsAll = PropsFromRedux & WithRouterProps;

export class EditPack extends Component<EditPackDetailsPropsAll, PackDetailsState> {
  private readonly pages: Pages;
  private readonly id: number;
  private readonly revertModalManager: IModalManager;
  private readonly deleteModalManager: IModalManager;
  private readonly publishModalManager: IModalManager;

  private stepsManager = new WizardStepsManager();

  constructor(props: EditPackDetailsPropsAll) {
    super(props);
    this.state = {
      isSaving: false,
      modifiedDate: undefined,
      isValid: true,
      isContentValid: false,
      packUsageCount: 0,
      packIdToDelete: 0,
      triggerOpenDeletePackDialog: false,
      itemWasDeleted: false,
      issueLicensePackIds: [],
    };
    this.pages = {
      performance: {
        index: 0,
        segment: {
          label: "Performance",
          to: "performance",
        },
      },
      configure: {
        index: 1,
        segment: {
          label: "Configure",
        },
      },
      content: {
        index: 2,
        segment: {
          to: "content",
          label: "Content",
        },
      },
      visibility: {
        index: 3,
        segment: {
          to: "visibility",
          label: "Visibility",
        },
      },
    };
    this.id = this.getPackId();
    this.revertModalManager = new ModalManager(
      ModalTypes.RevertPack,
      this.handleRevert,
      false,
      this.props.currentUserId,
    );
    this.deleteModalManager = new ModalManager(
      ModalTypes.DeletePack,
      this.handleDelete,
      false,
      this.props.currentUserId,
    );

    this.publishModalManager = new ModalManager(
      ModalTypes.PublishPack,
      this.handlePublish,
      false,
      this.props.currentUserId,
    );
  }

  async componentDidMount() {
    this.props.packDetailsActions.getPack(this.id);
    RtnEventsEmitter.subscribe([rtnEvents.PackPublishSuccess, rtnEvents.PackDiscardSuccess], this.onPublishedEvent);
    RtnEventsEmitter.subscribe([rtnEvents.PackLockSuccess], this.onLockedEvent);
  }

  componentWillUnmount() {
    this.props.resetContentTabContext();
    this.props.resetLicensingModal();
    this.stepsManager.dispose();
    this.revertModalManager.dispose();
    this.deleteModalManager.dispose();
    RtnEventsEmitter.unsubscribe([rtnEvents.PackPublishSuccess, rtnEvents.PackDiscardSuccess], this.onPublishedEvent);
    RtnEventsEmitter.unsubscribe([rtnEvents.PackLockSuccess], this.onLockedEvent);
  }

  onLockedEvent = () => this.props.packDetailsActions.getPack(this.id);

  onPublishedEvent = () => {
    this.props.packDetailsActions.clearPack();
    this.props.navigate(packsOverviewUrl);
  };

  getPackId = () => {
    return Number.parseInt(this.props.params.id);
  };

  onIsValidChange = (isValid: boolean) => {
    this.setState({ isValid });
  };

  onIssueLicensePackIdsChanged = (packIds: number[]) => {
    this.setState({ issueLicensePackIds: packIds });
  };

  onPublishWithRemovedItemsOberver = new Observable();

  handlePublish = () => {
    this.props.packEntityStateActions.publishDraftPackEntity(this.id, this.state.issueLicensePackIds);
  };

  handlePublishModal = async () => {
    const { pack } = this.props;
    const shouldShowModal = await this.props.shouldShowLicensingModal(pack.id);
    const skipDeletePackItemsStep = !(
      (pack.canRemoveItems && this.state.itemWasDeleted) ||
      pack.hasItemsRemoveFromPublished
    );

    if (pack.hasBeenPublished && shouldShowModal) {
      this.publishModalManager.execute();
    } else if (!skipDeletePackItemsStep) {
      this.onPublishWithRemovedItemsOberver.notify(() =>
        this.props.packEntityStateActions.publishDraftPackEntity(this.id, this.state.issueLicensePackIds),
      );
    } else {
      this.props.packEntityStateActions.publishDraftPackEntity(this.id, this.state.issueLicensePackIds);
    }
  };

  handleClose = () => {
    this.props.navigate(packsOverviewUrl);
  };

  handleEdit = () => {
    this.props.packEntityStateActions.fetchDraftPackEntity(this.id);
    this.props.packDetailsActions.getPackContentWithDetails(this.id);
  };

  handleRevert = () => {
    this.props.packEntityStateActions.fetchDiscardPackEntity(this.id);
  };

  handleDelete = () => {
    this.deletePack(this.id);
  };

  deletePack = async (id: number) => {
    const params = {
      id: "DeletePack",
      title: `Deletion of pack`,
      getMessageIds: async () => {
        const { data } = await packsDataService.deletePack(id);
        return [data];
      },
      onCompleted: this.handleClose,
      successTransientMessage: `Pack has been deleted!`,
      failureTransientMessage: `Pack delete failed!`,
    };

    const {
      backgroundTasksActions: { addOperationV1 },
      notificationsActions: { sendTransientNotification },
    } = this.props;

    await backgroundTask.updateEntity(params, {
      addOperation: addOperationV1,
      sendTransientNotification,
    });
  };

  onCancelPackDelete = () => {
    this.setState({
      packIdToDelete: 0,
      triggerOpenDeletePackDialog: false,
    });
  };

  onPackItemDelete = () => {
    this.setState({
      itemWasDeleted: true,
    });
  };

  onContinuePackDelete = async () => {
    this.setState({
      triggerOpenDeletePackDialog: false,
    });

    const { packIdToDelete } = this.state;

    const params = {
      id: "DeletePack",
      title: `Deletion of pack`,
      getMessageIds: async () => {
        const { data } = await packsDataService.deletePack(packIdToDelete);
        return [data];
      },
      onCompleted: this.handleClose,
      successTransientMessage: `Pack was deleted!`,
      failureTransientMessage: `Pack delete failed!`,
    };

    const {
      backgroundTasksActions: { addOperationV1 },
      notificationsActions: { sendTransientNotification },
    } = this.props;

    await backgroundTask.updateEntity(params, {
      addOperation: addOperationV1,
      sendTransientNotification,
    });
  };

  handleDeleteClick = async () => {
    let usageCount = await packsDataService.getPackUsageCount(this.id);
    this.setState({
      packUsageCount: usageCount,
      packIdToDelete: this.id,
      triggerOpenDeletePackDialog: true,
    });
  };

  isPrivatePack = () => !this.props.hasPacksManagePermissions && this.props.hasFlowsCreatePermissions;

  renderActionButtons = () => [<DeleteLinkButton key={1} onClick={this.handleDeleteClick} isDisabled={false} />];

  render() {
    const { pack, isEntityCommandInProgress } = this.props;
    const { performance, configure, content, visibility } = this.pages;
    const { isValid } = this.state;
    const disabled = pack.isDraft === false;
    const skipDeletePackItemsStep = !(
      (pack.canRemoveItems && this.state.itemWasDeleted) ||
      pack.hasItemsRemoveFromPublished
    );
    const packContextItems = {
      type: "pack",
      id: pack.id.toString(),
      title: pack.title,
      thumbnailUrl: pack.thumbnailImageUrl,
      skipDeletePackItemsStep: skipDeletePackItemsStep,
    };
    return (
      <div className="nested-content scrollable-content">
        <EditPackDetailsHeader
          pack={pack}
          packsOverviewUrl={packsOverviewUrl}
          isEntityCommandInProgress={isEntityCommandInProgress}
          isValid={isValid}
          handlePublish={this.handlePublishModal}
          handleClose={this.handleClose}
          handleEdit={this.handleEdit}
          revertModalManager={this.revertModalManager}
          renderActionButtons={this.renderActionButtons}
        ></EditPackDetailsHeader>
        <RevertConfirmationModalTs modalManager={this.revertModalManager} />
        <PublishPackConfirmationModal
          modalManager={this.publishModalManager}
          pack={packContextItems}
          setIssueLicensePackIds={this.onIssueLicensePackIdsChanged}
        />
        <DeletePackConfirmationDialog
          packUsageCount={this.state.packUsageCount}
          triggerOpen={this.state.triggerOpenDeletePackDialog}
          onCancel={this.onCancelPackDelete}
          onContinue={this.onContinuePackDelete}
        />
        <TriggerableConfirmationModal
          title="Remove and Publish?"
          content="You are removing content from a Pack that is published. Those who purchased Packs may be affected and any Performance data associated with the Pack will be lost. Do you want to remove this content and publish anyway?"
          confirmButtonLabel="Confirm"
          onTriggerModalObserver={this.onPublishWithRemovedItemsOberver}
          size={ModalSizes.large}
          styles={{ content: styles["delete-items-modal"] }}
        ></TriggerableConfirmationModal>
        <EditPackSubheaderContainer
          publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!pack.isDraft)}
          isUpdateInProgress={pack.isDraft}
          lastModifiedDateTime={pack.dateModified}
        >
          <Segments to={`/${RouteNames.licensingPacks}/${this.id}`}>
            <Segments.Segment {...performance.segment} />
            <Segments.Segment {...configure.segment} />
            <Segments.Segment disabled={!isValid} {...content.segment} />
            {!this.isPrivatePack() && <Segments.Segment disabled={!isValid} {...visibility.segment} />}
          </Segments>
        </EditPackSubheaderContainer>
        <Dimmer active={this.props.isLoading || this.props.isShouldShowModalLoading} inverted>
          <Loader />
        </Dimmer>
        <Routes>
          <Route path={performance.segment.to} element={<PackPerformance />} />
          <Route
            path="/"
            element={
              <div className="edit-form scrollable-content">
                <Configure
                  entityId={this.id}
                  key={`${this.props.pack.id}-${this.props.pack.isDraft}`}
                  acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, configure.index)}
                  onIsValidChange={this.onIsValidChange}
                  disabled={disabled}
                  isPrivatePack={this.isPrivatePack()}
                />
              </div>
            }
          />
          <Route
            path={content.segment.to}
            element={<PackContent id={this.id} onPackItemDelete={this.onPackItemDelete} />}
          />
          {!this.isPrivatePack() && (
            <Route
              path={visibility.segment.to}
              element={
                <div className={styles["visibility-step-content"]}>
                  <PackVisibilityTabs packId={this.id} isReadOnlyMode={!pack.isDraft} />
                </div>
              }
            />
          )}
          <Route path="*" element={<Navigate to="../" replace />} />
        </Routes>
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  pack: state.packs.packDetailsReducer.pack,
  currentUserId: state.userProfile.id,
  isLoading: state.packs.packDetailsReducer.isLoading,
  isLoaded: state.packs.packDetailsReducer.isPackLoaded,
  isEntityCommandInProgress: state.packs.packEntityStateReducer.isEntityCommandInProgress,
  hasPacksManagePermissions: hasPacksManagePermissionSelector(state),
  hasFlowsCreatePermissions: hasFlowsCreatePermissionSelector(state),
  isShouldShowModalLoading: state.licensing.licensingModal.isLoading,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  packDetailsActions: bindActionCreators(packDetailsActions, dispatch),
  packEntityStateActions: bindActionCreators(packEntityStateActions, dispatch),
  backgroundTasksActions: bindActionCreators(backgroundTasksActions, dispatch),
  notificationsActions: bindActionCreators(notificationsActions, dispatch),
  resetContentTabContext: bindAction(resetContext, dispatch),
  shouldShowLicensingModal: bindAction(shouldShowModal, dispatch),
  resetLicensingModal: bindAction(reset, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(EditPack));
