import { useCallback, useEffect, useMemo, useState } from "react";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import cn from "classnames";
import { Tabs } from "components";
import { PeopleOutreach } from "./PeopleOutreach";
import { getPeopleActivityExport, getPeopleInteractionExport, getPeopleOutreachExport } from "../actions/peopleActions";
import { ReportingExport } from "components/reportingExport/ReportingExport";
import { ReportingFilter } from "components/reportingFilter/ReportingFilter";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import {
  ChartWrapper,
  GroupedBarChart,
  GroupedBarProps,
  LineChart,
  LinechartProps,
  getDateFormatByCount,
} from "components/charts";

import { usePerformanceBreadcrumbs } from "features/Library/Common/Hooks/usePerformanceBreadcrumbs";
import {
  createDateRange,
  defaultDateFilter,
  getFormattedTimeStringFromPeriod,
  lineChartFactory,
  noData,
  opensColor,
  sendsColor,
  validLineData,
} from "features/Library/Common/utils/performanceUtils";
import { sharedAccountReportingLineProps } from "features/Reporting/Content/shared";
import { sharedGoogleReportingBarProps } from "features/Reporting/Google/utils/shared";
import {
  usePeopleActivityDrilldownQuery,
  usePeopleInteractionTableQuery,
  usePeopleOutreachTableQuery,
} from "../queries";

import { useGoogleTooltipFormats } from "features/Reporting/Google/utils/useGoogleTooltipFormats";
import { useChartPeriodMeasure } from "hooks/useChartPeriodMeasure";

import "features/Library/Common/utils/performanceSCSSUtils.scss";
import "../peopleStyles.scss";
import { PerformancePeriodQueryContext } from "features/Library/Common/models";
import { peopleReportingService } from "features/Reporting/services/peopleReportingService";
import moment from "moment";
import { PeopleActivityDrilldown } from "./PeopleActivityDrilldown";
import { RootState, AppDispatch } from "features/Application/globaltypes/redux";
import {
  selectExportInfo,
  selectExporting,
  selectHasDataToExport,
  setHasDataToExport,
  setExportAction,
  reset,
} from "features/Reporting/state/export/exportSlice";
import { bindAction } from "interfaces";
import { connect, ConnectedProps } from "react-redux";
import { UserInteraction } from "./UserInteraction";

const colorRange = [opensColor, sendsColor];

const queryHasData = (result: UseQueryResult<unknown[]>) => {
  return result.isSuccess && result.data.length > 0;
};

const getLineChart = async ({
  queryKey,
}: PerformancePeriodQueryContext): Promise<{
  lineProps: LinechartProps;
  barProps: GroupedBarProps<{ userInteraction: number; platformOutreach: number; }>;
}> => {
  const { data } = await peopleReportingService.activity.getLineChart({ ...queryKey[1], period: queryKey[2] });
  const factory = lineChartFactory(data);

  const barData = data.map((d) => ({
    group: moment(d.Date, "YYYY-MM-DD").format("YYYY-MM-DD"),
    userInteraction: d.PlatformInteraction,
    platformOutreach: d.PlatformOutreach,
  }));

  return {
    lineProps: {
      ...sharedAccountReportingLineProps,
      xData: [factory.Date, factory.Date],
      yData: [factory.PlatformOutreach, factory.PlatformInteraction],
      xFormatterFunc: getDateFormatByCount(factory?.Date?.length),
      colorRange,
      tooltipFormatter: (_x, y) => y.toLocaleString(),
    },
    barProps: {
      ...sharedGoogleReportingBarProps,
      data: barData,
      order: ["platformOutreach", "userInteraction"],
      colorRange,
    },
  };
};
const lineWrapperProps = {
  titles: ["Activity - Line Chart", "Activity - Bar Chart"],
  showLegend: true,
  legendLabels: ["Platform Outreach", "User Interactions"],
  colorRange,
};

type Categories = "outreach" | "interaction";

export type Props = PropsFromRedux;

export const PeopleActivityReport = ({
  exportInfo,
  exporting,
  hasDataToExport,
  getPeopleActivityExport,
  getPeopleInteractionExport,
  getPeopleOutreachExport,
  setExport,
  setIsExportEnabled,
  resetExport,
}: Props) => {
  const [dateFilter, setDateFilter] = useState(defaultDateFilter());
  const [chartState, setChartState] = useState("Activity - Line Chart");
  const [selectedCategory, setSelectedCategory] = useState<Categories>();
  const [selectedType, setSelectedType] = useState("");
  const dateRange = useMemo(
    () => createDateRange(dateFilter.dateFrom, dateFilter.dateTo),
    [dateFilter.dateFrom, dateFilter.dateTo],
  );

  const { domElements, registerBreadcrumb, hasBreadcrumbsToDisplay } = usePerformanceBreadcrumbs();

  const [chartPeriod, measureRef] = useChartPeriodMeasure(
    dateRange,
    sharedAccountReportingLineProps.margins!.left + sharedAccountReportingLineProps.margins!.right,
  );

  const { groupedBarFormatter } = useGoogleTooltipFormats(chartPeriod, dateRange);

  const lineChartQuery = useQuery({
    queryFn: getLineChart,
    queryKey: ["people activity line chart", dateFilter, chartPeriod],
    enabled: chartPeriod !== "UNSET",
  });

  const handleCategorySelection = useCallback(
    (text: string, category: "outreach" | "interaction") => {
      registerBreadcrumb({
        text,
        action: () => {
          setSelectedType("");
          setSelectedCategory(undefined);
        },
      });
      setSelectedType(text);
      setSelectedCategory(category);
    },
    [registerBreadcrumb],
  );

  // Setting appropriate export method
  useEffect(() => {
    if (selectedCategory) {
      setExport({
        // @ts-ignore
        method: selectedCategory === "interaction" ? getPeopleInteractionExport : getPeopleOutreachExport,
        args: [{ ...dateFilter, type: selectedType }],
      });
    } else {
      setExport({
        // @ts-ignore
        method: getPeopleActivityExport,
        args: [dateFilter],
      });
    }
  }, [
    dateFilter,
    getPeopleActivityExport,
    getPeopleInteractionExport,
    getPeopleOutreachExport,
    selectedCategory,
    selectedType,
    setExport,
  ]);

  // Determining export button visiblity
  const peopleInteractionTableQuery = usePeopleInteractionTableQuery(dateFilter);
  const peopleOutreachTableQuery = usePeopleOutreachTableQuery(dateFilter);
  const detailsQuery = usePeopleActivityDrilldownQuery({ dateFilter, selectedType, selectedCategory });

  useEffect(() => {
    if (selectedCategory) {
      setIsExportEnabled(queryHasData(detailsQuery));
    } else {
      setIsExportEnabled(queryHasData(peopleInteractionTableQuery) || queryHasData(peopleOutreachTableQuery));
    }
  }, [detailsQuery, peopleInteractionTableQuery, peopleOutreachTableQuery, selectedCategory, setIsExportEnabled]);

  useEffect(() => {
    return () => resetExport();
  }, [resetExport]);

  const handleExportClick = useCallback(() => {
    exportInfo?.method(...exportInfo.args);
  }, [exportInfo]);

  const activitySection = useMemo(() => {
    if (!lineChartQuery.data) return null;

    return chartState === "Activity - Line Chart" ? (
      <LineChart {...lineChartQuery.data.lineProps} />
    ) : (
      <GroupedBarChart
        {...lineChartQuery.data.barProps}
        tooltipFormatter={groupedBarFormatter}
        xTickFormatter={(d) => getFormattedTimeStringFromPeriod(d.toString(), chartPeriod, dateRange)}
      />
    );
  }, [chartState, lineChartQuery.data, chartPeriod, dateRange, groupedBarFormatter]);

  return (
    <div>
      <div className="performanceRoot">
        <div className={cn("performanceHeader", "peopleActivityHeader")}>
          {hasBreadcrumbsToDisplay ? (
            <div>{domElements}</div>
          ) : (
            <div className="performanceHeader">
              <h2 className="performanceTitle">Summary</h2>
              <ReportingFilter currentFilter={dateFilter} callback={setDateFilter} includeAccounts={false} />
            </div>
          )}
          <ReportingExport onClick={handleExportClick} currentlyExporting={exporting} disabled={!hasDataToExport} />
        </div>

        {selectedCategory ? (
          <PeopleActivityDrilldown
            dateFilter={dateFilter}
            selectedType={selectedType}
            selectedCategory={selectedCategory}
          />
        ) : (
          <>
            <div className="lineChartContainer" ref={measureRef}>
              <ChartWrapper {...lineWrapperProps} onChange={setChartState}>
                <RequestStatusRenderer state={lineChartQuery.status}>
                  {lineChartQuery.isSuccess && validLineData(lineChartQuery.data.lineProps.yData)
                    ? activitySection
                    : noData(dateFilter)}
                </RequestStatusRenderer>
              </ChartWrapper>
            </div>
            <Tabs>
              <Tabs.Pane label="Platform Outreach" id="0">
                <PeopleOutreach
                  dateFilter={dateFilter}
                  setSelectedDrilldown={(type) => handleCategorySelection(type, "outreach")}
                />
              </Tabs.Pane>
              <Tabs.Pane label="User Interactions" id="1">
                <UserInteraction
                  dateFilter={dateFilter}
                  setSelectedDrilldown={(type) => handleCategorySelection(type, "interaction")}
                />
              </Tabs.Pane>
            </Tabs>
          </>
        )}
      </div>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    exportInfo: selectExportInfo(state),
    exporting: selectExporting(state),
    hasDataToExport: selectHasDataToExport(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    getPeopleOutreachExport: bindAction(getPeopleOutreachExport, dispatch),
    getPeopleInteractionExport: bindAction(getPeopleInteractionExport, dispatch),
    getPeopleActivityExport: bindAction(getPeopleActivityExport, dispatch),
    setIsExportEnabled: bindAction(setHasDataToExport, dispatch),
    setExport: bindAction(setExportAction, dispatch),
    resetExport: bindAction(reset, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PeopleActivityReport);
