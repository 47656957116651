import { once } from "lodash";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import accountsReducer from "./features/Accounts/state/accountsReducer";
import { packsReducer } from "./features/Licensing/Packs/state/reducers/packsReducer";
import { libraryReducer } from "./features/Library";
import { vendorsReducer } from "./features/Vendors";
import peopleReducer from "./features/People/state/peopleReducer";
import { reportingReducer } from "./features/Reporting";
import { sidebarReducer } from "./features/Sidebar";
import { wizardWrapperReducer } from "./features/WizardWrapper";
import { backgroundTasksReducer } from "./features/BackgroundTasks/state/backgroundTasksReducer";
import { operationMiddleware } from "./features/BackgroundTasks/events/operationMiddleware";
import userProfileReducer from "./features/Application/slices/userProfileSlice";
import generalLoaderReducer from "./features/Application/reducers/generalLoaderReducer";
import assignmentModalLicensingReducers from "./features/Licensing/ContentAssignmentModalLicensingSteps/state/reducers/assignmentModalLicensingReducers";
import { systemNotificationsReducer } from "./features/SystemNotifications/state/reducers/notifyStepReducers";
import asyncOperationReducer from "./features/Application/slices/asyncOperationSlice";

// draft reducer for signalR notificatios
import { notificationsReducer } from "./features/Notifications/state/notificationsReducer";
import appError from "./features/Application/slices/appErrorSlice";

export const RootReducer = combineReducers({
  accounts: accountsReducer,
  packs: packsReducer,
  licensing: assignmentModalLicensingReducers,
  library: libraryReducer,
  vendors: vendorsReducer,
  people: peopleReducer,
  reporting: reportingReducer,
  notifications: notificationsReducer,
  wizard: wizardWrapperReducer,
  sidebar: sidebarReducer,
  userProfile: userProfileReducer,
  backgroundTasks: backgroundTasksReducer,
  generalLoader: generalLoaderReducer,
  systemNotifications: systemNotificationsReducer,
  asyncOperations: asyncOperationReducer,
  appError: appError,
});

export const initializeStore = (initialState?: DeepPartial<ReturnType<typeof RootReducer>>) => {
  const store = configureStore({
    preloadedState: initialState as any,
    reducer: RootReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredPaths: ["reporting.export"],
          ignoredActions: ["REPORTING/EXPORT/setExportAction"],
        },
      }).concat(operationMiddleware),
  });

  return store;
};

const getStore = once(initializeStore);

export default getStore;
