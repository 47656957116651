import { bindActionCreators } from "@reduxjs/toolkit";
import { useEffect, useMemo, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";

import { AppDispatch, RootState } from "../../../features/Application/globaltypes/redux";
import dataService from "../../../features/Application/services/dataServices/dataService";
import { initialNotifyConfigDefault } from "../../../features/SystemNotifications/config";
import {
  setInitialNotifySettings,
  setTeamsInitialNotifySettings,
  updateForm,
  updateTeamsForm,
} from "../../../features/SystemNotifications/state/slices/notifyStepSlice";
import { bindAction, NotifyStepSettings } from "../../../interfaces";
import InfoBanner from "../../banners/InfoBanner";
import FormFooter from "../../forms/FormFooter";
import PreventTransitionPrompt from "../../preventTransition/PreventTransitionPrompt";
import { INotifyByEmailController } from "../notifyByEmailController";
import NotifyWizardStep from "../NotifyWizardStep/NotifyWizardStep";
import { createEmailEditorOptions } from "../utils/createEmailEditorOptions";
import { templateUtils } from "../utils/templateUtils";
import { NotifyStepSwitch } from "../NotifyStepSwitch";
import { CommunicationChannels, TemplateTypes } from "enums";
import { NotifyConfigEntry, NotifySettings } from "features/SystemNotifications/types";
import { getNotificationSettings, getUpdatedNoficationOptionsClone } from "utils/notificationsUtils";

export interface EditNotifySettingsProps extends PropsFromRedux {
  templateType: TemplateTypes;
  notificationId: string;
  infoBannerContent?: string | React.ReactElement;
  switchLabelText?: string;
  disabled?: boolean;
  onSaved?(settings: NotifyStepSettings): void;
  saveComplete?: () => void;
}

const clearWhitespace = (value: string) => value.replace(/\s/g, "");

export const EditNotifySettings = ({
  notifyStepState,
  setNotifySettings,
  setTeamsNotifySettings,
  accountId,
  notificationId,
  templateType,
  infoBannerContent,
  switchLabelText,
  onSaved,
  saveComplete,
  onInitialNotifySettingsChanged,
  onInitialNotifySettingsTeamsChanged,
  disabled = false,
}: EditNotifySettingsProps) => {
  const [isValid, setIsValid] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isToggleDirty, setIsToggleDirty] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isEmailDirty, setIsEmailDirty] = useState(false);
  const [notifyConfig, setNotifyConfig] = useState({
    ...initialNotifyConfigDefault,
    shouldNotifyUsers: notifyStepState.initialShouldNotifyUsers,
    communicationChannel: notifyStepState.initialCommunicationChannel,
  });

  const notifyByEmailControllerRef = useRef<INotifyByEmailController | undefined>(undefined);

  useEffect(() => {
    return () => {
      notifyByEmailControllerRef.current?.resetState();
    };
  }, []);

  useEffect(() => {
    if (isLoaded) {
      const isDirty =
        notifyStepState.initialForm.subject !== notifyStepState.form.subject ||
        notifyStepState.initialForm.previewText !== notifyStepState.form.previewText;

      setIsFormDirty(isDirty);
    }
  }, [
    isLoaded,
    notifyStepState.form.previewText,
    notifyStepState.form.subject,
    notifyStepState.initialForm.previewText,
    notifyStepState.initialForm.subject,
  ]);

  useEffect(() => {
    const isDirty =
      notifyStepState.initialCommunicationChannel !== notifyConfig.communicationChannel ||
      notifyStepState.initialShouldNotifyUsers !== notifyConfig.shouldNotifyUsers;
    setIsToggleDirty(isDirty);
  }, [
    notifyConfig.communicationChannel,
    notifyStepState.initialCommunicationChannel,
    notifyStepState.initialShouldNotifyUsers,
    notifyConfig.shouldNotifyUsers,
  ]);

  useEffect(() => {
    setNotifyConfig((config) => ({
      ...config,
      shouldNotifyUsers: notifyStepState.initialShouldNotifyUsers,
      communicationChannel: notifyStepState.initialCommunicationChannel,
    }));
  }, [notifyStepState.initialShouldNotifyUsers, notifyStepState.initialCommunicationChannel]);

  /* istanbul ignore next */
  const onNotifyStepLoaded = (controller: INotifyByEmailController) => {
    // @ts-ignore
    notifyByEmailControllerRef.current = controller;
    setIsLoaded(true);
  };

  /* istanbul ignore next */
  const onNotifyStepCancel = () => {
    if (notifyConfig.communicationChannel === CommunicationChannels.Email) {
      if (isEmailDirty) {
        const options = createEmailEditorOptions({
          template: notifyStepState.updatedTemplate || notifyStepState.initialTemplate,
          accountId: accountId,
          onDataChanged: onTemplateChange,
        });
        notifyByEmailControllerRef.current?.reInitializeEmailEditor?.(options);
        setIsEmailDirty(false);
      }
    }
    if (isFormDirty) {
      setNotifySettings(notifyStepState.initialForm);
      setTeamsNotifySettings(notifyStepState.initialTeamsForm);
    }
    setNotifyConfig((config) => ({
      ...config,
      shouldNotifyUsers: notifyStepState.initialShouldNotifyUsers,
      communicationChannel: notifyStepState.initialCommunicationChannel,
    }));
  };

  /* istanbul ignore next */
  const onTemplateChange = async () => {
    const notifyByEmailController = notifyByEmailControllerRef.current;
    const template = await notifyByEmailController?.getTemplate();
    const initial = clearWhitespace(notifyStepState.initialTemplate);
    const actual = clearWhitespace(templateUtils.extractTemplateFromLayout(template!));

    const isDirty = initial !== actual;

    setIsEmailDirty(isDirty);
  };

  const handleNotifyStepOnValidChange = (value: boolean) => {
    setIsValid(value);
  };

  const handleNotifyStepOnDirtyChange = (value: boolean) => {
    setIsFormDirty(value);
  };

  const disableControls = useMemo(
    () => !isToggleDirty && !isFormDirty && !isEmailDirty && !(notifyConfig.shouldNotifyUsers && !isValid),
    [isToggleDirty, isFormDirty, isEmailDirty, notifyConfig.shouldNotifyUsers, isValid],
  );

  const handleNotifyByEmailSave = async (controller: INotifyByEmailController) => {
    const { template } = await controller.saveChanges();
    const notifySettings = await controller.getSettings(
      notifyConfig.shouldNotifyUsers,
      notifyConfig.communicationChannel,
      disabled,
    );
    notifySettings.id = notificationId;
    await dataService.systemNotifications.settings.saveSettings(notifySettings);

    onInitialNotifySettingsChanged({
      template,
      subject: notifySettings.subject,
      previewText: notifySettings.previewText,
      shouldNotifyUsers: notifyConfig.shouldNotifyUsers,
      communicationChannel: notifyConfig.communicationChannel,
    });

    onSaved?.(notifySettings);
  };

  /* istanbul ignore next */
  const onNotifyStepSave = async () => {
    setIsSaving(true);

    const notifyByEmailController = notifyByEmailControllerRef.current!;
    if (notifyConfig.communicationChannel === CommunicationChannels.Email) {
      await handleNotifyByEmailSave(notifyByEmailController);
    } else {
      const notifySettings = getNotificationSettings(
        notifyStepState,
        notificationId,
        notifyConfig.shouldNotifyUsers,
        notifyConfig.communicationChannel,
      );
      onInitialNotifySettingsTeamsChanged({
        headline: notifySettings.headline,
        body: notifySettings.body,
        callToAction: notifySettings.callToAction,
        destinationUrl: notifySettings.destinationUrl,
        imageUrl: notifySettings.imageUrl,
        shouldNotifyUsers: notifyConfig.shouldNotifyUsers,
        communicationChannel: notifyConfig.communicationChannel,
      });
      onSaved?.(notifySettings);
      await dataService.systemNotifications.settings.saveSettings(notifySettings);
    }

    saveComplete?.();
    setIsSaving(false);
    setIsFormDirty(false);
    setIsEmailDirty(false);
    setIsToggleDirty(false);
  };

  const onNotifyConfigChange = (configEntry: NotifyConfigEntry<NotifySettings>) => {
    const updatedConfig = getUpdatedNoficationOptionsClone(notifyConfig, configEntry);
    setNotifyConfig(updatedConfig);
  };

  return (
    <>
      {infoBannerContent && <InfoBanner>{infoBannerContent}</InfoBanner>}
      <PreventTransitionPrompt
        when={!disableControls}
        title="Exit Without Saving?"
        message="Are you sure you want to exit without saving? All information entered will be lost."
      />
      <div className="stretch scrollable-content edit-form">
        <NotifyWizardStep
          templateType={templateType}
          onLoaded={onNotifyStepLoaded}
          onValidChange={handleNotifyStepOnValidChange}
          isDisabled={disabled || isSaving}
          shouldNotify={notifyConfig.shouldNotifyUsers}
          communicationChannel={notifyConfig.communicationChannel}
          renderSwitch={(switchProps) => (
            <NotifyStepSwitch
              config={notifyConfig}
              onNotifyConfigChange={onNotifyConfigChange}
              switchProps={switchProps}
              labelText={switchLabelText}
            />
          )}
          onTemplateChange={onTemplateChange}
          notificationId={notificationId}
          onDirtyChange={handleNotifyStepOnDirtyChange}
        />
      </div>
      <FormFooter
        onCancel={onNotifyStepCancel}
        onSave={onNotifyStepSave}
        isCancelBtnDisabled={disableControls}
        isSaveBtnDisabled={
          disableControls ||
          (notifyConfig.communicationChannel === CommunicationChannels.Email && !isLoaded) ||
          !notifyStepState.isTemplateLoaded
        }
        isSaving={isSaving}
      />
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    notifyStepState: state.systemNotifications.notifyStep,
    accountId: state.userProfile.accountId,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setNotifySettings: bindActionCreators(updateForm, dispatch),
  setTeamsNotifySettings: bindActionCreators(updateTeamsForm, dispatch),
  onInitialNotifySettingsChanged: bindAction(setInitialNotifySettings, dispatch),
  onInitialNotifySettingsTeamsChanged: bindAction(setTeamsInitialNotifySettings, dispatch),
});

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditNotifySettings);
