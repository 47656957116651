import axios, { AxiosResponse } from "axios";
import { isEmpty, isString } from "lodash";
import qs from "qs";
import StatusCode from "../../../../enums/httpStatusCodes";
import { ConfigApi, DefaultConfigApi, EditableUser, NotifyStepSettings, User } from "../../../../interfaces";
import { IUsersDataService } from "./IUsersDataService";

import {
  GetGroupsConfigApi,
  GetPeopleContentOptions,
  GetPeopleFlowsOptions,
  GetUsersConfigApi,
  GetUsersConfigApiV2,
  ICreateUserModel,
  IUserModel,
  StartUsersImportResponse,
  UserInfo,
  UserPreviewMappingPayload,
  v2FilterMap,
} from "../../../People/types";

import { flowFilterTypes } from "features/Library/Flows/types/models";
import { videoFilterTypes } from "features/Library/Videos/types/models";
import { formatFilters } from "utils/filterMapUtils";
import environmentConfig from "../../../../configuration/environmentConfig";
import { TemplateTypes } from "../../../../enums";
import RolePermissions, { toFeatureRight } from "../../../../enums/rolePermissions";
import { GetPagedDataRequestV2, WithPagedDataV2 } from "../../../../interfaces/getPagedDataRequest";
import http from "../../../../lib/http";
import { FiltersMap } from "../../../../utils/filterUtils";
import {
  Filters,
  formatFiltersV2api,
  mapToV2Filters,
  serializeArrayAndFilterNullable,
} from "../../../../utils/queryUtils";
import { escapeTerm } from "../../../../utils/searchUtils";
import {
  UserPerformanceRequestFilterParams,
  UserPerformanceRequestFilterWithPeriodParams,
} from "../../../Library/Common/models";
import { GetFlowsRequest } from "../../../Library/Flows/types/requests";
import { PeopleAvailableAssessment, PeopleAvailablePdf } from "../../../People/ContentAssignments/models";
import KnownHttpHeaders from "../knownHttpHeaders";

let departmentsAbortController = new AbortController();
let jobTitlesAbortController = new AbortController();
let officeLocationsAbortController = new AbortController();
let managersAbortController = new AbortController();
let licensesAbortController = new AbortController();

export const usersDataService: IUsersDataService = {
  userImportTemplateUrl: `${environmentConfig.apiUrl}/api/users/import/template`,

  async getImportFileTemplate() {
    await axios({
      url: "/api/users/import/template",
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "users-import-template.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  },

  async anyUsersExists(email: string) {
    const config: DefaultConfigApi = {
      params: {
        filter: `email eq ${email}`,
        top: 1,
      },
    };

    const { headers } = await axios.head("/api/users", config);
    return parseInt(headers[KnownHttpHeaders.RecordsCount]) !== 0;
  },

  async isUserEmailUnique(email: string, userId?: number) {
    const config = {
      params: {
        userId,
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    };

    const encodedEmail = encodeURIComponent(email);
    try {
      await axios.head(`/api/users/emails/${encodedEmail}`, config);
      return false;
    } catch (error: any) {
      if (error.response?.status === StatusCode.NotFound) {
        return true;
      }
      throw error;
    }
  },

  async getUsers(skip: number, top: number, orderBy: string, filterParams: Filters, accountId?: number) {
    const config: ConfigApi<GetUsersConfigApi> = {
      params: {
        skip,
        top,
      },
      paramsSerializer: qs.stringify,
    };

    if (accountId) {
      config.params.accountId = accountId;
    }

    if (orderBy) {
      config.params.orderBy = orderBy;
    }

    if (!isEmpty(filterParams) && isString(filterParams)) {
      config.params.filter = filterParams;
    } else {
      config.params = { ...config.params, ...filterParams };
    }

    const { data, headers } = await axios.get<EditableUser[]>("/api/users", config);

    return {
      items: data,
      count: parseInt(headers[KnownHttpHeaders.RecordsCount]),
    };
  },

  async getUsersV2(
    skip: number,
    top: number,
    orderBy: string | null,
    filters: Filters,
    search: string,
    currentUser?: { email: string; id: number },
  ) {
    const [sortBy, sortOrder] = orderBy?.split(" ") || [undefined, undefined];
    const requestConfig = {
      params: {
        skip,
        top,
        sortBy,
        sortOrder,
        term: escapeTerm(search),
        ...formatFiltersV2api(mapToV2Filters(filters, v2FilterMap)),
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    };

    let data: UserInfo[] = [];
    let headerCount: number = 0;

    const omitCurrentUser = (
      draftData: UserInfo[],
      currentUserEmail: string,
      top: number,
      currentUserData: UserInfo | undefined = undefined,
    ) => {
      const filteredDraftData = draftData.filter((user) => user.email !== currentUserEmail);
      if (currentUserData) filteredDraftData.unshift(currentUserData);
      return filteredDraftData.slice(0, top);
    };
    const showCurrentUser =
      currentUser &&
      search === "" &&
      Object.keys(filters).length === 0 &&
      sortBy === "createDate" &&
      sortOrder === "desc";

    if (showCurrentUser) {
      if (!skip) {
        const [{ data: currentUserData }, { data: draftData, headers }] = await Promise.all([
          axios.get<UserInfo>(`/api/users/${currentUser.id}`),
          axios.get<UserInfo[]>("/api/v2/users", requestConfig),
        ]);

        data = omitCurrentUser(draftData, currentUser.email, top, currentUserData);
        headerCount = parseInt(headers[KnownHttpHeaders.RecordsCount]);
      } else {
        const { data: draftData, headers } = await axios.get<UserInfo[]>("/api/v2/users", {
          ...requestConfig,
          params: {
            ...requestConfig.params,
            /* 
            Skip - 1 due to the currentUser being pushed onto the array on page one, yet we only keep 10 of the 11 results (currentUser + 10), in case the currentUser is also in this second endpoint call. Top + 1 in case we find the currentUser again, and have to remove them from the list, we have an extra person to add to it.
          */
            skip: skip - 1,
            top: top + 1,
          },
        });

        data = omitCurrentUser(draftData, currentUser.email, top);
        headerCount = parseInt(headers[KnownHttpHeaders.RecordsCount]);
      }
    } else {
      const { data: draftData, headers } = await axios.get<UserInfo[]>("/api/v2/users", requestConfig);
      data = draftData;
      headerCount = parseInt(headers[KnownHttpHeaders.RecordsCount]);
    }

    return {
      items: data,
      count: headerCount,
    };
  },

  async getUserRoles(userId: number, pagingOptions: any) {
    const config = {
      params: pagingOptions,
      paramsSerializer: serializeArrayAndFilterNullable,
    };

    const { data } = await axios.get(`/api/users/${userId}/roles`, config);

    return {
      items: data.items,
      itemsCount: data.count,
    };
  },

  async getUserInfo(id: number) {
    return axios.get(`/api/users/${id}`);
  },

  async getUserGroups(userId: number, pagingOptions: any) {
    const { skip, top, orderByParams, filter, owners } = pagingOptions;

    const config: GetGroupsConfigApi = {
      params: {
        skip,
        top,
      },
      paramsSerializer: qs.stringify,
    };

    if (!isEmpty(orderByParams)) {
      config.params.orderBy = orderByParams;
    }

    if (!isEmpty(filter)) {
      config.params.filter = filter;
    }

    if (!isEmpty(owners)) {
      config.params.owners = owners;
    }

    const { data, headers } = await axios.get(`/api/users/${userId}/groups`, config);

    return {
      items: data,
      itemsCount: parseInt(headers[KnownHttpHeaders.RecordsCount]),
    };
  },

  async getUserGroupsAssignments(userIds: number[]) {
    return axios.get("/api/users/groups", {
      params: {
        userIds,
      },
      paramsSerializer: (params) => qs.stringify(params),
    });
  },

  async createUser(userData: ICreateUserModel) {
    return axios.post("/api/users", userData);
  },

  async getUserRolesAssignments(userIds: number[]) {
    return axios.get(`/api/roles/users`, {
      params: {
        userIds,
      },
      paramsSerializer: (params) => qs.stringify(params),
    });
  },

  getUserAvailableFlows<T>(userIds: number[], pagingOptions: WithPagedDataV2<T>) {
    return axios.get("/api/v3/users/available-flows", {
      params: {
        ...pagingOptions,
        ids: userIds,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },

  getUserAvailableFlowsV4<T>(userIds: number[], pagingOptions: WithPagedDataV2<T>) {
    return axios.get("/api/v4/users/available-flows", {
      params: {
        ...pagingOptions,
        id: userIds,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },

  getUserAvailableVideos<T>(userIds: number[], pagingOptions: WithPagedDataV2<T>) {
    const config = {
      params: {
        ...pagingOptions,
        id: userIds,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    };

    return axios.get("/api/v3/users/available-videos", config);
  },

  getUserAvailableSurveys<T>(userIds: number[], pagingOptions: WithPagedDataV2<T>) {
    const config = {
      params: {
        ...pagingOptions,
        id: userIds,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    };

    return axios.get("/api/v2/users/available-surveys", config);
  },

  getUserAvailableAssessments<T>(
    userIds: number[],
    pagingOptions: WithPagedDataV2<T>,
  ): Promise<AxiosResponse<PeopleAvailableAssessment[]>> {
    const config = {
      params: { ...pagingOptions, id: userIds },
      paramsSerializer: serializeArrayAndFilterNullable,
    };
    return axios.get("/api/v2/users/available-assessments", config);
  },

  getUserAvailableEvents<T>(userIds: number[], pagingOptions: WithPagedDataV2<T>) {
    return axios.get("/api/v3/users/available-external-events", {
      params: {
        ...pagingOptions,
        ids: userIds,
        showPurchased: true,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },

  getUserAvailablePdfs<T>(
    userIds: number[],
    pagingOptions: WithPagedDataV2<T>,
  ): Promise<AxiosResponse<PeopleAvailablePdf[]>> {
    const config = {
      params: {
        ...pagingOptions,
        id: userIds,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    };
    return axios.get("/api/users/available-pdfs", config);
  },

  async updateUser(userData: IUserModel) {
    return axios.put(`/api/users/${userData.id}`, userData);
  },

  async getDefaultUserProfile() {
    return axios.get("/api/users/me");
  },

  async getPermissions(accountId?: number) {
    const params = accountId ? { accountId } : null;
    const result = await http.get("/api/me/permissions", { params });
    return result;
  },

  async hasAnyAccountPermission(permission: RolePermissions) {
    const config = {
      params: toFeatureRight(permission),
    };
    const { data } = await axios.get<{ hasPermission: boolean }>("/api/me/permission", config);

    return data.hasPermission;
  },

  async deleteUsers(userIds: number[]) {
    return axios.delete("/api/users", {
      data: { userIds },
    });
  },

  async assignUsersToRoles(userIds: number[], roleIds: number[]) {
    const userToRoleAssignments = [];

    for (const userId of userIds) {
      for (const roleId of roleIds) {
        userToRoleAssignments.push({ userId, roleId });
      }
    }

    return axios.post("/api/users/roles", {
      userToRoleAssignments,
    });
  },

  getUploadedFileColumns(fileName: string) {
    return axios.get(`/api/UsersFileUpload/${fileName}/columns`);
  },

  getUsersImportPreviewData(fileName: string, itemsCount: number, mappedFields: UserPreviewMappingPayload[]) {
    return axios.post(`/api/UsersFileUpload/${fileName}/preview`, {
      previewCount: itemsCount,
      propertyToColumnNameBindings: mappedFields,
    });
  },

  async startUsersImport(
    fileName: string,
    mappedFields: { [key: string]: any },
    notificationSettings?: NotifyStepSettings,
  ) {
    const { data } = await axios.post<StartUsersImportResponse>(`/api/UsersFileUpload/${fileName}/start-import`, {
      propertyToColumnNameBindings: mappedFields,
      notificationSettings: notificationSettings,
    });

    return data;
  },

  async startUsersImportForAccount(
    fileName: string,
    accountId: number,
    mappedFields: { [key: string]: any },
    notificationSettings?: NotifyStepSettings,
  ) {
    const { data } = await axios.post<StartUsersImportResponse>(
      `/api/UsersFileUpload/${fileName}/${accountId}/start-import`,
      {
        propertyToColumnNameBindings: mappedFields,
        notificationSettings: notificationSettings,
      },
    );

    return data;
  },

  async cancelUsersImportTask(body: { [key: string]: any }) {
    return axios.post(`/api/UsersFileUpload/cancel-import`, body);
  },

  async startUsersExport(body: any) {
    return axios.post("/api/users/export", body);
  },

  getUserFlows(userId: number, options: GetPeopleFlowsOptions) {
    const [sortBy, sortOrder] = options.orderBy?.split(" ") || [];
    const request: GetFlowsRequest = {
      top: options.top,
      skip: options.skip,
      sortBy,
      sortOrder,
      term: escapeTerm(options.searchTerm) ?? "",
    };
    const endpoint = `/api/v4/users/${userId}/flows?showPurchased=true`;

    return axios.get(endpoint, {
      params: { ...request, ...formatFilters(options.filters as FiltersMap, flowFilterTypes) },
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },

  getUserContent(userId: number, payload: GetPeopleContentOptions) {
    const { top, skip, filters = {}, sortBy, sortOrder, contentType } = payload;
    const request = {
      top,
      skip,
      sortBy,
      sortOrder,
      contentType,
      term: escapeTerm(payload.searchTerm) ?? "",
      showPurchased: true,
    };

    return axios.get(`/api/users/${userId}/content`, {
      params: { ...request, ...formatFilters(filters, videoFilterTypes) },
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },

  async getUserSigningSecret() {
    return axios.get("/api/users/signing-secret");
  },

  notifyUser(userId: number, notificationType: TemplateTypes, notificationSettings: NotifyStepSettings) {
    const data = { notificationType, subject: notificationSettings.subject, notificationSettings };
    return axios.post(`/api/users/${userId}/notify`, data);
  },

  notifyUserByEmail(
    email: string,
    accountId: number,
    notificationType: TemplateTypes,
    notificationSettings: NotifyStepSettings,
  ) {
    const data = { notificationType, accountId, subject: notificationSettings.subject, notificationSettings };
    return axios.post(`/api/users/${email}/notify`, data);
  },

  async getDepartments() {
    departmentsAbortController.abort();
    departmentsAbortController = new AbortController();
    return axios.get("/api/users/departments", {
      signal: departmentsAbortController.signal,
    });
  },

  async getJobTitles() {
    jobTitlesAbortController.abort();
    jobTitlesAbortController = new AbortController();
    return axios.get("/api/users/jobTitles", {
      signal: jobTitlesAbortController.signal,
    });
  },

  async getOfficeLocations() {
    officeLocationsAbortController.abort();
    officeLocationsAbortController = new AbortController();
    return axios.get("/api/users/officeLocations", {
      signal: officeLocationsAbortController.signal,
    });
  },

  async getManagers() {
    managersAbortController.abort();
    managersAbortController = new AbortController();
    return axios.get("/api/users/managers", {
      signal: managersAbortController.signal,
    });
  },

  getLicenses() {
    licensesAbortController.abort();
    licensesAbortController = new AbortController();
    return axios.get("/api/integrations/msgraph/licenses", {
      signal: licensesAbortController.signal,
    });
  },

  async getAvailableContacts(skip: number, top: number, orderBy: string, filter: Filters, accountId?: number) {
    const config: ConfigApi<GetUsersConfigApi> = {
      params: {
        skip,
        top,
      },
      paramsSerializer: qs.stringify,
    };

    if (accountId) {
      config.params.accountId = accountId;
    }

    if (orderBy) {
      config.params.orderBy = orderBy;
    }

    if (!isEmpty(filter) && isString(filter)) {
      config.params.filter = filter;
    } else {
      config.params = { ...config.params, ...filter };
    }

    const { data, headers } = await axios.get(`/api/contacts/available-users`, {
      ...config,
      paramsSerializer: (params) => qs.stringify(params),
    });

    return {
      items: data,
      count: parseInt(headers[KnownHttpHeaders.RecordsCount]),
    };
  },

  async getAvailableContactsV2(
    skip: number,
    top: number,
    orderBy: string,
    filters: Filters,
    search: string,
    accountId?: number,
  ) {
    const [sortBy, sortOrder] = orderBy?.split(" ") || [undefined, undefined];
    const config: ConfigApi<GetUsersConfigApiV2> = {
      params: {
        skip,
        top,
        term: escapeTerm(search),
        sortBy,
        sortOrder,
        ...formatFiltersV2api(mapToV2Filters(filters, v2FilterMap)),
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    };

    if (accountId) {
      config.params.accountId = accountId;
    }
    const { data, headers } = await axios.get(`/api/v2/contacts/available-users`, config);

    return {
      items: data,
      count: parseInt(headers[KnownHttpHeaders.RecordsCount]),
    };
  },

  getUserLineChart(params: UserPerformanceRequestFilterWithPeriodParams) {
    if (params.period === "UNSET") params.period = "DAY";
    return axios.get("/api/reports/v2/user/linechart", { params });
  },

  getUserActivityByType(params: UserPerformanceRequestFilterParams) {
    return axios.get("/api/reports/v2/user/engagement", { params });
  },

  getUserOutreach(params: UserPerformanceRequestFilterParams) {
    return axios.get("/api/reports/v2/users/outreach/table", { params });
  },

  getUserInteraction(params: UserPerformanceRequestFilterParams) {
    return axios.get("/api/reports/v2/users/interaction/table", { params });
  },

  getUserInteractionDetails(params: UserPerformanceRequestFilterParams) {
    return axios.get("/api/reports/v2/users/interaction/details", { params });
  },

  getUserOutreachDetails(params: UserPerformanceRequestFilterParams) {
    return axios.get("/api/reports/v2/users/outreach/details", { params });
  },

  async getAccountUsers(accountId: number, skip: number, top: number, sortBy?: string, sortOrder?: string) {
    const config: ConfigApi<GetPagedDataRequestV2> = {
      params: {
        skip,
        top,
        sortBy,
        sortOrder,
      },
      paramsSerializer: qs.stringify,
    };

    const { data, headers } = await axios.get<User[]>(`/api/accounts/${accountId}/users`, config);

    return {
      items: data,
      totalCount: parseInt(headers[KnownHttpHeaders.RecordsCount]),
    };
  },

  async getOwnGroups() {
    const config: GetGroupsConfigApi = {
      params: {
        skip: 0,
        top: 100,
      },
      paramsSerializer: qs.stringify,
    };

    const response = await http.get<{ id: number }[]>("/api/me/groups", config);

    if (response.isSuccess) {
      return { ...response, data: response.data.map((x) => x.id) };
    }

    return response;
  },
};

export default usersDataService;
