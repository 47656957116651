import { GroupTemplatePacksConfirmationModal } from "components/assignmentModals/groupTemplatePacksConfirmationModal/GroupTemplatePacksConfirmationModal";
import { AppDispatch } from "features/Application/globaltypes/redux";
import { useObserver } from "hooks/useObserver";
import { IObservable } from "interfaces/IObservable";
import { FC, useEffect, useMemo, useRef } from "react";
import { Observable } from "utils";
import { editPackAccounts } from "../../state/thunks/TemplatePacksThunk";
import { bindAction } from "interfaces";
import { connect, ConnectedProps } from "react-redux";
import { PackMap } from "components/assignmentModals/groupTemplatePacksConfirmationModal/types";
import { PackView } from "../../types/models";
import { PackAccountsContextItem } from "../../../../../components/assignmentModals/groupTemplatePacksConfirmationModal/types";
import { LicenseTypes } from "../../../../../enums";

type Props = PropsFromRedux & {
  onTriggerModalObserver: IObservable<(templateId: number, pack: PackView) => void>;
};

export const EditPackAccountsModal: FC<Props> = (props) => {
  const { onTriggerModalObserver, editPackAccounts } = props;
  const onTriggerPacksConfirmationObserver = useMemo(() => new Observable(), []);
  const packMap = useRef<PackMap>(new Map());

  const [subscribeOnModalTrigger] = useObserver(onTriggerModalObserver);

  const initPackMap = (pack: PackView): PackMap => {
    const map = new Map();
    const item: PackAccountsContextItem = {
      id: pack.id,
      title: pack.title,
      thumbnailImageUrl: pack.thumbnailImageUrl,
      isTrial: false,
      isTrialAllowed: false,
      licenseTypeId: LicenseTypes.CustomDate,
    };
    map.set(pack.id, item);
    return map;
  };

  useEffect(() => {
    const onTriggerModal = async (templateId: number, pack: PackView) => {
      packMap.current = initPackMap(pack);
      onTriggerPacksConfirmationObserver.notify(() => {
        const expirationDate = packMap.current.get(pack.id)?.expirationDate;
        expirationDate && editPackAccounts(templateId, pack.id, expirationDate);
      }, packMap.current);
    };

    return subscribeOnModalTrigger(onTriggerModal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribeOnModalTrigger]);

  return (
    <GroupTemplatePacksConfirmationModal
      isEdit={true}
      isPackEdit={true}
      onTriggerModalObserver={onTriggerPacksConfirmationObserver}
    />
  );
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    editPackAccounts: bindAction(editPackAccounts, dispatch),
  };
};

/* istanbul ignore next */
const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditPackAccountsModal);
